import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import StickyInfo from './StickyInfo';
import { useParams } from 'react-router-dom';
import usePartnerQueries from 'hooks/queries/partner';
import EstimateModal from '../../Common/Modal/EstimateModal';
import ConfirmModal from 'components/Common/Modal/ConfirmModal';

import PartnerInfo from './PartnerInfo';
import ReportList from './ReportList';
import { ORDER_ESTIMATE_TYPE } from 'utils/enum/estimate';
import images from 'libs/images';
import seller_license from './images/seller_license_logo.png';
import seller_insurance from './images/seller_insurance_logo.png';
import useObserver from './useObserver.hook';
import debounce from 'lodash/debounce';

const Container = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;
  padding-top: 100px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    padding-top: 60px;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const Content = styled.div`
  width: calc(100% - 480px);
  margin-bottom: 200px;

  @media screen and (max-width: 1100px) {
    width: calc(100% - 380px);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 75px;
  }
`;

const TabWrap = styled.div`
  margin-top: -70px;
  position: sticky;
  top: 35px;
  background-color: #ffffff;
  display: flex;
  z-index: 300;

  & > p {
    width: 50%;
    text-align: center;
    padding: 100px 15px 10px 15px;
    font-size: 22px;
    font-weight: 700;
    color: #8f8e8e;
    cursor: pointer;
    border-bottom: 2px solid #d9d9d9;

    &.active {
      color: #000000;
      border-bottom: 5px solid #000000;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MoreWrap = styled.div`
  margin-top: 100px;
  text-align: center;

  & > button {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 16px 145px;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin: 50px auto 0 auto;

    & > button {
      padding: 10px 0;
      width: 100%;
      font-size: 12px;
      font-weight: 600;
      box-shadow: none;
      line-height: 20px;
    }
  }
`;

const MobileImageSection = styled.div<{ url: string }>`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
    position: relative;
    padding-bottom: 62%;

    .image_content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url(${(props) => props.url}) no-repeat center center;
      background-size: cover;
    }

    .badge_content {
      position: absolute;
      top: 0;
      right: 20px;
      display: flex;
      gap: 10px;

      .badge {
        width: 52px;
        height: 52px;
        border-radius: 2px;
        text-align: center;

        & > img {
          width: 38px;
          margin-top: 2px;
        }

        & > p {
          text-align: center;
          color: #ffffff;
          margin-top: 5px;
          font-size: 11px;
          line-height: 10px;
          font-weight: 700;
          word-break: break-word;
        }

        &.license {
          background-color: #ff5500;
        }

        &.insurance {
          background-color: #000000;
        }
      }
    }
  }
`;

const PartnerDetail = () => {
  const { id } = useParams();
  const { getPartnerDetailQuery, getPartnerDetailCaseQuery } =
    usePartnerQueries();

  const [isOpenEstimate, setIsOpenEstimate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const [tabType, setTabType] = useState<'info' | 'report'>('info');

  const { serviceRef } = useObserver(setTabType, 'info');
  const { reportRef } = useObserver(setTabType, 'report');

  const { data: partnerDetail } = getPartnerDetailQuery(String(id));
  const {
    data: partnerDetailCase,
    hasNextPage,
    fetchNextPage,
  } = getPartnerDetailCaseQuery(String(id));

  const caseList = useMemo(
    () =>
      partnerDetailCase
        ? partnerDetailCase.pages.flatMap((data) => data.case_list)
        : [],
    [partnerDetailCase]
  );

  const moveHandler = (type: 'info' | 'report') => {
    if (type === 'info') {
      serviceRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    } else if (type === 'report') {
      reportRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    updateTabType(type);
  };

  const updateTabType = debounce((type: 'info' | 'report') => {
    setTabType(type);
  }, 500);

  const callback = () => {
    setIsOpenEstimate(false);
    setIsConfirm(true);
  };

  if (!partnerDetail || !partnerDetailCase) return null;

  const partner = {
    seller_icon: partnerDetail.seller_icon,
    seller_biz_name: partnerDetail.seller_biz_name,
    seller_intro: partnerDetail.seller_intro,
    is_license: partnerDetail.is_license,
    is_insurance: partnerDetail.is_bohum,
  };

  return (
    <Container>
      <MobileImageSection url={caseList[0]?.case_photo || images.img_defult_01}>
        <div className="image_content" />
        <div className="badge_content">
          {partner.is_license === '1' && (
            <div className="badge license">
              <img src={seller_license} alt="seller_license_image" />
              <p>실내건축업면허</p>
            </div>
          )}
          {partner.is_insurance === '1' && (
            <div className="badge insurance">
              <img src={seller_insurance} alt="seller_insurance_image" />
              <p>하자 보증 보험</p>
            </div>
          )}
        </div>
      </MobileImageSection>
      <Box>
        <StickyInfo
          data={partner}
          onClickEstimate={() => setIsOpenEstimate(true)}
        />
        <Content>
          <TabWrap>
            <p
              className={tabType === 'info' ? 'active' : undefined}
              onClick={() => moveHandler('info')}
            >
              업체 정보
            </p>
            <p
              className={tabType === 'report' ? 'active' : undefined}
              onClick={() => moveHandler('report')}
            >
              시공사례
            </p>
          </TabWrap>
          {partnerDetail && (
            <div>
              <div ref={serviceRef} className="scroll_box" />
              <PartnerInfo info={partnerDetail} />
            </div>
          )}
          <div>
            <div ref={reportRef} className="scroll_box" />
            <ReportList reports={caseList} />
          </div>
          {!!hasNextPage && (
            <MoreWrap>
              <button onClick={() => fetchNextPage()}>더 보기</button>
            </MoreWrap>
          )}
        </Content>
      </Box>

      {isOpenEstimate && (
        <EstimateModal
          close={() => setIsOpenEstimate(false)}
          order_estimate_type={ORDER_ESTIMATE_TYPE.PARTNER}
          key_id={id}
          callback={callback}
        />
      )}
      {isConfirm && <ConfirmModal close={() => setIsConfirm(false)} />}
    </Container>
  );
};

export default PartnerDetail;
