import React, { useEffect } from 'react';
import ReactDom from 'react-dom';

import styled from 'styled-components';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20000;
  display: flex;
  background-color: rgb(144, 144, 144, 0.8);
`;

interface Props {
  children: React.ReactNode;
}

const ModalPortal = (props: Props) => {
  const { children } = props;
  const el = document.getElementById('react-portal') as HTMLElement;

  return ReactDom.createPortal(children, el);
};

const Modal = (props: Props) => {
  const { children } = props;

  useEffect(() => {
    const header = document.getElementsByClassName(
      'layhome_header'
    ) as HTMLCollectionOf<HTMLElement>;

    if (header.length > 0) {
      document.body.style.cssText = `
            position: fixed;
            top: -${window.scrollY}px;
            width: 100%;
        `;

      header[0].style.cssText = `
            background-color: #ffffff;
            z-index: 999;
        `;

      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);

        header[0].style.cssText = `
                background-color: none;
            `;
      };
    }
  }, []);

  return (
    <ModalPortal>
      <ModalContainer>{children}</ModalContainer>
    </ModalPortal>
  );
};
export default Modal;
