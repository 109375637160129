import React from 'react';
import { Link } from 'react-router-dom';
import images from '../../libs/images';
import { ThemeMain } from '../../types/theme';
import styled from 'styled-components';

const LinkWrap = styled(Link)`
  &.disabled {
    cursor: not-allowed;
  }
`;

const ImageItem = styled.div`
  position: relative;
  padding-bottom: 140%;

  & > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ThemeBox = styled.div`
  position: relative;
  bottom: 35px;
  left: 30px;
  white-space: pre-wrap;

  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.18;
    color: #ffffff;
    width: 80%;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  @media screen and (max-width: 767px) {
    left: 14px;
    bottom: 20px;

    .title {
      font-size: 26px;
      font-weight: bold;
      line-height: 1.38;
      letter-spacing: -1.08px;
    }
  }
`;

interface Props {
  rows: ThemeMain;
}
export default function FrontThemeMain(props: Props) {
  const { rows } = props;
  rows.theme_title = rows.theme_title.replace('  ', '\n');

  /* 대체 이미지 */
  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = images.no_img;
  };

  return (
    <LinkWrap
      to={`/theme/${rows.theme_table_id}`}
      className={rows.theme_state === '4' ? 'disabled' : undefined}
      onClick={(e) => {
        if (rows.theme_state === '4') {
          e.preventDefault();
        }
      }}
    >
      <ImageItem>
        <img
          src={rows.main_img ? rows.main_img : images.no_img}
          onError={handleImgError}
        />
      </ImageItem>
      {rows.theme_state !== '4' && (
        <ThemeBox>
          <p className={'title'}>
            {rows.theme_title.length < 20
              ? rows.theme_title
              : rows.theme_title.slice(0, 20) + '...'}
          </p>
        </ThemeBox>
      )}
    </LinkWrap>
  );
}
