export const PARTNER_DETAIL = 'partner';
export const PARTNER_LIST = 'partner_list';
export const PARTNER_DETAIL_CASE = 'partner_detail_case';

export const REPORT_LIST = 'report_list';
export const REPORT_DETAIL = 'report';

export const EVENT_LIST = 'event_list';
export const Event_DETAIL = 'event';

export const THEME_LIST = 'theme_list';
export const THEME_DETAIL = 'theme';

export const HOME_DATA = 'home';
export const BANNER_TARGET = 'banner_target';
export const APTN_PHOTO_DATA = 'aptn_photo';
export const CITY_LIST = 'city_list';
export const AREA_LIST = 'area_list';
export const TERMS_DATA = 'term';
export const REVIEW_LIST = 'review_list';

export const LIFE_MOMTHLY_ITEM_LIST = 'life_monthly_item_list';
