import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../../assets/images/event/aptn_event_title_logo.png';
import Input from 'components/Common/Input/Input';
import InputSelect from 'components/Common/Input/InputSelect';
import consts from 'libs/consts';
import Checkbox from 'components/Common/Checkbox';
import images from '../../libs/images';
import AptEventCompleteModal from '../../components/AptEvent/Modal/CompleteModal';
import { createAptnEvent } from 'apis/Common';
import Footer from 'components/Layout/Footer';
import Header from 'components/Layout/Header';
import useClause from 'hooks/useClause';

const Container = styled.div`
  height: 100vh;
  padding-top: 85px;
`;

const TitleWrap = styled.div`
  border-top: 1px solid rgb(225, 225, 225);
  padding-top: 50px;
  text-align: center;

  & > img {
    width: 300px;
    height: 70px;
  }

  & > p {
    font-size: 17px;
    line-height: 24px;
    font-family: Pretendard;
  }
`;

const ContentWrap = styled.div`
  max-width: 1020px;
  margin: 40px auto 0 auto;

  .input_box {
    margin-top: 40px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .sub_text {
    color: #fb3b3b;
    font-size: 12px;
    margin-top: 4px;
  }

  @media screen and (max-width: 1055px) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;

const StyleFooter = styled.div`
  margin: 40px 0 80px 0;
  text-align: center;

  & > button {
    background: #066e4d;
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    width: 290px;
    color: #ffffff;

    &:disabled {
      background-color: #e1e1e1;
      border: none;
      color: #fff;
      cursor: auto;
    }
  }

  @media screen and (max-width: 460px) {
    & > button {
      width: calc(100% - 40px);
    }
  }
`;

const ModalContent = styled.div`
  padding-bottom: 100px;
  text-align: center;

  & > div {
    & > img {
      width: 36px;
      height: 36px;
      margin-top: 60px;
    }

    & > h3 {
      margin-top: 15px;
      font-size: 24px;
    }

    & > div {
      margin-top: 20px;
      color: #606060;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
    }
  }

  @media screen and (max-width: 720px) {
    padding: 0;
    display: flex;

    & > div {
      margin: 15vh auto auto auto;
    }
  }
`;

const ApartnerConsultingPage = () => {
  const { showClause } = useClause();

  const [apt_name, setApt_name] = useState('');
  const [order_size, setOrder_size] = useState('');
  const [phone, setPhone] = useState('');
  const [checked, setChecked] = useState(false);
  const [isOpenComplete, setIsOpenComplete] = useState(false);

  const submitHandler = async (): Promise<void> => {
    if (!apt_name || !order_size || !phone) {
      alert('필수 정보를 입력해주세요.');
      return;
    }
    try {
      const params = {
        access_type: 4,
        apart_name: apt_name,
        phone: phone,
        size: order_size,
      };

      const res = await createAptnEvent(params);

      if (res.code === 'OK') {
        setIsOpenComplete(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Header />
      <TitleWrap>
        <img src={logo} alt="event_title_logo" />
        <p>
          상담 정보를 입력해 주시면
          <br /> 1-2일 내로 연락 드리겠습니다.
        </p>
      </TitleWrap>
      <ContentWrap>
        <Input
          type="text"
          label={'*아파트 명'}
          value={apt_name}
          setValue={setApt_name}
          maxlength={20}
        />

        <InputSelect
          name=""
          label="*평형"
          option={[{ idx: '', title: '선택' }, ...consts.acreageoption]}
          value={order_size}
          setValue={setOrder_size}
        />

        <Input
          type="text"
          label={'*휴대폰 번호'}
          value={phone}
          setValue={setPhone}
          maxlength={11}
          valid={'num'}
        />
        <p className="sub_text">상담 완료 시 번호는 삭제 처리 됩니다.</p>

        <div className="flex">
          <Checkbox
            value={checked}
            onChange={() => setChecked(!checked)}
            labelText="개인정보 제공 동의 (필수)"
          />
          <button onClick={() => showClause(2)}>
            <img src={images.right_arrow2} alt="개인정보 동의" />
          </button>
        </div>
      </ContentWrap>
      <StyleFooter>
        <button onClick={submitHandler} disabled={!checked}>
          신청 완료
        </button>
      </StyleFooter>
      <Footer />
      {isOpenComplete && (
        <AptEventCompleteModal
          close={() => setIsOpenComplete(false)}
          content={
            <ModalContent>
              <div>
                <img src={images.clipboard_icon} alt="" />
                <h3>상담 접수 완료!</h3>
                <div>
                  감사합니다. <br />
                  1-2일 내로 리모델링 업체 연락을
                  <br />
                  받아 보실 수 있습니다.
                </div>
              </div>
            </ModalContent>
          }
        />
      )}
    </Container>
  );
};

export default ApartnerConsultingPage;
