import React, { useEffect } from 'react';
import axios from 'axios';
import { kakaoLogin } from 'apis/User';

const KakaoLoginCallbackPage = () => {
  useEffect(() => {
    getTokenHandler();
  }, []);

  const getTokenHandler = async () => {
    try {
      const code = new URL(window.location.href).searchParams.get('code');

      const REST_API_KEY = `${process.env.REACT_APP_REST_API_KEY}`;
      const REDIRECT_URI = `${process.env.REACT_APP_REDIRECT_URL}`;
      const client_secret = `${process.env.REACT_APP_CLIENT_SECRET}`;

      const res = await axios.post(
        `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&code=${code}&client_secret=${client_secret}`
      );

      const { access_token } = res.data;

      const userData = await axios.get(`https://kapi.kakao.com/v2/user/me`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      });

      const { id } = userData.data;

      const kakaoData = await kakaoLogin({
        kakao_auth_key: id,
      });

      if (kakaoData.code === 'OK') {
        window.opener.kakaoLoginCallback(kakaoData.user_info[0]);
        window.close();
        return;
      } else if (kakaoData.code === 'ERR_304') {
        window.opener.parent.location.href = `/signup?kakao_auth_key=${id}`;
        window.close();
        return;
      }
    } catch (err) {
      console.log(err);
      alert('카카오 로그인에 문제가 발생했습니다.');
      window.close();
    }
  };

  return <div></div>;
};

export default KakaoLoginCallbackPage;
