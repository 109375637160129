import React from 'react';
import images from '../../../libs/images';

export default function StarBox(props) {
  const { width = '80px', star = 5 } = props;

  return (
    <div className="star_progress" style={{ width: width }}>
      <span className="star" style={{ width: star * 20 + '%' }}>
        <img src={images.star} />
        <img src={images.star} />
        <img src={images.star} />
        <img src={images.star} />
        <img src={images.star} />
      </span>
      <span className="star_off">
        <img src={images.star_off} />
        <img src={images.star_off} />
        <img src={images.star_off} />
        <img src={images.star_off} />
        <img src={images.star_off} />
      </span>
    </div>
  );
}
