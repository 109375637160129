/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment } from 'react';
import images from '../../../libs/images';
import styled from 'styled-components';
import { ThemeList, ZoneList } from '../../../types/theme';
import DetailModal from '../Modal/DetailModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    &:nth-last-child(1) {
      margin-top: 20px;
    }
  }
`;

const Content = styled.div`
  transition: 0.3s;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
  background: #000000;
  border-radius: 100px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.36);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    color: #ffffff;
    display: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }

  &:hover {
    width: 231px;
    cursor: pointer;

    & > img {
      margin-right: 13px;
    }

    & > p {
      display: block;
    }
  }

  @media screen and (max-width: 767px) {
    width: 44px;
    height: 44px;

    & > img {
      width: 16px;
      height: 16px;
    }
  }
`;

const ImageWrap = styled.div`
  position: relative;
  width: 100%;
`;

const ImageBox = styled.img`
  width: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const DescriptionText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: -0.67px;
  margin: 24px 0 65px 0;
  word-break: break-word;

  &:nth-last-child(1) {
    margin-top: 16px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px;

    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.58px;
  }
`;

interface Props {
  row: ZoneList[];
  stylelist: ThemeList[];
  onClickHomeStyle: () => void;
  more: number | boolean;
  detailStyle: any;
  activeIndex: number | string;
}
export default function FrontThemeRightBox1(props: Props) {
  const { row, onClickHomeStyle, more, stylelist } = props;

  const [isHover1, setIsHover1] = useState(false);

  const [detailImg, setDetailImg] = useState('');

  const imgslide = (src: string) => {
    setDetailImg(src);
  };

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = images.no_img;
  };

  return (
    <>
      <Container>
        {row &&
          row.map((x, i) => {
            if (more && i > Number(more)) return;

            return (
              <Fragment key={i}>
                <ImageWrap>
                  {x.zone_imgs.length > 0 && (
                    <ImageBox
                      src={x.zone_imgs[0].img_url}
                      onClick={() => imgslide(x.zone_imgs[0].img_url)}
                      onError={handleImgError}
                    />
                  )}

                  {stylelist.length > 0 && (
                    <Content
                      onMouseOver={() => setIsHover1(true)}
                      onMouseOut={() => setIsHover1(false)}
                      onClick={() => onClickHomeStyle()}
                      style={{ zIndex: 999 }}
                    >
                      <img
                        src={isHover1 ? images.home_icon2 : images.home_icon2}
                      />
                      <p>홈 스타일링 제품보기</p>
                    </Content>
                  )}
                </ImageWrap>
                <DescriptionText>{x.zone_desc}</DescriptionText>
              </Fragment>
            );
          })}
      </Container>
      {detailImg && (
        <DetailModal detailImg={detailImg} close={() => setDetailImg('')} />
      )}
    </>
  );
}
