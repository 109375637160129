export const styleList = [
  { idx: 1, title: '우드&화이트', value: '1' },
  { idx: 2, title: '모던', value: '2' },
  { idx: 3, title: '젠스타일', value: '3' },
  { idx: 4, title: '모든미드센츄리', value: '4' },
  { idx: 5, title: '미니멀', value: '5' },
  { idx: 6, title: '클래식&엔틱', value: '6' },
];

export const serviceList = [
  { idx: 1, title: '실내 건축업 면허', value: '1' },
  { idx: 2, title: '하자 보증 보험', value: '2' },
  { idx: 3, title: '3D 디자인', value: '3' },
  { idx: 4, title: '전문 현장 감리', value: '4' },
  { idx: 5, title: '현금 영수증', value: '5' },
  { idx: 6, title: '설계 도면', value: '6' },
  { idx: 7, title: '숙소 제공', value: '7' },
  { idx: 8, title: '이삿짐 센터', value: '8' },
  { idx: 9, title: '공정표', value: '9' },
  { idx: 10, title: '계약서', value: '10' },
  { idx: 11, title: '그린리모델링', value: '11' },
];
