/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '../utils/axios';
import { Theme, ThemeMain } from '../types/theme';

export const getTheme = async (params: {
  theme_table_id: string;
}): Promise<Theme> => {
  const res = await axios.post('/UserHome/ThemeDetail', params);

  return res.data;
};

export const getThemeList = async (): Promise<{
  banner_cnt: string;
  banner_list: any;
  code: string;
  theme_list: ThemeMain[];
  total_cnt: string;
  zone_data: any;
}> => {
  const res = await axios.post('/UserHome/ThemeList');

  return res.data;
};
