import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import similar_size from './images/similar_size.png';
import similar_price from './images/similar_price.png';
import useReportStore from 'stores/useReportStore';

const Container = styled.div`
  margin: 200px 65px 0 65px;

  @media screen and (max-width: 767px) {
    margin: 115px 0 0 0;
  }
`;

const Content = styled.div`
  display: flex;
  margin-bottom: 200px;
  gap: 0 95px;

  &:first-of-type {
    margin-bottom: 260px;
  }

  @media screen and (max-width: 980px) {
    gap: 0 50px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const Image = styled.div`
  width: 47%;
  position: relative;
  padding-bottom: 30%;
  overflow: hidden;
  border-radius: 4px;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s;
    }
  }

  @media screen and (max-width: 980px) {
    width: 60%;
    padding-bottom: 40%;
  }

  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
    padding-bottom: 65%;
    margin-top: 20px;

    & > button {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 13px 20px;
      background-color: #000000;
      color: #ffffff;
      font-weight: 700;
      font-size: 13px;
    }
  }
`;

const Description = styled.div<{ align: 'left' | 'right' }>`
  display: flex;
  margin-left: ${(props) => (props.align === 'right' ? 'auto' : '0')};

  & > div {
    margin: auto 0;

    .content_wrap {
      & > p {
        font-size: 36px;
        font-weight: 700;
        line-height: 50px;
        text-align: ${(props) => props.align};
        letter-spacing: 2px;
      }
    }

    .button_wrap {
      margin-top: 40px;
      margin-left: ${(props) => (props.align === 'right' ? 'auto' : '0')};

      & > button {
        color: #ffffff;
        background: #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        height: 70px;
        width: 360px;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  @media screen and (max-width: 980px) {
    & > div {
      .content_wrap {
        & > p {
          font-size: 28px;
          line-height: 35px;
        }
      }
      .button_wrap {
        & > button {
          width: 200px;
        }
      }
    }
  }
`;

const MobileContent = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    width: calc(100% - 60px);
    margin: 0 auto 112px auto;

    & > h2 {
      font-size: 24px;
      line-height: 33px;

      &:last-of-type {
        margin-top: 55px;
      }
    }
  }
`;

interface Props {
  size: string;
  price: string;
}

const Similar = (props: Props) => {
  const { size, price } = props;

  const navigate = useNavigate();
  const { setParams } = useReportStore();

  const getRange = () => {
    const numberSize = size ? Number(size) : 0;
    const numberPrice = price ? Number(price) : 0;

    let rangeSize = 0;
    let rangePrice = 0;

    if (numberSize >= 20 && numberSize < 70) {
      rangeSize = Math.floor(numberSize / 10) * 10;
    }

    if (numberPrice >= 1000 && numberPrice < 6000) {
      rangePrice = Math.floor(numberPrice / 1000) * 1000;
    }

    return {
      rangeSize,
      rangePrice,
    };
  };

  const onClickMoreHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: 'size' | 'price'
  ) => {
    e.preventDefault();

    const defaultData = {
      apart_name: '',
      case_size: [],
      case_work_price: [],
      style_area: [],
      case_service: [],
      case_sortBy: [],
      city: '',
      area: '',
      areaText: '',
      page: 1,
    };

    if (type === 'size') {
      const size = String(getRange().rangeSize);
      setParams({
        ...defaultData,
        case_size: [size],
      });
    } else {
      const price = String(getRange().rangePrice);
      setParams({
        ...defaultData,
        case_work_price: [price],
      });
    }
    scrollTo(0, 0);
    navigate('/report');
  };

  return (
    <Container>
      <Content>
        <Image>
          <img src={similar_size} alt="similar_size_img" />
        </Image>
        <Description align="left">
          <div>
            <div className="content_wrap">
              <p>
                비슷한 평형의
                <br />
                시공사례를
                <br />
                찾아보세요
              </p>
            </div>
            <div className="button_wrap">
              <button onClick={(e) => onClickMoreHandler(e, 'size')}>
                더보기
              </button>
            </div>
          </div>
        </Description>
      </Content>
      <Content>
        <Description align="right">
          <div>
            <div className="content_wrap">
              <p>
                비슷한 견적의
                <br />
                시공사례를
                <br />
                둘러보세요
              </p>
            </div>
            <div className="button_wrap">
              <button onClick={(e) => onClickMoreHandler(e, 'price')}>
                더보기
              </button>
            </div>
          </div>
        </Description>
        <Image>
          <img src={similar_price} alt="similar_price_img" />
        </Image>
      </Content>
      <MobileContent>
        <h2>
          비슷한 평형의 <br />
          시공사례를 찾아보세요.
        </h2>
        <Image>
          <img src={similar_size} alt="similar_size_img" />
          <button onClick={(e) => onClickMoreHandler(e, 'size')}>
            더 보기 {`>`}
          </button>
        </Image>
        <h2>
          비슷한 견적의 <br />
          시공사례를 찾아보세요.
        </h2>
        <Image>
          <img src={similar_price} alt="similar_price_img" />
          <button onClick={(e) => onClickMoreHandler(e, 'price')}>
            더 보기 {`>`}
          </button>
        </Image>
      </MobileContent>
    </Container>
  );
};

export default Similar;
