import React from 'react';
import Layout from 'components/Layout';
import ServiceComponent from 'components/Service';

const ServicePage = () => {
  return (
    <Layout>
      <ServiceComponent />
    </Layout>
  );
};

export default ServicePage;
