import React from 'react';
import Layout from 'components/Layout';
import ThemeComponent from 'components/Theme';

const ThemePage = () => {
  return (
    <Layout headerText="테마 인테리어" is_mobile_header_white>
      <ThemeComponent />
    </Layout>
  );
};

export default ThemePage;
