import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useFloating from './floating.hook';
import prev_icon from './images/prev_icon.png';
import next_icon from './images/next_icon.png';

const Container = styled.div`
  padding: 6px 0;

  .floating-list-title {
    line-height: 18px;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
  }
`;

const Content = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > a {
    margin-bottom: 9px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & > img {
      width: 59px;
      height: 40px;
    }
  }
`;

const PagingWrap = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  & > p {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
  }
`;

const FloatingList = () => {
  const { page, totalPage, prevHandler, nextHandler, recentReports } =
    useFloating(4);

  const getHref = (id: string, type: string) => {
    const typeToPath = {
      '1': `/report/${id}`,
      '2': `/theme/${id}`,
    } as Record<string, string>;

    return typeToPath[type] || '/';
  };

  return (
    <Container>
      <p className="floating-list-title">최근 본 공간</p>
      <Content>
        {recentReports.length > 0 &&
          recentReports.map((report, i) => (
            <Link key={i} to={getHref(report.id, report.r_type)}>
              <img src={report.img} alt="report_image" />
            </Link>
          ))}
      </Content>
      <PagingWrap>
        <button onClick={prevHandler}>
          <img src={prev_icon} alt="prev_icon" />
        </button>
        <p>{`${page} / ${totalPage}`}</p>
        <button onClick={nextHandler}>
          <img src={next_icon} alt="next_icon" />
        </button>
      </PagingWrap>
    </Container>
  );
};

export default FloatingList;
