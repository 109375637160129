import banner_washbasin from '../assets/images/life-construction/banner_washbasin.png'; // 세면기
import banner_shower_faucet from '../assets/images/life-construction/banner_shower_faucet.png'; //샤워수전
import banner_toilet from '../assets/images/life-construction/banner_toilet.png'; // 양변기
import banner_range_hood from '../assets/images/life-construction/banner_range_hood.png'; // 렌지후드
import banner_tile from '../assets/images/life-construction/banner_tile.png'; // 타일
import banner_door from '../assets/images/life-construction/banner_door.png'; // 문

export const slideBannerList = [
  { id: 1, title: '세면기', image: banner_washbasin, position: 'top' },
  { id: 2, title: '샤워수전', image: banner_shower_faucet, position: 'bottom' },
  { id: 3, title: '양변기', image: banner_toilet, position: 'bottom' },
  { id: 4, title: '렌지후드', image: banner_range_hood, position: 'top' },
  { id: 5, title: '타일', image: banner_tile, position: 'bottom' },
  { id: 6, title: '문', image: banner_door, position: 'bottom' },
];

export const productList = [
  {
    id: 1,
    name: '욕실',
    items: [
      {
        id: 1,
        name: '세면기 교체',
        price: 191000,
        unit: '개당',
      },
      {
        id: 2,
        name: '양변기 교체',
        price: 383000,
        unit: '개당',
      },
      {
        id: 3,
        name: '욕실장 교체',
        price: 106000,
        unit: '개당',
      },
      {
        id: 4,
        name: '거울 교체',
        price: 77000,
        unit: 'M2',
      },
      {
        id: 5,
        name: '바닥타일 교체 교체',
        price: 98000,
        unit: 'M2',
      },
      {
        id: 6,
        name: '벽타일 교체',
        price: 115000,
        unit: 'M2',
      },
      {
        id: 7,
        name: '탕틸 줄눈 시공',
        price: 450000,
        unit: '개소당',
      },
      {
        id: 8,
        name: '환풍기 교체',
        price: 56000,
        unit: '개당',
      },
      {
        id: 9,
        name: '세면기수전 교체',
        price: 132000,
        unit: '개당',
      },
      {
        id: 10,
        name: '샤워기수전 교체',
        price: 156000,
        unit: '개당',
      },
    ],
  },
  {
    id: 2,
    name: '주방',
    items: [
      {
        id: 11,
        name: '냉장고장 교체',
        price: 250000,
        unit: '개당',
      },
      {
        id: 12,
        name: '대수전 교체',
        price: 144007,
        unit: '개당',
      },
      {
        id: 13,
        name: '벽수전 교체',
        price: 112458,
        unit: '개당',
      },
      {
        id: 14,
        name: '상부장 교체',
        price: 150000,
        unit: 'M당',
      },
      {
        id: 15,
        name: '하부장 교체',
        price: 682000,
        unit: 'M당',
      },
      {
        id: 16,
        name: '후드 교체',
        price: 176000,
        unit: '개당',
      },
    ],
  },
  {
    id: 3,
    name: '일반',
    items: [
      {
        id: 17,
        name: '몰딩 교체',
        price: 9800,
        unit: 'M당',
      },
      {
        id: 18,
        name: '몰딩 시트지 마감',
        price: 0,
        unit: '',
      },
      {
        id: 19,
        name: '도배',
        price: 13000,
        unit: 'M2',
      },
      {
        id: 20,
        name: '장판',
        price: 26000,
        unit: 'M2',
      },
      {
        id: 21,
        name: 'LED조명 교체(방)',
        price: 125000,
        unit: '개당',
      },
      {
        id: 22,
        name: 'LED조명 교체(거실)',
        price: 170000,
        unit: '개당',
      },
      {
        id: 23,
        name: 'LED조명 교체(주방)',
        price: 125000,
        unit: '개당',
      },
      {
        id: 24,
        name: 'LED조명 교체(현관)',
        price: 120000,
        unit: '개당',
      },
      {
        id: 25,
        name: '창호 교체(단창)',
        price: 292000,
        unit: 'M2',
      },
      {
        id: 26,
        name: '창호 교체(이중창)',
        price: 460000,
        unit: 'M2',
      },
      {
        id: 27,
        name: '방충망 틀 교체',
        price: 34800,
        unit: 'M2',
      },
      {
        id: 28,
        name: '문짝 교체',
        price: 194000,
        unit: '짝당',
      },
      {
        id: 29,
        name: '문짝,틀 교체',
        price: 464000,
        unit: '짝당',
      },
    ],
  },
];
