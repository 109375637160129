import React, { useState, useEffect } from 'react';
import Modal from 'components/Common/Modal/ModalPortal';
import styled from 'styled-components';
import images from 'libs/images';

import style_dummy1 from '../../Home/images/style_dummy1.png';
import style_dummy2 from '../../Home/images/style_dummy2.png';
import style_dummy3 from '../../Home/images/style_dummy3.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import consts from 'libs/consts';

import useCommonQueries from '../../../hooks/queries/common';
import { UseQueryResult } from 'react-query';
import { Area } from 'types/home';
import { createOrder, createEventOrder } from 'apis/Common';
import { ORDER_ESTIMATE_TYPE } from 'utils/enum/estimate';
import useVh from 'hooks/useVh';
import useUserStore from 'stores/useUserStore';
import useClause from 'hooks/useClause';

const Container = styled.div`
  margin: auto;
  background-color: #ffffff;
  width: 1200px;

  @media screen and (max-width: 1200px) {
    width: 1000px;
  }

  @media screen and (max-width: 1000px) {
    width: 767px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: end;

  & > h3 {
    display: none;
  }

  @media screen and (max-width: 767px) {
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;

    & > h3 {
      font-size: 16px;
      display: block;
      margin: auto 0;
    }
  }
`;

const Content = styled.div`
  max-height: 745px;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #434343;
  }

  position: relative;

  @media screen and (max-width: 1200px) {
    max-height: 730px;
  }

  @media screen and (max-width: 1000px) {
    max-height: none;
  }

  @media screen and (max-width: 767px) {
    max-height: calc(var(--vh, 1vh) * 100 - 65px);
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const TopSectionLeftWrap = styled.div`
  margin-left: 97px;
  width: calc(40% - 140px);
  min-width: 250px;

  & > img {
    width: 202px;
    height: 63px;
  }

  & > h3 {
    margin-top: 25px;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;

    &.event_partner {
      font-size: 30px;
      line-height: 40px;
    }
  }

  & > p {
    margin-top: 25px;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;

    &.event_description {
      font-size: 16px;
      line-height: 25px;
    }
  }

  @media screen and (max-width: 1200px) {
    margin-left: 60px;

    & > h3 {
      font-size: 26px;
      line-height: 35px;
    }

    & > p {
      margin-top: 20px;
      font-size: 16px;
      line-height: 35px;
    }
  }

  @media screen and (max-width: 1000px) {
    & > img {
      width: 150px;
      height: 45px;
    }

    & > h3 {
      font-size: 22px;
      line-height: 30px;
    }

    & > p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0 20px;

    & > img {
      display: none;
    }

    & > h3 {
      font-size: 24px;
      line-height: 35px;
    }

    & > p {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

const TopSectionRightWrap = styled.div`
  width: calc(100% - 35% - 280px);
  .swiper {
    .swiper-wrapper {
      .swiper-slide {
        & > img {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    width: calc(100% - 35% - 180px);
  }

  @media screen and (max-width: 1000px) {
    width: calc(100% - 35% - 120px);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 35px;
  }
`;

const InfoSection = styled.div`
  width: calc(100% - 194px);
  margin: 40px auto 0 auto;

  .info_flex_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
  }

  @media screen and (max-width: 1200px) {
    width: calc(100% - 120px);
  }

  @media screen and (max-width: 1000px) {
    margin-top: 40px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 40px;
    width: calc(100% - 40px);

    .info_flex_box {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
`;

const ItemBox = styled.div<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : 'calc(50% - 70px)')};

  @media screen and (max-width: 1000px) {
    width: ${(props) =>
      props.width ? `${props.width + 2}%` : 'calc(50% - 70px)'};
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const CustomInput = styled.div<{ isShow?: boolean }>`
  display: ${(props) => (props.isShow ? 'flex' : 'none')};
  width: auto;

  & > span {
    color: #fa5500;
    font-size: 16px;
    width: 10px;
  }

  & > p {
    font-size: 16px;
    font-weight: 700;
    margin-right: 45px;

    &.address {
      width: 65px;
    }

    &.date {
      width: 120px;
      margin-right: 25px;
    }

    &.description {
      width: 150px;
      margin-right: 25px;

      & ~ .input_wrap {
        width: calc(100% - 185px);
      }
    }
  }

  .disabled_msg {
    font-size: 12px;
    color: #f10124;
    font-weight: 600;
    width: 100%;
    margin: 10px 0 0 0;
  }

  .input_wrap {
    margin-top: -10px;
    width: 100%;
    position: relative;

    & > div {
      border-bottom: 1px solid #8f8e8e;
      width: 100%;

      & > input {
        margin-top: 6px;
        width: 100%;
        border: none;
        padding: 3px 5px 4px 0;
        font-size: 16px;
        font-weight: 400;

        &::placeholder {
          font-size: 16px;
        }
      }

      .unit {
        position: absolute;
        right: -20px;
        top: 10px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .select_wrap {
    width: calc(100% - 115px);
    margin-top: -5px;
    display: flex;
    justify-content: space-between;

    & > div {
      width: 45%;
      border: none;

      display: flex;
      flex-wrap: wrap;

      &.date_select_box {
        width: 100%;
      }

      & > select {
        margin-top: -3px;
        border: none;
        width: 100%;
        padding: 3px 5px 4px 5px;
        border-bottom: 1px solid #8f8e8e;
        font-weight: 400;
        font-size: 16px;

        background: url(${images.down}) no-repeat center right 0px;
        box-sizing: content-box;
        color: #000000;

        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;

        &.disabled {
          font-size: 16px;
          color: #8f8e8e;
        }

        &:focus {
          outline: none;
          background: url(${images.up}) no-repeat center right 10px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    & > span {
      font-size: 18px;
    }

    & > p {
      &.address {
        margin-right: 30px;
      }

      &.date {
        width: 100px;
        margin-right: 30px;
      }

      &.description {
        width: 130px;
      }
    }

    .disabled_msg {
      font-size: 11px;
    }

    .input_wrap {
      .unit {
        right: -15px;
      }
    }
  }

  .select_wrap {
    width: calc(100% - 100px);
    & > div {
      & > select {
        margin-top: 2px;

        &.disabled {
          margin-top: 2px;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    & > p {
      font-size: 14px;
    }

    .input_wrap {
      & > div {
        .unit {
          font-size: 14px;
        }
        & > input {
          font-size: 12px;

          &::placeholder {
            font-size: 12px;
          }
        }
      }
    }

    .select_wrap {
      & > div {
        & > select {
          font-size: 12px;

          &.disabled {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
    flex-wrap: wrap;

    & > span {
      font-size: 12px;
      margin-top: 12px;
    }

    & > p {
      font-size: 12px;
      font-weight: 700;
      line-height: 40px;

      &.description {
        width: auto;

        & ~ .input_wrap {
          width: 100%;
        }
      }
    }

    .disabled_msg {
      margin: 10px 0 0 0;
      font-size: 11px;
    }

    .input_wrap {
      margin: 10px 10px 0 10px;
      & > div {
        & > input {
          padding-bottom: 10px;
          font-size: 12px;

          &::placeholder {
            font-size: 12px;
          }
        }

        .unit {
          top: 12px;
          right: 0;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .select_wrap {
      flex-wrap: wrap;
      width: 100%;
      margin: 10px 10px 0 10px;

      & > div {
        width: 100%;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        & > select {
          font-size: 12px;
          font-weight: 400;
          line-height: 40px;

          &.disabled {
            font-size: 12px;
          }
        }
      }
    }
  }
`;

const CustomCheckbox = styled.label`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 80%;
    display: inline-block;
    position: relative;
    cursor: pointer;

    & > input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      box-sizing: content-box;
      width: 16px;
      height: 16px;
      position: absolute;
      background: none;
      border: 2px solid #434343;

      &::after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 4px;
        height: 10px;
        border: solid #fa5500;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      background-color: #ffffff;
      border: 2px solid #fa5500;

      &::after {
        display: block;
      }
    }

    input:disabled ~ .checkmark {
      opacity: 0.5;
    }

    input:disabled ~ p {
      color: gray;
    }

    & > p {
      font-size: 16px;
      font-weight: 600;
      margin: 1px 0 0 30px;
    }
  }

  & > button {
    display: inline-block;
    background: none;
    font-size: 13px;
    color: #7e7e7e;
    text-decoration: underline;
  }

  @media screen and (max-width: 767px) {
    & > div {
      width: auto;
      display: inline-flex;

      .checkmark {
        width: 13px;
        height: 13px;

        &::after {
          left: 5px;
          top: 1px;
          width: 2px;
          height: 7px;
        }
      }

      & > p {
        font-size: 13px;
        margin-top: 0;
      }
    }

    & > button {
      font-size: 12px;
    }
  }
`;

const ApplyWrap = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 40px;

  & > button {
    margin-right: 80px;
    padding: 16px 90px;
    background-color: #000000;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &.disabled {
      background-color: #8f8e8e;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 0;

    & > button {
      margin: 0;
      padding: 17px 0;
      width: 100%;
      font-size: 13px;

      &.disabled {
        background-color: #e0e0e0;
        color: #8f8e8e;
      }
    }
  }
`;

const AllCheckWrap = styled.div`
  background-color: #f3f3f3;
  padding: 14px 24px 14px 29px;

  & ~ .disabled_msg {
    margin: 5px 0 0 29px;
    height: 20px;
    color: #f10124;
    font-weight: 600;
    font-size: 12px;
  }

  @media screen and (max-width: 1200px) {
    & ~ .disabled_msg {
      font-size: 11px;
    }
  }
`;

const MoreTermsWrap = styled.div`
  margin: 5px 24px 40px 29px;

  & > label {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

interface Props {
  close: () => void;
  order_estimate_type: number;
  key_id?: string | number;
  url_table_id?: string;
  callback: () => void;
  eventData?: {
    seller_biz_name: string;
    form_types: string[];
    seller_table_id: string;
  };
}

const EstimateModal = (props: Props) => {
  const { getCityListQuery } = useCommonQueries();
  const { user } = useUserStore();
  const { showClause } = useClause();

  const { data: city_list }: UseQueryResult<{ area: Area[] }, unknown> =
    getCityListQuery();

  const {
    close,
    order_estimate_type,
    key_id,
    url_table_id,
    callback,
    eventData,
  } = props;

  useVh();

  const [isDisabled, setIsDisabled] = useState(false);

  const forms = eventData
    ? eventData.form_types.map((item) => Number(item))
    : [];

  const [data, setData] = useState({
    order_user_name: '',
    order_phone: '',
    order_size: '',
    order_area_1st: '',
    order_area_2nd: '',
    order_desired_period: '',
    order_msg: '',
    market_agree: '0',
  });

  const [terms, setTerms] = useState({
    terms1: false,
    terms2: false,
  });

  const [showMoreTerms, setShowMoreTerms] = useState(false);

  useEffect(() => {
    if (user) {
      setData((prev) => ({
        ...prev,
        order_user_name: user.user_name,
        order_phone: user.user_phone,
      }));
    }
  }, [user]);

  const mock = [
    { id: 1, img: style_dummy1 },
    { id: 2, img: style_dummy2 },
    { id: 3, img: style_dummy3 },
  ];

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === 'order_area_1st' && {
        order_area_2nd: '',
      }),
    }));
  };

  const isButtonDisabled = () => {
    const {
      order_user_name,
      order_phone,
      order_size,
      order_area_1st,
      order_area_2nd,
      order_desired_period,
    } = data;

    if (eventData) {
      if (
        (forms.includes(1) && order_user_name === '') ||
        (forms.includes(2) && order_phone === '') ||
        (forms.includes(3) && order_size === '') ||
        (forms.includes(4) &&
          (order_area_1st === '' || order_area_2nd === '')) ||
        (forms.includes(5) && order_desired_period === '') ||
        !terms.terms1 ||
        !terms.terms2
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        order_user_name === '' ||
        order_phone === '' ||
        order_size === '' ||
        order_area_1st === '' ||
        order_area_2nd === '' ||
        order_desired_period === '' ||
        !terms.terms1 ||
        !terms.terms2
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const afterSubmit = (data: string) => {
    if (data === 'OK') {
      callback();
    } else {
      alert('오류가 발생하였습니다.');
    }
  };

  const submitHandler = async (): Promise<void> => {
    const isDisabled = isButtonDisabled();

    if (isDisabled) {
      setIsDisabled(true);
      return;
    }

    setIsDisabled(false);

    try {
      // 이벤트 상담 신청일 경우
      if (order_estimate_type === ORDER_ESTIMATE_TYPE.EVENT) {
        if (!eventData) {
          alert('Seller 정보가 없습니다.');
          return;
        }

        const eventEstimateParams = {
          user_name: data.order_user_name,
          user_phone: data.order_phone,
          size: data.order_size,
          area_id: data.order_area_1st,
          area_detail_id: data.order_area_2nd,
          order_desired_period: data.order_desired_period,
          request_desc: data.order_msg,
          seller_table_id: eventData.seller_table_id,
          seller_biz_name: eventData.seller_biz_name,
          evt_fk_id: String(key_id),
          url_table_id: String(url_table_id),
          market_agree: data.market_agree,
        };

        const res = await createEventOrder(eventEstimateParams);

        afterSubmit(res.code);
      } else {
        // 이벤트 상담이 아닐때
        const params = {
          ...data,
          order_estimate_type,
          ...([
            ORDER_ESTIMATE_TYPE.REPORT,
            ORDER_ESTIMATE_TYPE.THEME,
            ORDER_ESTIMATE_TYPE.PARTNER,
          ].includes(order_estimate_type) && {
            order_fk_id: key_id,
          }),
        };

        const res = await createOrder(params);

        afterSubmit(res.code);
      }
    } catch (err) {
      console.log(err);
      close();
    }
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>상담 신청</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit" />
          </button>
        </Header>
        <Content>
          <TopSection>
            <TopSectionLeftWrap>
              <img src={images.logo_black} alt="logo_black" />
              {eventData ? (
                <>
                  <h3 className="event_partner">
                    {eventData.seller_biz_name}을 <br />
                    찾아주셔서 감사합니다
                  </h3>
                  <p className="event_description">
                    아래 내용을 작성해주시면
                    <br />
                    담당자가 1-2일 내로 연락드리겠습니다.
                  </p>
                </>
              ) : (
                <>
                  <h3>
                    취향에 맞는 <br />
                    인테리어 중개
                  </h3>
                  <p>
                    전문 매니저가 고객님께 <br />
                    1:1 맞춤형 상담을 진행해 드립니다.
                  </p>
                </>
              )}
            </TopSectionLeftWrap>
            <TopSectionRightWrap>
              <Swiper
                slidesPerView={2}
                spaceBetween={15}
                centeredSlides
                loop
                breakpoints={{
                  768: {
                    slidesPerView: 2.5,
                    spaceBetween: 25,
                    centeredSlides: false,
                    loop: false,
                  },
                }}
              >
                {mock.length > 0 &&
                  mock.map((item) => (
                    <SwiperSlide key={item.id}>
                      <img src={item.img} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </TopSectionRightWrap>
          </TopSection>
          <InfoSection>
            <div
              className={
                forms.includes(1) || forms.includes(2) || !eventData
                  ? 'info_flex_box'
                  : undefined
              }
            >
              <ItemBox width={35}>
                <CustomInput isShow={forms.includes(1) || !eventData}>
                  <span>*</span>
                  <p>이름</p>
                  <div className="input_wrap">
                    <div>
                      <input
                        name="order_user_name"
                        value={data.order_user_name}
                        onChange={changeInputHandler}
                        placeholder="고객님의 성함을 입력해주세요"
                      />
                    </div>
                    <p className="disabled_msg">
                      {isDisabled &&
                        !data.order_user_name &&
                        '이름을 입력해주세요.'}
                    </p>
                  </div>
                </CustomInput>
              </ItemBox>

              <ItemBox width={53.5}>
                <CustomInput isShow={forms.includes(2) || !eventData}>
                  <span>*</span>
                  <p>연락처</p>
                  <div className="input_wrap">
                    <div>
                      <input
                        name="order_phone"
                        value={data.order_phone}
                        onChange={changeInputHandler}
                        placeholder="상담 가능한 연락처를 작성해주세요"
                      />
                    </div>
                    <p className="disabled_msg">
                      {isDisabled &&
                        !data.order_phone &&
                        '연락처를 입력해주세요.'}
                    </p>
                  </div>
                </CustomInput>
              </ItemBox>
            </div>
            <div
              className={
                forms.includes(3) || forms.includes(4) || !eventData
                  ? 'info_flex_box'
                  : undefined
              }
            >
              <ItemBox width={35}>
                <CustomInput isShow={forms.includes(3) || !eventData}>
                  <span>*</span>
                  <p>평형</p>
                  <div className="input_wrap">
                    <div>
                      <input
                        name="order_size"
                        onChange={changeInputHandler}
                        placeholder="평수를 입력하세요"
                      />
                      <p className="unit">평</p>
                    </div>
                    <p className="disabled_msg">
                      {isDisabled && !data.order_size && '평형을 입력해주세요.'}
                    </p>
                  </div>
                </CustomInput>
              </ItemBox>
              <ItemBox width={53.5}>
                <CustomInput isShow={forms.includes(4) || !eventData}>
                  <span>*</span>
                  <p className="address">공사 주소</p>
                  <div className="select_wrap">
                    <div>
                      <select
                        name="order_area_1st"
                        onChange={changeSelectHandler}
                        className={data.order_area_1st === '' ? 'disabled' : ''}
                      >
                        <option value={''}>{'도/시'}</option>
                        {city_list?.area.map((item) => (
                          <option key={item.area_id} value={item.area_id}>
                            {item.area_name}
                          </option>
                        ))}
                      </select>
                      <p className="disabled_msg">
                        {isDisabled &&
                          !data.order_area_1st &&
                          '도/시를 입력해주세요.'}
                      </p>
                    </div>
                    <div>
                      <select
                        name="order_area_2nd"
                        onChange={changeSelectHandler}
                        className={data.order_area_2nd === '' ? 'disabled' : ''}
                      >
                        <option value={''}>{'시/구/군'}</option>
                        {city_list?.area
                          .find((item) => item.area_id === data.order_area_1st)
                          ?.area_2nd.map((item) => (
                            <option
                              key={item.area_detail_id}
                              value={item.area_detail_id}
                            >
                              {item.area_detail_name}
                            </option>
                          ))}
                      </select>
                      <p className="disabled_msg">
                        {isDisabled &&
                          !data.order_area_2nd &&
                          '시/구/군을 입력해주세요.'}
                      </p>
                    </div>
                  </div>
                </CustomInput>
              </ItemBox>
            </div>
            <div
              className={
                forms.includes(5) || !eventData ? 'info_flex_box' : undefined
              }
            >
              <ItemBox width={41}>
                <CustomInput isShow={forms.includes(5) || !eventData}>
                  <span>*</span>
                  <p className="date">공사 희망일</p>
                  <div className="select_wrap">
                    <div className="date_select_box">
                      <select
                        name="order_desired_period"
                        onChange={changeSelectHandler}
                        className={
                          data.order_desired_period === '' ? 'disabled' : ''
                        }
                      >
                        <option value={''}>{'선택'}</option>
                        {consts.wishdayoption.map((item) => (
                          <option key={item.idx} value={item.idx}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                      <p className="disabled_msg">
                        {isDisabled &&
                          !data.order_desired_period &&
                          '공사 희망일을 입력해주세요.'}
                      </p>
                    </div>
                  </div>
                </CustomInput>
              </ItemBox>
            </div>
            <div
              className={
                forms.includes(6) || !eventData ? 'info_flex_box' : undefined
              }
            >
              <ItemBox width={100}>
                <CustomInput isShow={forms.includes(6) || !eventData}>
                  <span />
                  <p className="description">요청사항(선택)</p>
                  <div className="input_wrap">
                    <div>
                      <input
                        name="order_msg"
                        onChange={changeInputHandler}
                        placeholder="기타 요청사항을 입력해주세요"
                      />
                    </div>
                    <p className="disabled_msg" />
                  </div>
                </CustomInput>
              </ItemBox>
            </div>
            <AllCheckWrap>
              <CustomCheckbox>
                <div>
                  <input
                    type="checkbox"
                    checked={
                      terms.terms1 && terms.terms2 && data.market_agree === '1'
                    }
                    onChange={() => {
                      if (
                        terms.terms1 &&
                        terms.terms2 &&
                        data.market_agree === '1'
                      ) {
                        setTerms({
                          terms1: false,
                          terms2: false,
                        });
                        setData((prev) => ({
                          ...prev,
                          market_agree: '0',
                        }));
                      } else {
                        setTerms({
                          terms1: true,
                          terms2: true,
                        });
                        setData((prev) => ({
                          ...prev,
                          market_agree: '1',
                        }));
                      }
                    }}
                  />
                  <span className="checkmark" />
                  <p>전체 약관 동의</p>
                </div>
                <button onClick={() => setShowMoreTerms(!showMoreTerms)}>
                  약관 펼쳐보기
                </button>
              </CustomCheckbox>
            </AllCheckWrap>
            <p className="disabled_msg">
              {isDisabled &&
                (!terms.terms1 || !terms.terms2) &&
                '동의 여부 체크가 필요합니다.'}
            </p>
            {showMoreTerms && (
              <MoreTermsWrap>
                <CustomCheckbox>
                  <div>
                    <input
                      type="checkbox"
                      checked={terms.terms1}
                      onChange={() =>
                        setTerms((prev) => ({
                          ...prev,
                          terms1: !terms.terms1,
                        }))
                      }
                    />
                    <span className="checkmark" />
                    <p>[필수] 이용약관 동의</p>
                  </div>
                  <button onClick={() => showClause(5)}>보기</button>
                </CustomCheckbox>
                <CustomCheckbox>
                  <div>
                    <input
                      type="checkbox"
                      checked={terms.terms2}
                      onChange={() =>
                        setTerms((prev) => ({
                          ...prev,
                          terms2: !terms.terms2,
                        }))
                      }
                    />
                    <span className="checkmark" />
                    <p>[필수] 개인정보 수집 및 이용 동의</p>
                  </div>
                  <button onClick={() => showClause(1)}>보기</button>
                </CustomCheckbox>
                <CustomCheckbox>
                  <div>
                    <input
                      type="checkbox"
                      checked={data.market_agree === '1'}
                      onChange={() =>
                        setData((prev) => ({
                          ...prev,
                          market_agree: prev.market_agree === '0' ? '1' : '0',
                        }))
                      }
                    />
                    <span className="checkmark" />
                    <p>[선택] 마케팅 정보 수신 동의</p>
                  </div>
                  <button onClick={() => showClause(4)}>보기</button>
                </CustomCheckbox>
              </MoreTermsWrap>
            )}
          </InfoSection>
          <ApplyWrap>
            <button
              className={isButtonDisabled() ? 'disabled' : ''}
              onClick={submitHandler}
            >
              상담 신청
            </button>
          </ApplyWrap>
        </Content>
      </Container>
    </Modal>
  );
};

export default EstimateModal;
