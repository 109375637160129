import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import images from '../../libs/images';

const ItemLink = styled(Link)`
  width: 489px;

  & > img {
    height: 652px;
    width: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1023px) {
    & > img {
      height: 450px;
    }
  }

  @media screen and (max-width: 720px) {
    width: 252px;

    & > img {
      height: 336px;
    }
  }
`;

const SubList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;

  & > img {
    width: 217px;
    height: 217px;
    border-radius: 50%;
  }

  .sub_list_box {
    position: relative;

    .name {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.4;
      color: #000;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  @media screen and (max-width: 720px) {
    & > img {
      position: relative;
      left: 9px;
    }

    .sub_list_box {
      .name {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

interface Props {
  rows: {
    model_name: string;
    style_imgs: {
      img_url: string;
      is_main: string;
      si_table_id: string;
    }[];
    theme_table_id: string;
    url: string;
    zone_type: string;
    zs_table_id: string;
  };
  type?: number;
}

export default function FrontThemeSubMain(props: Props) {
  const { rows } = props;

  const [img, setImg] = useState('');

  useEffect(() => {
    if (rows) {
      const imgArr = rows.style_imgs.filter((item) => item.img_url);
      setImg(imgArr.length > 0 ? imgArr[0].img_url : images.no_img);
    }
  }, [rows]);

  /* 대체 이미지 */
  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = images.no_img;
  };

  return (
    <ItemLink to={`/theme/${rows.theme_table_id}?zone=${rows.zone_type}`}>
      <div>
        <SubList>
          <div className="sub_list_box">
            <p className="name">{rows.model_name}</p>
          </div>
          <img src={img} onError={handleImgError} />
        </SubList>
      </div>
    </ItemLink>
  );
}
