import React from 'react';
import Layout from 'components/Layout';
import { useParams } from 'react-router-dom';
import useThemeQueries from 'hooks/queries/theme';
import ThemeDetailComponent from 'components/Theme/Detail';

const ThemeDetailPage = () => {
  const { id } = useParams();
  const { getThemeDetailQuery } = useThemeQueries();

  const { data: theme, isFetching } = getThemeDetailQuery({
    theme_table_id: String(id),
  });

  if (!theme || isFetching) {
    return <></>;
  }

  return (
    <Layout headerText={theme.theme_title} is_hide_mobile_bottom_sheet>
      <ThemeDetailComponent id={String(id)} theme={theme} />
    </Layout>
  );
};

export default ThemeDetailPage;
