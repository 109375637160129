// 해당 컴포넌트는 모바일 환경일 때, 가로로 화면 전화 했을 경우 화면에 구성 되는 컴포넌트 입니다.

import React, { useEffect } from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const ModalPortal = (props: Props) => {
  const { children } = props;
  const el = document.getElementById('mobile-cover') as HTMLElement;

  return ReactDom.createPortal(children, el);
};

const MobileCover = () => {
  useEffect(() => {
    const layout = document.getElementsByClassName(
      'layout'
    ) as HTMLCollectionOf<HTMLElement>;

    if (layout.length > 0) {
      layout[0].style.cssText = `
              position: fixed;
              top: -${window.scrollY}px;
              width: 100%;
          `;

      return () => {
        const scrollY = layout[0].style.top;
        layout[0].style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  }, []);

  return (
    <ModalPortal>
      <ModalContainer>
        <p>세로화면에 최적화 되어있습니다.</p>
      </ModalContainer>
    </ModalPortal>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30000;
  display: flex;
  background-color: rgb(0, 0, 0, 0.8);

  & > p {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    margin: auto;
  }
`;

export default MobileCover;
