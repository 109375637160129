import React from 'react';
import styled from 'styled-components';
import emphasize_circel from '../../assets/images/life-construction/emphasize_circel.svg';
import { slideBannerList } from '../../utils/life-construction';
import { Swiper, SwiperSlide } from 'swiper/react';

const Container = styled.section`
  background-color: #f3f1f1;
  height: 445px;
`;

const TitleWrap = styled.div`
  margin-left: 20%;
  padding-top: 65px;

  & > h1 {
    color: #000000;
    font-size: 40px;
    line-height: 50px;
    font-weight: 600;

    & > div {
      font-weight: bold;
      display: flex;
      line-height: 82px;

      & > p {
        font-weight: bold;
        width: 190px;
        height: 82px;
        background: url(${emphasize_circel});
        background-size: 190px 82px;
        margin-left: -15px;
        padding-left: 15px;
        line-height: 82px;
      }
    }
  }

  @media screen and (max-width: 1106px) {
    & > h1 {
      font-size: 32px;

      & > div {
        line-height: 52px;
        & > p {
          line-height: 52px;
          width: 160px;
          height: 68px;
          background-size: 160px 68px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-left: 24px;
    padding-top: 106px;

    & > h1 {
      font-size: 24px;
      line-height: 1.5;

      & > div {
        & > p {
          width: 110px;
          height: 48px;
          background-size: 110px 48px;
        }
      }
    }
  }
`;

const SlideWrap = styled.div`
  margin-top: 42px;

  .swiper {
    height: 100%;

    .swiper-wrapper {
      transition-timing-function: linear;

      .swiper-slide {
        text-align: right;
        width: 286px;
        height: 100%;
        display: flex;
      }
    }
  }

  @media screen and (max-width: 1106px) {
    margin-top: 46px;
  }
`;

const SlideImage = styled.img<{ auto: string }>`
  margin-left: auto;
  margin-top: ${(props) => props.auto === 'bottom' && 'auto'};
  margin-bottom: ${(props) => props.auto === 'top' && 'auto'};
  width: 204px;
  height: 206px;
`;

const BannerSection = () => {
  return (
    <Container>
      <TitleWrap>
        <h1>
          합리적인 가격의 <br />
          <div>
            <p>생활시공</p> 서비스를 경험하세요.
          </div>
        </h1>
      </TitleWrap>
      <SlideWrap>
        <Swiper
          loop={true}
          autoplay={{ delay: 1, disableOnInteraction: false }}
          slidesPerView="auto"
          speed={5000}
          allowTouchMove={false}
        >
          {slideBannerList.map((banner) => (
            <SwiperSlide key={banner.id} className="tttt">
              <SlideImage
                src={banner.image}
                alt={banner.title}
                auto={banner.position}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </SlideWrap>
    </Container>
  );
};

export default BannerSection;
