import React, { useEffect, useState, useRef } from 'react';
import images from '../../../libs/images';
import consts from '../../../libs/consts';

import { numFormat, findJson } from '../../../utils/utils';

import StarBox from '../../Common/StarBox';
import { ThemeList, ThemeType, Theme } from '../../../types/theme';
import styled from 'styled-components';
import ReviewModal from 'components/Review/Modal/ReviewModal';

const Content = styled.div`
  .title {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 34px;

    & > p {
      font-size: 44px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: -1.83px;
    }
  }

  .tag_wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    .tag_item {
      & > p {
        border: 1px solid #e1e1e1;
        border-radius: 20px;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        margin-right: 4px;
        padding: 10px 14px 10px 15px;
      }
    }

    .review_item {
      align-items: center;
      display: flex;

      & > p {
        &:first-of-type {
          font-size: 26px;
          font-weight: 600;
          letter-spacing: 3.51px;
          line-height: 1.5;
          margin-right: 7.7px;
        }

        &:last-of-type {
          color: #212121;
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          margin-top: 8px;
          text-decoration: underline;
        }
      }
    }
  }

  .description_wrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 27px;

    & > p {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.67px;
      line-height: 1.75;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      word-break: break-all;
    }
  }

  @media (max-width: 767px) {
    border-bottom: 1px solid #e1e1e1;

    .title {
      margin: 20px 0 0 0;

      & > p {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -1px;
        line-height: 1.5;
      }
    }

    .tag_wrap {
      .tag_item {
        margin-top: 6px;
        & > p {
          margin-top: 4px;
        }
      }
    }

    .description_wrap {
      margin: 10px 0 19px;

      & > p {
        font-size: 14px;
        letter-spacing: -0.58px;
        line-height: 1.71;
        word-break: unset;
      }
    }

    & > button {
      color: #909090;
      left: 45%;
      margin-bottom: 19px;
    }
  }
`;

interface Props {
  list1: ThemeList[];
  res1: ThemeType[];
  row: Theme;
}

export default function FrontTopBox(props: Props) {
  const { row, list1, res1 } = props;

  const descRef = useRef<HTMLParagraphElement>(null);

  const [btnView, setBtnView] = useState(false);
  const [active, setActive] = useState(false);

  const [allPrice, setAllPrice] = useState(0);

  const [allPrice1, setAllPrice1] = useState(0);
  const [isOpenReview, setIsOpenReview] = useState(false);

  const addFunc = () => {
    if (row.review_cnt === 0) {
      return;
    }
    setIsOpenReview(true);
  };

  useEffect(() => {
    let total = 0;
    list1.map((x) => (total += Number(x.price) * Number(x.cnt)));
    setAllPrice(total);
  }, [list1]);

  /* 시공 총합 */
  useEffect(() => {
    let total = 0;
    res1.map((x) => (total += Number(x.price) * 1));
    setAllPrice1(total);
  }, [res1]);

  useEffect(() => {
    if (descRef.current) {
      if (descRef.current.scrollHeight > descRef.current.clientHeight) {
        setBtnView(true);
      }
    }
  }, [descRef]);

  return (
    <div>
      <Content>
        <div className="title">
          <p>{row.theme_title}</p> {/* 테마명 */}
          <StarBox star={row.review_score} />
        </div>
        <div className="tag_wrap">
          <div className="tag_item">
            {/* 평형 */}
            <p>{row.rep_size}평</p>
            <p>{numFormat(allPrice + allPrice1)}만원</p> {/* 테마 공사 가격 */}
            <p>{findJson(consts.workoption, row.theme_type)}</p>{' '}
            {/* 주거유형 */}
            <p>시공 {Math.round(row.theme_period / 7)}주</p> {/* 기간  */}
          </div>
          <div className="review_item">
            <p>{row.review_score}</p>
            <p onClick={() => addFunc()}>
              리뷰{' '}
              {row.review_cnt > 0 ? numFormat(row.review_cnt) + '개' : '없음'}
            </p>
          </div>
        </div>
        {/* 테마 설명 */}
        <div className="description_wrap">
          <p
            ref={descRef}
            style={{
              display: active ? 'flex' : '-webkit-box',
            }} /*dangerouslySetInnerHTML={{__html: row.theme_desc}}*/
          >
            {row.theme_desc}
          </p>
        </div>

        {btnView && !active ? (
          <button
            className="front_theme_detail_section2_box4_more"
            onClick={() => setActive(!active)}
          >
            more
            <img src={images.more_btn} />
          </button>
        ) : btnView ? (
          <button
            className="front_theme_detail_section2_box4_more"
            onClick={() => setActive(!active)}
          >
            close
            <img src={images.up} />
          </button>
        ) : (
          <></>
        )}
      </Content>
      {isOpenReview && <ReviewModal close={() => setIsOpenReview(false)} />}
    </div>
  );
}
