import React from 'react';
import styled from 'styled-components';
import strip_banner from '../../assets/images/life-construction/strip_banner.png';

const Container = styled.section`
  background: url(${strip_banner});
  background-size: cover;
  min-height: 285px;
  display: flex;
`;

const Content = styled.div`
  margin: auto;
  text-align: center;

  br {
    display: none;
  }

  & > h2 {
    font-size: 34px;
    font-weight: bold;
  }

  & > p {
    margin-top: 14px;
    font-size: 19px;
    font-weight: normal;

    & > span {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 767px) {
    br {
      display: block;
    }

    & > h2 {
      font-size: 24px;
    }

    & > p {
      font-size: 14px;
    }
  }
`;

const StripBannerSection = () => {
  return (
    <Container>
      <Content>
        <h2>
          업체마다 들쑥날쑥한
          <br /> 생활시공 비용에 불만이셨죠?
        </h2>
        <p>
          깐깐한 검수를 거친 합리적인 레이홈가로 만족도 높은 생활시공 서비스를
          경험해 보세요.
        </p>
      </Content>
    </Container>
  );
};

export default StripBannerSection;
