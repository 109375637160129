import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import images from 'libs/images';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { HeaderTypes } from './types/headers';
import { GrPrevious } from 'react-icons/gr';
import { ORDER_ESTIMATE_TYPE } from 'utils/enum/estimate';

import EstimateModal from '../Common/Modal/EstimateModal';
import ConfirmModal from 'components/Common/Modal/ConfirmModal';
import { getSaveDate } from 'utils/utils';
import useBannerStore from 'stores/useBannerStore';
import LoginComponent from './Login';
import useUserStore from 'stores/useUserStore';
import MyImage from 'components/Layout/Login/Common/My';
import LoginWrapModal from './Login/LoginWrapModal';
import BasicLogin from './Login/BasicLogin';
import useVh from 'hooks/useVh';

const Container = styled.header`
  height: 85px;
  border-bottom: 1px solid #d9d9d9;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;

  @media screen and (max-width: 767px) {
    height: 60px;
    background: none;
    border: none;

    &.scrolled {
      background-color: #ffffff;
      border-bottom: 1px solid #d9d9d9;

      .mobile_left_side_wrap {
        & > button {
          & > svg {
            & > polyline {
              stroke: #000000;
            }
          }
        }
        & > h3 {
          color: #000000;
        }
      }

      .mobile_right_side_wrap {
        & > button {
          & > svg {
            color: #000000;
          }
        }
      }
    }
  }
`;

const Content = styled.div`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  @media screen and (max-width: 1260px) {
    width: calc(100% - 60px);
  }
`;

const LogoWrap = styled.div`
  display: flex;

  & > a {
    margin: auto 0;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 26px;

    & > img {
      width: 126px;
      height: 40px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MobileLogo = styled.div<{
  isHeaderTextAlign?: 'center' | 'left';
  is_mobile_header_white?: boolean;
}>`
  display: none;

  @media screen and (max-width: 767px) {
    display: flex;
    width: 100%;

    & > a {
      margin: auto 0;
      font-weight: 700;
      font-size: 26px;

      & > img {
        width: 91px;
        height: 29px;
        object-fit: contain;
      }
    }

    & > div {
      display: flex;
      width: 100%;

      & > button {
        background: none;
        margin: auto 0;
        font-size: 22px;
        display: flex;

        & > svg {
          & > polyline {
            stroke: ${(props) =>
              props.is_mobile_header_white ? '#ffffff' : '#000000'};
          }
        }
      }
      & > h3 {
        margin: ${(props) =>
          props.isHeaderTextAlign === 'center' ? 'auto' : 'auto 0 auto 20px'};
        font-size: 18px;
        color: ${(props) =>
          props.is_mobile_header_white ? '#ffffff' : '#000000'};
        font-weight: normal;
      }
    }
  }
`;

const MenuWrap = styled.ul`
  margin-left: auto;
  display: flex;

  & > li {
    margin-left: 75px;
    height: 100%;
    display: flex;

    &:first-of-type {
      margin-left: 0;
    }

    &.report_menu {
      margin: 0 -20px 0 55px;
      padding: 0 20px;

      &:hover {
        & > ul {
          display: flex;
        }
      }
    }

    &:first-of-type {
      margin-left: 0;
    }

    & > a,
    div {
      margin: auto 0;
      color: #000000;
      font-size: 18px;
      font-weight: 600;

      &:hover {
        color: #fa5500;
      }

      &.active {
        color: #fa5500;
      }

      &.disabled_menu {
        cursor: not-allowed;
        position: relative;

        & > p {
          font-weight: 600;
          color: rgba(143, 142, 142, 0.63);
        }

        & > span {
          position: absolute;
          left: 3px;
          top: -15px;
          width: 110px;
          font-size: 12px;
          color: #fa5500;
          font-weight: 500;
        }
      }

      .sub_text {
        color: #fa5500;
      }
    }
  }

  @media screen and (max-width: 1090px) {
    & > li {
      margin-left: 30px;
      height: 100%;
      display: flex;

      &.report_menu {
        margin: 0 -20px 0 10px;
        padding: 0 20px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    & > li {
      & > a,
      div {
        font-size: 15px;

        &.disabled_menu {
          & > span {
            left: -3px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 840px) {
    & > li {
      margin-left: 20px;

      &.report_menu {
        margin: 0;
        padding: 0 20px;
      }
      & > a,
      div {
        font-size: 13px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const EstimateWrap = styled.div`
  margin-left: 20px;
  display: flex;

  & > button {
    margin: auto 0;
    padding: 8px 21px;
    font-size: 18px;
    font-weight: 600;
    background-color: #000000;
    color: #ffffff;
    border-radius: 5px;
  }

  @media screen and (max-width: 1090px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 960px) {
    & > button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }

  @media screen and (max-width: 960px) {
    & > button {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const SubMenuContent = styled.ul`
  background-color: rgba(225, 225, 225, 0.8);
  height: 55px;
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;
  display: none;

  & > div {
    display: flex;
    width: 1200px;
    margin: 0 auto !important;

    & > li {
      margin-left: 56px;
      display: flex;

      &:first-of-type {
        margin-left: auto;
      }

      &:last-of-type {
        margin-right: 303px;
      }

      & > a {
        margin: auto 0 auto auto;
        color: #000000;
        font-size: 16px;

        &.active {
          color: #fa5500;
          font-weight: 600;
        }

        &:hover {
          font-weight: 600;
          color: #fa5500;
        }
      }
    }
  }

  @media screen and (max-width: 1260px) {
    & > div {
      & > li {
        &:last-of-type {
          margin-right: 313px;
        }
      }
    }
  }

  @media screen and (max-width: 1240px) {
    & > div {
      & > li {
        &:last-of-type {
          margin-right: 333px;
        }
      }
    }
  }

  @media screen and (max-width: 1090px) {
    & > div {
      & > li {
        &:last-of-type {
          margin-right: 288px;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    & > div {
      & > li {
        &:last-of-type {
          margin-right: 212px;
        }

        & > a {
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 840px) {
    & > div {
      & > li {
        &:last-of-type {
          margin-right: 198px;
        }
        & > a {
          font-size: 12px;
        }
      }
    }
  }
`;

const MobileMenuTab = styled.div<{ is_mobile_header_white?: boolean }>`
  display: none;
  margin: auto 0 auto auto;

  & > button {
    background: none;
    font-size: 22px;
    color: #000000;

    & > svg {
      color: ${(props) =>
        props.is_mobile_header_white ? '#ffffff' : '#000000'};
    }
  }

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const MobileMenuContent = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 1000;
`;

const MobileMenuHeader = styled.div`
  width: calc(100% - 60px);
  margin: 0 auto;
  padding: 14px 0;
  display: flex;
  justify-content: space-between;

  .btn_wrap {
    margin: auto 0;
  }
`;

const MobileMenuWrap = styled.div`
  width: calc(100% - 60px);
  margin: 20px auto 0 auto;
  height: calc(var(--vh, 1vh) * 100 - 200px);

  & > li {
    padding: 15px 0;
    display: table;

    .active {
      color: #fa5500;
    }

    & > a,
    p,
    div {
      cursor: pointer;
      font-family: 'Noto Sans KR';
      font-size: 18px;
      font-weight: 600;
      display: flex;

      & > svg {
        margin: auto 0 auto 20px;
      }

      &.disabled_mobile_menu {
        display: inline-flex;
        color: #abaaaa;
        margin: 0;
        cursor: not-allowed;

        .coming_soon {
          margin: auto 0 0 10px;
          color: #fa5500;
          font-size: 16px;
          font-weight: 500;
        }

        & > p {
          cursor: not-allowed;
        }
      }

      .sub_text {
        color: #fa5500;
        font-size: 16px;
        font-weight: 500;
        margin: auto 0 0 10px;
      }
    }

    .mobile_sub_menu {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;

      & > li {
        padding: 10px 0;
        width: 100%;

        & > a {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  & > div {
    margin-top: 20px;
    text-align: left;

    & > button {
      margin: auto 0;
      padding: 13px 57px;
      font-size: 13px;
      font-weight: 700;
      background-color: #000000;
      color: #ffffff;
    }
  }
`;

const MobileLoginWrap = styled.div`
  width: calc(100% - 60px);
  margin: 18px auto 0 auto;

  .after_login_wrap {
    display: flex;

    & > svg {
      width: 30px;
      height: 24px;
    }

    & > p {
      margin: auto 0 auto 10px;
      font-size: 18px;
      font-weight: 400;

      & > b {
        font-weight: 700;
      }
    }
  }

  .before_login_btn {
    display: flex;

    & > p {
      color: #000;
      font-size: 18px;
      font-weight: 400;
    }

    & > svg {
      margin: 8px 0 auto 22px;
    }
  }
`;

const MobileLogoutWrap = styled.div`
  width: calc(100% - 60px);
  margin: 0 auto;

  & > button {
    width: 97px;
    height: 30px;
    border: 1px solid #777;
    color: rgba(102, 102, 102, 0.8);
    font-size: 13px;
    font-weight: 400;
  }
`;

const Header = (props: HeaderTypes) => {
  const {
    isHeaderTextAlign = 'left',
    headerText,
    is_mobile_header_white,
  } = props;

  useVh();

  const { user, logout } = useUserStore();

  const [isOpenLoginWrap, setIsOpenLoginWrap] = useState(false);
  const [isOpenBasicLogin, setIsOpenBasicLogin] = useState(false);

  const navigate = useNavigate();
  const { pathname } = window.location;

  const [isOpenEstimate, setIsOpenEstimate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const [isMobileOpenMenu, setIsMobileOpenMenu] = useState(false);
  const [isOpenReportMenu, setIsOpenReportMenu] = useState(
    pathname.includes('/report')
  );

  const headerRef = useRef<HTMLDivElement | null>(null);

  const { isHideLineBanner } = useBannerStore();

  const savedate = getSaveDate();

  useEffect(() => {
    if (isMobileOpenMenu) {
      document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY}px;
        width: 100%;
    `;

      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  }, [isMobileOpenMenu]);

  const scrollHandler = () => {
    const isHide = isHideLineBanner === savedate;

    const header = headerRef.current;

    if (header) {
      const rect = header.getBoundingClientRect();
      const { top } = rect;

      if (isHide || !isHideLineBanner) {
        if (scrollY > 0) {
          header.classList.add('scrolled');
        } else {
          header.classList.remove('scrolled');
        }
      } else {
        if (Math.floor(top) <= 0) {
          header.classList.add('scrolled');
        } else {
          header.classList.remove('scrolled');
        }
      }
    }
  };

  useEffect(() => {
    scrollHandler();

    const handleScroll = () => {
      scrollHandler();
    };

    window.addEventListener('scroll', handleScroll);

    // 컴포넌트 언마운트 시에 이벤트 핸들러 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isHideLineBanner]);

  const callback = () => {
    setIsOpenEstimate(false);
    setIsConfirm(true);
  };

  const handleBasicLogin = () => {
    setIsOpenLoginWrap(false);
    setIsOpenBasicLogin(true);
  };

  const handleLoginWrap = () => {
    setIsOpenLoginWrap(true);
  };

  const logoutHandler = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      logout();
      setIsMobileOpenMenu(false);
    }
  };

  return (
    <Container className="layhome_header" ref={headerRef}>
      <Content>
        <LogoWrap>
          <Link to="/">
            <img src={images.logo_black} alt="logo" />
          </Link>
        </LogoWrap>
        {headerText ? (
          <MobileLogo
            isHeaderTextAlign={isHeaderTextAlign}
            is_mobile_header_white={is_mobile_header_white}
          >
            <div className="mobile_left_side_wrap">
              <button onClick={() => navigate(-1)}>
                <GrPrevious />
              </button>
              <h3>{headerText}</h3>
            </div>
          </MobileLogo>
        ) : (
          <MobileLogo>
            <Link to="/">
              <img src={images.logo_black} alt="logo" />
            </Link>
          </MobileLogo>
        )}
        <MenuWrap>
          <li>
            <Link
              to="/theme"
              className={
                pathname === '/theme' || pathname.includes('/theme/')
                  ? 'active'
                  : ''
              }
            >
              테마 인테리어
            </Link>
          </li>
          <li>
            <Link
              to="/life-construction"
              className={pathname === '/life-construction' ? 'active' : ''}
            >
              생활시공<span className="sub_text">Beta</span>
            </Link>
          </li>
          <li className="report_menu">
            <Link
              to="/report"
              className={pathname.includes('/report') ? ' active' : ''}
            >
              인테리어 중개
            </Link>
            <SubMenuContent>
              <div>
                <li>
                  <Link
                    to="/report"
                    className={
                      pathname === '/report' || pathname.includes('/report/')
                        ? 'active'
                        : ''
                    }
                  >
                    시공사례 모음
                  </Link>
                </li>
                <li>
                  <Link
                    to="/report-partner"
                    className={
                      pathname.includes('/report-partner') ? 'active' : ''
                    }
                  >
                    시공 전문가
                  </Link>
                </li>
              </div>
            </SubMenuContent>
          </li>
          <li>
            <Link to="/event" className={pathname === '/event' ? 'active' : ''}>
              특가/혜택
            </Link>
          </li>
          {/* <li>
            <div className="disabled_menu">
              <p>견적 계산기</p>
              <span>Coming Soon!</span>
            </div>
          </li> */}
        </MenuWrap>

        <LoginComponent />
        <EstimateWrap>
          <button onClick={() => setIsOpenEstimate(true)}>상담신청</button>
        </EstimateWrap>
        <MobileMenuTab
          is_mobile_header_white={is_mobile_header_white}
          className="mobile_right_side_wrap"
        >
          <button onClick={() => setIsMobileOpenMenu(!isMobileOpenMenu)}>
            <HiOutlineMenuAlt4 />
          </button>
        </MobileMenuTab>
      </Content>

      {isOpenEstimate && (
        <EstimateModal
          close={() => setIsOpenEstimate(false)}
          order_estimate_type={ORDER_ESTIMATE_TYPE.DEFAULT}
          callback={callback}
        />
      )}
      {isConfirm && <ConfirmModal close={() => setIsConfirm(false)} />}

      {isMobileOpenMenu && (
        <MobileMenuContent>
          <MobileMenuHeader>
            <LogoWrap>
              <Link to="/">
                <img src={images.logo_black} alt="logo" />
              </Link>
            </LogoWrap>
            <MobileLogo>
              <Link to="/">
                <img src={images.logo_black} alt="logo" />
              </Link>
            </MobileLogo>
            <div className="btn_wrap">
              <button onClick={() => setIsMobileOpenMenu(false)}>
                <img src={images.exit} alt="images_exit" />
              </button>
            </div>
          </MobileMenuHeader>
          <MobileLoginWrap>
            {user ? (
              <div className="after_login_wrap">
                <MyImage />
                <p>
                  <b>{user.user_name}</b> 님 안녕하세요!
                </p>
              </div>
            ) : (
              <button className="before_login_btn" onClick={handleLoginWrap}>
                <p>로그인/회원가입</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="12"
                  viewBox="0 0 9 12"
                  fill="none"
                >
                  <path
                    d="M1 11L7 5.733L1 0.75862"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            )}
          </MobileLoginWrap>
          <MobileMenuWrap>
            <li>
              <Link to="/" className={pathname === '/' ? 'active' : ''}>
                홈
              </Link>
            </li>

            <li>
              <Link
                to="/theme"
                className={
                  pathname === '/theme' || pathname.includes('/theme/')
                    ? 'active'
                    : ''
                }
              >
                테마 인테리어
              </Link>
            </li>
            <li>
              <Link
                to="/life-construction"
                className={pathname === '/life-construction' ? 'active' : ''}
              >
                생활시공 <span className="sub_text">Beta</span>
              </Link>
            </li>
            <li>
              <p
                onClick={() => setIsOpenReportMenu(!isOpenReportMenu)}
                className={
                  pathname === '/report' ||
                  pathname.includes('/report/') ||
                  pathname === '/report-partner' ||
                  pathname.includes('/report-partner/')
                    ? 'active'
                    : ''
                }
              >
                인테리어 중개{' '}
                {isOpenReportMenu ? <BsChevronUp /> : <BsChevronDown />}
              </p>
              {isOpenReportMenu && (
                <ul className="mobile_sub_menu">
                  <li>
                    <Link
                      to="/report"
                      className={
                        pathname === '/report' || pathname.includes('/report/')
                          ? 'active'
                          : ''
                      }
                    >
                      시공사례모음
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/report-partner"
                      className={
                        pathname === '/report-partner' ||
                        pathname.includes('/report-partner/')
                          ? 'active'
                          : ''
                      }
                    >
                      시공 전문가
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link
                to="/event"
                className={pathname === '/event' ? 'active' : ''}
              >
                특가 / 혜택
              </Link>
            </li>
            {/* <li>
              <div className="disabled_mobile_menu">
                <p>견적 계산기</p>
                <p className="coming_soon">Coming Soon!</p>
              </div>
            </li> */}
            <div>
              <button onClick={() => setIsOpenEstimate(true)}>상담신청</button>
            </div>
          </MobileMenuWrap>
          {user && (
            <MobileLogoutWrap>
              <button onClick={logoutHandler}>로그아웃</button>
            </MobileLogoutWrap>
          )}
          {isOpenLoginWrap && (
            <LoginWrapModal
              close={() => setIsOpenLoginWrap(false)}
              handleBasicLogin={handleBasicLogin}
            />
          )}
          {isOpenBasicLogin && (
            <BasicLogin close={() => setIsOpenBasicLogin(false)} />
          )}
        </MobileMenuContent>
      )}
    </Container>
  );
};

export default Header;
