import bottom_sheet_home from './bottom_sheet_home.png';
import bottom_sheet_home_active from './bottom_sheet_home_active.png';
import bottom_sheet_theme from './bottom_sheet_theme.png';
import bottom_sheet_theme_active from './bottom_sheet_theme_active.png';
import bottom_sheet_partner from './bottom_sheet_partner.png';
import bottom_sheet_partner_active from './bottom_sheet_partner_active.png';
import bottom_sheet_event from './bottom_sheet_event.png';
import bottom_sheet_event_active from './bottom_sheet_event_active.png';

const bottomSheetImages = {
  home: bottom_sheet_home,
  home_active: bottom_sheet_home_active,
  theme: bottom_sheet_theme,
  theme_active: bottom_sheet_theme_active,
  partner: bottom_sheet_partner,
  partner_active: bottom_sheet_partner_active,
  event: bottom_sheet_event,
  event_active: bottom_sheet_event_active,
};

export { bottomSheetImages };
