import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import prev_arrow_circle from './images/prev_arrow_circle.png';
import next_arrow_circle from './images/next_arrow_circle.png';
import { Home } from 'types/home';
import { Swiper as SwiperType } from 'swiper';

const Container = styled.div`
  background-color: #000000;
  padding: 30px 0 90px 0;

  & > h1 {
    color: #ffffff;
    font-weight: 700;
    font-size: 36px;
    line-height: 75px;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    padding: 33px 0 40px 0;

    & > h1 {
      font-size: 17px;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

const FilterWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 80px;
  padding-bottom: 20px;
  width: 830px;
  margin: 25px auto 0 auto;

  & > p {
    cursor: pointer;
    margin: auto 0;
    color: #8f8e8e;
    font-size: 18px;
    font-weight: 400;
    padding: 0 10px 10px 10px;

    &.active {
      color: #ffffff;
      border-bottom: 2px solid #ffffff;
      font-size: 22px;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 15px;
    padding: 0;
    width: 100%;
    gap: 30px;

    & > p {
      font-size: 11px;
      padding: 0 5px 5px 5px;

      &.active {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
`;

const Content = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 35px auto 0 auto;
  position: relative;

  &:hover {
    .arrow_wrap {
      display: block;
    }
  }

  .swiper {
    margin: 0 65px;

    .swiper-wrapper {
      .swiper-slide {
        cursor: pointer;

        &:hover {
          .image_content {
            & > img {
              transform: scale(1.1);
              transition: transform 0.2s;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    max-width: auto;
    width: calc(100% - 30px);
    margin: 20px 0 0 30px;

    &:hover {
      .arrow_wrap {
        display: none;
      }
    }

    .swiper {
      margin: 0;
    }
  }
`;

const ImageContent = styled.div`
  position: relative;
  padding-bottom: 133%;
  overflow: hidden;

  & > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.2s;
  }
`;

const InfoContent = styled.div`
  margin-top: 20px;
  text-align: center;

  & > h3,
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  & > h3 {
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    -webkit-line-clamp: 1;
  }

  & > p {
    margin-top: 8px;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    line-height: 30px;
    -webkit-line-clamp: 3;
    word-wrap: break-word;
  }

  @media screen and (max-width: 767px) {
    margin-top: 10px;

    & > h3 {
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      -webkit-line-clamp: 2;
    }

    & > p {
      margin-top: 5px;
      font-size: 12px;
      line-height: 15px;
      line-height: 20px;
    }
  }
`;

const ArrowWrap = styled.div`
  display: none;

  & > button {
    background: none;
    position: absolute;
    top: 33%;
    z-index: 100;

    &:disabled {
      display: none;
    }

    & > img {
      width: 65px;
      height: 65px;
    }

    &.prev_arrow {
      left: -10px;
    }

    &.next_arrow {
      right: -10px;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const Progressbar = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    width: calc(100% - 30px);
    position: relative;
    align-items: center;
    z-index: 1;

    .front_swiper_pagination {
      background: hsla(0, 0%, 100%, 0.25);
      height: 2px;
      position: relative;

      .swiper-pagination-progressbar-fill {
        opacity: 1 !important;
        background: #fff;
      }
    }
  }
`;

interface Props {
  data: Home['content_list'];
}

const InteriorBrokerage = (props: Props) => {
  const { data } = props;
  const [filter, setFilter] = useState(0);

  const [swiper, setSwiper] = useState<SwiperType | null>(null); // swiper 인스턴스를 저장할 상태

  const contentList = useMemo(() => {
    const seqData = data.sort((a, b): number => {
      if (Number(a.display_seq) > Number(b.display_seq)) {
        return 1;
      } else if (Number(a.display_seq) === Number(b.display_seq)) {
        return 0;
      } else if (Number(a.display_seq) < Number(b.display_seq)) {
        return -1;
      } else {
        return 0;
      }
    });

    return seqData.flatMap((item) => item.detail) || [];
  }, [data]);

  const handleSwiper = useCallback((swiperInstance: SwiperType) => {
    setSwiper(swiperInstance);
  }, []);

  const onClickTabHandler = (index: number) => {
    setFilter(index);

    if (swiper) {
      swiper.slideTo(index * 3);
    }
  };

  const onRoute = (url: string) => {
    window.location.href = url;
  };

  return (
    <Container>
      <h1>취향에 걸맞는 인테리어 중개</h1>
      <FilterWrap>
        {data.map((item, i) => (
          <p
            key={item.content_table_id}
            className={i === filter ? 'active' : ''}
            onClick={() => onClickTabHandler(i)}
          >
            {item.content_name}
          </p>
        ))}
      </FilterWrap>
      <Content>
        <Swiper
          slidesPerView={2.5}
          slidesPerGroup={1}
          spaceBetween={24}
          pagination={{
            el: '.front_sw_pagebox .front_swiper_pagination',
            type: 'progressbar',
          }}
          navigation={{
            prevEl: '.front_sw_pagebox .prev_arrow',
            nextEl: '.front_sw_pagebox .next_arrow',
          }}
          onSlideChange={(slide) => {
            const { currentBreakpoint } = slide;
            const slideIndex = slide.realIndex;

            if (currentBreakpoint === '768') {
              const dataIndex = Math.ceil(slideIndex / 3);

              setFilter(dataIndex);
            } else if (currentBreakpoint === 'max') {
              const dataIndex = Math.floor(slideIndex / 3);
              setFilter(dataIndex);
            }
          }}
          onSwiper={handleSwiper} // onSwiper 콜백을 설정하여 swiper 인스턴스를 저장
          breakpoints={{
            768: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
        >
          {contentList.map((item, i) => (
            <SwiperSlide
              key={i}
              onClick={() => onRoute(item.content_detail_url)}
            >
              <ImageContent className="image_content">
                <img src={item.content_detail_img} alt="content_detail_img" />
              </ImageContent>
              <InfoContent>
                <h3>{item.content_detail_title}</h3>
                <p>{item.content_detail_desc}</p>
              </InfoContent>
            </SwiperSlide>
          ))}
        </Swiper>
        <ArrowWrap className="front_sw_pagebox arrow_wrap">
          <button className="prev_arrow" disabled={swiper?.isBeginning}>
            <img src={prev_arrow_circle} alt="banner_arrow_left" />
          </button>
          <button className="next_arrow" disabled={swiper?.isEnd}>
            <img src={next_arrow_circle} alt="banner_arrow_right" />
          </button>
        </ArrowWrap>
        <Progressbar className="front_sw_pagebox">
          <div className="front_swiper_pagination"></div>
        </Progressbar>
      </Content>
    </Container>
  );
};

export default InteriorBrokerage;
