import { useQuery } from 'react-query';
import { getMonthlyList } from 'apis/userLifeOrder';
import * as QueryKeys from 'constants/queryKeys';

const defaultOptions = { staleTime: 60 * 1000 };

const useLifeQueries = () => {
  const getMonthlyListQuery = () => {
    return useQuery(QueryKeys.LIFE_MOMTHLY_ITEM_LIST, () => getMonthlyList(), {
      ...defaultOptions,
    });
  };

  return {
    getMonthlyListQuery,
  };
};

export default useLifeQueries;
