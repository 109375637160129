import React from 'react';
import styled from 'styled-components';
import {
  brand_1,
  brand_2,
  brand_3,
  brand_4,
  brand_5,
  brand_6,
  brand_7,
  brand_8,
} from '../../assets/images/life-construction';

const Container = styled.section`
  margin-top: 117px;
  margin-bottom: 220px;
  display: flex;

  @media screen and (max-width: 767px) {
    margin-top: 60px;
    margin-bottom: 95px;
  }
`;

const Content = styled.div`
  width: 890px;
  margin: 0 auto;

  @media screen and (max-width: 920px) {
    width: calc(100% - 60px);
  }
`;

const SectionHeader = styled.div`
  text-align: center;

  & > h3 {
    font-size: 30px;
    font-weight: 700;
  }

  @media screen and (max-width: 767px) {
    & > h3 {
      font-size: 19px;
    }
  }
`;

const ContentWrap = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  & > div {
    width: 200px;
    height: 76px;
    display: flex;

    & > img {
      margin: auto;
    }

    &:nth-child(1) {
      & > img {
        width: 139px;
        height: 51px;
      }
    }

    &:nth-child(2) {
      & > img {
        width: 138px;
        height: 76px;
      }
    }

    &:nth-child(3) {
      & > img {
        width: 167px;
        height: 76px;
      }
    }

    &:nth-child(4) {
      & > img {
        width: 162px;
        height: 62px;
      }
    }

    &:nth-child(5) {
      & > img {
        width: 195px;
        height: 62px;
      }
    }

    &:nth-child(6) {
      & > img {
        width: 152px;
        height: 61px;
      }
    }

    &:nth-child(7) {
      & > img {
        width: 180px;
        height: 53px;
      }
    }

    &:nth-child(8) {
      & > img {
        width: 162px;
        height: 73px;
      }
    }
  }

  @media screen and (max-width: 920px) {
    gap: 30px 2%;

    & > div {
      width: 23.5%;
    }
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 56px);
    margin: 32px auto 0 auto;
    gap: 20px 0;
    justify-content: space-between;

    & > div {
      width: 48%;
      height: 65px;

      & > img {
        width: 170px !important;
        height: 60px !important;
      }
    }
  }

  @media screen and (max-width: 500px) {
    & > div {
      height: 55px;

      & > img {
        width: 130px !important;
        height: 50px !important;
      }
    }
  }
`;

const BrandSection = () => {
  const brandList = [
    { id: 1, image: brand_1, name: 'brand_hanssem' },
    { id: 2, image: brand_2, name: 'brand_livart' },
    { id: 3, image: brand_3, name: 'brand_lxzin' },
    { id: 4, image: brand_4, name: 'brand_daelimbath' },
    { id: 5, image: brand_5, name: 'brand_americanstandard' },
    { id: 6, image: brand_6, name: 'brand_theinus' },
    { id: 7, image: brand_7, name: 'brand_younglim' },
    { id: 8, image: brand_8, name: 'brand_예림도어' },
  ];

  return (
    <Container>
      <Content>
        <SectionHeader>
          <h3>
            인테리어/리빙 인기 브랜드와
            <br />
            엄선한 프리미엄 자재로 시공합니다.{' '}
          </h3>
        </SectionHeader>
        <ContentWrap>
          {brandList.map((brand) => (
            <div key={brand.id}>
              <img src={brand.image} alt={brand.name} />
            </div>
          ))}
        </ContentWrap>
      </Content>
    </Container>
  );
};

export default BrandSection;
