import React, { useState } from 'react';
import styled from 'styled-components';
import useProductsPrice from './ProductsPriceSection.hook';
import { numFormat } from '../../utils/utils';
import close_btn from '../../assets/images/life-construction/close_btn.png';
import EstimateModal from './Modal/EstimateModal';

const Container = styled.section`
  margin-top: 140px;
  display: flex;

  @media screen and (max-width: 720px) {
    margin-top: 60px;
  }
`;

const Content = styled.div`
  width: 55%;
  min-width: 900px;
  margin: 0 auto;

  .filter-content-title {
    margin: 40px 0 12px 0;
    width: 100%;
    color: #909090;
    font-size: 14px;
    height: 17px;
  }

  @media screen and (max-width: 947px) {
    min-width: calc(100% - 44px);
  }

  @media screen and (max-width: 720px) {
    .filter-content-title {
      text-align: center;
    }
  }
`;

const SectionHeader = styled.div`
  text-align: center;

  & > h3 {
    font-size: 38px;
    font-weight: bold;
  }

  & > p {
    margin-top: 10px;
    font-size: 18px;

    & > b {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 720px) {
    & > h3 {
      font-size: 24px;
    }

    & > p {
      font-size: 14px;
    }
  }
`;

const FilterWrap = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: center;

  & > div {
    font-size: 18px;
    padding: 7px 19px;
    margin-right: 35px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.isSelected {
      border: 1px solid;
      border-radius: 19px;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 720px) {
    & > div {
      font-size: 14px;
    }
  }
`;

const FilterContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 720px) {
    justify-content: start;
    flex-wrap: wrap;
  }
`;

const FilterLeftWrap = styled.div`
  width: 55%;
  height: 552px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px; /* 스크롤바의 너비 */
  }

  &::-webkit-scrollbar-thumb {
    background: #e1e1e1; /* 스크롤바의 색상 */
    border-radius: 10px;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    height: auto;
  }
`;

const FilterLeftCard = styled.div<{ isSelected: boolean }>`
  margin-bottom: 12px;
  width: 49%;
  padding: 24px 0;
  background-color: ${(props) => (props.isSelected ? '#fff' : '#f7f9fc')};
  border: ${(props) =>
    props.isSelected ? '2px solid #066e4d' : '2px solid #ffffff'};
  border-radius: 10px;
  cursor: pointer;

  & > h3,
  p {
    margin-left: 24px;
  }

  & > h3 {
    font-size: 18px;
    font-weight: bold;
  }

  & > p {
    font-size: 14px;
    margin-top: 5px;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px 16px;

    & > h3 {
      margin: 0;
      font-size: 16px;
    }

    & > p {
      margin: auto 0;
    }
  }
`;

const FilterRightWrap = styled.div`
  border: 1px solid;
  width: 40%;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #e1e1e1;
  position: relative;

  @media screen and (max-width: 720px) {
    width: 100%;
    margin-top: 30px;
  }
`;

const FilterRightHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;

  & > button {
    display: flex;
    font-size: 14px;
    color: #909090;

    & > img {
      width: 14px;
      height: 14px;
      margin: 1px 3px auto 0;
    }
  }
`;

const FilterRightBody = styled.div`
  padding: 20px;
  max-height: 345px;
  overflow-y: auto;

  & > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-weight: 400;

    & > p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 720px) {
    min-height: 150px;
  }
`;

const FilterRightFooter = styled.div`
  height: 125px;
  border-top: 1px solid #ebebeb;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;

  & > div {
    display: flex;
    justify-content: space-between;

    & > p {
      font-size: 14px;
      font-weight: bold;
    }
  }

  & > button {
    margin-top: 20px;
    background-color: #066e4d;
    color: #ffffff;
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
  }

  @media screen and (max-width: 720px) {
    position: static;
  }
`;

const ProductPriceSection = () => {
  const {
    filterList,
    selectFilter,
    selectFilterItem,
    receiptList,
    clearFilter,
    setFilterList,
    create,
  } = useProductsPrice();
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <Container>
      <Content>
        <SectionHeader>
          <h3>레이홈 안심 정찰제</h3>
          <p>
            합리적인 가격의 레이홈 <b>안심 정찰제</b>를 확인하세요.
          </p>
        </SectionHeader>
        <FilterWrap>
          {filterList.map((item) => (
            <div
              className={item.isChecked ? 'isSelected' : ''}
              key={item.id}
              onClick={() => selectFilter(item.id)}
            >
              {item.name}
            </div>
          ))}
        </FilterWrap>
        <p className="filter-content-title">
          원하시는 생활시공 서비스를 선택하세요.
        </p>
        <FilterContent>
          <FilterLeftWrap>
            <div>
              {filterList
                .filter((filter) => filter.isChecked)[0]
                .items.map((item) => (
                  <FilterLeftCard
                    key={item.id}
                    onClick={() => selectFilterItem(item.id)}
                    isSelected={item.isChecked}
                  >
                    <h3>{item.name}</h3>
                    <p>{`${numFormat(item.price)}원 (${item.unit})`}</p>
                  </FilterLeftCard>
                ))}
            </div>
          </FilterLeftWrap>
          <FilterRightWrap>
            <FilterRightHeader>
              <h3>총 {receiptList.length}건</h3>
              <button onClick={clearFilter}>
                <img src={close_btn} alt="close_btn" />
                전체삭제
              </button>
            </FilterRightHeader>
            <FilterRightBody>
              {receiptList.map((receipt) => (
                <div key={receipt.id}>
                  <p>{`[${receipt.type}] ${receipt.name}`}</p>
                  <p>{numFormat(receipt.price)}원</p>
                </div>
              ))}
            </FilterRightBody>
            <FilterRightFooter>
              <div>
                <p>총 견적금액</p>
                <p>
                  {numFormat(
                    receiptList.reduce((acc, cur) => {
                      return acc + cur.price;
                    }, 0)
                  )}
                  원
                </p>
              </div>
              <button onClick={() => setIsOpenModal(true)}>견적 요청</button>
            </FilterRightFooter>
          </FilterRightWrap>
        </FilterContent>
      </Content>
      {isOpenModal && (
        <EstimateModal
          close={() => setIsOpenModal(false)}
          receiptList={receiptList}
          filterList={filterList}
          setFilterList={setFilterList}
          create={create}
          clearFilter={clearFilter}
        />
      )}
    </Container>
  );
};

export default ProductPriceSection;
