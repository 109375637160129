import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import PartnerDetail from '../../components/Partner/Detail';

const PartnerDetailPage = () => {
  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  return (
    <Layout headerText="시공 전문가">
      <PartnerDetail />
    </Layout>
  );
};

export default PartnerDetailPage;
