/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { Link } from 'react-router-dom';
import {
  pyeongList,
  priceList,
  styleList,
  sortList,
  serviceList,
} from '../../utils/frontReport';
import useReportStore from 'stores/useReportStore';
import useReportQueries from 'hooks/queries/report';
import images from '../../libs/images';
import { IoSearchOutline } from 'react-icons/io5';
import useCommonQueries from 'hooks/queries/common';
import reset_icon from './images/reset_icon.png';
import { Area } from '../../types/home';
import { UseQueryResult } from 'react-query';
import Card from './Card';
import AdComponent from './AdComponent';
import { GrPrevious } from 'react-icons/gr';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Center } from 'types/report';

const Container = styled.div`
  padding-top: 55px;
`;

const TabWrap = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 55px auto 0 auto;
  /* display: flex; */
  display: none; // 부분 시공 화면이 없어하이드 처리
  justify-content: center;

  & > a,
  p {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    width: 180px;
    height: 70px;
    line-height: 70px;
    border: 1px solid #434343;
  }

  & > a {
    background-color: #000000;
    color: #ffffff;
  }

  & > p {
    color: #8f8e8e;
    cursor: not-allowed;
  }

  @media screen and (max-width: 767px) {
    margin: 150px auto 0 auto;

    & > a,
    p {
      padding: 10px 35px;
      border-radius: 20px;
    }

    & > a {
      margin-right: -15px;
      z-index: 100;
    }

    & > p {
      margin-left: -15px;
      padding: 10px 30px 10px 45px;
    }
  }
`;

const FilterBox = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 30px auto 0 auto;

  & > h2 {
    font-size: 56px;
    font-weight: 700;
    line-height: 77px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const FilterWrap = styled.div`
  width: 60%;

  .filter_select_box {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;

    & > div {
      display: inline-block;

      position: relative;

      & > button {
        display: flex;
        padding: 5px 15px;
        background-color: #f5f5f5 !important;
        border: 1px solid #8f8e8e;
        border-radius: 3px;
        width: 100%;
        appearance: none;
        & > p {
          margin: auto 0;
        }

        & > img {
          margin: auto 0 auto 10px;
        }
      }
    }

    .input_box {
      border: 1px solid;
    }
  }

  .selected_box {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .selected_item {
      background-color: #000000;
      padding: 10px 15px 10px 15px;
      border: none;
      border-radius: 3px;

      display: flex;

      & > p {
        color: #ffffff;
        font-weight: 600;
        margin: auto 0;
      }

      & > button {
        background: none;
        color: #ffffff;
        font-weight: 600;
        margin: auto 0 auto 10px;
        font-size: 18px;
      }
    }
  }

  .reset_item_box {
    width: 80px;

    & > button {
      display: flex;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;

      & > img {
        width: 18px;
        height: 18px;
        margin: auto 5px auto 5px;
      }
    }
  }
`;

const FilterItem = styled.div`
  position: relative;
`;

const FilterSelect = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;

  width: 330px;
  z-index: 100;

  & > div {
    margin-top: 10px;
    border: 1px solid #8f8e8e;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;

    .select_header {
      display: flex;

      & > h3 {
        font-size: 16px;
      }

      & > p {
        color: #8f8e8e;
        font-size: 10px;
        margin: 5px 0 0 10px;
      }
    }

    .select_content {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      color: #434343;

      & > button {
        padding: 5px 10px;
        border: 1px solid #8f8e8e;
        border-radius: 20px;

        &.selected {
          border: 2px solid #000000;
          font-weight: 600;
        }
      }
    }
  }
`;

const MobileFilterWrap = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    width: calc(100% - 60px);
    margin: 50px auto 0 auto;

    & > h2 {
      font-size: 24px;
      font-weight: 600;
    }

    .mobile_filter_content {
      margin-top: 25px;
      display: flex;

      & > button {
        color: #000000;

        &:first-of-type {
          margin-top: 2px;
        }
      }

      & > p {
        margin: 1px 5px 0 5px;
        color: #8f8e8e;
      }
    }

    .mobile_area_content {
      display: flex;
      margin-top: 40px;

      & > button {
        font-size: 22px;
        margin: 15px 10px auto 0;
      }
    }
  }
`;

const MobileFilterOpenWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #ffffff;

  .filter_open_header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;

    & > h3 {
      font-size: 22px;
    }
  }

  .filter_open_content {
    padding: 20px 20px 0 20px;
    height: calc(100vh - 122px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9; /* 스크롤바의 색상 */
    }

    .select_content {
      margin: 20px 0 30px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      color: #434343;

      & > button {
        padding: 10px 15px;
        border: 1px solid #8f8e8e;
        border-radius: 20px;
        color: #000000;

        &.selected {
          border: 2px solid #000000;
          font-weight: 600;
        }
      }
    }
  }

  .filter_open_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    & > button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      height: 55px;

      &:first-of-type {
        color: #000000;
        width: 35%;
        background-color: #e2e2e2;
      }

      &:last-of-type {
        width: 65%;
        background-color: #000000;
        color: #ffffff;
      }
    }
  }
`;

const SmallBannerWrap = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 220px auto 0 auto;

  background: #f5f5f5;
  border-radius: 10px;
  padding: 40px 0;
  position: relative;
  cursor: pointer;

  .banner_info {
    width: 40%;
    margin-left: 15%;
    line-height: 20px;

    p,
    span {
      font-size: 28px;
      font-weight: 700;
      line-height: 30px;
    }
    & > p {
      color: #ff5e5e;
    }
  }

  .banner_image {
    position: absolute;
    width: 30%;
    max-width: 300px;
    bottom: 0;
    right: 15%;

    & > div {
      position: relative;
      padding-bottom: 100%;
      width: 100%;

      & > img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const SearchInput = styled.div`
  display: inline-flex;
  min-width: 410px;
  width: 35%;
  height: 40px;
  margin-top: 35px;
  border: none;
  border-bottom: 1px solid;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 600;
  position: relative;

  & > input {
    border: none;
    min-width: 280px;
  }

  & > button {
    position: absolute;
    right: 0;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  .area_select_box {
    position: relative;
    width: 100px;
    margin-right: 20px;

    & > button {
      width: 100%;
      font-weight: 600;
      color: #000000;

      & > img {
        margin-left: 10px;
      }
    }

    .front_search_area_box {
      top: 45px;
    }
  }

  @media screen and (max-width: 767px) {
    border: 2px solid;
    height: 50px;

    width: 100%;
    min-width: auto;
    padding: 0 15px;
    margin: 0;

    .area_select_box {
      display: flex;
      width: auto;
      margin: 3px 0 0 0;

      & > button {
        width: auto;
        margin: 0;
        font-size: 14px;

        & > img {
          margin: 0;
        }
      }
    }

    & > input {
      margin-left: 15px;
      font-size: 16px;
      min-width: auto;
      width: 50%;
    }

    & > button {
      position: static;
      margin: 3px 0 0 auto;
      color: #000000;
    }
  }
`;

const Content = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  margin-top: 50px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    gap: 0;
    margin-top: 40px;
  }
`;

const MoreWrap = styled.div`
  margin: 100px 0;
  text-align: center;

  & > button {
    border: 1px solid;
    line-height: 30px;
    padding: 19px 145px;
    font-size: 20px;
    font-weight: 700;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media screen and (max-width: 767px) {
    margin-top: 70px;

    & > button {
      font-size: 14px;
      padding: 15px 100px;
    }
  }
`;

interface FilterTypes {
  case_size: string[];
  case_work_price: string[];
  style_area: string[];
  sort: string[];
  case_service: string[];
}

const Report = () => {
  const { getReportListQuery } = useReportQueries();
  const { getCityListQuery, getAreaListQuery } = useCommonQueries();

  const { params, setParams } = useReportStore();

  const [area, setArea] = useState(params.area || '');
  const [city, setCity] = useState(params.city || '');

  const { data: city_list }: UseQueryResult<{ area: Area[] }, unknown> =
    getCityListQuery();
  const { data: area_list } = getAreaListQuery({
    area_id: city,
  });

  const contentRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [centerBanner, setCenterBanner] = useState<{
    centerList: Center[];
    random: number;
  }>();

  /* 검색 및 필터부분 */
  const [stx, setStx] = useState(params.apart_name || '');
  const [apartName, setApartName] = useState(params.apart_name || '');

  const [filterData, setFilterData] = useState<FilterTypes>({
    case_size: params.case_size || [],
    case_work_price: params.case_work_price || [],
    style_area: params.style_area || [],
    sort: params.case_sortBy || [],
    case_service: params.case_service || [],
  });

  const [copyFilterData, setCopyFilterData] = useState<FilterTypes>({
    case_size: [],
    case_work_price: [],
    style_area: [],
    sort: [],
    case_service: [],
  });

  const [isOpenArea, setIsOpenArea] = useState(false);
  const [isOpenMobileAreaStatus, setIsOpenMobileAreaStatus] = useState(false);

  const [areaText, setAreaText] = useState(params.areaText || '');

  const [isOpenFilters, setIsOpenFilters] = useState<{
    sort: boolean;
    size: boolean;
    price: boolean;
    style: boolean;
    service: boolean;
  }>({
    sort: false,
    size: false,
    price: false,
    style: false,
    service: false,
  });

  const { data, hasNextPage, fetchNextPage } = getReportListQuery({
    area_code: city,
    area_code_detail: area,
    case_size: filterData.case_size,
    case_work_price: filterData.case_work_price,
    search_name: apartName,
    interior_style: filterData.style_area,
    addService: filterData.case_service,
    sort_by: filterData.sort[0],
  });

  useEffect(() => {
    if (isOpenFilter) {
      setCopyFilterData(filterData);
    }
  }, [isOpenFilter]);

  useDidMountEffect(() => {
    const areaText = area_list?.area?.find(
      (item) => item.area_detail_id === area
    )?.area_detail_name;

    const sender = {
      case_size: filterData.case_size, // 평형
      case_work_price: filterData.case_work_price, // 가격
      style_area: filterData.style_area, //스타일별
      case_service: filterData.case_service,
      case_sortBy: filterData.sort,
      city: city,
      area: area,
      areaText: areaText,
      page: 1, // 페이지
    };

    setParams(sender);
  }, [filterData, areaText]);

  const caseList = useMemo(
    () => (data ? data.pages.flatMap((data) => data.case_list) : []),
    [data]
  );

  useEffect(() => {
    if (isOpenArea) {
      document.addEventListener('mousedown', clickContentOutside);

      return () => {
        document.removeEventListener('mousedown', clickContentOutside);
      };
    }
  }, [isOpenArea]);

  const clickContentOutside = (
    event: React.BaseSyntheticEvent | MouseEvent
  ) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      if (isOpenArea) {
        setIsOpenArea(false);
      }
    }
  };

  useEffect(() => {
    if (isOpenFilter) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      width: 100%;
  `;

      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  }, [isOpenFilter]);

  const changeSelectCaseHandler = (
    value: string,
    isCase: boolean,
    filed:
      | 'case_size'
      | 'case_work_price'
      | 'style_area'
      | 'sort'
      | 'case_service',
    isMobile?: boolean
  ) => {
    if (isMobile) {
      setCopyFilterData((prevData) => ({
        ...prevData,
        [filed]: isCase
          ? prevData[filed].filter((data) => data !== value)
          : filed === 'sort'
          ? [value]
          : [...prevData[filed], value],
      }));
    } else {
      setFilterData((prevData) => ({
        ...prevData,
        [filed]: isCase
          ? prevData[filed].filter((data) => data !== value)
          : filed === 'sort'
          ? [value]
          : [...prevData[filed], value],
      }));
    }
  };

  const filterEvent = (
    type: 'sort' | 'size' | 'price' | 'style' | 'service',
    value: boolean
  ) => {
    setIsOpenFilters((prevData) => ({
      ...prevData,
      [type]: value,
    }));
  };

  const caseInit = (isMobile?: boolean) => {
    if (isMobile) {
      setCopyFilterData({
        case_size: [],
        case_work_price: [],
        style_area: [],
        sort: [],
        case_service: [],
      });
    } else {
      setStx('');
      setApartName('');
      setArea('');
      setAreaText('');
      setFilterData({
        case_size: [],
        case_work_price: [],
        style_area: [],
        sort: [],
        case_service: [],
      });
    }
  };

  const caseApply = () => {
    setFilterData(copyFilterData);
    setIsOpenFilter(false);
  };

  useEffect(() => {
    if (data && data?.pages[0].center_list?.length > 0) {
      const centerList: any = [];

      data.pages[0].center_list.map((center) => {
        switch (center.center_state) {
          case '1':
            center.text_center_state = 'HOT';
            break;
          case '2':
            center.text_center_state = 'NEW';
            break;
          case '3':
            center.text_center_state = 'BEST';
            break;
          default:
            center.text_center_state = '';
            break;
        }
        centerList.push(center);
      });
      const randomIndex = Math.floor(Math.random() * centerList.length);

      setCenterBanner({
        centerList: centerList,
        random: randomIndex,
      });
    }
  }, [data]);

  const onRoute = (url: string) => {
    window.location.href = url;
  };

  const onSearchHandler = () => {
    setApartName(stx);
    setParams({
      apart_name: stx,
    });
  };

  const searchEl = () => (
    <SearchInput>
      {/* 지역 */}
      <div className="area_select_box" ref={contentRef}>
        <button onClick={() => setIsOpenArea(!isOpenArea)}>
          {area && areaText ? areaText : '시/구 선택'}
          <img src={images.down} alt="down_arrow" />
        </button>
        <div
          className="front_search_area_box"
          style={{ display: isOpenArea ? 'flex' : 'none' }}
        >
          <ul className="front_search_area_box_citylist">
            {city_list?.area?.map((area) => {
              return (
                <li
                  key={area.area_id}
                  className={area.area_id === city ? '' : 'active'}
                  onClick={() => {
                    setCity(area.area_id);
                    setAreaText(area.area_name);
                    setArea('');
                  }}
                >
                  {area.area_name}
                </li>
              );
            })}
          </ul>

          <ul className="front_search_area_box_arealist">
            {area_list?.area?.map((x, i) => {
              return (
                <li
                  key={i}
                  className={x.area_detail_id === area ? 'active' : ''}
                  onClick={() => {
                    setArea(x.area_detail_id);
                    setAreaText(x.area_detail_name);
                    setIsOpenArea(false);
                  }}
                >
                  {x.area_detail_name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <input
        placeholder="아파트명 또는 시공사례명을 입력하세요."
        value={stx}
        onChange={(e) => setStx(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onSearchHandler();
          }
        }}
      />
      <button onClick={onSearchHandler}>
        <IoSearchOutline />
      </button>
    </SearchInput>
  );

  if (!data) return null;

  return (
    <Container>
      <TabWrap>
        <Link to="/report">리모델링</Link>
        <p>부분시공</p>
      </TabWrap>
      <FilterBox>
        <h2>
          필터를 통해
          <br />
          마음에 드는 집을
          <br />
          찾아보세요
        </h2>
        <div>
          <FilterWrap>
            <div className="filter_select_box">
              {/* 정렬 */}
              <FilterItem onMouseLeave={() => filterEvent('sort', false)}>
                <button onMouseOver={() => filterEvent('sort', true)}>
                  <p>정렬</p>
                  <img src={images.down} alt="down" />
                </button>
                <FilterSelect open={isOpenFilters.sort}>
                  <div>
                    <div className="select_header">
                      <h3>정렬</h3>
                    </div>
                    <div className="select_content">
                      {sortList.map((item) => {
                        const isSort = !!filterData.sort.find(
                          (sort_item) => sort_item === item.value
                        );

                        return (
                          <button
                            className={isSort ? 'selected' : undefined}
                            key={item.idx}
                            onClick={() =>
                              changeSelectCaseHandler(
                                item.value,
                                isSort,
                                'sort'
                              )
                            }
                          >
                            {item.title}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </FilterSelect>
              </FilterItem>
              {/* 평형 */}
              <FilterItem onMouseLeave={() => filterEvent('size', false)}>
                <button onMouseOver={() => filterEvent('size', true)}>
                  <p>평형</p>
                  <img src={images.down} alt="down" />
                </button>
                <FilterSelect open={isOpenFilters.size}>
                  <div>
                    <div className="select_header">
                      <h3>평형</h3>
                      <p>중복선택 가능</p>
                    </div>
                    <div className="select_content">
                      {pyeongList.map((item) => {
                        const isCase = !!filterData.case_size.find(
                          (size) => size === item.value
                        );

                        return (
                          <button
                            className={isCase ? 'selected' : undefined}
                            key={item.idx}
                            onClick={() =>
                              changeSelectCaseHandler(
                                item.value,
                                isCase,
                                'case_size'
                              )
                            }
                          >
                            {item.title}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </FilterSelect>
              </FilterItem>

              {/* 예산 */}
              <FilterItem onMouseLeave={() => filterEvent('price', false)}>
                <button onMouseOver={() => filterEvent('price', true)}>
                  <p>예산</p>
                  <img src={images.down} alt="down" />
                </button>
                <FilterSelect open={isOpenFilters.price}>
                  <div>
                    <div className="select_header">
                      <h3>예산</h3>
                      <p>중복선택 가능</p>
                    </div>
                    <div className="select_content">
                      {priceList.map((item) => {
                        const isCase = !!filterData.case_work_price.find(
                          (price) => price === item.value
                        );

                        return (
                          <button
                            className={isCase ? 'selected' : undefined}
                            key={item.idx}
                            onClick={() =>
                              changeSelectCaseHandler(
                                item.value,
                                isCase,
                                'case_work_price'
                              )
                            }
                          >
                            {item.title}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </FilterSelect>
              </FilterItem>

              {/* 추구 스타일 */}
              <FilterItem onMouseLeave={() => filterEvent('style', false)}>
                <button onMouseOver={() => filterEvent('style', true)}>
                  <p>추구 스타일</p>
                  <img src={images.down} alt="down" />
                </button>
                <FilterSelect open={isOpenFilters.style}>
                  <div>
                    <div className="select_header">
                      <h3>추구 스타일</h3>
                      <p>중복선택 가능</p>
                    </div>
                    <div className="select_content">
                      {styleList.map((item) => {
                        const isCase = !!filterData.style_area.find(
                          (style) => style === item.value
                        );

                        return (
                          <button
                            className={isCase ? 'selected' : undefined}
                            key={item.idx}
                            onClick={() =>
                              changeSelectCaseHandler(
                                item.value,
                                isCase,
                                'style_area'
                              )
                            }
                          >
                            {item.title}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </FilterSelect>
              </FilterItem>

              {/* 제공 서비스 */}
              <FilterItem onMouseLeave={() => filterEvent('service', false)}>
                <button onMouseOver={() => filterEvent('service', true)}>
                  <p>제공 서비스</p>
                  <img src={images.down} alt="down" />
                </button>
                <FilterSelect open={isOpenFilters.service}>
                  <div>
                    <div className="select_header">
                      <h3>제공 서비스</h3>
                      <p>중복선택 가능</p>
                    </div>
                    <div className="select_content">
                      {serviceList.map((item) => {
                        const isService = !!filterData.case_service.find(
                          (service) => service === item.value
                        );

                        return (
                          <button
                            className={isService ? 'selected' : undefined}
                            key={item.idx}
                            onClick={() =>
                              changeSelectCaseHandler(
                                item.value,
                                isService,
                                'case_service'
                              )
                            }
                          >
                            {item.title}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </FilterSelect>
              </FilterItem>
            </div>
            {(apartName ||
              filterData.sort.length > 0 ||
              filterData.case_size.length > 0 ||
              filterData.case_work_price.length > 0 ||
              filterData.style_area.length > 0 ||
              filterData.case_service.length > 0 ||
              area) && (
              <div className="selected_box">
                {filterData.sort.map((item) => (
                  <div className="selected_item" key={item}>
                    <p>{sortList.find((data) => data.value === item)?.title}</p>
                    <button
                      onClick={() =>
                        setFilterData((prevData) => ({
                          ...prevData,
                          sort: prevData.sort.filter((data) => data !== item),
                        }))
                      }
                    >
                      x
                    </button>
                  </div>
                ))}
                {filterData.case_size.map((item) => (
                  <div className="selected_item" key={item}>
                    <p>{item}평대</p>
                    <button
                      onClick={() =>
                        setFilterData((prevData) => ({
                          ...prevData,
                          case_size: prevData.case_size.filter(
                            (data) => data !== item
                          ),
                        }))
                      }
                    >
                      x
                    </button>
                  </div>
                ))}
                {filterData.case_work_price.map((item) => (
                  <div className="selected_item" key={item}>
                    <p>{item === '0' ? '그 외' : `${item + '만원 대'}`}</p>
                    <button
                      onClick={() =>
                        setFilterData((prevData) => ({
                          ...prevData,
                          case_work_price: prevData.case_work_price.filter(
                            (data) => data !== item
                          ),
                        }))
                      }
                    >
                      x
                    </button>
                  </div>
                ))}
                {filterData.style_area.map((item) => (
                  <div className="selected_item" key={item}>
                    <p>
                      {styleList.find((style) => style.value === item)?.title}
                    </p>
                    <button
                      onClick={() =>
                        setFilterData((prevData) => ({
                          ...prevData,
                          style_area: prevData.style_area.filter(
                            (data) => data !== item
                          ),
                        }))
                      }
                    >
                      x
                    </button>
                  </div>
                ))}
                {filterData.case_service.map((item) => (
                  <div className="selected_item" key={item}>
                    <p>
                      {serviceList.find((data) => data.value === item)?.title}
                    </p>
                    <button
                      onClick={() =>
                        setFilterData((prevData) => ({
                          ...prevData,
                          case_service: prevData.case_service.filter(
                            (data) => data !== item
                          ),
                        }))
                      }
                    >
                      x
                    </button>
                  </div>
                ))}
                <div className="reset_item_box">
                  <button onClick={() => caseInit(false)}>
                    <img src={reset_icon} alt="reset_icon" />
                    초기화
                  </button>
                </div>
              </div>
            )}
          </FilterWrap>
          {searchEl()}
        </div>
      </FilterBox>
      <MobileFilterWrap>
        <h2>
          필터를 통해
          <br />
          마음에 드는 집을
          <br />
          찾아보세요
        </h2>
        {isOpenMobileAreaStatus ? (
          <div className="mobile_area_content">
            <button onClick={() => setIsOpenMobileAreaStatus(false)}>
              <GrPrevious />
            </button>
            {searchEl()}
          </div>
        ) : (
          <div className="mobile_filter_content">
            <button onClick={() => setIsOpenFilter(true)}>
              <img src={images.options_icon} /> 조건 필터
            </button>
            <p>|</p>
            <button onClick={() => setIsOpenMobileAreaStatus(true)}>
              지역 검색
            </button>
          </div>
        )}
      </MobileFilterWrap>
      <AdComponent list={data?.pages[0].ad_list} />

      {centerBanner && (
        <Swiper
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          loop={true}
          initialSlide={centerBanner.random}
        >
          {centerBanner.centerList.map((item) => (
            <SwiperSlide key={item.center_banner_id}>
              <SmallBannerWrap onClick={() => onRoute(item.center_url)}>
                <div className="banner_info">
                  <p>{item.text_center_state}</p>
                  <br />
                  <span>{item.center_title}</span>
                </div>
                <div className="banner_image">
                  <div>
                    <img src={item.center_thumbnail} alt="center_thumbnail" />
                  </div>
                </div>
              </SmallBannerWrap>
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <Content>
        {caseList.map((item, i) => (
          <Card item={item} key={i} />
        ))}
      </Content>
      {!!hasNextPage && (
        <MoreWrap>
          <button onClick={() => fetchNextPage()}>더 보기</button>
        </MoreWrap>
      )}
      {isOpenFilter && (
        <MobileFilterOpenWrap>
          <div className="filter_open_header">
            <h3>조건 필터</h3>
            <button onClick={() => setIsOpenFilter(false)}>
              <img src={images.exit} />
            </button>
          </div>
          <div className="filter_open_content">
            <h3>평형</h3>
            <div className="select_content">
              {pyeongList.map((item) => {
                const isCase = !!copyFilterData.case_size.find(
                  (size) => size === item.value
                );

                return (
                  <button
                    className={isCase ? 'selected' : undefined}
                    key={item.idx}
                    onClick={() =>
                      changeSelectCaseHandler(
                        item.value,
                        isCase,
                        'case_size',
                        true
                      )
                    }
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
            <h3>예산</h3>
            <div className="select_content">
              {priceList.map((item) => {
                const isCase = !!copyFilterData.case_work_price.find(
                  (price) => price === item.value
                );

                return (
                  <button
                    className={isCase ? 'selected' : undefined}
                    key={item.idx}
                    onClick={() =>
                      changeSelectCaseHandler(
                        item.value,
                        isCase,
                        'case_work_price',
                        true
                      )
                    }
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
            <h3>추구 스타일</h3>
            <div className="select_content">
              {styleList.map((item) => {
                const isCase = !!copyFilterData.style_area.find(
                  (style) => style === item.value
                );

                return (
                  <button
                    className={isCase ? 'selected' : undefined}
                    key={item.idx}
                    onClick={() =>
                      changeSelectCaseHandler(
                        item.value,
                        isCase,
                        'style_area',
                        true
                      )
                    }
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
            <h3>제공 서비스</h3>
            <div className="select_content">
              {serviceList.map((item) => {
                const isService = !!copyFilterData.case_service.find(
                  (service) => service === item.value
                );

                return (
                  <button
                    className={isService ? 'selected' : undefined}
                    key={item.idx}
                    onClick={() =>
                      changeSelectCaseHandler(
                        item.value,
                        isService,
                        'case_service',
                        true
                      )
                    }
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
            <h3>정렬</h3>
            <div className="select_content">
              {sortList.map((item) => {
                const isSort = !!copyFilterData.sort.find(
                  (sort_item) => sort_item === item.value
                );

                return (
                  <button
                    className={isSort ? 'selected' : undefined}
                    key={item.idx}
                    onClick={() =>
                      changeSelectCaseHandler(item.value, isSort, 'sort', true)
                    }
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="filter_open_footer">
            <button onClick={() => caseInit(true)}>초기화</button>
            <button onClick={caseApply}>적용</button>
          </div>
        </MobileFilterOpenWrap>
      )}
    </Container>
  );
};

export default Report;
