import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'components/Common/Modal/ModalPortal';
import images from 'libs/images';
import { Link } from 'react-router-dom';
import { login } from 'apis/User';
import useUserStore from 'stores/useUserStore';

const Container = styled.div`
  width: 503px;
  margin: auto;
  background-color: #ffffff;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

const Content = styled.div`
  margin: 0 86px;
  padding-bottom: 67px;

  & > h2 {
    margin-top: 30px;
    color: #000;
    font-size: 20px;
    font-weight: 700;
  }

  & > p {
    margin-top: 20px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }

  @media screen and (max-height: 500px) {
    height: calc(var(--vh, 1vh) * 100 - 64px);
    overflow-y: auto;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 30px);
    margin: 35px 0 0 30px;
    padding-right: 30px;

    & > h2 {
      margin-top: 36px;
      font-size: 16px;
      line-height: 40px;
    }

    & > p {
      font-size: 13px;
    }
  }
`;

const LogoWrap = styled.div`
  & > img {
    width: 125px;
  }
`;

const FormWrap = styled.div`
  margin-top: 20px;

  & > input {
    width: 100%;
    border: 1px solid #929292;
    height: 50px;
    padding: 0 20px;
    margin-bottom: 15px;
    font-size: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &::placeholder {
      color: #969696;
    }
  }

  & > button {
    width: 100%;
    background-color: #000;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 13px 0;
  }

  .disabled_msg {
    height: 22px;
    margin: 3px 0 5px 5px;

    & > p {
      color: #ff4646;
      font-size: 13px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 767px) {
    & > input {
      font-size: 13px;
    }

    & > button {
      font-size: 15px;
      font-weight: 700;
      padding: 0;
      height: 50px;
    }

    .disabled_msg {
      margin: 4px 0 2px 0;

      & > p {
        font-size: 12px;
      }
    }
  }
`;

const SignupWrap = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  & > a {
    font-size: 16px;
    font-weight: 400;
  }

  @media screen and (max-width: 767px) {
    margin-top: 16px;

    & > a {
      font-size: 13px;
    }
  }
`;

interface Props {
  close: () => void;
}

const BasicLogin = (props: Props) => {
  const { close } = props;
  const { setUser } = useUserStore();
  const [loginStatusMsg, setLoginStatusMsg] = useState('');

  const [userInfo, setUserInfo] = useState<{
    user_id: string;
    user_passwd: string;
  }>({
    user_id: '',
    user_passwd: '',
  });

  useEffect(() => {
    document.getElementsByName('user_id')[0].focus();
  }, []);

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const loginHandler = async () => {
    const { user_id, user_passwd } = userInfo;
    if (user_id === '') {
      setLoginStatusMsg('아이디를 입력해 주세요.');
      return;
    }

    if (user_passwd === '') {
      setLoginStatusMsg('비밀번호를 입력해 주세요.');
      return;
    }

    try {
      const res = await login(userInfo);

      if (res.code === 'OK') {
        setLoginStatusMsg('');
        setUser(res.user_info[0]);
        close();
      } else {
        const msg = '아이디 또는 비밀번호가 일치하지 않습니다.';
        setLoginStatusMsg(msg);
        alert(msg);
      }
    } catch (err) {
      alert('오류가 발생하였습니다.');
      console.log(err);
    }
  };

  return (
    <Modal>
      <Container>
        <Header>
          <button onClick={close}>
            <img src={images.exit} alt="exit" />
          </button>
        </Header>
        <Content>
          <LogoWrap>
            <img src={images.logo_black} alt="logo_black" />
          </LogoWrap>
          <h2>아이디 로그인</h2>
          <p>아이디와 비밀번호를 입력해주세요.</p>
          <FormWrap>
            <input
              placeholder="아이디"
              name="user_id"
              onChange={changeInputHandler}
            />
            <input
              placeholder="비밀번호"
              name="user_passwd"
              onChange={changeInputHandler}
              type="password"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  loginHandler();
                }
              }}
            />
            <div className="disabled_msg">
              <p>{loginStatusMsg}</p>
            </div>
            <button onClick={loginHandler}>로그인</button>
          </FormWrap>
          <SignupWrap>
            <Link to="/signup">회원가입</Link>
          </SignupWrap>
        </Content>
      </Container>
    </Modal>
  );
};

export default BasicLogin;
