import React, { useMemo } from 'react';
import styled from 'styled-components';
import { CasePart } from 'types/report';
import { groupBy } from '../../../utils/utils';
import consts from '../../../libs/consts';
import ReactCompareImage from 'react-compare-image';

const Container = styled.div`
  width: 65%;

  @media screen and (max-width: 767px) {
    margin: 40px auto 0 auto;
    width: calc(100% - 60px);
  }
`;

const Item = styled.div`
  margin-bottom: 80px;

  & > h3 {
    font-size: 36px;
    font-weight: 700;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 55px;

    & > h3 {
      font-size: 18px;
    }
  }
`;

const ImageWrap = styled.div`
  & > img {
    width: 100%;
    margin-top: 25px;
  }

  .compare_image_wrap {
    margin-top: 25px;
  }

  & > p {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    word-wrap: break-word;
  }

  @media screen and (max-width: 767px) {
    & > img {
      margin-top: 8px;
    }

    .compare_image_wrap {
      margin-top: 8px;
    }

    & > p {
      margin-top: 22px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

interface Props {
  case_parts: CasePart[];
}

const ReportDetailItem = (props: Props) => {
  const { case_parts } = props;

  const groupCaseParts = useMemo(() => {
    if (case_parts && case_parts.length > 0) {
      const groupedCaseParts = groupBy(case_parts, 'case_parts_type');

      const { homeStylePartsOption } = consts;

      const mergeResult = homeStylePartsOption
        .filter((item) => groupedCaseParts[item.idx])
        .map((option) => {
          return {
            ...option,
            data: groupedCaseParts[option.idx],
          };
        });

      return mergeResult;
    }
  }, [case_parts]);

  return (
    <Container>
      {groupCaseParts?.map((item) => (
        <Item key={item.idx}>
          <h3>{item.title}</h3>
          {item.data.map((data: CasePart) => (
            <ImageWrap key={data.case_img_table_id}>
              {data.imgs.map((img) => {
                if (img.case_zone_img_2) {
                  return (
                    <div
                      key={img.case_img_table_id}
                      className="compare_image_wrap"
                    >
                      <ReactCompareImage
                        leftImage={img.case_zone_img}
                        leftImageLabel="Before"
                        rightImage={img.case_zone_img_2}
                        rightImageLabel="After"
                      />
                    </div>
                  );
                } else {
                  return (
                    <img
                      key={img.case_img_table_id}
                      src={img.case_zone_img}
                      alt="case_zone_img"
                    />
                  );
                }
              })}
              <p>{data.case_desc}</p>
            </ImageWrap>
          ))}
        </Item>
      ))}
    </Container>
  );
};

export default ReportDetailItem;
