import { useQuery, useInfiniteQuery } from 'react-query';
import {
  PartnerListParams,
  getPartnerDetail,
  getPartnerDetailCase,
  getPartnerList,
} from '../../apis/Partner';
import * as QueryKeys from '../../constants/queryKeys';

const usePartnerQueries = () => {
  const getPartnerDetailQuery = (seller_table_id: string) => {
    return useQuery(
      [QueryKeys.PARTNER_DETAIL, seller_table_id],
      () => getPartnerDetail(seller_table_id),
      {
        staleTime: 60 * 5 * 1000,
      }
    );
  };

  const getPartnerDetailCaseQuery = (seller_table_id: string) => {
    return useInfiniteQuery(
      [QueryKeys.PARTNER_DETAIL_CASE, seller_table_id],
      async ({ pageParam = 1 }) => {
        const res = await getPartnerDetailCase({
          seller_table_id,
          page: pageParam,
        });

        return res;
      },
      {
        getNextPageParam: (data, allPages) => {
          return data.is_end === 0 ? allPages.length + 1 : undefined;
        },
        staleTime: 60 * 5 * 1000,
      }
    );
  };

  const getParnterListQuery = (params: Omit<PartnerListParams, 'page'>) => {
    return useInfiniteQuery(
      [QueryKeys.PARTNER_LIST, params],
      async ({ pageParam = 1 }) => {
        const res = await getPartnerList({
          ...params,
          page: pageParam,
        });

        return res;
      },
      {
        getNextPageParam: (data, allPages) => {
          return data.is_end === 0 ? allPages.length + 1 : undefined;
        },
        staleTime: 60 * 5 * 1000,
      }
    );
  };

  return {
    getPartnerDetailQuery,
    getPartnerDetailCaseQuery,
    getParnterListQuery,
  };
};

export default usePartnerQueries;
