import React from 'react';
import Layout from 'components/Layout';
import SignupComponent from 'components/Signup';

const SignupPage = () => {
  return (
    <Layout>
      <SignupComponent />
    </Layout>
  );
};

export default SignupPage;
