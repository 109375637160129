import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface Recent {
  id: string;
  img: string;
  r_type: string;
}
interface State {
  list: Recent[];
  setList: (recent: Recent) => void;
}

const useRecentStore = create(
  persist<State>(
    (set, get) => ({
      list: [],
      setList: (recent) => {
        const { list } = get();

        const arr = list;
        if (arr.length > 11) {
          arr.pop();
        }
        arr.unshift(recent);

        const newArray = arr.filter(
          (arr, index, callback) =>
            index === callback.findIndex((t) => t.id === arr.id)
        );

        return set(() => ({ list: newArray }));
      },
    }),
    {
      name: 'recent_list',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useRecentStore;
