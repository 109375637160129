import React, { useEffect } from 'react';
import Modal from 'components/Common/Modal/ModalPortal';
import styled from 'styled-components';
import consts from 'libs/consts';
import { numFormat, findJson } from 'utils/utils';
import images from 'libs/images';
import { ReportDetail } from '../../../types/report';
import useVh from 'hooks/useVh';
import useUserStore from 'stores/useUserStore';
import InduceLoginWrap from 'components/Common/Login/InduceLoginWrap';
import floating_banner from '../../../assets/images/receipt/floating_banner.png';
import useReceiptFloatingStore from 'stores/useReceiptFloatingStore';
import basic_exit from '../../../assets/images/basic_exit.svg';

const Container = styled.div`
  margin: auto;
  background-color: #ffffff;
  width: 468px;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
`;

const Content = styled.div<{
  isUser: boolean;
  isFloating: boolean;
}>`
  .receipt_popup_main {
    position: relative;
    overflow-y: ${(props) => (props.isUser ? 'auto' : 'clip')};
    height: 519px;
  }

  .user_floating_banner {
    display: none;
  }

  @media screen and (max-height: 500px) {
    height: calc(var(--vh, 1vh) * 100 - 154px);

    overflow-y: auto;

    .receipt_popup_main {
      height: auto !important;
      min-height: 300px;
    }
  }

  @media screen and (max-width: 767px) {
    .receipt_popup_main {
      height: ${(props) =>
        props.isFloating
          ? 'calc(var(--vh, 1vh) * 100 - 373px)'
          : 'calc(var(--vh, 1vh) * 100 - 293px)'};
    }
    .user_floating_banner {
      display: block;
      position: relative;

      & > img {
        width: 100%;
        height: 80px;
        cursor: pointer;
      }

      & > button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;

        & > img {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.mobile {
      .receipt_popup_main {
        height: 380px;
      }
    }
  }
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;

  & > h3 {
    font-size: 16px;
    font-weight: bold;
    white-space: pre-wrap;
  }

  @media screen and (max-width: 767px) {
    justify-content: start;

    & > h3 {
      margin: auto;
    }
  }
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid #e1e1e1;
  text-align: center;

  & > button {
    background: #000000;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    width: 302px;
  }
`;

const TopDescription = styled.div`
  background: #fafafa;
  padding: 12px 20px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;

  & > p {
    color: #909090;
    font-size: 12px;
    line-height: 1.5;
  }
`;

interface Props {
  close: () => void;
  data: ReportDetail;
}

const ReceiptModal = (props: Props) => {
  const { close, data } = props;
  useVh();
  const { user } = useUserStore();
  const { isClosed, closedAt, closeHandler, init } = useReceiptFloatingStore();

  useEffect(() => {
    if (closedAt) {
      const oneDayMillis = 1 * 24 * 60 * 60 * 1000;
      const now = new Date().getTime();
      const later1Day = closedAt + oneDayMillis;

      if (now >= later1Day) {
        init();
      }
    }
  }, []);

  const floatingHandler = () => {
    document.getElementById('report_estimate_btn')?.click();
  };

  const closeFloatingHandler = () => {
    closeHandler();
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>영수증</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit" />
          </button>
        </Header>
        <Content
          className="receipt_popup"
          isUser={!!user}
          isFloating={!!user && !isClosed}
        >
          <TopDescription>
            <p>- 아파트 {data.case_size}평 기준으로 작석된 견적금액 입니다.</p>
            <p>
              - 평형별 또는 시공할 집 상태에 따라 견적 금액이 다를 수 있습니다.
            </p>
          </TopDescription>
          <div className="receipt_popup_total">
            <p className="receipt_popup_total_title">총 비용</p>
            <div className="receipt_popup_total_box">
              <p className="receipt_popup_total_subtitle1">
                {numFormat(data.case_work_price)}만원
              </p>
            </div>
          </div>
          <div className="receipt_popup_main">
            <div className="receipt_popup_main_title_box">
              <p>시공 / {data.case_size}평 기준</p>
              <p>{numFormat(data.case_work_price)}만원</p>
            </div>
            <ul className="receipt_popup_list1">
              {data &&
                data.zone_parts.map((x, i) => {
                  return (
                    <li key={i} className="receipt_popup_list1_box">
                      <div className="receipt_popup_list1_box1">
                        <p className="receipt_popup_list1_title1">
                          {findJson(consts.buildsoption, x.case_parts_no)}
                        </p>
                        <p className="receipt_popup_list1_title2">
                          {x.case_desc}
                        </p>
                      </div>
                      <p className="receipt_popup_list1_title3">
                        {numFormat(x.case_price)} 만원
                      </p>
                    </li>
                  );
                })}
            </ul>
            {!user && <InduceLoginWrap />}
          </div>
          {user && !isClosed && (
            <div className="user_floating_banner">
              <img
                src={floating_banner}
                alt="floating_banner"
                onClick={floatingHandler}
              />
              <button onClick={closeFloatingHandler}>
                <img src={basic_exit} />
              </button>
            </div>
          )}
        </Content>
        <Footer>
          <button onClick={close}>닫기</button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default ReceiptModal;
