import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { bottomSheetImages } from './images';
import EstimateModal from '../Common/Modal/EstimateModal';
import ConfirmModal from 'components/Common/Modal/ConfirmModal';

import { ORDER_ESTIMATE_TYPE } from 'utils/enum/estimate';

const Container = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-top: 1px solid #b2b2b2;
  z-index: 900;
  height: 66px;

  @media screen and (max-width: 767px) {
    display: flex;
  }
`;

const Content = styled.div`
  width: calc(100% - 140px);
  margin: 0 0 0 30px;
  display: flex;
  justify-content: space-around;

  & > a {
    margin: auto 0;
    text-align: center;
    position: relative;

    & > p {
      margin-top: 3px;
      color: #ababab;
      font-size: 10px;
      font-weight: 500;

      &.active {
        color: #000000;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 110px);
    justify-content: space-between;
  }
`;

const EstimateWrap = styled.div`
  border-radius: 50%;
  background-color: #ef3737;
  width: 58px;
  height: 58px;
  display: flex;
  cursor: pointer;
  position: absolute;

  top: -29px;
  right: 30px;

  & > div {
    margin: auto;
    font-size: 12px;
    color: #ffffff;
    font-weight: 700;
  }

  @media screen and (max-width: 767px) {
    right: 20px;
  }
`;

const MobileBottomSheet = () => {
  const { pathname } = window.location;
  const [isOpenEstimate, setIsOpenEstimate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const isRoutedList = {
    isHome: pathname === '/',
    isTheme: pathname.includes('/theme'),
    isPartner: pathname.includes('/report-partner'),
    isEvent: pathname.includes('/event'),
  };

  const callback = () => {
    setIsOpenEstimate(false);
    setIsConfirm(true);
  };

  return (
    <Container id="mobile_bottom_sheet">
      <Content>
        <Link to="/">
          <img
            src={
              isRoutedList.isHome
                ? bottomSheetImages.home_active
                : bottomSheetImages.home
            }
            alt="bottom_sheet_home"
          />
          <p className={isRoutedList.isHome ? 'active' : undefined}>홈</p>
        </Link>

        <Link to="/theme">
          <img
            src={
              isRoutedList.isTheme
                ? bottomSheetImages.theme_active
                : bottomSheetImages.theme
            }
            alt="bottom_sheet_report"
          />
          <p className={isRoutedList.isTheme ? 'active' : undefined}>테마</p>
        </Link>
        <Link to="/report-partner">
          <img
            src={
              isRoutedList.isPartner
                ? bottomSheetImages.partner_active
                : bottomSheetImages.partner
            }
            alt="bottom_sheet_report"
          />
          <p className={isRoutedList.isPartner ? 'active' : undefined}>
            시공 전문가
          </p>
        </Link>
        <Link to="/event">
          <img
            src={
              isRoutedList.isEvent
                ? bottomSheetImages.event_active
                : bottomSheetImages.event
            }
            alt="bottom_sheet_event"
          />
          <p className={isRoutedList.isEvent ? 'active' : undefined}>
            특가/혜택
          </p>
        </Link>
        <EstimateWrap onClick={() => setIsOpenEstimate(true)}>
          <div>
            상담
            <br />
            신청
          </div>
        </EstimateWrap>
      </Content>
      {isOpenEstimate && (
        <EstimateModal
          close={() => setIsOpenEstimate(false)}
          order_estimate_type={ORDER_ESTIMATE_TYPE.DEFAULT}
          callback={callback}
        />
      )}
      {isConfirm && <ConfirmModal close={() => setIsConfirm(false)} />}
    </Container>
  );
};

export default MobileBottomSheet;
