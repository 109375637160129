import React from 'react';
import styled from 'styled-components';

const ResponsiveText = styled.span`
  display: block;

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export default function FrontReviewTest(props) {
  const { rows, review = false } = props;

  return (
    <>
      {rows && review ? (
        <figure className="review_columns" style={{ border: '2px solid red' }}>
          <div className="review_columns_top">
            <img src={rows.img} />
            <div>
              <p className="review_name">
                {rows.name}
                <ResponsiveText>2021년 1월 1일</ResponsiveText>
              </p>
              <p className="review_info1">{rows.title}</p>
              <p className="review_info2">{rows.subtitle}</p>
              <span className="review_star">
                <img src={rows.score1} />
                <img src={rows.score1} />
                <img src={rows.score1} />
                <img src={rows.score1} />
                <img src={rows.score2} />
              </span>
            </div>
          </div>
          <div className="review">
            <p className="review_columns_p">{rows.content}</p>
          </div>
          <div className="review_footer">
            <p>가격😐</p>
            <p>서비스👍</p>
            <p>품질😱</p>
            <p>홈스타일링👍</p>
          </div>
        </figure>
      ) : (
        <figure className="review_columns">
          <div className="review_columns_top">
            <div>
              <p className="review_name">
                {rows.name}
                <ResponsiveText>2021년 1월 1일</ResponsiveText>
              </p>
              <p className="review_info1">{rows.title}</p>
              <p className="review_info2">{rows.subtitle}</p>
              <span className="review_star">
                <img src={rows.score1} />
                <img src={rows.score1} />
                <img src={rows.score1} />
                <img src={rows.score1} />
                <img src={rows.score2} />
              </span>
            </div>
          </div>
          <div className="review">
            <p className="review_columns_p">{rows.content}</p>
          </div>
          <div className="review_footer">
            <p>가격😐</p>
            <p>서비스👍</p>
            <p>품질😱</p>
            <p>홈스타일링👍</p>
          </div>
        </figure>
      )}
    </>
  );
}
