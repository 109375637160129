import React, { useState } from 'react';
import styled from 'styled-components';
import { SignupInfo } from 'types/user';
import { checkId, signup } from 'apis/User';
import SignupConfirmModal from './confirmModal';
import { useNavigate } from 'react-router-dom';
import useUserStore from 'stores/useUserStore';
import useClause from 'hooks/useClause';

const Container = styled.div`
  max-width: 514px;
  width: calc(100% - 60px);
  margin: 0 auto;
  padding: 56px 0 150px 0;

  & > h1 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    margin-top: 60px;
    max-width: 100%;

    & > h1 {
      font-size: 18px;
    }
  }
`;

const BasicForm = styled.div`
  margin-top: 30px;

  & > div:last-of-type {
    margin: 25px 5px 0 5px;
  }
`;

const TitleBox = styled.div`
  border-bottom: 3px solid #000000;
  padding-bottom: 13px;

  & > h2 {
    font-size: 20px;
  }

  @media screen and (max-width: 767px) {
    & > h2 {
      font-size: 16px;
    }
  }
`;

const BasicInputItem = styled.div`
  &:last-of-type {
    margin-bottom: 0;
  }

  & > p {
    font-size: 16px;
    font-weight: 700;
  }

  & > input {
    margin-top: 5px;
    border: 1px solid #dcdcdc;
    height: 46px;
    padding: 0 18px;
    width: 100%;
    font-size: 16px;
  }

  .disabled_msg {
    height: 19px;
    margin: 3px 0 3px 10px;

    & > p {
      font-size: 13px;
      color: #ff4646;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 767px) {
    & > p {
      font-size: 13px;
    }

    & > input {
      font-size: 13px;
    }

    .disabled_msg {
      & > p {
        font-size: 12px;
      }
    }
  }
`;

const BasicInputWithButton = styled.div`
  & > p {
    font-size: 16px;
    font-weight: 700;
  }

  .input_wrap {
    display: flex;
    height: 46px;
    margin-top: 5px;

    & > input {
      border: 1px solid #dcdcdc;
      border-right: none;
      height: 100%;
      padding: 0 18px;
      width: calc(100% - 87px);
      font-size: 16px;
    }

    & > button {
      width: 87px;
      height: 100%;
      background-color: #000;
      color: #fff;
      font-size: 16px;
      font-weight: 700;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .disabled_msg {
    height: 19px;
    margin: 3px 0 3px 10px;

    & > p {
      font-size: 13px;
      color: #ff4646;
      font-weight: 400;

      &.abled_text {
        color: #000;
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > p {
      font-size: 13px;
    }

    .input_wrap {
      & > input {
        font-size: 13px;
      }

      & > button {
        font-size: 13px;
      }
    }

    .disabled_msg {
      & > p {
        font-size: 12px;
      }
    }
  }
`;

const AddForm = styled.div`
  margin-top: 60px;

  & > div {
    margin: 25px 5px 0 5px;

    &:first-of-type {
      margin: 0;
    }

    & > p {
      font-size: 16px;
      font-weight: 700;
      margin-left: 5px;
    }
    & > div {
      display: flex;
      justify-content: space-between;
    }

    &:last-of-type {
      & > div {
        justify-content: start;
        gap: 0 70px;
        margin-left: 15px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      & > p {
        font-size: 13px;
      }

      &:last-of-type {
        & > div {
          gap: 0 30px;
        }
      }
    }
  }
`;

const CustomDateInput = styled.div`
  margin-top: 5px;
  display: flex;

  width: 31%;

  & > input {
    border: 1px solid #dcdcdc;
    height: 46px;
    width: calc(100% - 27px);
    padding: 0 10px;
    font-size: 16px;
  }

  & > p {
    width: 20px;
    font-size: 16px;
    font-weight: 700;
    margin: auto 0 auto 7px;
  }

  @media screen and (max-width: 767px) {
    & > input {
      font-size: 13px;
    }

    & > p {
      font-size: 13px;
    }
  }
`;

const CustomRadio = styled.label`
  display: flex;
  margin-top: 15px;
  cursor: pointer;

  & > input {
    width: 20px;
    height: 20px;

    appearance: auto;
    margin: auto 0;
  }

  & > p {
    margin: 0px 0 auto 15px;
    font-size: 16px;
    font-weight: 700;
  }

  @media screen and (max-width: 767px) {
    & > input {
      width: 16px;
      height: 16px;
    }

    & > p {
      font-size: 13px;
    }
  }
`;

const CheckForm = styled.div`
  margin-top: 35px;
`;

const AllCheckWrap = styled.div`
  background-color: #f3f3f3;
  padding: 14px 24px 14px 29px;
`;

const MoreTermsWrap = styled.div`
  margin: 15px 24px 0 29px;

  & > label {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const CustomCheckbox = styled.label`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 80%;
    display: inline-block;
    position: relative;
    cursor: pointer;

    & > input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      box-sizing: content-box;
      width: 16px;
      height: 16px;
      position: absolute;
      background: #d6d6d6;
      border: 2px solid #d6d6d6;

      &::after {
        content: '';
        position: absolute;
        left: 5px;
        top: 1px;
        width: 4px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      background-color: #000;
      border: 2px solid #000;
    }

    input:disabled ~ .checkmark {
      opacity: 0.5;
    }

    input:disabled ~ p {
      color: gray;
    }

    & > p {
      font-size: 16px;
      font-weight: 600;
      margin: -2px 0 0 30px;
    }
  }

  & > button {
    display: inline-block;
    background: none;
    font-size: 13px;
    color: #7e7e7e;
    text-decoration: underline;
  }

  @media screen and (max-width: 767px) {
    & > div {
      width: auto;
      display: inline-flex;

      .checkmark {
        width: 13px;
        height: 13px;

        &::after {
          left: 4px;
          top: 0px;
          width: 3px;
          height: 9px;
        }
      }

      & > p {
        font-size: 13px;
      }
    }

    & > button {
      font-size: 12px;
    }
  }
`;

const SignUpWrap = styled.div`
  margin-top: 60px;
  text-align: center;

  & > button {
    width: 338px;
    height: 52px;
    background-color: #000;
    color: #fff;
    font-size: 18px;
    font-weight: 700;

    &.disabled {
      background-color: #e0e0e0;
      color: #8f8e8e;
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: 767px) {
    & > button {
      width: 100%;
      height: 50px;
      font-size: 15px;
    }
  }
`;

const SignupComponent = () => {
  const searchParams = new URLSearchParams(location.search);
  const kakao_auth_key = searchParams.get('kakao_auth_key');
  const { setUser } = useUserStore();
  const { showClause } = useClause();

  const [info, setInfo] = useState<SignupInfo>({
    user_id: '',
    user_passwd: '',
    user_name: '',
    user_phone: '',
    birth_year: '',
    birth_month: '',
    birth_day: '',
    gender: '0',
    market_agree: '0',
    kakao_auth_key: Number(kakao_auth_key) || undefined,
  });

  const [passwordCheck, setPasswordCheck] = useState('');

  const [showMoreTerms, setShowMoreTerms] = useState(false);

  const [terms, setTerms] = useState({
    terms1: false,
    terms2: false,
  });

  const [isCheckedId, setIsCheckedId] = useState(0);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [isSignUpConfirm, setIsSignUpConfirm] = useState(false);
  const [checkingId, setCheckingId] = useState('');

  const navigate = useNavigate();

  const validate = (input?: string) => {
    // 최소 1개의 영문자와 1개의 숫자를 포함하는 정규표현식
    const regex = /^(?=.*[a-zA-Z])(?=.*\d).+$/;

    if (input) {
      return regex.test(input);
    } else {
      return false;
    }
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const newValue =
      name === 'user_phone' ? value.replace(/[^0-9]/g, '') : value;

    setInfo((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const onClickCheckId = async () => {
    const { user_id } = info;

    if (user_id === '') {
      alert('아이디를 입력해 주세요.');
      return;
    } else if (user_id && user_id.length < 2) {
      alert('2자 이상의 아이디를 입력해주세요.');
      return;
    } else if (validate(user_id) === false) {
      alert('영문, 숫자를 조합해주세요.');
      return;
    }

    try {
      const res = await checkId(info?.user_id);

      if (res.code === 'OK') {
        if (res.is_chk === 1) {
          setIsCheckedId(1);
          setCheckingId('');
          alert('사용 가능한 아이디 입니다.');
        } else if (res.is_chk === 2) {
          setIsCheckedId(2);
          setCheckingId(user_id || '');
          alert('이미 사용중인 아이디 입니다.');
        }
      } else {
        setIsCheckedId(0);
      }
    } catch (err) {
      setIsCheckedId(0);
      alert('오류가 발생하였습니다.');
      console.log(err);
    }
  };

  const changeRadioHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfo((prev) => ({
      ...prev,
      gender: e.target.value,
    }));
  };

  const getIdStatus = (): {
    status: boolean;
    msg: string;
  } => {
    const { user_id } = info;

    let status = false;
    let msg = '';

    if (kakao_auth_key) {
      status = true;
      msg = '';
    } else {
      if (user_id === '') {
        status = false;
        msg = '아이디를 입력해 주세요.';
      } else if (user_id && user_id.length < 2) {
        status = false;
        msg = '2자리 이상 입력해 주세요.';
      } else if (validate(user_id) === false) {
        status = false;
        msg = '영문, 숫자를 조합해주세요.';
      } else if (isCheckedId === 0) {
        status = false;
        msg = '아이디 중복체크를 해주세요.';
      } else if (isCheckedId === 1) {
        status = true;
        msg = '사용 가능한 아이디 입니다.';
      } else if (isCheckedId === 2) {
        if (user_id === checkingId) {
          status = false;
          msg = '이미 사용중인 아이디 입니다.';
        } else {
          status = false;
          msg = '';
        }
      }
    }

    return {
      status,
      msg,
    };
  };

  const getPasswordStatus = (): {
    status: boolean;
    msg: string;
  } => {
    const { user_passwd } = info;

    let status = false;
    let msg = '';

    if (kakao_auth_key) {
      status = true;
      msg = '';
    } else {
      if (user_passwd === '') {
        status = false;
        msg = '비밀번호를 입력해 주세요.';
      } else if (user_passwd && user_passwd.length < 6) {
        status = false;
        msg = '6~16자리에 맞춰서 입력해 주세요.';
      } else if (validate(user_passwd) === false) {
        status = false;
        msg = '영문, 숫자를 조합해주세요.';
      } else {
        status = true;
        msg = '';
      }
    }

    return {
      status,
      msg,
    };
  };

  const getPasswordCheckStatus = (): {
    status: boolean;
    msg: string;
  } => {
    let status = false;
    let msg = '';

    if (kakao_auth_key) {
      status = true;
      msg = '';
    } else {
      if (passwordCheck === '') {
        status = false;
        msg = '비밀번호 확인을 입력해 주세요.';
      } else if (info.user_passwd !== passwordCheck) {
        status = false;
        msg = '비밀번호가 일치하지 않습니다.';
      } else {
        status = true;
        msg = '';
      }
    }

    return {
      status,
      msg,
    };
  };

  const getNameStatus = (): {
    status: boolean;
    msg: string;
  } => {
    let status = false;
    let msg = '';

    const { user_name } = info;

    if (user_name === '') {
      status = false;
      msg = '이름을 입력해 주세요.';
    } else {
      status = true;
      msg = '';
    }

    return {
      status,
      msg,
    };
  };

  const getPhoneStatus = (): {
    status: boolean;
    msg: string;
  } => {
    let status = false;
    let msg = '';

    const { user_phone } = info;

    if (user_phone === '') {
      status = false;
      msg = '전화번호를 입력해 주세요.';
    } else {
      status = true;
      msg = '';
    }

    return {
      status,
      msg,
    };
  };

  const getIsDisabledStatus = () => {
    const pass = getPasswordStatus();
    const passCheck = getPasswordCheckStatus();
    const name = getNameStatus();
    const phone = getPhoneStatus();

    const { terms1, terms2 } = terms;

    if (
      (kakao_auth_key ? isCheckedId === 0 : isCheckedId === 1) &&
      pass.status &&
      passCheck.status &&
      name.status &&
      phone.status &&
      terms1 &&
      terms2
    ) {
      return false;
    } else {
      return true;
    }
  };

  const submitHandler = async () => {
    const isDisabled = getIsDisabledStatus();

    if (isDisabled) {
      setIsSubmitDisabled(true);
      if (isCheckedId === 2 && info.user_id !== checkingId) {
        setIsCheckedId(0);
      }
      return;
    }
    setIsSubmitDisabled(false);

    try {
      const res = await signup(info);

      if (res.code === 'OK') {
        setUser(res.user_info[0]);
        setIsSignUpConfirm(true);
      } else {
        alert('오류가 발생하였습니다.');
        return;
      }
    } catch (err) {
      alert('회원가입 중 오류가 발생하였습니다.');
      console.log(err);
    }
  };

  const signupConfirmCallback = () => {
    setIsSignUpConfirm(false);
    navigate('/');
  };

  return (
    <Container>
      <h1>회원가입</h1>
      <BasicForm>
        <TitleBox>
          <h2>기본정보</h2>
        </TitleBox>
        <div>
          <BasicInputWithButton>
            <p>* 아이디</p>
            <div className="input_wrap">
              <input
                placeholder="2자리 이상의 영문 + 숫자 조합"
                name="user_id"
                value={
                  kakao_auth_key ? `${kakao_auth_key}(kakao)` : info.user_id
                }
                onChange={changeInputHandler}
                disabled={!!kakao_auth_key || isCheckedId === 1}
              />
              {isCheckedId === 1 ? (
                <button onClick={() => setIsCheckedId(0)}>변경</button>
              ) : (
                <button onClick={onClickCheckId} disabled={!!kakao_auth_key}>
                  {kakao_auth_key ? '인증완료' : '중복체크'}
                </button>
              )}
            </div>
            <div className="disabled_msg">
              {isSubmitDisabled && (
                <p className={isCheckedId === 1 ? 'abled_text' : ''}>
                  {getIdStatus().msg}
                </p>
              )}
            </div>
          </BasicInputWithButton>
          {!kakao_auth_key && (
            <>
              <BasicInputItem>
                <p>* 비밀번호</p>
                <input
                  placeholder="6자 ~ 16자의 영문 + 숫자 조합"
                  name="user_passwd"
                  onChange={changeInputHandler}
                  maxLength={16}
                  type="password"
                />
                <div className="disabled_msg">
                  {info?.user_passwd && !getPasswordStatus().status ? (
                    <p>{getPasswordStatus().msg}</p>
                  ) : (
                    <></>
                  )}
                </div>
              </BasicInputItem>
              <BasicInputItem>
                <p>* 비밀번호 확인</p>
                <input
                  placeholder="비밀번호를 다시 입력하세요."
                  maxLength={16}
                  type="password"
                  onChange={(e) => setPasswordCheck(e.target.value)}
                />
                <div className="disabled_msg">
                  {passwordCheck ? (
                    <p>{getPasswordCheckStatus().msg}</p>
                  ) : (
                    isSubmitDisabled && <p>{getPasswordCheckStatus().msg}</p>
                  )}
                </div>
              </BasicInputItem>
            </>
          )}
          <BasicInputItem>
            <p>* 이름</p>
            <input
              placeholder="이름을 입력하세요."
              name="user_name"
              onChange={changeInputHandler}
            />
            <div className="disabled_msg">
              {isSubmitDisabled && <p>{getNameStatus().msg}</p>}
            </div>
          </BasicInputItem>
          <BasicInputItem>
            <p>* 전화번호</p>
            <input
              placeholder="숫자만 입력하세요."
              name="user_phone"
              onChange={changeInputHandler}
              value={info.user_phone}
            />
            <div className="disabled_msg">
              {isSubmitDisabled && <p>{getPhoneStatus().msg}</p>}
            </div>
          </BasicInputItem>
        </div>
      </BasicForm>
      <AddForm>
        <TitleBox>
          <h2>추가정보(선택)</h2>
        </TitleBox>
        <div>
          <p>생년월일</p>
          <div>
            <CustomDateInput>
              <input
                placeholder="1900"
                maxLength={4}
                name="birth_year"
                onChange={changeInputHandler}
              />
              <p>년</p>
            </CustomDateInput>
            <CustomDateInput>
              <input
                placeholder="00"
                maxLength={2}
                name="birth_month"
                onChange={changeInputHandler}
              />
              <p>월</p>
            </CustomDateInput>
            <CustomDateInput>
              <input
                placeholder="00"
                maxLength={2}
                name="birth_day"
                onChange={changeInputHandler}
              />
              <p>일</p>
            </CustomDateInput>
          </div>
        </div>
        <div>
          <p>성별</p>
          <div>
            <CustomRadio htmlFor="male">
              <input
                type="radio"
                value="1"
                onChange={changeRadioHandler}
                checked={info.gender === '1'}
                id="male"
              />
              <p>남성</p>
            </CustomRadio>
            <CustomRadio htmlFor="female">
              <input
                type="radio"
                value="2"
                onChange={changeRadioHandler}
                checked={info.gender === '2'}
                id="female"
              />
              <p>여성</p>
            </CustomRadio>
          </div>
        </div>
      </AddForm>
      <CheckForm>
        <AllCheckWrap>
          <CustomCheckbox>
            <div>
              <input
                type="checkbox"
                checked={
                  terms.terms1 && terms.terms2 && info.market_agree === '1'
                }
                onChange={() => {
                  if (
                    terms.terms1 &&
                    terms.terms2 &&
                    info.market_agree === '1'
                  ) {
                    setTerms({
                      terms1: false,
                      terms2: false,
                    });
                    setInfo((prev) => ({
                      ...prev,
                      market_agree: '0',
                    }));
                  } else {
                    setTerms({
                      terms1: true,
                      terms2: true,
                    });
                    setInfo((prev) => ({
                      ...prev,
                      market_agree: '1',
                    }));
                  }
                }}
              />
              <span className="checkmark" />
              <p>전체 약관 동의</p>
            </div>
            <button onClick={() => setShowMoreTerms(!showMoreTerms)}>
              약관 펼쳐보기
            </button>
          </CustomCheckbox>
        </AllCheckWrap>

        {showMoreTerms && (
          <MoreTermsWrap>
            <CustomCheckbox>
              <div>
                <input
                  type="checkbox"
                  checked={terms.terms1}
                  onChange={() =>
                    setTerms((prev) => ({
                      ...prev,
                      terms1: !terms.terms1,
                    }))
                  }
                />
                <span className="checkmark" />
                <p>[필수] 이용약관 동의</p>
              </div>
              <button onClick={() => showClause(5)}>보기</button>
            </CustomCheckbox>
            <CustomCheckbox>
              <div>
                <input
                  type="checkbox"
                  checked={terms.terms2}
                  onChange={() =>
                    setTerms((prev) => ({
                      ...prev,
                      terms2: !terms.terms2,
                    }))
                  }
                />
                <span className="checkmark" />
                <p>[필수] 개인정보 수집 및 이용 동의</p>
              </div>
              <button onClick={() => showClause(1)}>보기</button>
            </CustomCheckbox>
            <CustomCheckbox>
              <div>
                <input
                  type="checkbox"
                  checked={info.market_agree === '1'}
                  onChange={() =>
                    setInfo((prev) => ({
                      ...prev,
                      market_agree: prev.market_agree === '0' ? '1' : '0',
                    }))
                  }
                />
                <span className="checkmark" />
                <p>[선택] 마케팅 정보 수신 동의</p>
              </div>
              <button onClick={() => showClause(4)}>보기</button>
            </CustomCheckbox>
          </MoreTermsWrap>
        )}
      </CheckForm>
      <SignUpWrap>
        <button
          onClick={submitHandler}
          className={getIsDisabledStatus() ? 'disabled' : ''}
        >
          회원가입
        </button>
      </SignUpWrap>
      {isSignUpConfirm && (
        <SignupConfirmModal signupConfirmCallback={signupConfirmCallback} />
      )}
    </Container>
  );
};

export default SignupComponent;
