import moment from 'moment';

const getSpecialDdayText = (avt_days?: string) => {
  if (avt_days) {
    const endDate = avt_days.split(' ~ ')[1]; // avt_days에서 종료일
    const calcEndDate = moment(endDate).format('YYYY-MM-DD 23:59:59'); // 23시 59분 59초로 초기화

    const endTime: Date = new Date(calcEndDate); // date type으로 만듦
    const nowTime: Date = new Date();

    const diff = endTime.getTime() - nowTime.getTime();

    const diffDay = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (diffDay < 0) {
      return '마감됨';
    } else if (diffDay === 0) {
      return 'D-day (오늘까지!)';
    } else {
      return `D-${diffDay}`;
    }
  } else {
    return null;
  }
};

export { getSpecialDdayText };
