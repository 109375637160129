const exportData = {
  life_construction: '/life-construction', // 생활시공
  home: '/', // 홈(메인)
  theme: '/theme', //테마 인테리어
  theme_detail: '/theme/:id', // 테마인테리어 상세
  theme_detail_outbound: '/theme/outbound', // 테마 인테리어 외부 유도 (홈스타일링에서 제품 상세 링크 이동)
  report: '/report', // 시공사례 모음
  report_detail: '/report/:id', // 시공사례 상세
  partner: '/report-partner', // 시공 전문가
  partner_detail: '/report-partner/:id', // 시공 전문가 상세
  event: '/event', // 특가/혜택
  event_detail: '/event/:id', // 특가/혜택 상세
  hsEvent: '/aptn/hs_event',
  aptn_event_consulting: '/aptn/event/consulting',
  clause: '/clause/:id', // 이용 약관
  kakao_login_callback: '/auth', // 카카오 로그인 callback
  signup: '/signup', // 회원가입 페이지
  noticeDetail: '/notice/detail',
  service: '/service', // 서비스 소개 페이지
};

export default exportData;
