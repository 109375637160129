import { useRef, useEffect, useCallback } from 'react';
import { productList } from '../../utils/life-construction';

const useMonthly = () => {
  const dayRef = useRef<HTMLDivElement | null>(null);
  const dateRef = useRef<HTMLDivElement | null>(null);

  const flatList = productList
    .map((item) => {
      return {
        ...item,
        items: item.items.map((childItem) => {
          return {
            ...childItem,
            parent: item,
          };
        }),
      };
    })
    .flatMap((item) => item.items);

  const mockData = [
    {
      ...{
        ...flatList.find((item) => item.id === 7),
      },
      apllication_count: 130,
      image: 'https://static.layhome.co.kr/62998823e8931.jpeg',
      discount: 10,
      isApplication: true,
    },
    {
      ...{
        ...flatList.find((item) => item.id === 15),
      },
      apllication_count: 130,
      image: 'https://static.layhome.co.kr/62998823e8931.jpeg',
      discount: 35,
      isApplication: true,
    },
    {
      ...{
        ...flatList.find((item) => item.id === 16),
      },
      apllication_count: 130,
      image: 'https://static.layhome.co.kr/62998823e8931.jpeg',
      discount: 10,
      isApplication: false,
    },
    {
      ...{
        ...flatList.find((item) => item.id === 26),
      },
      apllication_count: 130,
      image: 'https://static.layhome.co.kr/62998823e8931.jpeg',
      discount: 20,
      isApplication: true,
    },
  ];

  const getDiff = () => {
    const today = new Date();
    const lastDay = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
      23,
      59,
      59
    );

    const diff = Number(lastDay) - Number(today);

    const diffDay = Math.floor(diff / (1000 * 60 * 60 * 24));
    const diffHour = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const diffMin = Math.floor((diff / (1000 * 60)) % 60);
    const diffSec = Math.floor((diff / 1000) % 60);

    return {
      day: diffDay,
      date: `${diffHour > 9 ? diffHour : '0' + diffHour}:${
        diffMin > 9 ? diffMin : '0' + diffMin
      }:${diffSec > 9 ? diffSec : '0' + diffSec}`,
    };
  };

  const setInnerText = useCallback(() => {
    const getDate = getDiff();
    if (dayRef.current && dateRef.current) {
      dayRef.current.innerText = `D-${getDate.day}`;
      dateRef.current.innerText = getDate.date;
    }
  }, []);

  useEffect(() => {
    setInnerText();

    const timer = setInterval(() => {
      setInnerText();
    }, 1000);

    return () => clearInterval(timer);
  }, [setInnerText]);

  return {
    dayRef,
    dateRef,
    mockData,
  };
};

export default useMonthly;
