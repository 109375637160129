import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { numFormat, findJson } from 'utils/utils';
import consts from 'libs/consts';
import images from 'libs/images';

import ReportDetailReceipt from './receipt';
import ReportDetailItem from './item';
import ReportDetailStyle from './style';
import Similar from './Similar';
import EstimateModal from 'components/Common/Modal/EstimateModal';
import ConfirmModal from 'components/Common/Modal/ConfirmModal';

import useRecentStore from 'stores/useRecentStore';
import FrontThemeDrowingModal from 'components/Theme/Modal/DrowingModal';
import ReceiptModal from '../Modal/ReceiptModal';
import { styleList } from 'utils/frontReport';
import { ORDER_ESTIMATE_TYPE } from 'utils/enum/estimate';
import { LineBanner } from 'types/home';
import { ReportDetail as ReportType } from 'types/report';

const Container = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    max-width: auto;
    width: 100%;
    padding-top: 60px;
    position: relative;
  }
`;

const TitleWrap = styled.div`
  margin-top: 90px;
  text-align: center;
  padding-bottom: 40px;
  border-bottom: 1px solid;

  & > h1 {
    font-size: 36px;
    font-weight: 700;
  }

  & > h3 {
    margin-top: 40px;
    font-size: 22px;
    font-weight: 400;
  }

  .mobile_partner_info {
    display: none;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    width: calc(100% - 60px);
    margin: 34px auto 0 auto;
    text-align: left;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px;

    & > h1 {
      display: none;
    }

    & > h3 {
      color: #030303;
      font-size: 17px;
      font-weight: 700;
    }

    .mobile_partner_info {
      display: flex;
      margin-top: 15px;

      .partner_logo {
        & > img {
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }
      }

      .partner_info {
        margin: auto 0 auto 10px;
        & > p {
          font-size: 14px;
        }
      }
    }
  }
`;

const InfoWrap = styled.div`
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;

  .left_info_box {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;

    gap: 20px 0px;

    & > div {
      width: 150px;

      &:nth-child(1),
      &:nth-child(4) {
        margin-right: 60px;
      }

      & > h3 {
        font-size: 20px;
        font-weight: 700;
      }

      & > p {
        font-size: 20px;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }

  .right_info_box {
    width: 30%;
    margin-top: 20px;

    & > h3 {
      font-size: 22px;
      font-weight: 700;
    }

    & > div {
      display: flex;
      margin-top: 25px;

      .partner_logo {
        & > img {
          width: 71px;
          height: 71px;
          border-radius: 50%;
        }
      }

      .partner_info {
        margin: 10px 0 0 25px;
        display: flex;
        flex-wrap: wrap;

        & > h3 {
          font-size: 20px;
          font-weight: 700;
        }

        & > p {
          width: 100%;
          margin-top: 2px;
          font-size: 14px;
          font-weight: 400;
        }

        & > a {
          font-size: 14px;
          font-weight: 400;
          margin-top: 20px;
          padding: 10px 20px;
          border: 1px solid;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 15px;

    .left_info_box {
      max-width: 100%;
      width: calc(100% - 60px);
      margin: 0 auto;
      gap: 16px 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #d9d9d9;

      & > div {
        margin-right: 0 !important;
        & > h3,
        p {
          font-size: 12px;
        }
      }
    }

    .right_info_box {
      display: none;
    }
  }

  @media screen and (max-width: 674px) {
    .left_info_box {
      & > div {
        width: 125px;

        &:nth-child(4) {
          width: 100px;
        }
      }
    }
  }
  @media screen and (max-width: 550px) {
    .left_info_box {
      & > div {
        &:nth-child(4) {
          width: 125px;
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    .left_info_box {
      justify-content: space-between;
      & > div {
        width: 100px !important;
      }
    }
  }
`;

const BeltBannerWrap = styled.div<{ isMobile: boolean }>`
  div,
  p,
  span,
  h1,
  h2,
  h3 {
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
  }

  background-color: #000000;
  display: ${(props) => (props.isMobile ? 'none' : 'flex')};
  justify-content: center;
  padding: 15px 0;
  margin-top: 45px;

  & > div {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px 30px;

    & > div {
      cursor: pointer;
      margin: auto;
    }
  }

  @media screen and (max-width: 767px) {
    div,
    p,
    span,
    h1,
    h2,
    h3 {
      font-size: 12px;
    }

    margin-top: 0;
    display: ${(props) => (props.isMobile ? 'flex' : 'none')};
    padding: 10px 0;
  }
`;

const Content = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
`;

const MobileImageSection = styled.div<{ url: string }>`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
    position: relative;
    padding-bottom: 62%;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url(${(props) => props.url}) no-repeat center center;
      background-size: cover;
    }
  }
`;

const MobileDrawingWrap = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    width: calc(100% - 60px);
    margin: 0 auto;
    padding: 80px 0 0 0;

    & > h3 {
      font-size: 18px;
    }

    & > img {
      width: 100%;
      max-height: 400px;
      cursor: pointer;
      margin-top: 8px;
    }
  }
`;

const MobileBottomSheet = styled.div<{ isReceipt: boolean }>`
  display: none;

  @media screen and (max-width: 767px) {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 900;

    & > button {
      height: 55px;
      width: ${(props) => (props.isReceipt ? '50%' : '100%')};
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      display: block;

      &.bottom_sheet_receipt {
        background-color: #fa5500;
        display: block;
      }

      &.bottom_sheet_estimate {
        background-color: #000000;
      }
    }
  }
`;

interface Props {
  id: string;
  report: ReportType;
}

const ReportDetail = (props: Props) => {
  const { id, report } = props;
  const { setList } = useRecentStore();

  const [isOpenEstimate, setIsOpenEstimate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const [isOpenDrawing, setIsOpenDrawing] = useState(false);
  const [isOpenReceipt, setIsOpenReceipt] = useState(false);

  const [lineBanner, setLineBanner] = useState<LineBanner>();

  useEffect(() => {
    if (id && report) {
      window.scrollTo({ top: 0, behavior: 'auto' });
      setList({ id: id, r_type: '1', img: report.case_photo });
    }
  }, [id, report]);

  const getRandomLineBanner = () => {
    if (report) {
      const { line_banner_list } = report;

      const max = line_banner_list.length;
      const randomIndex = Math.floor(Math.random() * max);

      return line_banner_list[randomIndex];
    }
  };

  useEffect(() => {
    if (report) {
      const randomLineBanner = getRandomLineBanner();

      setLineBanner(randomLineBanner);
    }
  }, [report]);

  const estimateHandler = () => {
    setIsOpenEstimate(true);
  };

  const onRoute = (url: string) => {
    window.location.href = url;
  };

  const callback = () => {
    setIsOpenEstimate(false);
    setIsConfirm(true);
  };

  const beltEl = (isMobile: boolean) =>
    lineBanner && (
      <BeltBannerWrap isMobile={isMobile}>
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: lineBanner.line_title,
            }}
            onClick={() => onRoute(lineBanner.line_url)}
          />
        </div>
      </BeltBannerWrap>
    );

  return (
    <Container>
      {report.case_photo && (
        <MobileImageSection url={report.case_photo}>
          <div />
        </MobileImageSection>
      )}
      {lineBanner && beltEl(true)}
      <TitleWrap>
        <h1>{report.case_desc}</h1>
        <h3>{report.case_name}</h3>
        <div className="mobile_partner_info">
          <div className="partner_logo">
            <img src={images.comp_logo} />
          </div>
          <div className="partner_info">
            <p>{report.seller_biz_name}</p>
          </div>
        </div>
      </TitleWrap>
      <InfoWrap>
        <div className="left_info_box">
          <div>
            <h3>주소</h3>
            <p>{report.work_area}</p>
          </div>
          <div>
            <h3>평형</h3>
            <p>{report.case_size}평</p>
          </div>
          <div>
            <h3>비용</h3>
            <p>{numFormat(Number(report.case_work_price))}만원</p>
          </div>
          <div>
            <h3>주거형태</h3>
            <p>{findJson(consts.workoption, report.case_home_type)}</p>
          </div>
          <div>
            <h3>시공 기간</h3>
            <p>{Math.round(Number(report.case_work_period) / 7)}주</p>
          </div>
          <div>
            <h3>스타일</h3>
            <p>
              {
                styleList.find((item) => item.value === report.interior_style)
                  ?.title
              }
            </p>
          </div>
        </div>
        <div className="right_info_box">
          <h3>시공 업체</h3>
          <div>
            <div className="partner_logo">
              <img src={report.seller_icon || images.comp_logo} />
            </div>
            <div className="partner_info">
              <h3>{report.seller_intro || '-'}</h3>
              <p>{report.seller_biz_name}</p>
              <Link to={`/report-partner/${report.seller_table_id}`}>
                보러가기
              </Link>
            </div>
          </div>
        </div>
      </InfoWrap>
      {lineBanner && beltEl(false)}
      {report.case_drawing && (
        <MobileDrawingWrap>
          <h3>도면</h3>
          <img
            src={report.case_drawing}
            alt="drawing"
            onClick={() => setIsOpenDrawing(true)}
          />
        </MobileDrawingWrap>
      )}
      <Content>
        <ReportDetailReceipt
          type={findJson(consts.workoption, report.case_home_type)}
          size={report.case_size}
          totalPrice={report.case_work_price}
          receipts={report.zone_parts}
          drawing={report.case_drawing}
          estimateHandler={estimateHandler}
        />
        <ReportDetailItem case_parts={report.case_parts} />
      </Content>
      <ReportDetailStyle contents={report.content_list} />
      <Similar size={report.case_size} price={report.case_work_price} />
      {isOpenEstimate && (
        <EstimateModal
          close={() => setIsOpenEstimate(false)}
          order_estimate_type={ORDER_ESTIMATE_TYPE.REPORT}
          key_id={id}
          callback={callback}
        />
      )}
      {isOpenDrawing && report.case_drawing && (
        <FrontThemeDrowingModal
          close={() => setIsOpenDrawing(false)}
          image={report.case_drawing}
        />
      )}
      <MobileBottomSheet isReceipt={report.zone_parts.length > 0}>
        {report.zone_parts.length > 0 && (
          <button
            className="bottom_sheet_receipt"
            onClick={() => setIsOpenReceipt(true)}
          >
            영수증 보기
          </button>
        )}
        <button
          className="bottom_sheet_estimate"
          id="report_estimate_btn"
          onClick={estimateHandler}
        >
          견적 받기
        </button>
      </MobileBottomSheet>
      {isOpenReceipt && (
        <ReceiptModal close={() => setIsOpenReceipt(false)} data={report} />
      )}
      {isConfirm && <ConfirmModal close={() => setIsConfirm(false)} />}
    </Container>
  );
};

export default ReportDetail;
