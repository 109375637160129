// 생활 시공
import { MonthlyList } from 'types/life';
import axios from '../utils/axios';

const MIN_SWIPER_MONTHLY_ITEM_COUNT = 3;
// 생활시공 견적요청
export const createLifeOrder = async (params = {}) => {
  const res = await axios.post('/UserLifeOrder/LifeOrderCreate', params);

  return res.data;
};

export const getMonthlyList = async (): Promise<{
  code: string;
  life_monthly_list: MonthlyList[];
  total_cnt: number;
}> => {
  const res = await axios.post('/UserLifeOrder/MonthlyItemList', {});

  if (res.data.total_cnt < MIN_SWIPER_MONTHLY_ITEM_COUNT) {
    const refeel =
      MIN_SWIPER_MONTHLY_ITEM_COUNT - res.data.life_monthly_list.length;
    [...Array(refeel)].map((d, i) => {
      res.data.life_monthly_list.push({
        monthly_table_id: i,
        title: '',
        image: '',
        isApplication: '0',
        url: '',
      });
    });
    res.data.total_cnt = MIN_SWIPER_MONTHLY_ITEM_COUNT;
  }
  return res.data;
};
