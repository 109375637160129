import images from 'libs/images';

export const howToList = [
  {
    idx: 1,
    next: images.right_arrow,
    num: 1,
    title: '테마 상담/실측',
    img: images.theme_img1,
    description:
      'CS 상담\n전담매니저 1:1 미팅\n현장실측 (현장실측 비용유료)\n견적서 발송',
  },
  {
    idx: 2,
    next: images.right_arrow,
    num: 2,
    title: '계약/입금',
    img: images.theme_img2,
    description: '전자 계약\n계약금 입금',
  },
  {
    idx: 3,
    next: images.right_arrow,
    num: 3,
    title: '시공',
    img: images.theme_img3,
    description:
      '시공시작\n중도금 입금\n매니저와 중간점검\n(시공사진, 진행상황 피드백)',
  },
  {
    idx: 4,
    next: images.right_arrow,
    num: 4,
    title: '홈스타일링 배치/마감',
    img: images.theme_img4,
    description: '가구소품 배치\n시공 마감확인서\n잔금 입금',
  },
];
