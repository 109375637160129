import React from 'react';
import Layout from 'components/Layout';
import ReportDetail from 'components/Report/Detail';
import { useParams } from 'react-router-dom';
import useReportQueries from 'hooks/queries/report';

const ReportDetailPage = () => {
  const { id } = useParams();

  const { getReportDetailQuery } = useReportQueries();
  const { data: report, isFetching } = getReportDetailQuery({
    case_table_id: String(id),
  });

  if (!report || isFetching) {
    return <></>;
  }

  return (
    <Layout
      headerText={report.case_desc}
      isHeaderTextAlign="center"
      is_hide_mobile_bottom_sheet
    >
      <ReportDetail id={String(id)} report={report} />
    </Layout>
  );
};

export default ReportDetailPage;
