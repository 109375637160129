import React, { useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Home } from '../../types/home';
import { Link } from 'react-router-dom';
import prev_arrow_circle from './images/prev_arrow_circle.png';
import next_arrow_circle from './images/next_arrow_circle.png';

const Container = styled.div`
  position: relative;

  &:hover {
    .banner-image {
      transform: scale(1.1);
      transition: transform 0.2s;
    }

    .arrow_wrap {
      display: flex;
    }
  }

  .swiper-slide {
    position: relative;
    width: 100%;
    padding-bottom: 29%;
    overflow: hidden;

    .banner-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: transform 0.2s;
    }
  }

  @media screen and (max-width: 1048px) {
    .swiper-slide {
      height: 400px;
    }
  }

  @media screen and (max-width: 767px) {
    &:hover {
      .arrow_wrap {
        display: none;
      }
    }
  }
`;

const ImageContent = styled.div`
  cursor: pointer;

  .banner-image {
    &.pc {
      display: block;
    }

    &.mo {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    .banner-image {
      &.pc {
        display: none;
      }

      &.mo {
        display: block;
      }
    }
  }
`;

const Progressbar = styled.div`
  display: block;
  position: absolute;
  left: 5%;

  bottom: 13.5%;
  width: 100%;
  z-index: 1;

  .front_sw_pagebox {
    max-width: 1200px;
    width: calc(100% - 60px);
    margin: 0 auto;
    display: flex;

    .swiper-pagination {
      margin-top: 13px;
      width: 340px;
      background: rgba(0, 0, 0, 0.2);
      position: relative;
      height: 2px;

      .swiper-pagination-progressbar-fill {
        opacity: 1 !important;
        background: #000000;
      }
    }

    .swiper-active-number-wrap {
      display: flex;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;

      & > a {
        margin: -1px 0 0 5px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MOPageInfo = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    position: absolute;
    bottom: 20px;
    right: 35px;
    z-index: 100;

    & > p {
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 2px;
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.15);
      padding: 5px 10px;
    }
  }
`;

const ArrowWrap = styled.div`
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  & > div {
    max-width: 1200px;
    width: calc(100% - 60px);
    margin: auto;
    display: flex;
    justify-content: space-between;

    & > button {
      background: none;
      z-index: 100;

      & > img {
        width: 65px;
        height: 65px;
      }

      &.banner_prev_arrow {
        margin-left: -10px;
      }

      &.banner_next_arrow {
        margin-right: -10px;
      }
    }
  }
`;

interface Props {
  data: Home['hero_banner_list'];
}

const HomeBanner = (props: Props) => {
  const { data } = props;
  const [activeIndex, setActiveIndex] = useState(1);

  const detailHandler = (url: string) => {
    window.location.href = url;
  };

  return (
    <Container>
      <Swiper
        slidesPerView={1}
        autoplay={{ delay: 5000 }}
        loop={true}
        pagination={{
          el: '.front_sw_pagebox .front_swiper_pagenation',
          type: 'progressbar',
        }}
        navigation={{
          prevEl: '.front_sw_pagebox .banner_prev_arrow',
          nextEl: '.front_sw_pagebox .banner_next_arrow',
        }}
        onSlideChange={(swiper) => {
          const { realIndex } = swiper;
          setActiveIndex(realIndex + 1);
        }}
      >
        {data.map((item) => (
          <SwiperSlide key={item.hero_banner_id}>
            <ImageContent onClick={() => detailHandler(item.hero_url)}>
              <img
                className="banner-image pc"
                src={item.hero_thumbnail_pc}
                alt="hero_thumbnail_pc"
              />
              <img
                className="banner-image mo"
                src={item.hero_thumbnail_mo}
                alt="hero_thumbnail_mo"
              />
            </ImageContent>
          </SwiperSlide>
        ))}
      </Swiper>
      <ArrowWrap className="arrow_wrap">
        <div className="front_sw_pagebox">
          <button className="banner_prev_arrow">
            <img src={prev_arrow_circle} alt="banner_arrow_left" />
          </button>
          <button className="banner_next_arrow">
            <img src={next_arrow_circle} alt="banner_arrow_right" />
          </button>
        </div>
      </ArrowWrap>
      <Progressbar>
        <div className="front_sw_pagebox">
          <div className="swiper-pagination front_swiper_pagenation"></div>
          <div className="swiper-active-number-wrap">
            <p>
              {activeIndex}/{data.length}
            </p>
            <Link to="/event">+</Link>
          </div>
        </div>
      </Progressbar>
      <MOPageInfo>
        <p>
          {activeIndex}/{data.length}
        </p>
      </MOPageInfo>
    </Container>
  );
};

export default HomeBanner;
