import React, { useState } from 'react';
import consts from 'libs/consts';
import styled from 'styled-components';
import { findJson, numFormat } from 'utils/utils';
import FrontThemeDrowingModal from 'components/Theme/Modal/DrowingModal';
import useUserStore from 'stores/useUserStore';
import InduceLoginWrap from 'components/Common/Login/InduceLoginWrap';

const Container = styled.div`
  width: 30%;
  padding-bottom: 80px;

  & > h3 {
    font-size: 24px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const InfoWrap = styled.div`
  margin-top: 20px;
  border-bottom: 0.5px solid #8f8e8e;
  border-top: 0.5px solid #8f8e8e;
  padding: 12px 0;

  & > p {
    color: #8f8e8e;
    font-size: 13px;
    line-height: 1.5;
  }
`;

const ContentBox = styled.div`
  margin-top: 20px;
  border: 1px solid #8f8e8e;
`;

const TotalWrap = styled.div`
  align-items: center;
  border-bottom: 1px solid #8f8e8e;
  display: flex;
  height: 65px;
  justify-content: space-between;
  padding: 0 15px;

  & > p {
    color: #000;
    font-size: 16px;
    font-weight: 700;
  }

  & > div {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    & > p {
      color: #000;
      font-size: 24px;
      font-weight: 700;
    }
  }
`;

const Content = styled.div`
  position: relative;
  min-height: 350px;
  padding: 15px;

  .total_content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;

    & > h3,
    p {
      color: #000;
      font-size: 14px;
      font-weight: 700;
    }
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;

  &:last-of-type {
    margin: 0;
  }

  & > div {
    & > h3 {
      color: #000;
      font-size: 14px;
    }
    & > p {
      color: #909090;
      font-size: 12px;
    }
  }
  & > p {
    color: #000;
    font-size: 14px;
    margin: 0 0 auto 0;
  }
`;

const DrawingWrap = styled.div`
  margin-top: 75px;

  & > h3 {
    font-size: 24px;
  }

  & > img {
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
  }
`;

const EstimateWrap = styled.div`
  margin-top: 50px;

  & > button {
    border: none;
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    height: 70px;
    font-size: 20px;
    font-weight: 700;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

interface Props {
  type: string;
  size: string;
  totalPrice: string;
  receipts?: {
    case_desc: string;
    case_parts_no: string;
    case_price: string;
    case_price_table_id: string;
  }[];
  drawing?: string;
  estimateHandler: () => void;
}

const ReportDetailReceipt = (props: Props) => {
  const { type, size, totalPrice, receipts, drawing, estimateHandler } = props;
  const { user } = useUserStore();

  const [isOpenDrawing, setIsOpenDrawing] = useState(false);

  return (
    <Container>
      <h3>영수증</h3>
      <InfoWrap>
        <p>{`- ${type} ${size}평 기준으로 작성된 견적금액 입니다.`}</p>
        <p>- 평형별 또는 시공할 집 상태에 따라 견적 금액이 다를 수 있습니다.</p>
      </InfoWrap>
      <ContentBox>
        <TotalWrap>
          <p>총 비용</p>
          <div>
            <p>{numFormat(totalPrice)}만원</p>
          </div>
        </TotalWrap>
        {receipts && receipts?.length > 0 ? (
          <Content>
            <div className="total_content">
              <h3>{`시공 / ${size}평 기준`}</h3>
              <p>{numFormat(totalPrice)}만원</p>
            </div>
            {receipts.map((receipt) => (
              <Item key={receipt.case_price_table_id}>
                <div>
                  <h3>
                    {findJson(consts.buildsoption, receipt.case_parts_no)}
                  </h3>
                  <p>{receipt.case_desc}</p>
                </div>
                <p>{numFormat(receipt.case_price)} 만원</p>
              </Item>
            ))}
            {!user && <InduceLoginWrap />}
          </Content>
        ) : (
          <Content>영수증 없음</Content>
        )}
      </ContentBox>

      {drawing && (
        <DrawingWrap>
          <h3>도면</h3>
          <img
            src={drawing}
            alt="drawing"
            onClick={() => setIsOpenDrawing(true)}
          />
        </DrawingWrap>
      )}
      <EstimateWrap>
        <button onClick={estimateHandler}>이 스타일로 견적받기</button>
      </EstimateWrap>
      {isOpenDrawing && drawing && (
        <FrontThemeDrowingModal
          close={() => setIsOpenDrawing(false)}
          image={drawing}
        />
      )}
    </Container>
  );
};

export default ReportDetailReceipt;
