import React, { useState } from 'react';
import ModalPortal from '../../Common/Modal/ModalPortal';
import styled from 'styled-components';
import exit from '../../../assets/images/exit.svg';
import alert_circle from '../../../assets/images/alert_circle.svg';
import InputSelectArea from '../../Common/Input/InputSelectArea';
import Input from '../../Common/Input/Input';
import images from '../../../libs/images';
import { numFormat } from '../../../utils/utils';
import EstimateAddModal from './EstimateAddModal';
import ConfirmModal from '../../Common/Modal/ConfirmModal';
import useClause from 'hooks/useClause';

const Container = styled.div`
  width: 480px;
  min-height: 500px;
  margin: auto;
  background-color: #ffffff;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ModalHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;

  & > h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
  }
`;

const ConstructionContent = styled.div`
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;

  .alert_content {
    display: flex;

    & > img {
      margin: 0 5px auto 0;
    }

    & > p {
      font-size: 12px;
      color: #909090;
    }
  }

  .item_list_title {
    margin-top: 15px;
    font-size: 14px;
  }

  .construction_more_box {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    & > p {
      font-size: 14px;
      margin: auto 0;
    }

    & > button {
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 20px;
      border: solid 1px #e1e1e1;
    }
  }
`;

const ConstructionItemBox = styled.div`
  padding: 16px;
  border: 1px solid #e1e1e1;
  margin-top: 10px;

  & > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    & > p {
      font-size: 16px;
    }

    .price {
      font-weight: bold;
    }
  }
`;

const InfomationContent = styled.div`
  padding: 20px 20px 0 20px;

  .info_area {
    .input_box {
      margin-bottom: 5px;
    }

    & > span {
      font-size: 12px;
      color: #909090;
    }
  }

  .info_phone {
    margin-top: 25px;

    .input_box {
      margin-bottom: 5px;
    }

    & > span {
      font-size: 12px;
      color: #ff4a4a;
    }
  }

  .info_agree {
    margin-top: 28px;

    .estimate2_popup_main_box {
      display: flex;

      & > img {
        cursor: pointer;
      }
    }
  }
`;

const ModalFooter = styled.div`
  padding: 20px;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;

  & > button {
    height: 48px;
    font-size: 16px;
    font-weight: bold;
  }

  .close {
    width: 130px;
    color: #718096;
    border: 1px solid #e4e9f2;
  }

  .submit {
    width: 300px;
    color: #ffffff;
    border: none;
    background-color: #066e4d;
  }

  @media screen and (max-width: 720px) {
    .close {
      width: 33%;
    }

    .submit {
      width: 63%;
    }
  }
`;

const EstimateModal = (props) => {
  const { close, receiptList, filterList, setFilterList, create, clearFilter } =
    props;
  const { showClause } = useClause();

  const [city, setCity] = useState(''); // 도,시
  const [area, setArea] = useState(''); // 시,구,군
  const [phone, setPhone] = useState(''); // 휴대폰번호
  const [agree, setAgree] = useState({
    agree1: false, // (필수) 개인정보 제공 동의
    agree2: false, // (필수) 개인정보 제3자 제공 동의
  });
  const [isOpenAddModal, setIsOpenAddModal] = useState(false); // 생활시공 추가 모달
  const [isConfirmModal, setIsConfirmModal] = useState(false); // 견적요청 완료 모달

  const checkboxChangeHandler = (e) => {
    setAgree({
      ...agree,
      [e.target.id]: e.target.checked,
    });
  };

  const submit = async () => {
    const { agree1, agree2 } = agree;
    if (!city || !area) {
      alert('시공지역을 선택해주세요.');
      return;
    }
    if (!phone) {
      alert('휴대폰번호를 입력해주세요.');
      return;
    }
    if (!agree1 || !agree2) {
      alert('개인정보 제공 동의를 체크 해주세요.');
      return;
    }

    if (receiptList.length === 0) {
      alert('시공항목을 선택해주세요.');
      return;
    }
    const params = {
      life_order_area_1st: city,
      life_order_area_2nd: area,
      life_order_phone: phone,
      life_order_approve_personal: 1,
      life_order_approve_provide: 1,
      life_order_total_price: receiptList.reduce((acc, cur) => {
        return acc + cur.price;
      }, 0),
      life_order_items: receiptList.map((item) => {
        return {
          life_order_item_category:
            item.type === '욕실' ? 0 : item.type === '주방' ? 1 : 2,
          life_order_item_title: item.name,
          life_order_item_cnt: 1,
          life_order_item_price: item.price,
        };
      }),
    };

    const res = await create(params);

    if (res.code === 'OK') {
      setIsConfirmModal(true);
    }
  };

  return (
    <ModalPortal>
      <Container>
        <ModalHeader>
          <h3>생활시공 견적 받기</h3>
          <button onClick={close}>
            <img src={exit} alt="close_btn" />
          </button>
        </ModalHeader>
        <ConstructionContent>
          <div className="alert_content">
            <img src={alert_circle} alt="alert_circle" />
            <p>
              공동구매는 신청 접수 기간 완료 후에 시공을 시작합니다. 빠른 시공을
              원하시면 상담을 통해 도움 드리겠습니다.
            </p>
          </div>
          <p className="item_list_title">*시공항목</p>
          <ConstructionItemBox>
            {receiptList.length > 0 ? (
              receiptList.map((receipt) => (
                <div key={receipt.id}>
                  <p className="name">{`[${receipt.type}] ${receipt.name} / ${receipt.unit}`}</p>
                  <p className="price">{numFormat(receipt.price)}원</p>
                </div>
              ))
            ) : (
              <div>-</div>
            )}
          </ConstructionItemBox>
          <div className="construction_more_box">
            <p>더 추가하실 상품은 없으신가요?</p>
            <button onClick={() => setIsOpenAddModal(true)}>추가</button>
          </div>
        </ConstructionContent>
        <InfomationContent>
          <div className="info_area">
            <InputSelectArea
              name="work"
              label="시공지역"
              value={city}
              setValue={setCity}
              area={area}
              setArea={setArea}
            />
            <span>*현재는 서울, 경기 지역만 서비스를 제공합니다.</span>
          </div>
          <div className="info_phone">
            <Input
              className="input_text"
              type="text"
              name="phone"
              value={phone}
              setValue={setPhone}
              label={'*휴대폰번호'}
              valid={'num'}
              maxlength={11}
              placeholder="숫자만 입력해 주세요."
            />
            <span>*휴대폰번호는 상담이 완료되면 폐기 처리합니다.</span>
          </div>
          <div className="info_agree">
            <div className="estimate2_popup_main_box">
              <input
                type="checkbox"
                id="agree1"
                className="checkbox"
                onChange={checkboxChangeHandler}
              />

              <label className="checkbox_label_agree" htmlFor="agree1">
                (필수) 개인정보 제공 동의
              </label>
              <img
                src={images.right_arrow2}
                alt="개인정보 동의"
                onClick={() => showClause(2)}
              />
            </div>

            <div className="estimate2_popup_main_box">
              <input
                type="checkbox"
                id="agree2"
                className="checkbox"
                onChange={checkboxChangeHandler}
              />

              <label className="checkbox_label_agree" htmlFor="agree2">
                (필수) 개인정보 제3자 제공 동의
              </label>
              <img
                src={images.right_arrow2}
                alt="개인정보 제3자 제공 동의"
                onClick={() => showClause(1)}
              />
            </div>
          </div>
        </InfomationContent>
        <ModalFooter>
          <button className="close" onClick={close}>
            닫기
          </button>
          <button className="submit" onClick={submit}>
            견적요청 완료
          </button>
        </ModalFooter>
      </Container>
      {isOpenAddModal && (
        <EstimateAddModal
          close={() => setIsOpenAddModal(false)}
          filterList={filterList}
          setFilterList={setFilterList}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          close={() => {
            setIsConfirmModal(false);
            close();
            clearFilter();
          }}
        />
      )}
    </ModalPortal>
  );
};

export default EstimateModal;
