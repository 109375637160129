import React from 'react';
import styled from 'styled-components';
import Modal from 'components/Common/Modal/ModalPortal';
import images from 'libs/images';
import { Link } from 'react-router-dom';
import useUserStore from 'stores/useUserStore';
import { User } from 'types/user';

const Container = styled.div`
  width: 503px;
  margin: auto;
  background-color: #ffffff;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

const Content = styled.div`
  padding-bottom: 38px;

  @media screen and (max-height: 500px) {
    height: calc(var(--vh, 1vh) * 100 - 64px);
    overflow-y: auto;
  }
`;

const ContentTopWrap = styled.div`
  margin-left: 86px;
  margin-bottom: 30px;

  & > img {
    width: 125px;
  }

  & > p {
    margin-top: 18px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin: 0 auto 30px auto;

    & > p {
      font-size: 18px;
      margin-top: 25px;
    }
  }
`;

const SubContentWrap = styled.div`
  margin: 30px 86px 0;

  & > h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
  }

  & > p {
    margin-top: 20px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin: 30px auto 0 auto;

    & > h3 {
      font-size: 16px;
    }

    & > p {
      font-size: 13px;
    }
  }
`;

const KakaoLoginWrap = styled.div`
  margin: 30px 86px 35px 86px;

  & > button {
    width: 100%;
    background-color: #fae100;
    padding: 13px 0;
    border-radius: 4px;

    & > div {
      display: flex;

      & > img {
        margin: auto 0 auto 20px;
      }

      & > p {
        margin: auto;
        color: #000;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin: 22px auto 30px auto;

    & > button {
      & > div {
        & > p {
          font-size: 15px;
        }
      }
    }
  }
`;

const HRLine = styled.div`
  border-bottom: 1px solid #ededed;
  margin: 0 36px;

  @media screen and (max-width: 767px) {
    margin: 0 30px;
  }
`;

const BasicLoginWrap = styled.div`
  margin: 30px 86px 0 86px;

  & > h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
  }

  & > button {
    margin-top: 20px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #000000;
    color: #000000;
    font-size: 18px;
    padding: 13px 0;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin: 20px auto 0 auto;

    & > h3 {
      font-size: 16px;
    }

    & > button {
      font-size: 15px;
      font-weight: 700;
      padding: 15px 0;
    }
  }
`;

const SignUpWrap = styled.div`
  margin: 10px 86px 0 0;
  display: flex;
  justify-content: flex-end;

  & > a {
    font-size: 16px;
    font-weight: 400;
  }

  @media screen and (max-width: 767px) {
    margin: 6px 30px 0 0;

    & > a {
      font-size: 13px;
    }
  }
`;

interface Props {
  close: () => void;
  handleBasicLogin: () => void;
}

const LoginWrapModal = (props: Props) => {
  const { close, handleBasicLogin } = props;

  const { setUser } = useUserStore();

  const CLIENT_ID = `${process.env.REACT_APP_REST_API_KEY}`;
  const REDIRECT_URI = `${process.env.REACT_APP_REDIRECT_URL}`;

  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;

  const kakaoLoginHandler = () => {
    const width = 480;
    const height = 700;

    // 팝업을 가운데 위치시키기 위해 아래와 같이 값 구하기
    const left = Math.ceil((window.screen.width - width) / 2);
    const top = Math.ceil((window.screen.height - height) / 2);

    window.open(
      kakaoURL,
      '_blank',
      'width=' +
        width +
        ', height=' +
        height +
        ', left=' +
        left +
        ', top=' +
        top
    );

    window.kakaoLoginCallback = (user: User) => {
      setUser(user);
      close();
    };
  };

  return (
    <Modal>
      <Container>
        <Header>
          <button onClick={close}>
            <img src={images.exit} alt="exit" />
          </button>
        </Header>
        <Content>
          <ContentTopWrap>
            <img src={images.logo_black} alt="logo_black" />
            <p>
              상상만해온 우리집,
              <br />
              이제는 현실로 만드세요!
            </p>
          </ContentTopWrap>
          <HRLine />
          <SubContentWrap>
            <h3>카카오 로그인</h3>
            <p>
              아이디와 비밀번호를 입력하기 귀찮으시죠?
              <br />
              카카오로 1초 만에 로그인 하세요.
            </p>
          </SubContentWrap>
          <KakaoLoginWrap>
            <button onClick={kakaoLoginHandler}>
              <div>
                <img src={images.kakao_icon} alt="kakao_icon" />
                <p>1초 로그인/회원가입</p>
              </div>
            </button>
          </KakaoLoginWrap>
          <HRLine />
          <BasicLoginWrap>
            <h3>아이디 로그인</h3>
            <button onClick={handleBasicLogin}>로그인</button>
          </BasicLoginWrap>
          <SignUpWrap>
            <Link to="/signup">회원가입</Link>
          </SignUpWrap>
        </Content>
      </Container>
    </Modal>
  );
};

export default LoginWrapModal;
