import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Theme } from 'types/theme';

import useRecentStore from 'stores/useRecentStore';

import images from 'libs/images';

import FrontLeftBox from './LeftBox';
import FrontThemeRightBox from './RightBox';
import FrontTopBox from './TopBox';
import FrontThemeFooter from './ThemeFooter';
import ReceiptModal from '../Modal/ReceiptModal';

const Container = styled.div`
  padding-bottom: 40px;
`;

const BannerSection = styled.div`
  position: relative;
  display: flex;

  & > img {
    height: 785px;
    object-fit: inherit;
    width: 100%;
  }

  & > div {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    & > div {
      max-width: 1200px;
      width: calc(100% - 60px);
      margin: 0 auto;
      display: flex;

      & > button {
        margin-left: auto;
        background: #000000;
        width: 166px;
        height: 56px;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media (max-width: 767px) {
    height: 505px;

    & > img {
      height: 505px;
      object-fit: cover;
      width: 100%;
    }

    & > div {
      & > div {
        width: 100%;

        & > button {
          font-size: 14px;
          height: 44px;
          line-height: 1.43;
          width: 130px;
        }
      }
    }
  }
`;

const TopSection = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;
`;

const ContentSection = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 50px auto 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

interface Props {
  id: string;
  theme: Theme;
}

const ThemeDetailComponent = (props: Props) => {
  const { id, theme } = props;

  const { setList } = useRecentStore();

  const [isOpenReceipt, setIsOpenReceipt] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (id && theme) {
      setList({ id: id, r_type: '2', img: theme.main_img });
    }
  }, [id, theme]);

  const receipt = () => {
    setIsOpenReceipt(true);
  };

  return (
    <Container>
      <BannerSection>
        <img src={theme.view_img} /> {/* 테마상세 상단 이미지 */}
        <div>
          <div>
            <button onClick={receipt}>
              <img src={images.receipt} />
              영수증 보기
            </button>
          </div>
        </div>
      </BannerSection>
      <TopSection>
        {theme && (
          <FrontTopBox
            row={theme}
            list1={theme.style_list}
            res1={theme.work_price_list}
          />
        )}
      </TopSection>
      <ContentSection>
        {theme && (
          <FrontLeftBox
            row={theme}
            res1={theme.work_price_list}
            list={theme.style_list}
          />
        )}
        {theme && (
          <FrontThemeRightBox
            row={theme}
            list1={theme.style_list}
            zone={theme.zone_list}
            receipt={receipt}
          />
        )}
      </ContentSection>
      <FrontThemeFooter
        id={String(id)}
        row={theme}
        all={theme}
        res1={theme.work_price_list}
        list1={theme.style_list}
      />

      {isOpenReceipt && (
        <ReceiptModal
          close={() => setIsOpenReceipt(false)}
          all={theme}
          res1={theme.work_price_list}
          list1={theme.style_list}
        />
      )}
    </Container>
  );
};

export default ThemeDetailComponent;
