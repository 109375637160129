import React from 'react';
import Router from './Router';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './assets/css/common.css';
import './assets/css/layout.css';
import './assets/css/media.css';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-cube';
import 'swiper/css/effect-creative';

import SwiperCore, {
  EffectFlip,
  EffectFade,
  EffectCube,
  EffectCreative,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper';

SwiperCore.use([
  EffectFlip,
  EffectFade,
  EffectCube,
  EffectCreative,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
]);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
