import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import AdCard from './AdCard';
import { ReportList } from 'types/report';
import { Swiper, SwiperSlide } from 'swiper/react';
import prev_arrow_circle from '../Home/images/prev_arrow_circle.png';
import next_arrow_circle from '../Home/images/next_arrow_circle.png';
import { Swiper as SwiperType } from 'swiper';

const Container = styled.div`
  background-color: #dbdbdb;

  position: relative;
  margin-top: 60px;
`;

const Box = styled.div`
  padding: 15px 0 30px 0;
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    max-width: 100%;

    padding: 15px 0 20px 0;
    margin-top: 30px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;

  & > h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 77px;

    & > b {
      color: #fa5500;
      font-size: 28px;
      font-weight: 700;
    }
  }

  & > p {
    margin: auto 0 auto 15px;
    font-size: 14px;
    color: #7e7e7e;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px 12px;
  }

  @media screen and (max-width: 767px) {
    & > h3 {
      font-size: 17px;
      line-height: normal;
      & > b {
        font-size: 17px;
        color: #000000;
      }
    }

    & > p {
      font-size: 7px;
      padding: 2px 7px;
      margin-left: 7px;
    }
  }
`;

const Content = styled.div`
  margin-top: 27px;
  position: relative;

  &:hover {
    .arrow_wrap {
      display: block;
    }
  }

  .swiper {
    .swiper-slide {
      cursor: pointer;

      &:hover {
        .image_item {
          transform: scale(1.1);
          transition: transform 0.2s;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: calc(100% + 30px);
    margin-top: 15px;

    &:hover {
      .arrow_wrap {
        display: none;
      }
    }
  }
`;

const ArrowWrap = styled.div`
  display: none;

  & > button {
    background: none;
    position: absolute;
    top: 30%;
    z-index: 100;

    &:disabled {
      display: none;
    }

    & > img {
      width: 65px;
      height: 65px;
    }

    &.prev_arrow {
      left: -30px;
    }

    &.next_arrow {
      right: -35px;
    }
  }

  @media screen and (max-width: 1110px) {
    & > button {
      top: 25%;
    }
  }

  @media screen and (max-width: 910px) {
    & > button {
      top: 20%;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

interface Props {
  list: ReportList['ad_list'];
}

const AdComponent = (props: Props) => {
  const { list } = props;

  const [swiper, setSwiper] = useState<SwiperType | null>(null); // swiper 인스턴스를 저장할 상태

  const adList = useMemo(() => {
    const sortList = list?.sort(() => Math.random() - 0.5) || [];

    if (sortList.length > 21) {
      return sortList.slice(0, 21);
    } else if (sortList.length < 3) {
      return Array.from({ length: 3 }, (_, index) => {
        if (index < sortList.length) {
          return sortList[index];
        } else {
          return undefined;
        }
      });
    } else {
      return sortList;
    }
  }, [list]);

  const handleSwiper = useCallback((swiperInstance: SwiperType) => {
    setSwiper(swiperInstance);
  }, []);

  return (
    <Container>
      <Box>
        <Title>
          <h3>
            <b>우리 동네</b> 전문가
          </h3>
          <p>AD</p>
        </Title>
        <Content>
          <Swiper
            slidesPerView={1.3}
            spaceBetween={10}
            navigation={{
              prevEl: '.prev_arrow',
              nextEl: '.next_arrow',
            }}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 36,
              },
            }}
            onSwiper={handleSwiper} // onSwiper 콜백을 설정하여 swiper 인스턴스를 저장
          >
            {adList.map((item, i) => (
              <SwiperSlide key={i}>
                <AdCard item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <ArrowWrap className="arrow_wrap">
            <button className="prev_arrow" disabled={swiper?.isBeginning}>
              <img src={prev_arrow_circle} alt="arrow_left" />
            </button>
            <button className="next_arrow" disabled={swiper?.isEnd}>
              <img src={next_arrow_circle} alt="arrow_right" />
            </button>
          </ArrowWrap>
        </Content>
      </Box>
    </Container>
  );
};

export default AdComponent;
