import React from 'react';
import Layout from 'components/Layout';
import Partner from 'components/Partner';

const PartnerPage = () => {
  return (
    <Layout headerText="시공 전문가">
      <Partner />
    </Layout>
  );
};

export default PartnerPage;
