import React from 'react';

const MyImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="39"
      viewBox="0 0 50 39"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9305 22.1852C30.676 22.1852 34.5231 18.3382 34.5231 13.5926C34.5231 8.84703 30.676 5 25.9305 5C21.1849 5 17.3379 8.84703 17.3379 13.5926C17.3379 18.3382 21.1849 22.1852 25.9305 22.1852ZM25.9305 20.037C29.4897 20.037 32.3749 17.1518 32.3749 13.5926C32.3749 10.0334 29.4897 7.14815 25.9305 7.14815C22.3713 7.14815 19.486 10.0334 19.486 13.5926C19.486 17.1518 22.3713 20.037 25.9305 20.037Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9305 22.1852C30.676 22.1852 34.5231 18.3382 34.5231 13.5926C34.5231 8.84703 30.676 5 25.9305 5C21.1849 5 17.3379 8.84703 17.3379 13.5926C17.3379 18.3382 21.1849 22.1852 25.9305 22.1852ZM25.9305 20.037C29.4897 20.037 32.3749 17.1518 32.3749 13.5926C32.3749 10.0334 29.4897 7.14815 25.9305 7.14815C22.3713 7.14815 19.486 10.0334 19.486 13.5926C19.486 17.1518 22.3713 20.037 25.9305 20.037Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06068 34.0001C8.35372 34.0001 7.83224 33.3375 8.05007 32.665C10.4237 25.3364 17.3052 20.0371 25.4244 20.0371C33.5436 20.0371 40.4251 25.3364 42.7988 32.665C43.0166 33.3375 42.4951 34.0001 41.7882 34.0001C41.2967 34.0001 40.8694 33.6704 40.7142 33.2041C38.5829 26.8018 32.5428 22.1853 25.4244 22.1853C18.306 22.1853 12.2659 26.8018 10.1346 33.2041C9.97941 33.6704 9.55209 34.0001 9.06068 34.0001Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06068 34.0001C8.35372 34.0001 7.83224 33.3375 8.05007 32.665C10.4237 25.3364 17.3052 20.0371 25.4244 20.0371C33.5436 20.0371 40.4251 25.3364 42.7988 32.665C43.0166 33.3375 42.4951 34.0001 41.7882 34.0001C41.2967 34.0001 40.8694 33.6704 40.7142 33.2041C38.5829 26.8018 32.5428 22.1853 25.4244 22.1853C18.306 22.1853 12.2659 26.8018 10.1346 33.2041C9.97941 33.6704 9.55209 34.0001 9.06068 34.0001Z"
        fill="black"
      />
    </svg>
  );
};

export default MyImage;
