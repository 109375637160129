// 해당 hook은 반응형 웹 구축 중 100vh 가 모바일에서 제대로 작동하지 않는 문제를 해결하는 hook 입니다.
import { useEffect } from 'react';

const useVh = () => {
  const setScreenSize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    setScreenSize();

    window.addEventListener('resize', () => {
      setTimeout(() => {
        setScreenSize();
      }, 200);
    });

    return () => window.removeEventListener('resize', setScreenSize);
  }, []);
};

export default useVh;
