import React, { useState } from 'react';
import images from '../../../libs/images';
import { Theme, ThemeList, ThemeType } from '../../../types/theme';
import styled from 'styled-components';
import ConstructionInfoModal from '../Modal/ConstructionInfoModal';
import EstimateModal from 'components/Common/Modal/EstimateModal';
import ConfirmModal from 'components/Common/Modal/ConfirmModal';
import { ORDER_ESTIMATE_TYPE } from 'utils/enum/estimate';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  z-index: 900;
  height: 80px;
  background: #000000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  @media screen and (max-width: 767px) {
    height: 55px;
  }
`;

const Content = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
    color: #ffffff;
  }

  .footer_btn {
    width: 259px;
    height: 80px;
    background: #fa5500;
    color: #ffffff;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 24px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MobileContent = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    & > button {
      height: 55px;
      width: 50%;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      display: block;

      &:first-of-type {
        background: #000000;
      }

      &:last-of-type {
        background: #fa5500;
      }
    }
  }
`;

interface Props {
  id: string;
  all?: Theme;
  list1: ThemeList[];
  row: Theme;
  res1: ThemeType[];
}
export default function FrontThemeFooter(props: Props) {
  const { id, row, list1, res1 } = props;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isopen = searchParams.get('isopen');

  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isOpenEstimate, setIsOpenEstimate] = useState(isopen === 'true');
  const [isConfirm, setIsConfirm] = useState(false);

  const estimate3 = () => {
    if (row.theme_state === '0') return false;
    setIsOpenEstimate(true);
  };

  const constinfo = () => {
    setIsOpenInfo(true);
  };

  const callback = () => {
    setIsOpenEstimate(false);
    setIsConfirm(true);
  };

  return (
    <Container>
      <MobileContent>
        <button className="footer_btn" onClick={() => constinfo()}>
          시공정보 보기
        </button>
        <button
          className="footer_btn"
          id="theme_estimate_btn"
          onClick={() => estimate3()}
        >
          견적받기
        </button>
      </MobileContent>
      <Content>
        <p>{row.theme_title}</p>
        <button
          type="button"
          className="footer_btn"
          id="theme_estimate_btn"
          onClick={() => estimate3()}
        >
          견적받기
          <img src={images.right_arrow1} />
        </button>
      </Content>
      {isOpenInfo && (
        <ConstructionInfoModal
          close={() => setIsOpenInfo(false)}
          row={row}
          list={list1}
          res1={res1}
        />
      )}
      {isOpenEstimate && (
        <EstimateModal
          close={() => setIsOpenEstimate(false)}
          order_estimate_type={ORDER_ESTIMATE_TYPE.THEME}
          key_id={id}
          callback={callback}
        />
      )}
      {isConfirm && <ConfirmModal close={() => setIsConfirm(false)} />}
    </Container>
  );
}
