import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LoginWrapModal from './LoginWrapModal';
import BasicLogin from './BasicLogin';
import useUserStore from 'stores/useUserStore';
import MyImage from './Common/My';

const LoginWrap = styled.div`
  position: relative;
  display: flex;
  margin-left: 60px;

  @media screen and (max-width: 960px) {
    margin-left: 40px;
  }

  @media screen and (max-width: 840px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const BeforeLoginButton = styled.button`
  width: 100%;
  margin: auto 0;
  padding: 8px 21px;
  font-size: 16px;
  font-weight: 600;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 19.5px;

  @media screen and (max-width: 960px) {
    font-size: 14px;
    padding: 8px 16px;
  }

  @media screen and (max-width: 840px) {
    font-size: 12px;
  }
`;

const AfterLoginButton = styled.button`
  padding: 0 19px;

  & > svg {
    width: 50px;
    height: 39px;
  }

  @media screen and (max-width: 960px) {
    padding: 0 16px;

    & > svg {
      width: 40px;
    }
  }

  @media screen and (max-width: 840px) {
    padding: 0 13.5px;
  }
`;

const MyMenuWrap = styled.div`
  position: absolute;
  top: 84px;
  border: 1px solid #ededed;
  background: #fff;
  width: 215px;
  padding-bottom: 10px;

  .user_name_text {
    margin: 25px 0 0 25px;
    color: #000;
    font-size: 15px;
    font-weight: 600;
  }

  & > button {
    padding: 20px 25px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    text-align: left;
  }

  @media screen and (max-width: 960px) {
    width: 180px;
  }
`;

const LoginComponent = () => {
  const { user, logout, lastLoginAt } = useUserStore();

  const [isOpenLoginWrap, setIsOpenLoginWrap] = useState(false);
  const [isOpenBasicLogin, setIsOpenBasicLogin] = useState(false);

  const [isOpenMyMenu, setIsOpenMyMenu] = useState(false);

  useEffect(() => {
    // 마지막 로그인 시간에서 3일이 자났을 경우, 자동으로 로그아웃 시킴
    if (lastLoginAt) {
      const threeDayMillis = 3 * 24 * 60 * 60 * 1000;
      const now = new Date().getTime();
      const later3Day = lastLoginAt + threeDayMillis;

      if (now >= later3Day) {
        logout();
      }
    }
  }, []);

  const handleLoginWrap = () => {
    setIsOpenLoginWrap(true);
  };

  const handleBasicLogin = () => {
    setIsOpenLoginWrap(false);
    setIsOpenBasicLogin(true);
  };

  const logoutHandler = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      logout();
      setIsOpenMyMenu(false);
    }
  };

  return user ? (
    <LoginWrap>
      <AfterLoginButton onClick={() => setIsOpenMyMenu(!isOpenMyMenu)}>
        <MyImage />
      </AfterLoginButton>
      {isOpenMyMenu && (
        <MyMenuWrap>
          <p className="user_name_text">{user.user_name} 님 안녕하세요!</p>
          <button onClick={logoutHandler}>로그아웃</button>
        </MyMenuWrap>
      )}
    </LoginWrap>
  ) : (
    <LoginWrap>
      <BeforeLoginButton id="login_btn" onClick={handleLoginWrap}>
        로그인
      </BeforeLoginButton>
      {isOpenLoginWrap && (
        <LoginWrapModal
          close={() => setIsOpenLoginWrap(false)}
          handleBasicLogin={handleBasicLogin}
        />
      )}
      {isOpenBasicLogin && (
        <BasicLogin close={() => setIsOpenBasicLogin(false)} />
      )}
    </LoginWrap>
  );
};

export default LoginComponent;
