import { create } from 'zustand';
import { User } from 'types/user';
import { persist, createJSONStorage } from 'zustand/middleware';

interface State {
  user?: User;
  setUser: (user: User) => void;
  logout: () => void;
  lastLoginAt?: number;
}

const useUserStore = create(
  persist<State>(
    (set) => ({
      user: undefined,
      setUser: (data) =>
        set(() => ({
          user: data,
          lastLoginAt: new Date().getTime(),
        })),
      logout: () =>
        set(() => ({
          user: undefined,
          lastLoginAt: undefined,
        })),
    }),
    {
      name: 'user',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useUserStore;
