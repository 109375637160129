import { useEffect, useRef } from 'react';

export const useObserver = (
  setState: React.Dispatch<React.SetStateAction<'info' | 'report'>>,
  type: 'info' | 'report'
) => {
  const serviceRef = useRef<HTMLDivElement | null>(null);
  const reportRef = useRef<HTMLDivElement | null>(null);

  const option = {
    threshold: 1,
    rootMargin: '-100px 0px',
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entry) => {
      if (entry.length === 1) {
        if (entry[0].isIntersecting) {
          setState(type);
        } else {
          setState('report');
        }
      } else if (entry.length === 2) {
        if (entry[0].isIntersecting && entry[1].isIntersecting) {
          setState('info');
        } else if (!entry[0].isIntersecting && entry[1].isIntersecting) {
          setState('report');
        } else if (entry[0].isIntersecting && !entry[1].isIntersecting) {
          setState('info');
        }
      }
    }, option);

    const scrollBoxList = document.querySelectorAll('.scroll_box');

    scrollBoxList.forEach((el) => {
      observer.observe(el);
    });
    return () => {
      observer.disconnect();
    };
  });

  return { serviceRef, reportRef };
};

export default useObserver;
