import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Case } from 'types/report';
import { numFormat } from 'utils/utils';
import { styleList } from 'utils/frontReport';
import images from 'libs/images';

const Item = styled(Link)`
  width: 32%;
  margin-bottom: 50px;

  &:hover {
    img {
      transform: scale(1.1);
      transition: transform 0.2s;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const ImageItem = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 70%;
  overflow: hidden;
  border-radius: 4px;

  & > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    transition: transform 0.2s;
  }

  .badge_wrap {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    gap: 5px;

    & > p {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      padding: 3px 8px;
      border-radius: 2px;

      &.receipt_badge {
        background-color: #fa5500;
      }

      &.before_badge {
        background-color: #000000;
      }

      &.special_badge {
        background-color: #ff5e5e;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .badge_wrap {
      & > p {
        font-size: 10px;
        padding: 2px 6px;
      }
    }
  }
`;

const InfoItem = styled.div`
  margin-top: 15px;

  & > h3 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    font-weight: 700;
  }

  & > p {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }

  & > div {
    display: flex;

    & > p {
      color: rgba(0, 0, 0, 0.8);
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
    }
  }
  .case_tag {
    display: flex;
    gap: 10px;
    margin-top: 3px;

    & > p {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      font-weight: 400;
      padding: 3px 8px;
      border-radius: 2px;
      background: #f5f5f5;
    }
  }

  @media screen and (max-width: 767px) {
    & > h3 {
      font-size: 16px;
    }

    & > p {
      font-size: 14px;
    }

    & > div {
      margin-top: 5px;

      & > p {
        font-size: 16px;
      }
    }

    .case_tag {
      gap: 5px;

      & > p {
        font-size: 10px;
        padding: 2px 5px;
        line-height: 15px;
      }
    }
  }
`;

interface Props {
  item: Case;
}

const Card = (props: Props) => {
  const { item } = props;

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = images.no_img;
  };

  return (
    <Item to={`/report/${item.case_table_id}`}>
      <ImageItem>
        <img src={item.case_photo} alt="case_photo" onError={handleImgError} />
        <div className="badge_wrap">
          {String(item.is_receipt) === '1' && (
            <p className="receipt_badge">영수증</p>
          )}
          {String(item.is_before) === '1' && (
            <p className="before_badge">비포/애프터</p>
          )}
          {String(item.is_special) === '1' && (
            <p className="special_badge">특가/혜택</p>
          )}
        </div>
      </ImageItem>
      <InfoItem>
        <h3>{item.case_desc}</h3>
        <p>{item.case_name}</p>
        <div>
          <p>
            {item.case_size}평 {numFormat(item.case_work_price)}만원
          </p>
        </div>
        <div className="case_tag">
          <p>
            {'#' +
              styleList.find((obj) => obj.value === item.interior_style)?.title}
          </p>
          <p>{Math.floor(Number(item.case_work_period) / 7) + '주'}</p>
        </div>
      </InfoItem>
    </Item>
  );
};

export default Card;
