import { useState, useEffect } from 'react';

const useFooterScroll = (ref: React.RefObject<HTMLDivElement>) => {
  const [isFooter, setIsFooter] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.5,
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer && observer.disconnect();
  }, [ref]);

  const handleObserver = (entries: IntersectionObserverEntry[]) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setIsFooter(true);
    } else {
      setIsFooter(false);
    }
  };

  return { isFooter };
};

export default useFooterScroll;
