import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { numFormat } from 'utils/utils';
import { Link, useNavigate } from 'react-router-dom';
import { Home } from '../../types/home';
import { Swiper as SwiperType } from 'swiper';
import { styleList } from 'utils/frontReport';
import prev_arrow_circle from './images/prev_arrow_circle.png';
import next_arrow_circle from './images/next_arrow_circle.png';

const Container = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;
  padding-bottom: 100px;

  & > h1 {
    margin-top: 50px;
    font-weight: 700;
    font-size: 36px;
    text-align: center;

    & > span {
      font-weight: 700;
      font-size: 36px;
      color: #fa5500;
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 60px;

    & > h1 {
      font-size: 17px;

      & > span {
        font-size: 17px;
      }
    }
  }
`;

const TabWrap = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 15px 35px;

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin: 15px auto 0 auto;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const Tab = styled.p<{ active: boolean }>`
  padding: 0 10px 10px 10px;
  cursor: pointer;
  border-bottom: ${(props) => props.active && '2px solid #000000'};
  font-size: ${(props) => (props.active ? '22px' : '18px')};
  font-weight: ${(props) => (props.active ? '700' : '400')};
  margin: auto 0;

  @media screen and (max-width: 767px) {
    padding: 0 5px 5px 5px;
    font-size: ${(props) => (props.active ? '13px' : '11px')};
    font-weight: ${(props) => (props.active ? '700' : '500')};
    color: ${(props) => (props.active ? '#000000' : '#8F8E8E')};
  }
`;

const Content = styled.div`
  position: relative;
  margin-top: 50px;

  &:hover {
    .arrow_wrap {
      display: block;
    }
  }

  .swiper-slide {
    cursor: pointer;

    &:hover {
      .image_content {
        transform: scale(1.1);
        transition: transform 0.2s;
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    width: calc(100% + 30px);

    &:hover {
      .arrow_wrap {
        display: none;
      }
    }
  }
`;

const ImageItem = styled.div`
  position: relative;
  padding-bottom: 61%;
  overflow: hidden;
  border-radius: 4px;

  .image_content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.2s;
  }

  .badge_content {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;

    .badge {
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      padding: 3px 10px;

      &.receipt {
        background-color: #fa5500;
      }

      &.before {
        background-color: #000000;
      }
    }
  }
`;

const InfoItem = styled.div`
  margin-top: 18px;

  .info-wrap {
    & > h3 {
      font-weight: 700;
      font-size: 20px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    & > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
    }
  }

  .price-wrap {
    & > p {
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
    }
  }

  .tag_wrap {
    display: flex;
    gap: 10px;
    margin-top: 3px;

    & > p {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      font-weight: 400;
      border-radius: 2px;
      background: #f5f5f5;

      &:first-of-type {
        padding: 4px 8px;
      }

      &:last-of-type {
        padding: 4px 15px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .info-wrap {
      & > h3 {
        font-size: 16px;
      }

      & > p {
        font-size: 14px;
      }
    }
    .price-wrap {
      & > p {
        font-size: 16px;
      }
    }
  }
`;

const Progressbar = styled.div`
  margin: 100px auto 0 auto;
  max-width: 800px;
  width: 100%;
  position: relative;
  z-index: 1;

  .swiper-progressbar {
    background: #8f8e8e;
    height: 2px;

    .swiper-pagination-progressbar-fill {
      background: #000000;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 30px 0 0 0;
    width: calc(100% - 30px);
  }
`;

const MoreWrap = styled.div`
  display: flex;
  justify-content: center;

  & > a {
    margin-top: 50px;
    display: block;
    background-color: #000000;
    color: #ffffff;
    width: 369px;
    height: 70px;
    font-size: 20px;
    line-height: 70px;

    font-weight: 700;
    text-align: center;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media screen and (max-width: 767px) {
    margin-top: 60px;

    & > a {
      width: 270px;
      height: 45px;
      line-height: 45px;
      font-size: 13px;
    }
  }
`;

const ArrowWrap = styled.div`
  display: none;
  z-index: 300;

  & > button {
    background: none;
    position: absolute;
    top: 23%;
    z-index: 100;

    &:disabled {
      display: none;
    }

    & > img {
      width: 65px;
      height: 65px;
    }

    &.prev_arrow {
      left: -9px;
    }

    &.next_arrow {
      right: -11px;
    }
  }

  @media screen and (max-width: 1110px) {
    & > button {
      top: 20%;
    }
  }

  @media screen and (max-width: 950px) {
    & > button {
      top: 15%;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

interface Props {
  data: Home['hot_case_list'];
}

const Report = (props: Props) => {
  const { data } = props;
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState<SwiperType | null>(null); // swiper 인스턴스를 저장할 상태

  const [tab, setTab] = useState(0);

  const onClickTabHandler = (index: number) => {
    setTab(index);

    if (swiper) {
      swiper.slideTo(index * 3);
    }
  };

  const caseList = useMemo(() => {
    const seqData = data.sort((a, b): number => {
      if (Number(a.display_seq) > Number(b.display_seq)) {
        return 1;
      } else if (Number(a.display_seq) === Number(b.display_seq)) {
        return 0;
      } else if (Number(a.display_seq) < Number(b.display_seq)) {
        return -1;
      } else {
        return 0;
      }
    });

    return seqData.flatMap((item) => item.case_list) || [];
  }, [data]);

  const handleSwiper = useCallback((swiperInstance: SwiperType) => {
    setSwiper(swiperInstance);
  }, []);

  const onRoute = (url: string) => {
    navigate(`/report/${url}`);
  };

  return (
    <Container>
      <h1>
        이번 달 <br />
        가장 <span>많은 선택</span>을 받았어요!
      </h1>
      <TabWrap>
        {data.map((item, i) => (
          <Tab
            key={item.hot_case_id}
            onClick={() => onClickTabHandler(i)}
            active={tab === i}
          >
            {item.hot_case_name}
          </Tab>
        ))}
      </TabWrap>
      <Content>
        <Swiper
          slidesPerView={1.4}
          slidesPerGroup={1}
          spaceBetween={10}
          onSlideChange={(slide) => {
            const slideIndex = slide.realIndex;
            if (slide.currentBreakpoint === '768') {
              const dataIndex = Math.ceil(slideIndex / 3);

              setTab(dataIndex);
            } else if (slide.currentBreakpoint === 'max') {
              const dataIndex = Math.floor(slideIndex / 3);
              setTab(dataIndex);
            }
          }}
          breakpoints={{
            768: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              centeredSlides: false,
              spaceBetween: 35,
            },
          }}
          onSwiper={handleSwiper} // onSwiper 콜백을 설정하여 swiper 인스턴스를 저장
          pagination={{
            el: '.report_home_progress .swiper-progressbar',
            type: 'progressbar',
          }}
          navigation={{
            prevEl: '.report_home_progress .prev_arrow',
            nextEl: '.report_home_progress .next_arrow',
          }}
        >
          {caseList.length > 0 &&
            caseList.map((item, i) => (
              <SwiperSlide key={i} onClick={() => onRoute(item.case_table_id)}>
                <ImageItem>
                  <img
                    className="image_content"
                    src={item.case_photo}
                    alt="case_photo"
                  />
                  <div className="badge_content">
                    {item.is_receipt === 1 && (
                      <p className="badge receipt">영수증</p>
                    )}
                    {item.is_before === 1 && (
                      <p className="badge before">비포/애프터</p>
                    )}
                  </div>
                </ImageItem>
                <InfoItem>
                  <div className="info-wrap">
                    <h3>
                      {item.case_name.length < 21
                        ? item.case_name
                        : item.case_name.slice(0, 21) + '...'}
                    </h3>
                    <p>{item.case_desc}</p>
                  </div>
                  <div className="price-wrap">
                    <p>{`${item.case_size}평 ${numFormat(
                      item.case_work_price
                    )}만원`}</p>
                  </div>
                  <div className="tag_wrap">
                    <p>
                      {'#' +
                        styleList.find(
                          (obj) => obj.value === item.interior_style
                        )?.title}
                    </p>
                    <p>
                      {Math.floor(Number(item.case_work_period) / 7) + '주'}
                    </p>
                  </div>
                </InfoItem>
              </SwiperSlide>
            ))}
        </Swiper>
        <ArrowWrap className="report_home_progress arrow_wrap">
          <button className="prev_arrow" disabled={swiper?.isBeginning}>
            <img src={prev_arrow_circle} alt="banner_arrow_left" />
          </button>
          <button className="next_arrow" disabled={swiper?.isEnd}>
            <img src={next_arrow_circle} alt="banner_arrow_right" />
          </button>
        </ArrowWrap>
      </Content>
      <Progressbar className="report_home_progress">
        <div className="swiper-progressbar"></div>
      </Progressbar>
      <MoreWrap>
        <Link to="/report">더 많은 사례 보러 가기</Link>
      </MoreWrap>
    </Container>
  );
};

export default Report;
