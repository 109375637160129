import React, { useState } from 'react';
import Modal from 'components/Common/Modal/ModalPortal';
import styled from 'styled-components';
import images from 'libs/images';
import FrontReview1 from '../ReviewTest';

const Container = styled.div`
  margin: auto;
  background-color: #ffffff;

  @media screen and (max-width: 1146px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    height: 100%;
  }
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;

  & > h3 {
    font-size: 16px;
    font-weight: bold;
    white-space: pre-wrap;
  }
`;

const ResponsiveBox = styled.div`
  display: none;

  @media screen and (max-width: 720px) {
    display: flex;
  }
`;

interface Props {
  close: () => void;
}

const ReviewModal = (props: Props) => {
  const { close } = props;

  const [open1, setOpen1] = useState(false);

  const [active, setActive] = useState(0);

  const [choice, setChoice] = useState('최신순');

  const [more, setMore] = useState(10);

  const [list4] = useState([
    {
      idx: 1,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '1래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 2,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '2비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 3,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '3마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 4,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '4그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 5,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '5래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 6,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '6비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 7,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '7마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 8,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '8그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 9,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '9래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 10,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '10비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 11,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '11마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 12,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '12그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 13,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '13래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 14,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '14비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 15,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '15마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 16,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '16그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 17,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '17래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 18,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '18비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 19,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '19마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 20,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '20그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 21,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '21래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 22,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '22비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 23,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '23마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 24,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '24그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 25,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '25래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 26,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '26비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 27,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '27마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 28,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '28그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 29,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '29래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 30,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '30비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 31,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '31마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 32,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '32그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 33,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '33래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 34,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '34비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 35,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '35마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 36,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '36그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 37,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '37래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 38,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '38비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 39,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '39마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 40,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '40그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 41,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '41래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 42,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '42비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 43,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '43마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 44,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '44그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 45,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '45래미안안양메가트리아',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img1,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 46,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '46비산삼성래미안아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img2,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 47,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '47마포래미안푸르지오',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img3,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
    {
      idx: 48,
      name: '송**',
      score1: images.star,
      score2: images.star_off,
      title: '48그랑시티자이아파트',
      subtitle: '24평형 / 2개월 / 2,000만원',
      img: images.slider_img4,
      content:
        '집주인의 취향을 더한 그림들과 조화롭게 어우러져 더 깊이 있는 취향의 집으로 발전되었어요.',
    },
  ]);
  return (
    <Modal>
      <Container>
        <Header>
          <h3>고객리뷰</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit" />
          </button>
        </Header>
        <div className="popup_list">
          <div className="review_popup">
            <div className="review_popup_left">
              <div className="review_popup_left1">
                <p>총 평점</p>
              </div>
              <div className="review_popup_left2">
                <p>4.2</p>
                <div className="review_popup_left_score_box">
                  <img src={images.star}></img>
                  <img src={images.star}></img>
                  <img src={images.star}></img>
                  <img src={images.star}></img>
                  <img src={images.star}></img>
                </div>
              </div>
              <div className="review_popup_left3">
                <div className="review_popup_left4">
                  <p>가격</p>
                  <div className="gauge1"></div>
                  <p>👍만족</p>
                </div>
                <div className="review_popup_left4">
                  <p>서비스</p>
                  <div className="gauge2"></div>
                  <p>😱불만족</p>
                </div>
                <div className="review_popup_left4">
                  <p>품질</p>
                  <div className="gauge3"></div>
                  <p>👍만족</p>
                </div>
                <div className="review_popup_left4">
                  <p>홈스타일링</p>
                  <div className="gauge4"></div>
                  <p>😐보통</p>
                </div>
              </div>
            </div>
            <div className="review_popup_right">
              <div className="review_popup_right_top_box">
                <div className="review_popup_right_title_box">
                  <p className="review_popup_right_title1">리뷰&nbsp;</p>
                  <p className="review_popup_right_title2">123개</p>
                </div>
                <div className="front_review_box4">
                  <img src={images.list_icon} />
                  <p onClick={() => setOpen1(!open1)}>{choice}</p>
                  <div
                    className="front_review_remocon"
                    style={{ display: open1 ? 'flex' : 'none' }}
                  >
                    <p
                      style={{ color: active === 1 ? '#06624d' : '#292929' }}
                      onClick={() => {
                        setActive(1);
                        setChoice('최신순');
                        setOpen1(false);
                      }}
                    >
                      최신순
                    </p>
                    <p
                      style={{ color: active === 2 ? '#06624d' : '#292929' }}
                      onClick={() => {
                        setActive(2);
                        setChoice('평점 높은순');
                        setOpen1(false);
                      }}
                    >
                      평점 높은순
                    </p>
                    <p
                      style={{ color: active === 3 ? '#06624d' : '#292929' }}
                      onClick={() => {
                        setActive(3);
                        setChoice('평점 낮은순');
                        setOpen1(false);
                      }}
                    >
                      평점 낮은순
                    </p>
                  </div>
                </div>
              </div>
              <div className="front_review_list">
                <div className="review_columns_list">
                  {list4 &&
                    list4.map((x, i) => {
                      if (i + 1 > more) {
                        return;
                      }
                      return <FrontReview1 key={i} rows={x} type={1} />;
                    })}
                </div>
              </div>
              <div className="front_theme_detail_section3_btn_box">
                <button className="more_btn" onClick={() => setMore(more + 20)}>
                  more
                </button>
              </div>
            </div>
            <ResponsiveBox className="front_theme_footer1">
              <button onClick={close}>닫기</button>
            </ResponsiveBox>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default ReviewModal;
