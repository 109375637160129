import React, { useState } from 'react';
import SubModal from '../../Common/Modal/SubModal';
import styled from 'styled-components';
import exit from '../../../assets/images/exit.svg';
import { numFormat } from '../../../utils/utils';
import checkbox_off from '../../../assets/images/checkbox_off.svg';
import checkbox_on from '../../../assets/images/checkbox_on.svg';

const Container = styled.div`
  width: 470px;
  min-height: 600px;
  background-color: #ffffff;
  margin: auto;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ModalHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;

  & > div {
    margin-top: 4px;

    & > h3 {
      font-size: 16px;
      font-weight: bold;
    }

    & > p {
      margin-top: 3px;
      font-size: 14px;
    }
  }
`;

const ModalContent = styled.div`
  max-height: 493px;
  overflow-y: auto;

  @media screen and (max-width: 720px) {
    max-height: calc(100% - 180px);
  }
`;

const ContentWrap = styled.div`
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;

  & > h3 {
    font-size: 18px;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;

    .name {
      font-size: 16px;
    }

    .price {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const ModalFooter = styled.div`
  padding: 20px;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;

  & > button {
    height: 48px;
    font-size: 16px;
    font-weight: bold;
  }

  .close {
    width: 130px;
    color: #718096;
    border: 1px solid #e4e9f2;
  }

  .submit {
    width: 280px;
    color: #ffffff;
    border: none;
    background-color: #066e4d;
  }

  @media screen and (max-width: 720px) {
    .close {
      width: 33%;
    }

    .submit {
      width: 63%;
    }
  }
`;

const CheckBox = styled.label`
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 16px;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: -3px;
    left: 0;
    width: 24px;
    height: 24px;
    background: url(${checkbox_off});
    background-size: cover;
  }

  input:checked ~ .checkmark {
    background: url(${checkbox_on});
  }
`;

const EstimateAddModal = (props) => {
  const { close, filterList, setFilterList } = props;
  const [list, setList] = useState([...filterList]);

  const onCheckHandler = (id, item_id) => {
    setList(
      list.map((l) =>
        l.id === id
          ? {
              ...l,
              items: l.items.map((item) =>
                item.id === item_id
                  ? {
                      ...item,
                      isChecked: !item.isChecked,
                    }
                  : {
                      ...item,
                    }
              ),
            }
          : l
      )
    );
  };

  const submitHandler = () => {
    setFilterList([...list]);
    close();
  };

  return (
    <SubModal>
      <Container>
        <ModalHeader>
          <div>
            <h3>생활시공 추가</h3>
            <p>추가하실 상품을 선택하시고 아래 선택완료를 눌러주세요.</p>
          </div>
          <button onClick={close}>
            <img src={exit} alt="close_btn" />
          </button>
        </ModalHeader>
        <ModalContent>
          {list.map((filter) => (
            <ContentWrap key={filter.id}>
              <h3>{filter.name}</h3>
              {filter.items.map((item) => (
                <div key={item.id}>
                  <CheckBox>
                    {`${item.name} / ${item.unit}`}
                    <input
                      type="checkbox"
                      checked={item.isChecked}
                      onChange={() => onCheckHandler(filter.id, item.id)}
                    />
                    <span className="checkmark"></span>
                  </CheckBox>
                  <p className="price">{numFormat(item.price)} 원</p>
                </div>
              ))}
            </ContentWrap>
          ))}
        </ModalContent>
        <ModalFooter>
          <button className="close" onClick={close}>
            닫기
          </button>
          <button className="submit" onClick={submitHandler}>
            선택 완료
          </button>
        </ModalFooter>
      </Container>
    </SubModal>
  );
};

export default EstimateAddModal;
