import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from './libs/routes';

import MainPage from './pages/main';
import ReportPage from 'pages/report';
import ReportDetailPage from 'pages/report/detail';
import PartnerPage from 'pages/partner';
import PartnerDetailPage from 'pages/partner/detail';
import EventPage from 'pages/event';
import EventDetailPage from 'pages/event/detail';
import ThemePage from 'pages/theme';
import ThemeDetailPage from 'pages/theme/detail';
import ClausePage from 'pages/clause';
import NoticeDetailPage from 'pages/noticeDetail';

import AptEvent from './pages/event/AptEvent';
import LifeConstructionPage from './pages/life-construction';
import ThemeDetailOutboundPage from 'pages/theme/outbound';
import ApartnerConsultingPage from './pages/event/ApartnerConsultingPage';

import SignupPage from 'pages/signup';
import KakaoLoginCallbackPage from 'pages/Kakao/callback';
import ServicePage from 'pages/service';

function Router() {
  const {
    clause,
    home,
    theme,
    theme_detail,
    report,
    report_detail,
    partner,
    partner_detail,
    event,
    event_detail,
    hsEvent,
    life_construction,
    theme_detail_outbound,
    aptn_event_consulting,
    kakao_login_callback,
    signup,
    noticeDetail,
    service,
  } = routes;

  // const isDevelop = isDevelopEnv();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path={clause} element={<ClausePage />} />
          <Route exact path={home} element={<MainPage />} />
          <Route exact path={theme} element={<ThemePage />} />
          <Route exact path={theme_detail} element={<ThemeDetailPage />} />
          <Route
            exact
            path={theme_detail_outbound}
            element={<ThemeDetailOutboundPage />}
          />
          <Route exact path={report} element={<ReportPage />} />
          <Route exact path={report_detail} element={<ReportDetailPage />} />
          <Route exact path={partner} element={<PartnerPage />} />
          <Route exact path={partner_detail} element={<PartnerDetailPage />} />
          <Route exact path={event} element={<EventPage />} />
          <Route exact path={event_detail} element={<EventDetailPage />} />

          <Route exact path={hsEvent} element={<AptEvent />} />
          <Route
            exact
            path={aptn_event_consulting}
            element={<ApartnerConsultingPage />}
          />
          <Route
            exact
            path={life_construction}
            element={<LifeConstructionPage />}
          />
          <Route
            exact
            path={kakao_login_callback}
            element={<KakaoLoginCallbackPage />}
          />
          <Route exact path={signup} element={<SignupPage />} />
          <Route exact path={service} element={<ServicePage />} />
          <Route exact path="*" element={<MainPage />} />
          <Route exact path={noticeDetail} element={<NoticeDetailPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Router;
