import { useState, useEffect } from 'react';
import useRecentStore from 'stores/useRecentStore';

interface RecentReportType {
  id: string;
  img: string;
  r_type: string;
}

const useFloating = (pagePerSize: number) => {
  const { list } = useRecentStore();
  const [page, setPage] = useState(1);
  const [recentReports, setRecentReports] = useState<RecentReportType[]>([]);

  const prevHandler = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextHandler = () => {
    if (page < Math.ceil(list.length / pagePerSize)) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    setRecentReports(
      list.filter(
        (item, i) =>
          i >= page * pagePerSize - pagePerSize &&
          i < page * pagePerSize &&
          item
      )
    );
  }, [page, list]);

  return {
    page,
    totalPage: Math.ceil(list.length / pagePerSize),
    prevHandler,
    nextHandler,
    recentReports,
  };
};

export default useFloating;
