/* eslint-disable no-undef */
const homeUrl = window.location.protocol + '//' + window.location.host;

let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
if (
  varUA.indexOf('iphone') > -1 ||
  varUA.indexOf('ipad') > -1 ||
  varUA.indexOf('ipod') > -1
) {
  //IOS
  varUA = 'ios';
} else {
  varUA = 'and';
}

const exportData = {
  apiBaseUrl: process.env.REACT_APP_BASEURL,
  apiBaseUrlAdmin: process.env.REACT_APP_BASEURL_ADMIN,
  apiBaseUrlPartner: process.env.REACT_APP_BASEURL_PARTNER,
  apiBaseUrlFront: process.env.REACT_APP_BASEURL_FRONT,
  homeUrl: homeUrl,
  platform: varUA,
  kakaoKey: '431a075aaa57e6866c44fd75f134e3fc',
  pagerows: 15,
  stateoption: [
    { idx: '1', title: '정상' },
    { idx: '2', title: '대기' },
    { idx: '3', title: '중지' },
  ],
  workoption: [
    { idx: 1, title: '아파트' },
    { idx: 2, title: '빌라' },
    { idx: 3, title: '주택' },
    { idx: 4, title: '카페/식당' },
    { idx: 5, title: '상가/매장' },
    { idx: 6, title: '학원/교육' },
    { idx: 7, title: '사무실' },
    { idx: 8, title: '숙박/병원' },
    { idx: 9, title: '기타' },
  ],
  partsoption: [
    { idx: 1, title: '거실' },
    { idx: 5, title: '주방' },
    { idx: 6, title: '보조주방' },
    // {idx : 7, title : '방'},
    { idx: 8, title: '안방' },
    { idx: 9, title: '아이방' },
    { idx: 10, title: '서재방' },
    { idx: 11, title: '방1' },
    { idx: 12, title: '방2' },
    { idx: 13, title: '방3' },
    { idx: 14, title: '방4' },
    { idx: 15, title: '펜트리룸' },
    { idx: 16, title: '드레스룸' },
    { idx: 17, title: '공용욕실' },
    { idx: 18, title: '부부욕실' },
    { idx: 19, title: '베란다' },
    { idx: 20, title: '실외기실' },
    { idx: 21, title: '창고' },
    { idx: 22, title: '테라스' },
    { idx: 23, title: '다락방' },
    { idx: 24, title: '현관' },
    { idx: 25, title: '전실' },
    { idx: 26, title: '세탁실' },
  ],
  buildsoption: [
    { idx: 1, title: '도배' },
    { idx: 2, title: '장판' },
    { idx: 3, title: '마루' },
    { idx: 4, title: '타일' },
    { idx: 5, title: '탄성코드' },
    { idx: 6, title: '주방' },
    { idx: 7, title: '욕실' },
    { idx: 8, title: '문' },
    { idx: 9, title: '붙박이장' },
    { idx: 10, title: '중문' },
    { idx: 11, title: '현관장' },
    { idx: 12, title: '몰딩' },
    { idx: 13, title: '필름' },
    { idx: 14, title: '조명' },
    { idx: 15, title: '목공' },
    { idx: 16, title: '전기' },
    { idx: 17, title: '창호' },
    { idx: 18, title: '철거' },
    { idx: 19, title: '설비/미장' },
    { idx: 20, title: '확장' },
    { idx: 21, title: '걸레받이' },
    { idx: 22, title: '공과잡비' },
    { idx: 23, title: '기타' },
    { idx: 24, title: '가구' },
    { idx: 25, title: '기초공사' },
  ],
  tagoption: [
    { idx: '1', title: '모던' },
    { idx: '2', title: '심플' },
    { idx: '3', title: '미니멀' },
    { idx: '4', title: '화이트' },
    { idx: '5', title: '그레이' },
    { idx: '6', title: '베이지' },
    { idx: '7', title: '아이보리' },
    { idx: '8', title: '시크' },
    { idx: '9', title: '럭셔리' },
    { idx: '10', title: '앤틱' },
    { idx: '11', title: '프로방스' },
    { idx: '12', title: '내추럴' },
    { idx: '13', title: '네오클래식' },
    { idx: '14', title: '빈티지' },
    { idx: '15', title: '친환경' },
    { idx: '16', title: '로맨틱' },
    { idx: '17', title: '정크' },
    { idx: '18', title: '북유럽' },
    { idx: '19', title: '그린' },
    { idx: '20', title: '네이비' },
    { idx: '21', title: '핑크' },
    { idx: '22', title: '퍼플' },
  ],
  serveoption: [
    { idx: '1', title: '설계도면' },
    { idx: '2', title: '3D디자인' },
    { idx: '3', title: '현금연수증' },
    { idx: '4', title: '하자보증보험' },
    { idx: '5', title: '공정표' },
    { idx: '6', title: '그린리모델링' },
    { idx: '7', title: '계약서' },
    { idx: '8', title: '전문현장감리' },
    { idx: '9', title: '숙소제공' },
    { idx: '10', title: '이삿짐센터' },
  ],
  instanceStateoption: [
    { idx: '0', title: '작성중' },
    { idx: '1', title: '요청중' },
    { idx: '2', title: '반려' },
    { idx: '3', title: '대기' },
    { idx: '4', title: '사용중' },
    { idx: '5', title: '재요청' },
  ],
  asoption: [
    { idx: '없음', title: '보증없음' },
    { idx: '1년', title: '1년' },
    { idx: '2년', title: '2년' },
    { idx: '3년', title: '3년' },
    { idx: '4년', title: '4년' },
    { idx: '5년', title: '5년' },
    { idx: '6년', title: '6년' },
    { idx: '7년', title: '7년' },
    { idx: '8년', title: '8년' },
    { idx: '9년', title: '9년' },
    { idx: '10년', title: '10년' },
  ],

  styleoption: [
    { idx: 0, title: '없음' },
    { idx: 1, title: '전체' },
    { idx: 2, title: '부분' },
  ],

  arealist: [
    { idx: 1, title: '종로구' },
    { idx: 2, title: '중구' },
    { idx: 3, title: '용산구' },
    { idx: 4, title: '성동구' },
    { idx: 5, title: '광진구' },
    { idx: 6, title: '동대문구' },
    { idx: 7, title: '중랑구' },
    { idx: 8, title: '성북구' },
    { idx: 9, title: '강북구' },
    { idx: 10, title: '도봉구' },
    { idx: 11, title: '노원구' },
    { idx: 12, title: '은평구' },
    { idx: 13, title: '서대문구' },
    { idx: 14, title: '마포구' },
    { idx: 15, title: '양천구' },
    { idx: 16, title: '강서구' },
    { idx: 17, title: '구로구' },
    { idx: 18, title: '금천구' },
    { idx: 19, title: '영등포구' },
    { idx: 20, title: '동작구' },
    { idx: 21, title: '관악구' },
    { idx: 22, title: '서초구' },
    { idx: 23, title: '강남구' },
    { idx: 24, title: '송파구' },
    { idx: 25, title: '강동구' },
  ],

  acreageoption: [
    { idx: 20, title: '20평' },
    { idx: 21, title: '21평' },
    { idx: 22, title: '22평' },
    { idx: 23, title: '23평' },
    { idx: 24, title: '24평' },
    { idx: 25, title: '25평' },
    { idx: 26, title: '26평' },
    { idx: 27, title: '27평' },
    { idx: 28, title: '28평' },
    { idx: 29, title: '29평' },
    { idx: 30, title: '30평' },
    { idx: 31, title: '31평' },
    { idx: 32, title: '32평' },
    { idx: 33, title: '33평' },
    { idx: 34, title: '34평' },
    { idx: 35, title: '35평' },
    { idx: 36, title: '36평' },
    { idx: 37, title: '37평' },
    { idx: 38, title: '38평' },
    { idx: 39, title: '39평' },
    { idx: 40, title: '40평' },
    { idx: 41, title: '41평' },
    { idx: 42, title: '42평' },
    { idx: 43, title: '43평' },
    { idx: 44, title: '44평' },
    { idx: 45, title: '45평' },
    { idx: 46, title: '46평' },
    { idx: 47, title: '47평' },
    { idx: 48, title: '48평' },
    { idx: 49, title: '49평' },
    { idx: 50, title: '50평' },
    { idx: 51, title: '51평' },
    { idx: 52, title: '52평' },
  ],

  wishdayoption: [
    { idx: '1', title: '1개월이내' },
    { idx: '2', title: '2개월이내' },
    { idx: '3', title: '3개월이내' },
    { idx: '4', title: '4개월이내' },
    { idx: '5', title: '4개월이후' },
    { idx: '0', title: '미정' },
  ],

  demolitiondayoption: [
    { idx: 'y', title: '철거 필요' },
    { idx: 'n', title: '철거 필요 없음' },
  ],
  authoption: [
    { idx: '', title: '선택' },
    { idx: '1', title: '사용자' },
    { idx: '2', title: '관리자' },
  ],
  partnerUserStateoption: [
    { idx: 1, title: '정상' },
    { idx: 2, title: '대기' },
  ],
  settingTalkoption: [
    { idx: '1', title: '즉시' },
    { idx: '2', title: '지연' },
  ],
  estimateTypeoption: [
    { idx: '1', title: '일반' },
    { idx: '2', title: '테마' },
    { idx: '3', title: '시공사례' },
  ],
  homeDetailPopupOption: [
    { idx: 1, title: '전체' },
    { idx: 2, title: '거실' },
    { idx: 3, title: '방/침실' },
    { idx: 4, title: '주방' },
    { idx: 5, title: '욕실' },
    { idx: 6, title: '발코니' },
    { idx: 7, title: '현관/중문' },
  ],

  homeStylePartsMerge: [
    {
      idx: 0,
      title: '현관/중문',
      active_img: 'front_room4',
      default_img: 'front_room3',
      type_img: '4',
    },
    {
      idx: 1,
      title: '주방',
      active_img: 'kitchen4',
      default_img: 'kitchen3',
      type_img: '2',
    },
    {
      idx: 2,
      title: '방/침실',
      active_img: 'room4',
      default_img: 'room3',
      type_img: '3',
    },
    {
      idx: 3,
      title: '거실',
      active_img: 'living_room4',
      default_img: 'living_room3',
      type_img: '1',
    },
    {
      idx: 4,
      title: '욕실',
      active_img: 'bath_room4',
      default_img: 'bath_room3',
      type_img: '5',
    },
    {
      idx: 5,
      title: '발코니',
      active_img: 'terrace4',
      default_img: 'terrace3',
      type_img: '6',
    },
  ],

  homeStylePartsOption: [
    {
      idx: '0',
      title: '전체',
      img_text: 'all',
      merge: 100,
      active_img: 'room1_on',
      default_img: 'room1_off',
      type_img: '6',
    }, // no
    {
      idx: '1',
      title: '거실',
      img_text: 'Living\nroom',
      merge: 3,
      active_img: 'living_room1_on',
      default_img: 'living_room1_off',
      type_img: '2',
    },
    {
      idx: '2',
      title: '주방',
      img_text: 'Kitchen',
      merge: 1,
      active_img: 'kitchen1_on',
      default_img: 'kitchen1_off',
      type_img: '2',
    },
    {
      idx: '3',
      title: '안방',
      img_text: 'Main\nroom',
      merge: 2,
      active_img: 'main_room1_on',
      default_img: 'main_room1_off',
      type_img: '6',
    }, // no
    {
      idx: '4',
      title: '방1',
      img_text: 'Room1',
      merge: 2,
      active_img: 'room1_on',
      default_img: 'room1_off',
      type_img: '6',
    },
    {
      idx: '5',
      title: '방2',
      img_text: 'Room2',
      merge: 2,
      active_img: 'room2_on',
      default_img: 'room2_off',
      type_img: '6',
    },
    {
      idx: '6',
      title: '방3',
      img_text: 'Room3',
      merge: 2,
      active_img: 'room3_on',
      default_img: 'room3_off',
      type_img: '6',
    },
    {
      idx: '7',
      title: '방4',
      img_text: 'Room4',
      merge: 2,
      active_img: 'room4_on',
      default_img: 'room4_off',
      type_img: '6',
    },
    {
      idx: '9',
      title: '욕실1',
      img_text: 'bath\nroom1',
      merge: 4,
      active_img: 'bathroom1_on',
      default_img: 'bathroom1_off',
      type_img: '6',
    },
    {
      idx: '8',
      title: '현관',
      img_text: 'front\rdoor',
      merge: 0,
      active_img: 'frontdoor1_on',
      default_img: 'frontdoor1_off',
      type_img: '4',
    },
    {
      idx: '10',
      title: '욕실2',
      img_text: 'bath\nroom2',
      merge: 4,
      active_img: 'bathroom2_on',
      default_img: 'bathroom2_off',
      type_img: '6',
    },
    {
      idx: '11',
      title: '욕실3',
      img_text: 'bath\nroom3',
      merge: 4,
      active_img: 'bathroom3_on',
      default_img: 'bathroom3_off',
      type_img: '6',
    },
    {
      idx: '12',
      title: '펜트리룸',
      img_text: 'Pantry',
      merge: 2,
      active_img: 'pantry_on',
      default_img: 'pantry_off',
      type_img: '6',
    },
    {
      idx: '13',
      title: '베란다',
      img_text: 'Terrace',
      merge: 5,
      active_img: 'terrace1_on',
      default_img: 'terrace1_off',
      type_img: '6',
    },
    {
      idx: '14',
      title: '다용도실',
      img_text: 'Utility\nroom',
      merge: 5,
      active_img: 'utility_room_on',
      default_img: 'utility_room_off',
      type_img: '6',
    },
    {
      idx: '15',
      title: '기타공간',
      img_text: 'Etc',
      merge: 5,
      active_img: 'etc_on',
      default_img: 'etc_off',
      type_img: '6',
    }, // no
  ],

  themeEqCode: [
    { title: '침대', idx: 1 },
    { title: '매트리스/토퍼', idx: 2 },
    { title: '소파', idx: 3 },
    { title: '테이블/책상', idx: 4 },
    { title: '거실장/TV수납장', idx: 5 },
    { title: '서랍/수납장', idx: 6 },
    { title: '책장/진열장', idx: 7 },
    { title: '선반', idx: 8 },
    { title: '행거/옷장', idx: 9 },
    { title: '의자', idx: 10 },
    { title: '화장대/콘솔', idx: 11 },
    { title: '거울', idx: 12 },
    { title: '가벽/파티션', idx: 13 },
    { title: '야외 가구', idx: 14 },
    { title: '유아동 가구', idx: 15 },
    { title: '기타(가구)', idx: 16 },
    { title: '침구세트', idx: 17 },
    { title: '이불/이불솜', idx: 18 },
    { title: '베개/베개커버', idx: 19 },
    { title: '토퍼/패드', idx: 20 },
    { title: '매트리스커버', idx: 21 },
    { title: '블라인드/롤스크린', idx: 22 },
    { title: '커튼/부자재', idx: 23 },
    { title: '러그/카페트', idx: 24 },
    { title: '매트', idx: 25 },
    { title: '쿠션/방석', idx: 26 },
    { title: '소파/생활커버', idx: 27 },
    { title: '홈패브릭', idx: 28 },
    { title: '주방패브릭', idx: 29 },
    { title: '욕실패브릭', idx: 30 },
    { title: '유아용패브릭', idx: 31 },
    { title: '기타(패브릭)', idx: 32 },
    { title: '공간별조명', idx: 33 },
    { title: 'LED평판등', idx: 34 },
    { title: '천장등', idx: 35 },
    { title: '장스탠드', idx: 36 },
    { title: '단스탠드', idx: 37 },
    { title: '데스크스탠드', idx: 38 },
    { title: '무드등/장식조명', idx: 39 },
    { title: '벽조명', idx: 40 },
    { title: '센서등', idx: 41 },
    { title: '기타(조명)', idx: 42 },
    { title: '냉장고', idx: 43 },
    { title: 'TV', idx: 44 },
    { title: '세탁기/건조기', idx: 45 },
    { title: '에어컨', idx: 46 },
    { title: '청소기', idx: 47 },
    { title: '컴퓨터/노트북', idx: 48 },
    { title: '디지털가전', idx: 49 },
    { title: '주방가전', idx: 50 },
    { title: '계절가전', idx: 51 },
    { title: '음향가전', idx: 52 },
    { title: '영상가전', idx: 53 },
    { title: '생활/건강가전', idx: 54 },
    { title: '이미용가전', idx: 55 },
    { title: '소형가전', idx: 56 },
    { title: '기타(가전)', idx: 57 },
    { title: '그릇/홈세트', idx: 58 },
    { title: '국내도자기그릇', idx: 59 },
    { title: '수입주방용품', idx: 60 },
    { title: '냄비/프라이팬/솥', idx: 61 },
    { title: '보관/용기/도시락', idx: 62 },
    { title: '주방수납/정리', idx: 63 },
    { title: '식기건조대', idx: 64 },
    { title: '주방잡화', idx: 65 },
    { title: '수저/커트러리', idx: 66 },
    { title: '컵/잔/텀블러', idx: 67 },
    { title: '조리도구/도마', idx: 68 },
    { title: '칼/커팅기구', idx: 69 },
    { title: '이유/유아식기', idx: 70 },
    { title: '주방패브릭', idx: 71 },
    { title: '와인/칵테일용품', idx: 72 },
    { title: '커피/티용품', idx: 73 },
    { title: '베이킹용품', idx: 74 },
    { title: '기타(주방용품)', idx: 75 },
    { title: '캔들/디퓨저', idx: 76 },
    { title: '갤러리/액자', idx: 77 },
    { title: '플라워/식물', idx: 78 },
    { title: '시계', idx: 79 },
    { title: '인테리어소품/장식', idx: 80 },
    { title: '기타(데코/식물)', idx: 81 },
    { title: '수납장/서랍장', idx: 82 },
    { title: '리빙박스/수납함', idx: 83 },
    { title: '바구니/바스켓', idx: 84 },
    { title: '행거', idx: 85 },
    { title: '선반', idx: 86 },
    { title: '옷걸이/옷정리', idx: 87 },
    { title: '화장대/테이블정리', idx: 88 },
    { title: '현관/신발정리', idx: 89 },
    { title: '후크/수납걸이', idx: 90 },
    { title: '기타(수납/정리)', idx: 91 },
    { title: '욕실용품', idx: 92 },
    { title: '수건/타월', idx: 93 },
    { title: '청소용품', idx: 94 },
    { title: '세탁용품', idx: 95 },
    { title: '생활잡화', idx: 96 },
    { title: '기타(생활용품)', idx: 97 },
    { title: '욕실 용품', idx: 98 },
    { title: '욕실 텍스타일', idx: 99 },
    { title: '세면대 세트', idx: 100 },
    { title: '기타(욕실)', idx: 101 },
  ],

  reviewImti: [
    { idx: '1', title: '😱' },
    { idx: '3', title: '😐' },
    { idx: '5', title: '👍' },
  ],
  aptnName: [
    { code: 'A10027906', name: '공덕자이' },
    { code: 'A10027851', name: '신촌푸르지오' },
    { code: 'A15701007', name: '강서힐스테이트' },
    { code: 'A15792602', name: '화곡푸르지오' },
    { code: 'A15701003', name: '우장산아이파크e편한세상' },
    { code: 'A10027424', name: '상도파크자이' },
    { code: 'A15606004', name: '본동신동아' },
    { code: 'A15805002', name: '목동한신청구아파트' },
    { code: 'A15681110', name: '대방대림아파트' },
    { code: 'C11500105', name: '마곡힐스테이트' },
    { code: 'C11410120', name: 'DMC에코자이' },
    { code: 'A10025822', name: '연희파크푸르지오' },
    { code: 'A10025092', name: 'e편한세상서울대입구' },
    { code: 'A15003002', name: '영등포푸르지오' },
    { code: 'A15003802', name: '당산푸르지오' },
    { code: 'A15873701', name: '목동10단지아파트' },
    { code: 'A15883402', name: '신정뉴타운롯데캐슬' },
    { code: 'A10026299', name: '래미안 로이파크' },
    { code: 'A12109001', name: '마포자이더센트리지아파트' },
  ],
  newApartList: [
    { idx: 'A15003802', title: '당산푸르지오' },
    { idx: 'A15883402', title: '신정뉴타운롯데캐슬' },
    { idx: 'A15873701', title: '목동10단지아파트' },
    { idx: 'A15701007', title: '강서힐스테이트' },
    { idx: 'A15792602', title: '화곡푸르지오' },
    { idx: 'A15701003', title: '우장산아이파크e편한세상' },
    { idx: 'A10026881', title: '서울역센트럴자이' },
    { idx: 'A10027851', title: '신촌푸르지오' },
    { idx: 'A10027687', title: '마곡힐스테이트' },
    { idx: 'A10027906', title: '공덕자이' },
    { idx: 'A10027227', title: '돈암코오롱하늘채' },
    { idx: 'A13606004', title: '동소문한신한진아파트' },
    { idx: 'A10026571', title: '꿈의숲코오롱하늘채' },
    { idx: 'A10027189', title: '보문파크뷰자이아파트' },
    { idx: 'A10027227', title: '돈암코오롱하늘채' },
    { idx: 'A13606201', title: '돈암브라운스톤' },
    { idx: 'A13611006', title: '길음뉴타운대림4단지(e편한세상)' },
    { idx: 'A13611007', title: '길음뉴타운푸르지오2,3단지' },
    { idx: 'A13611202', title: '길음동부센트레빌' },
    { idx: 'A13613007', title: '래미안월곡' },
    { idx: 'A13676703', title: '정릉e편한세상1차' },
    { idx: 'A13677101', title: '정릉2차e편한세상' },
    { idx: 'A13686302', title: '종암삼성래미안' },
    { idx: 'A14272304', title: 'SK북한산시티아파트' },
    { idx: 'A14272308', title: '래미안트리베라2차' },
    { idx: 'A10026469', title: '묵동자이1,2단지(주상복합)' },
    { idx: 'A10025159', title: '한양수자인사가정파크' },
    { idx: 'A13113008', title: '신내우디안1단지' },
    { idx: 'A10026015', title: '반포써밋' },
    { idx: 'A10026227', title: '아크로리버뷰신반포' },
    { idx: 'A10027439', title: '래미안신반포팰리스' },
    { idx: 'A13704104', title: '반포자이' },
    { idx: 'A13704404', title: '반포미도아파트' },
    { idx: 'A13776301', title: '반포리체' },
    { idx: 'A13776509', title: '반포래미안퍼스티지' },
    { idx: 'C11650106', title: '신반포자이' },
    { idx: 'A10027985', title: 'LH강남힐스테이트' },
    { idx: 'A13519006', title: '세곡푸르지오' },
  ],
};

export default exportData;
