import React, { useState } from 'react';
import styled from 'styled-components';
import calc_icon from './images/calculator.png';
import chat_icon from './images/chat.png';

import FloatingList from './list';
import EstimateModal from '../../Common/Modal/EstimateModal';
import ConfirmModal from 'components/Common/Modal/ConfirmModal';

import { ORDER_ESTIMATE_TYPE } from 'utils/enum/estimate';

const Container = styled.div<{ isFooter: boolean }>`
  position: fixed;
  bottom: ${(props) => (props.isFooter ? '300px' : '25px')};
  right: 25px;
  padding: 6px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 3000;
  width: 86px;
  transition: 0.3s;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const Box = styled.div<{ isDisabled?: boolean }>`
  text-align: center;
  padding: 11px 0;
  border-bottom: 1px solid #e1e1e1;
  margin: 0 auto;

  & > div {
    position: relative;
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

    & > h3 {
      margin-top: 5px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: ${(props) =>
        props.isDisabled ? 'rgba(143, 142, 142, 0.33);' : '#000000'};
    }

    .coming_text {
      position: absolute;
      width: 100%;
      top: 10px;

      & > p {
        text-align: center;
        color: #fa5500;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
`;

interface Props {
  isFooter: boolean;
}

const Floating = (props: Props) => {
  const { isFooter } = props;

  const [isOpenEstimate, setIsOpenEstimate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const callback = () => {
    setIsOpenEstimate(false);
    setIsConfirm(true);
  };

  return (
    <Container isFooter={isFooter}>
      <Box>
        <div onClick={() => setIsOpenEstimate(true)}>
          <img src={chat_icon} alt="chat_icon" />
          <h3>상담신청</h3>
        </div>
      </Box>
      <Box isDisabled={true}>
        <div>
          <img src={calc_icon} alt="calc_icon" />
          <h3>견적계산</h3>
          <div className="coming_text">
            <p>
              Coming
              <br />
              Soon!
            </p>
          </div>
        </div>
      </Box>
      <FloatingList />
      {isOpenEstimate && (
        <EstimateModal
          close={() => setIsOpenEstimate(false)}
          order_estimate_type={ORDER_ESTIMATE_TYPE.DEFAULT}
          callback={callback}
        />
      )}
      {isConfirm && <ConfirmModal close={() => setIsConfirm(false)} />}
    </Container>
  );
};

export default Floating;
