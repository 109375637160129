import React from 'react';
import Layout from 'components/Layout';
import EventComponent from 'components/Event';

const EventPage = () => {
  return (
    <Layout headerText="특가/혜택">
      <EventComponent />
    </Layout>
  );
};

export default EventPage;
