import React from 'react';
import styled from 'styled-components';
import useCommonQueries from 'hooks/queries/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import images from 'libs/images';
import { getSaveDate } from 'utils/utils';
import useBannerStore from 'stores/useBannerStore';

const Container = styled.div`
  background-color: #000000;
  padding: 15px 0;
  position: relative;

  div,
  p,
  span,
  h1,
  h2,
  h3 {
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
  }

  & > button {
    background: none;
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 30px;

    & > img {
      width: 32px;
      height: 32px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    height: 34px;
    line-height: 34px;

    & > button {
      top: 5px;

      & > img {
        width: 24px;
        height: 24px;
      }
    }

    div,
    p,
    span,
    h1,
    h2,
    h3 {
      color: #ffffff;
      font-weight: 700;
      font-size: 12px;
    }
  }
`;

const Content = styled.div`
  display: block;
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 30px;

  & > div {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MobileContent = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;

    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          text-align: center;

          & > div {
            display: inline-block;
            cursor: pointer;
          }
        }
      }
    }
  }
`;

const TopBanner = () => {
  const { getHomeQuery } = useCommonQueries();
  const { data: home } = getHomeQuery();

  const { isHideLineBanner, closeLineBanner } = useBannerStore();

  const savedate: string = getSaveDate();

  const onRoute = (url: string) => {
    window.location.href = url;
  };

  const close = () => {
    closeLineBanner(savedate);
  };

  if (!home) return null;
  if (home.line_banner_list.length === 0) return null;

  if (isHideLineBanner === savedate) return null;

  return (
    <Container>
      <Content>
        {home.line_banner_list.map((item) => (
          <div
            key={item.avt_table_id}
            dangerouslySetInnerHTML={{
              __html: item.line_title,
            }}
            onClick={() => onRoute(item.line_url)}
          />
        ))}
      </Content>
      <MobileContent>
        <Swiper slidesPerView={1} loop autoplay={{ delay: 5000 }}>
          {home.line_banner_list.map((item) => (
            <SwiperSlide key={item.avt_table_id}>
              <div
                key={item.avt_table_id}
                dangerouslySetInnerHTML={{
                  __html: item.line_title,
                }}
                onClick={() => onRoute(item.line_url)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </MobileContent>

      <button onClick={close}>
        <img src={images.exit_white} alt="close_btn" />
      </button>
    </Container>
  );
};

export default React.memo(TopBanner);
