import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mockService } from './data';
import { ReportPartnerDetail, ReportSpecial } from 'types/report';
import useCommonQueries from 'hooks/queries/common';
import { getSpecialDdayText } from 'utils/date';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Container = styled.div`
  @media screen and (max-width: 767px) {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
`;

const Box = styled.div`
  padding-top: 40px;

  &:first-of-type {
    padding-top: 80px;
  }

  & > h3 {
    font-size: 24px;
    font-weight: 700;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0 25px 0;
    border-bottom: 1px solid #d9d9d9;

    &:first-of-type {
      padding: 20px 0 40px 0;
    }

    & > h3 {
      font-size: 14px;
      font-weight: 700;
    }
  }
`;

const ServiceContent = styled.div`
  margin-top: 50px;
  min-height: 100px;
  display: flex;
  flex-wrap: wrap;
  gap: 35px;

  & > div {
    display: flex;
    flex-wrap: wrap;
    width: 100px;
    text-align: center;

    & > img {
      margin: 0 auto 10px auto;
      object-fit: contain;
    }

    & > p {
      width: 100%;
      margin-top: auto;
      background-color: #f5f5f5;
      color: #7e7e7e;
      border: 1px solid #8f8e8e;
      border-radius: 3px;
      padding: 8px 0;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    width: 100%;
    gap: 15px 10px;

    & > div {
      width: 86px;

      & > img {
        transform: scale(0.5);
        margin-bottom: 0;
      }
      & > p {
        padding: 6px 0;
        font-size: 10px;
      }
    }
  }
`;

const EventContent = styled(Link)`
  display: inline-block;
  margin-top: 45px;
  position: relative;
  width: 50%;

  @media screen and (max-width: 767px) {
    width: 65%;
    margin: 25px auto 0 auto;
  }
`;

const EventBadge = styled.div`
  position: absolute;
  right: 0;
  z-index: 100;
  background-color: #bb0f33;
  border-radius: 5px;
  padding: 4px 16px;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  font-weight: 400;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    font-weight: 400;
    padding: 3px 12px;
  }
`;

const EventImage = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 100%;

  & > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const EventInfo = styled.div`
  margin: 26px 0 24px 20px;

  & > h2 {
    color: #8f8e8e;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }
  & > h3 {
    font-size: 20px;
    font-weight: 700;
  }

  & > p {
    margin-top: 5px;
    color: #fa5500;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  @media screen and (max-width: 767px) {
    margin: 10px 20px;

    & > h2 {
      font-size: 12px;
    }

    & > h3 {
      margin-top: 2px;
      font-size: 14px;
    }

    & > p {
      margin-top: 2px;
      font-size: 12px;
    }
  }
`;

const MainInfoContent = styled.div`
  margin-top: 38px;

  @media screen and (max-width: 767px) {
    margin: 20px 30px 0 30px;
  }
`;

const InfoItem = styled.div`
  margin-bottom: 30px;

  &:last-of-type {
    min-height: 100px;
  }

  & > p {
    font-size: 22px;
    font-weight: 400;

    b {
      font-weight: 700;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & > p {
      font-size: 14px;
    }
  }
`;

interface Props {
  info: ReportPartnerDetail;
}

const PartnerInfo = (props: Props) => {
  const { info } = props;
  const { getCityListQuery } = useCommonQueries();
  const { data: city } = getCityListQuery();

  const [eventDetail, setEventDetail] = useState<ReportSpecial>();

  useEffect(() => {
    if (info && info.special_list.length > 0) {
      const length = info.special_list.length;

      const randomIndex = Math.floor(Math.random() * length);

      setEventDetail(info.special_list[randomIndex]);
    }
  }, [info]);

  const dDayText = getSpecialDdayText(eventDetail?.avt_days);

  const getArea = () => {
    if (city) {
      const { seller_area } = info;

      if (seller_area.length === 0) {
        return '알수 없음';
      } else {
        const areaText = seller_area.map((item) => {
          const findCity = city.area.find((area) => area.area_id === item);

          if (findCity) {
            return `${findCity.area_name} 전체`;
          } else {
            const cityId = item.slice(0, 2);
            const findCity = city.area.find(
              (cityItem) => cityItem.area_id === cityId
            );
            const findArea = findCity?.area_2nd.find(
              (areaItem) => areaItem.area_detail_id === item
            );

            return `${findCity?.area_name} ${findArea?.area_detail_name}`;
          }
        });
        return areaText.join(', ');
      }
    } else {
      return '알수 없음';
    }
  };

  return (
    <Container>
      <Box>
        <h3>제공서비스</h3>
        <ServiceContent>
          {info.seller_service?.map((service) => {
            const findItem = mockService.find(
              (item) => String(item.id) === service
            );

            if (findItem) {
              return (
                <div key={service}>
                  <img src={findItem?.img} alt="service_img" />
                  <p>{findItem?.title}</p>
                </div>
              );
            }
          })}
        </ServiceContent>
      </Box>
      {eventDetail && (
        <Box>
          <h3>특가/혜택 진행 중</h3>

          <EventContent
            to={`/event/${eventDetail.url_table_id}?avt_id=${eventDetail.avt_table_id}`}
          >
            <EventBadge>{dDayText}</EventBadge>
            <EventImage className="partner_detail_event_image">
              <img
                src={String(eventDetail?.avt_thumbnail)}
                alt="avt_thumbnail"
              />
            </EventImage>
            <EventInfo>
              <h2>{eventDetail.seller_biz_name}</h2>
              <h3>{eventDetail?.avt_title}</h3>
              <p>
                {eventDetail?.avt_days
                  .split(' ~ ')
                  .map((day) => moment(day).format('YYYY-MM-DD'))
                  .join(' ~ ')}
              </p>
            </EventInfo>
          </EventContent>
        </Box>
      )}
      <Box>
        <h3>기본 정보</h3>
        <MainInfoContent>
          <InfoItem>
            <p>
              <b>대표자</b>
              {info.seller_name}
            </p>
          </InfoItem>
          <InfoItem>
            <p>
              <b>경력</b>
              {info.seller_career}년
            </p>
          </InfoItem>
          <InfoItem>
            <p>
              <b>주소</b>
              {info.seller_addr}
            </p>
          </InfoItem>
          <InfoItem>
            <p>
              <b>시공 가능 지역</b>
              {getArea()}
            </p>
          </InfoItem>
          <InfoItem>
            <p>
              <b>A/S 보증 기간</b>
              {info.seller_as}
            </p>
          </InfoItem>
          <InfoItem>
            <p>
              <b>소개</b>
              {info.seller_desc}
            </p>
          </InfoItem>
        </MainInfoContent>
      </Box>
    </Container>
  );
};

export default PartnerInfo;
