import React from 'react';
import styled from 'styled-components';
import { ReportPartner } from 'types/report';
import { Link } from 'react-router-dom';
import images from '../../libs/images';
import { numFormat } from 'utils/utils';
import { styleList } from 'utils/frontReport';

const Container = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 90px auto 275px auto;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    margin: 40px auto;
  }
`;

const Card = styled(Link)`
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding: 75px 0;
  border-bottom: 1px solid #8f8e8e;
  position: relative;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border: none;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 25px;
    padding: 0 0 35px 0;
    border-bottom: 1px solid #d9d9d9;
  }
`;

const PartnerInfo = styled.div`
  margin-top: 20px;
  display: flex;

  & > img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  & > p {
    margin: auto 0 auto 8px;
    color: #000;
    font-size: 18px;
    font-weight: 400;
  }

  @media screen and (max-width: 767px) {
    margin-top: 5px;

    & > img {
      width: 19px;
      height: 19px;
    }

    & > p {
      font-size: 12px;
      font-weight: 500;
      margin-left: 10px;
    }
  }
`;

const CardItemWrap = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;

  &:hover {
    & > div:first-of-type {
      .image_item {
        transform: scale(1.1);
        transition: transform 0.2s;
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 15px;
    display: grid;
    gap: 10px;
    grid-template-areas:
      'a a a a a a b b'
      'a a a a a a b b'
      'a a a a a a c c'
      'a a a a a a c c';
  }
`;

const Item = styled.div`
  width: 26.5%;
  margin-top: auto;

  &:first-of-type {
    width: 40%;
    margin-right: 70px;
  }

  &:nth-child(2) {
    margin-right: 25px;
  }

  @media screen and (max-width: 767px) {
    &:first-of-type {
      width: 100%;
      grid-area: a;
      margin: 0;
    }

    &:nth-child(2) {
      width: 100%;
      grid-area: b;
      margin: 0;

      & > div {
        padding-bottom: 98%;
      }
    }

    &:last-of-type {
      width: 100%;
      grid-area: c;
      margin: 0;

      & > div {
        padding-bottom: 98%;
      }
    }
  }
`;

const Image = styled.div`
  position: relative;
  padding-bottom: 62%;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  overflow: hidden;

  .image_item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: transform 0.2s;
  }

  .badge_item {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
    display: flex;
    gap: 5px;

    &.disabled {
      display: none;
    }

    .badge {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      padding: 3px 8px;
      border-radius: 2px;

      &.receipt {
        background-color: #fa5500;
      }

      &.before {
        background-color: #000000;
      }

      &.special {
        background-color: #ff5e5e;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 65%;

    .image_item {
      object-fit: cover;
    }

    .badge_item {
      .badge {
        font-size: 10px;
        padding: 2px 6px;
      }
    }
  }
`;

const ItemInfo = styled.div`
  min-height: 114px;
  margin-top: 18px;

  & > h3 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  & > p {
    color: rgba(0, 0, 0, 0.8);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }

  .price_info {
    display: flex;
    gap: 5px;

    & > p {
      font-weight: 600;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.8);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .tag_info {
    display: flex;
    gap: 10px;
    margin-top: 3px;

    & > p {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      font-weight: 400;
      border-radius: 2px;
      background: #f5f5f5;

      &:first-of-type {
        padding: 4px 8px;
      }

      &:last-of-type {
        padding: 4px 15px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const CardEvent = styled.div`
  margin: auto 0 0 0;
  height: 50px;
  line-height: 50px;
  min-width: 500px;
  text-align: center;
  background-color: #000000;
  color: #ffffff;

  & > div {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MobileItemInfo = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    margin-top: 15px;

    & > h3 {
      font-size: 16px;
      font-weight: 700;
    }

    & > p {
      color: rgba(0, 0, 0, 0.8);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
    }

    .size_price_info {
      display: flex;
      margin-top: 5px;
      gap: 5px;

      & > p {
        color: rgba(0, 0, 0, 0.8);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-weight: 700;
        line-height: 30px;
      }
    }

    .tag_info {
      display: flex;
      gap: 5px;
      margin-top: 3px;

      & > p {
        color: #6c6c6c;
        font-size: 10px;
        font-weight: 400;
        border-radius: 2px;
        background: #f5f5f5;
        padding: 2px 6px;
      }
    }

    .line_banner_item {
      width: 100%;
      margin: 20px auto 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.7);
      padding: 9px 0;
      text-align: center;
      color: #434343;
      font-size: 12px;
      font-weight: 700;
    }
  }
`;

const PartnerBadge = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 22px;

  & > p {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    font-weight: 400;
    padding: 3px 5px;
    border-radius: 2px;
    background: #f5f5f5;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;

    & > p {
      font-size: 10px;
      padding: 2px 5px;
      line-height: 15px;
    }
  }
`;

const PartnerTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  & > h3 {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
  }

  @media screen and (max-width: 767px) {
    & > h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
    }
  }
`;

interface Props {
  partnerList: ReportPartner[];
}

const List = (props: Props) => {
  const { partnerList } = props;

  const onRoute = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    url: string
  ) => {
    e.preventDefault();
    window.location.href = url;
  };

  return (
    <Container>
      {partnerList?.map((partner) => (
        <Card
          key={partner.seller_table_id}
          to={`/report-partner/${partner.seller_table_id}`}
        >
          <PartnerBadge>
            {String(partner.is_license) === '1' && (
              <p className="license">견축 면허</p>
            )}
            {String(partner.is_bohum) === '1' && (
              <p className="insurance">보증 보험</p>
            )}
          </PartnerBadge>
          <PartnerTitleWrap>
            <h3>{partner.seller_intro}</h3>
            {partner.line_banner_list[0] && (
              <CardEvent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: partner.line_banner_list[0].line_title,
                  }}
                  onClick={(e) =>
                    onRoute(e, partner.line_banner_list[0].line_url)
                  }
                />
              </CardEvent>
            )}
          </PartnerTitleWrap>
          <PartnerInfo>
            <img
              src={partner.seller_icon || images.partner_logo}
              alt="seller_icon"
            />
            <p>{partner.seller_biz_name}</p>
          </PartnerInfo>
          <CardItemWrap>
            {[0, 1, 2].map((item) => {
              const { img_defult_01, img_defult_02, img_defult_03 } = images;

              const defaultImages = [
                img_defult_01,
                img_defult_02,
                img_defult_03,
              ];

              return (
                <Item key={item}>
                  <Image>
                    <img
                      className="image_item"
                      src={
                        partner.case_list[item]?.case_photo ||
                        defaultImages[item]
                      }
                    />
                    <div
                      className={`badge_item ${item === 0 ? '' : 'disabled'}`}
                    >
                      {String(partner.case_list[item]?.is_receipt) === '1' && (
                        <p className="badge receipt">영수증</p>
                      )}
                      {String(partner.case_list[item]?.is_before) === '1' && (
                        <p className="badge before">Before & After</p>
                      )}
                      {String(partner.case_list[item]?.is_special) === '1' && (
                        <p className="badge special">특가/혜택</p>
                      )}
                    </div>
                  </Image>
                  <ItemInfo>
                    {partner.case_list[item] && (
                      <>
                        <h3>{partner.case_list[item]?.case_desc}</h3>
                        <p>{partner.case_list[item]?.case_name}</p>
                        <div className="price_info">
                          <p>{partner.case_list[item]?.case_size}평</p>
                          <p>
                            {numFormat(
                              partner.case_list[item]?.case_work_price
                            )}
                            만원
                          </p>
                        </div>
                        <div className="tag_info">
                          <p>
                            {'#' +
                              styleList.find(
                                (obj) =>
                                  obj.value ===
                                  partner.case_list[item]?.interior_style
                              )?.title}
                          </p>
                          <p>
                            {Math.floor(
                              Number(
                                partner.case_list[item]?.case_work_period
                              ) / 7
                            ) + '주'}
                          </p>
                        </div>
                      </>
                    )}
                  </ItemInfo>
                </Item>
              );
            })}
          </CardItemWrap>
          <MobileItemInfo>
            <h3>{partner?.case_list[0]?.case_desc || '시공 컨셉 등록중'}</h3>
            <p>{partner?.case_list[0]?.case_name || '아파트 명 등록중'}</p>
            {partner?.case_list[0] && (
              <div className="size_price_info">
                <p>{partner?.case_list[0]?.case_size || '0'}평</p>
                <p>
                  {numFormat(partner?.case_list[0]?.case_work_price) || '0'}만원
                </p>
              </div>
            )}
            {partner?.case_list[0] && (
              <div className="tag_info">
                <p>
                  {'#' +
                    styleList.find(
                      (obj) =>
                        obj.value === partner.case_list[0]?.interior_style
                    )?.title}
                </p>
                <p>
                  {Math.floor(
                    Number(partner.case_list[0]?.case_work_period) / 7
                  ) + '주'}
                </p>
              </div>
            )}
            {partner.line_banner_list[0] && (
              <div
                className="line_banner_item"
                dangerouslySetInnerHTML={{
                  __html: partner.line_banner_list[0].line_title,
                }}
                onClick={(e) =>
                  onRoute(e, partner.line_banner_list[0].line_url)
                }
              />
            )}
          </MobileItemInfo>
        </Card>
      ))}
    </Container>
  );
};

export default List;
