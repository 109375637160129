import React from 'react';
import Layout from 'components/Layout';
import ClauseComponent from 'components/Clause';

const ClausePage = () => {
  return (
    <Layout>
      <ClauseComponent />
    </Layout>
  );
};

export default ClausePage;
