import React from 'react';
import styled from 'styled-components';
import {
  guarantee,
  guarantee2,
  guarantee3,
  guarantee_mobile,
  guarantee2_mobile,
  guarantee3_mobile,
} from '../../assets/images/life-construction';

const Container = styled.section`
  margin-top: 117px;
  display: flex;

  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
`;

const Content = styled.div`
  width: 1147px;
  margin: 0 auto;

  @media screen and (max-width: 1207px) {
    width: calc(100% - 60px);
  }
`;

const SectionHeader = styled.div`
  text-align: center;

  & > h3 {
    font-size: 38px;
    font-weight: bold;
  }

  @media screen and (max-width: 767px) {
    & > h3 {
      font-size: 24px;
    }
  }
`;

const ContentWrap = styled.div`
  margin-top: 64px;

  & > div {
    margin-bottom: 48px;

    & > img {
      width: 100%;
      object-fit: cover;

      &.pc {
        display: block;
      }

      &.mo {
        display: none;
      }
    }

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      margin-bottom: 34px;

      & > img {
        &.pc {
          display: none;
        }

        &.mo {
          display: block;
        }
      }
    }
  }
`;

const GuaranteeSection = () => {
  return (
    <Container>
      <Content>
        <SectionHeader>
          <h3>레이홈만의 안심보장 서비스</h3>
        </SectionHeader>
        <ContentWrap>
          <div>
            <img
              className="guarantee_image pc"
              src={guarantee}
              alt="guarantee_banner"
            />
            <img
              className="guarantee_image mo"
              src={guarantee_mobile}
              alt="guarantee_banner_mobile"
            />
          </div>
          <div>
            <img
              className="guarantee_image pc"
              src={guarantee2}
              alt="guarantee_banner2"
            />
            <img
              className="guarantee_image mo"
              src={guarantee2_mobile}
              alt="guarantee_banner2_mobile"
            />
          </div>
          <div>
            <img
              className="guarantee_image pc"
              src={guarantee3}
              alt="guarantee_banner3"
            />
            <img
              className="guarantee_image mo"
              src={guarantee3_mobile}
              alt="guarantee_banner3_mobile"
            />
          </div>
        </ContentWrap>
      </Content>
    </Container>
  );
};

export default GuaranteeSection;
