import axios from '../utils/axios';
import { ReportList, ReportDetail } from '../types/report';

export const getReport = async (params: {
  case_table_id: string;
}): Promise<ReportDetail> => {
  const res = await axios.post('/WorkCase/WorkCaseDetail', params);

  return res.data;
};

interface ReporListtParams {
  area_code: string;
  area_code_detail: string;
  case_size: string[];
  case_work_price: string[];
  search_name: string;
  interior_style: string[];
  addService: string[];
  page: number;
  sort_by: string;
}
export const getReportList = async (
  params: ReporListtParams
): Promise<ReportList> => {
  const res = await axios.post('/WorkCase/WorkCaseList', params);

  return res.data;
};
