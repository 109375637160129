import React, { useMemo } from 'react';
import styled from 'styled-components';
import useEventQueries from 'hooks/queries/event';
import { getSpecialDdayText } from 'utils/date';
import images from 'libs/images';
import { Link } from 'react-router-dom';

const Container = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;
  padding-bottom: 100px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    padding-bottom: 25px;
  }
`;

const Content = styled.div`
  margin: 100px 65px 0 65px;
  display: flex;
  flex-wrap: wrap;
  gap: 100px 4.25%;

  @media screen and (max-width: 1200px) {
    margin: 100px 35px 0 35px;
  }

  @media screen and (max-width: 767px) {
    margin: 100px 65px 0 65px;
    gap: 0;
  }

  @media screen and (max-width: 550px) {
    margin: 100px 15px 0 15px;
    gap: 0;
  }
`;

const Item = styled(Link)`
  position: relative;
  width: 30.5%;

  &.event-item {
    &:hover {
      .image {
        transform: scale(1.1);
        transition: transform 0.2s;
      }
    }
  }

  &.disabled-event-item {
    cursor: not-allowed;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const ImageItem = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;

  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s;
  }

  .badge-item {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 18px;
    background-color: #bb0f33;
    border-radius: 4px;
    color: #fff;
    font-size: 24px;
    font-weight: 400;

    &.disabled {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    .badge-item {
      font-size: 16px;
      padding: 3px 12px;
    }
  }
`;

const InfoItem = styled.div`
  padding: 25px 20px;

  & > h3 {
    color: #8f8e8e;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }

  & > h4 {
    font-size: 20px;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  & > p {
    margin-top: 5px;
    color: #fa5500;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  @media screen and (max-width: 767px) {
    padding: 10px 20px;

    & > h3 {
      font-size: 12px;
    }

    & > h4 {
      font-size: 14px;
      line-height: normal;
      margin-top: 2px;
    }

    & > p {
      font-size: 12px;
      margin-top: 2px;
    }
  }
`;

const MoreWrap = styled.div`
  text-align: center;
  margin: 150px 0 100px 0;

  & > button {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    border: 1px solid;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 16px 145px;
  }
`;

const DisabledWrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;

  & > p {
    margin: auto;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
  }
`;

const EventComponent = () => {
  const { getEventListQuery } = useEventQueries();

  const { data, hasNextPage, fetchNextPage } = getEventListQuery();

  const eventList = useMemo(() => {
    if (data) {
      const events = data.pages.flatMap((item) => item.sale_list);

      const activeEvents = events.filter(
        (event) => Number(event.remain_day) > 0
      );

      const disabledEvents = events.filter(
        (event) => Number(event.remain_day) <= 0
      );

      return [...activeEvents, ...disabledEvents];
    } else {
      return [];
    }
  }, [data]);

  return (
    <Container>
      <Content>
        {eventList.map((event) => {
          const isDisabledEvent =
            getSpecialDdayText(event.avt_days) === '마감됨';

          return (
            <Item
              to={`/event/${event.url_table_id}?avt_id=${event.avt_table_id}`}
              key={event.avt_table_id}
              className={isDisabledEvent ? 'disabled-event-item' : 'event-item'}
              onClick={(e) => {
                if (isDisabledEvent) {
                  e.preventDefault();
                }
              }}
            >
              <ImageItem>
                <img
                  className="image"
                  src={event.avt_thumbnail || images.fallback}
                  alt="avt_thumbnail"
                />
                <p className={`badge-item ${isDisabledEvent && 'disabled'}`}>
                  {getSpecialDdayText(event.avt_days)}
                </p>
              </ImageItem>
              <InfoItem>
                <h3>{event.seller_biz_name}</h3>
                <h4>
                  {event.avt_title.length < 21
                    ? event.avt_title
                    : event.avt_title.slice(0, 21) + '...'}
                </h4>
                <p>{event.avt_days}</p>
              </InfoItem>
              {isDisabledEvent && (
                <DisabledWrap>
                  <p>이벤트 종료되었습니다.</p>
                </DisabledWrap>
              )}
            </Item>
          );
        })}
      </Content>
      {!!hasNextPage && (
        <MoreWrap>
          <button onClick={() => fetchNextPage()}>더 보기</button>
        </MoreWrap>
      )}
    </Container>
  );
};

export default EventComponent;
