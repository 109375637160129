// 이용약관 컴포넌트

import React from 'react';
import styled from 'styled-components';
import useCommonQueries from 'hooks/queries/common';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 50px auto;
  min-height: 50vh;

  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }
`;

const ClauseComponent = () => {
  const { id } = useParams();

  const { getTermsQuery } = useCommonQueries();

  const { data: terms } = getTermsQuery({ tid: String(id) });

  if (!id || !terms) {
    return null;
  }

  return (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: terms.terms }}></div>
    </Container>
  );
};

export default ClauseComponent;
