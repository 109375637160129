import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import EventDetailComponent from 'components/Event/Detail';
import { useParams } from 'react-router-dom';
import useEventQueries from 'hooks/queries/event';

const EventDetailPage = () => {
  const { id } = useParams();

  const { getEventDetailQuery } = useEventQueries();
  const { data: event } = getEventDetailQuery(String(id));

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  if (!event) return null;

  return (
    <Layout
      headerText={event?.url_title || '알수 없음'}
      isHeaderTextAlign="center"
      is_hide_footer
      is_hide_mobile_bottom_sheet
    >
      <EventDetailComponent event={event} id={String(id)} />
    </Layout>
  );
};

export default EventDetailPage;
