import exit from '../assets/images/exit.svg';
import exit_white from '../assets/images/exit_white.svg';
import checkbox_off from '../assets/images/checkbox_off.svg';
import checkbox_on from '../assets/images/checkbox_on.svg';
import calendar from '../assets/images/calendar.svg';
import camera from '../assets/images/camera.svg';
import down from '../assets/images/down.svg';
import up from '../assets/images/up.svg';
import left_icon from '../assets/images/left_icon.svg';
import right_icon from '../assets/images/right_icon.svg';
import warning from '../assets/images/warning.svg';
import checkmark from '../assets/images/checkmark.svg';
import radio_on from '../assets/images/radio_on.svg';
import radio_off from '../assets/images/radio_off.svg';

import plus_black from '../assets/images/plus_black.svg';
import list_icon from '../assets/images/list_icon.svg';

import clipboard_icon from '../assets/images/clipboard-icon.svg';

import no_img from '../assets/images/no_img.png';

import star from '../assets/images/star.svg';
import star_off from '../assets/images/star-off.svg';

import options_icon from '../assets/images/options_icon.svg';

import slider_img1 from '../assets/images/slider_img1.png';
import slider_img2 from '../assets/images/slider_img2.png';
import slider_img3 from '../assets/images/slider_img3.png';
import slider_img4 from '../assets/images/slider_img4.png';

import front_door_off from '../assets/images/theme/front_door_off.svg';
import front_door_on from '../assets/images/theme/front_door_on.svg';
import kitchen_off from '../assets/images/theme/kitchen_off.svg';
import kitchen_on from '../assets/images/theme/kitchen_on.svg';
import room_off from '../assets/images/theme/room_off.svg';
import room_on from '../assets/images/theme/room_on.svg';
import terrace_off from '../assets/images/theme/terrace_off.svg';
import terrace_on from '../assets/images/theme/terrace_on.svg';
import bathroom_off from '../assets/images/theme/bathroom_off.svg';
import bathroom_on from '../assets/images/theme/bathroom_on.svg';
import livingroom_off from '../assets/images/theme/livingroom_off.svg';
import livingroom_on from '../assets/images/theme/livingroom_on.svg';

import frontdoor1_off from '../assets/images/theme/frontdoor1_off.svg';
import frontdoor1_on from '../assets/images/theme/frontdoor1_on.svg';
import kitchen1_off from '../assets/images/theme/kitchen1_off.svg';
import kitchen1_on from '../assets/images/theme/kitchen1_on.svg';
import main_room1_off from '../assets/images/theme/main_room1_off.svg';
import main_room1_on from '../assets/images/theme/main_room1_on.svg';
import terrace1_off from '../assets/images/theme/terrace1_off.svg';
import terrace1_on from '../assets/images/theme/terrace1_on.svg';
import bathroom0_off from '../assets/images/theme/bathroom0_off.svg';
import bathroom0_on from '../assets/images/theme/bathroom0_on.svg';
import living_room1_off from '../assets/images/theme/living_room1_off.svg';
import living_room1_on from '../assets/images/theme/living_room1_on.svg';

import bathroom1_on from '../assets/images/theme/bathroom1_on.svg';
import bathroom1_off from '../assets/images/theme/bathroom1_off.svg';
import bathroom2_on from '../assets/images/theme/bathroom2_on.svg';
import bathroom2_off from '../assets/images/theme/bathroom2_off.svg';
import bathroom3_on from '../assets/images/theme/bathroom3_on.svg';
import bathroom3_off from '../assets/images/theme/bathroom3_off.svg';
import room1_on from '../assets/images/theme/room1_on.svg';
import room1_off from '../assets/images/theme/room1_off.svg';
import room2_on from '../assets/images/theme/room2_on.svg';
import room2_off from '../assets/images/theme/room2_off.svg';
import room3_on from '../assets/images/theme/room3_on.svg';
import room3_off from '../assets/images/theme/room3_off.svg';
import room4_on from '../assets/images/theme/room4_on.svg';
import room4_off from '../assets/images/theme/room4_off.svg';
import alpha_room_on from '../assets/images/theme/alpha_room_on.svg';
import alpha_room_off from '../assets/images/theme/alpha_room_off.svg';
import utility_room_on from '../assets/images/theme/utility_room_on.svg';
import utility_room_off from '../assets/images/theme/utility_room_off.svg';
import pantry_on from '../assets/images/theme/pantry_on.svg';
import pantry_off from '../assets/images/theme/pantry_off.svg';
import etc_on from '../assets/images/theme/etc_on.svg';
import etc_off from '../assets/images/theme/etc_off.svg';

import theme_img1 from '../assets/images/theme/theme_img4.png';
import theme_img2 from '../assets/images/theme/theme_img5.png';
import theme_img3 from '../assets/images/theme/theme_img6.png';
import theme_img4 from '../assets/images/theme/theme_img7.png';

import main_theme1 from '../assets/images/theme/front_door_main.svg';
import main_theme2 from '../assets/images/theme/kitchin_main.svg';
import main_theme3 from '../assets/images/theme/room_main.svg';
import main_theme4 from '../assets/images/theme/terrace_main.svg';
import main_theme5 from '../assets/images/theme/bathroom_main.svg';
import main_theme6 from '../assets/images/theme/living_room_main.svg';

import howto from '../assets/images/theme/how_to.svg';
import right_arrow from '../assets/images/theme/right_arrow.png';
import receipt from '../assets/images/theme/receipt.svg';
import more_btn from '../assets/images/theme/more_btn.svg';
import right_arrow1 from '../assets/images/theme/right_arrow1.svg';

import home_icon2 from '../assets/images/theme/home_icon2.svg';

import down_arrow from '../assets/images/theme/down_arrow.svg';

import living_room3 from '../assets/images/theme/living_room3.svg';
import living_room4 from '../assets/images/theme/living_room4.svg';
import kitchen3 from '../assets/images/theme/kitchen3.svg';
import kitchen4 from '../assets/images/theme/kitchen4.svg';
import room3 from '../assets/images/theme/room3.svg';
import room4 from '../assets/images/theme/room4.svg';
import front_door3 from '../assets/images/theme/front_door3.svg';
import front_door4 from '../assets/images/theme/front_door4.svg';
import bath_room3 from '../assets/images/theme/bath_room3.svg';
import bath_room4 from '../assets/images/theme/bath_room4.svg';
import terrace3 from '../assets/images/theme/terrace3.svg';
import terrace4 from '../assets/images/theme/terrace4.svg';

import comp_logo from '../assets/images/report/comp_logo.png';

import img_defult_01 from '../assets/images/partner/img_defult_01.png';
import img_defult_02 from '../assets/images/partner/img_defult_02.png';
import img_defult_03 from '../assets/images/partner/img_defult_03.png';

import right_arrow2 from '../assets/images/partner/right_arrow.svg';

import partner_logo from '../assets/images/partner/partner_logo.png';
import partner_logo1 from '../assets/images/partner/partner_logo1.png';

import slide_btn_left from '../assets/images/slide_btn_left.svg';
import slide_btn_right from '../assets/images/slide_btn_right.svg';

import check_icon from '../assets/images/check_icon.svg';
import fallback from '../assets/images/fallback.png';
import logo_black from '../assets/images/logo_black.png';
import kakao_icon from '../assets/images/kakao.svg';

const exportData = {
  exit,
  exit_white,

  checkbox_off,
  checkbox_on,
  calendar,
  camera,
  down,
  up,
  warning,
  checkmark,
  radio_on,
  radio_off,
  slider_img1,
  slider_img2,
  slider_img3,
  slider_img4,
  plus_black,
  left_icon,
  right_icon,
  list_icon,
  star,
  star_off,
  front_door_off,
  front_door_on,
  kitchen_off,
  kitchen_on,
  room_off,
  room_on,
  terrace_off,
  terrace_on,
  bathroom_off,
  bathroom_on,
  livingroom_off,
  livingroom_on,
  main_theme1,
  main_theme2,
  main_theme3,
  main_theme4,
  main_theme5,
  main_theme6,
  howto,
  right_arrow,
  theme_img1,
  theme_img2,
  theme_img3,
  theme_img4,
  receipt,
  more_btn,
  right_arrow1,

  home_icon2,
  down_arrow,

  comp_logo,
  right_arrow2,
  slide_btn_left,
  slide_btn_right,
  clipboard_icon,
  options_icon,
  living_room3,
  living_room4,
  kitchen3,
  kitchen4,
  room3,
  room4,
  front_door3,
  front_door4,
  bath_room3,
  bath_room4,
  terrace3,
  terrace4,
  check_icon,
  no_img,
  bathroom1_on,
  bathroom1_off,
  bathroom2_on,
  bathroom2_off,
  bathroom3_on,
  bathroom3_off,
  room1_on,
  room1_off,
  room2_on,
  room2_off,
  room3_on,
  room3_off,
  room4_on,
  room4_off,
  frontdoor1_off,
  frontdoor1_on,
  kitchen1_off,
  kitchen1_on,
  main_room1_off,
  main_room1_on,
  terrace1_off,
  terrace1_on,
  bathroom0_off,
  bathroom0_on,
  living_room1_off,
  living_room1_on,
  alpha_room_on,
  alpha_room_off,
  utility_room_on,
  utility_room_off,
  pantry_on,
  pantry_off,
  etc_on,
  etc_off,
  partner_logo,
  partner_logo1,
  img_defult_01,
  img_defult_02,
  img_defult_03,
  fallback,
  logo_black,
  kakao_icon,
};

export default exportData;
