import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Special } from 'types/home';
import { getSpecialDdayText } from 'utils/date';
import { Swiper, SwiperSlide } from 'swiper/react';

const Container = styled.div`
  padding: 75px 0 50px 0;
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    padding: 27px 0 23px 0;
  }
`;

const Title = styled.div`
  display: flex;

  & > h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 77px;

    & > b {
      color: #fa5500;
      font-weight: 700;
    }
  }

  .pc_more {
    display: block;
    margin: auto 0 auto 20px;
    border: 1px solid;
    width: 118px;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .mo_more {
    display: none;
  }

  @media screen and (max-width: 767px) {
    justify-content: space-between;

    & > h1 {
      font-size: 17px;
      line-height: 40px;
    }

    .pc_more {
      display: none;
    }

    .mo_more {
      display: block;
      margin: auto 0;
      color: #75787b;
      font-size: 13px;
      font-weight: 400;
    }
  }
`;

const Content = styled.div`
  margin-top: 28px;

  @media screen and (max-width: 767px) {
    margin-top: 10px;
    width: calc(100% + 30px);
  }
`;

const Card = styled(Link)`
  &:hover {
    .image {
      transform: scale(1.1);
      transition: transform 0.2s;
    }
  }
`;

const ImageWrap = styled.div`
  position: relative;
  padding-bottom: 103%;
  overflow: hidden;

  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s;
  }

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #bb0f33;
    padding: 5px 17px;
    border-radius: 5px;

    p {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 767px) {
    .badge {
      padding: 5px 12px;

      & > p {
        font-size: 16px;
      }
    }
  }
`;

const InfoWrap = styled.div`
  padding: 17px 20px;

  & > h2 {
    color: #8f8e8e;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }

  & > h3 {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  & > p {
    margin-top: 5px;
    color: #fa5500;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  @media screen and (max-width: 767px) {
    padding: 10px 20px;

    & > h2 {
      font-size: 12px;
    }

    & > h3 {
      font-size: 14px;
    }

    & > p {
      font-size: 12px;
    }
  }
`;

const Progressbar = styled.div`
  margin: 50px auto 0 auto;
  max-width: 800px;
  width: 100%;
  position: relative;
  z-index: 1;

  .swiper-progressbar {
    background: #8f8e8e;
    height: 2px;

    .swiper-pagination-progressbar-fill {
      background: #000000;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

interface Props {
  data: Special[];
}

const Event = (props: Props) => {
  const { data } = props;

  if (!data) return null;

  const eventList = useMemo(() => {
    if (data.length > 4) {
      return data.slice(0, 4);
    } else if (data.length < 4) {
      return Array.from({ length: 4 }, (_, index) => {
        if (index < data.length) {
          return data[index];
        } else {
          return undefined;
        }
      });
    } else {
      return data;
    }
  }, [data]);

  return (
    <Container>
      <Title>
        <h1>
          <b>기간 한정</b> 특가/혜택 모음
        </h1>
        <Link to="/event" className="pc_more">
          모두 보기
        </Link>
        <Link to="/event" className="mo_more">
          더보기 {`>`}
        </Link>
      </Title>
      <Content>
        <Swiper
          slidesPerView={1.3}
          spaceBetween={12}
          pagination={{
            el: '.event_home_progress .swiper-progressbar',
            type: 'progressbar',
          }}
          breakpoints={{
            768: {
              slidesPerView: 3.3,
              spaceBetween: 44,
            },
          }}
        >
          {eventList.map((item) => (
            <SwiperSlide key={item?.avt_table_id}>
              <Card
                to={`/event/${item?.url_table_id}?avt_id=${item?.avt_table_id}`}
              >
                <ImageWrap>
                  <img
                    className="image"
                    src={item?.avt_thumbnail}
                    alt="avt_thumbnail"
                  />
                  <div className="badge">
                    <p>{getSpecialDdayText(item?.avt_days)}</p>
                  </div>
                </ImageWrap>
                <InfoWrap>
                  <h2>{item?.seller_biz_name}</h2>
                  <h3>{item?.url_title}</h3>
                  <p>{item?.avt_days}</p>
                </InfoWrap>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
        <Progressbar className="event_home_progress">
          <div className="swiper-progressbar"></div>
        </Progressbar>
      </Content>
    </Container>
  );
};

export default Event;
