export const sortList = [
  { idx: 1, title: '최신순', value: '1' },
  { idx: 2, title: '높은 견적순', value: '2' },
  { idx: 3, title: '낮은 견적순', value: '3' },
  { idx: 4, title: '인기순', value: '4' },
  { idx: 5, title: '큰 평형순', value: '5' },
  { idx: 6, title: '작은 평형순', value: '6' },
];

export const serviceList = [
  { idx: 1, title: '영수증', value: '1' },
  { idx: 2, title: '비포/에프터', value: '2' },
  { idx: 3, title: '레이홈 인증', value: '3' },
];

export const pyeongList = [
  { idx: 1, title: '20평대', value: '20' },
  { idx: 2, title: '30평대', value: '30' },
  { idx: 3, title: '40평대', value: '40' },
  { idx: 4, title: '50평대', value: '50' },
  { idx: 5, title: '60평대', value: '60' },
  { idx: 6, title: '그 외', value: '0' },
];

export const priceList = [
  { idx: 1, title: '1000만원 대', value: '1000' },
  { idx: 2, title: '2000만원 대', value: '2000' },
  { idx: 3, title: '3000만원 대', value: '3000' },
  { idx: 4, title: '4000만원 대', value: '4000' },
  { idx: 5, title: '5000만원 대', value: '5000' },
  { idx: 6, title: '그 외', value: '0' },
];

export const styleList = [
  { idx: 1, title: '우드&화이트', value: '1' },
  { idx: 2, title: '모던', value: '2' },
  { idx: 3, title: '젠스타일', value: '3' },
  { idx: 4, title: '모던미드센츄리', value: '4' },
  { idx: 5, title: '내츄럴', value: '5' },
  { idx: 6, title: '클래식', value: '6' },
  { idx: 7, title: '북유럽', value: '7' },
  { idx: 8, title: '기타', value: '8' },
];
