import React, { useEffect, useRef, useState } from 'react';
import Input from '../../components/Common/Input/Input';
import InputSelect from '../../components/Common/Input/InputSelect';

import images from '../../libs/images';
import consts from '../../libs/consts';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import g27 from '../../assets/images/event/group27.png';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import AptEventCompleteModal from '../../components/AptEvent/Modal/CompleteModal';
import { createAptnEvent } from 'apis/Common';
import useClause from 'hooks/useClause';

export default function EventPopup(props) {
  const { data } = props;

  const [popupStep] = useState(1);

  const [chk3, setChk3] = useState(false);

  const [city] = useState(data ? data.case_work_area : '');

  const [area] = useState(data ? data.case_work_area2 : '');

  const [order_size, setOrder_size] = useState('');

  const [order_phone, setOrder_phone] = useState('');

  const [error, setError] = useState('');

  const [error2, setError2] = useState('');

  const [wallpaperList] = useState([]);

  const [floorList1] = useState([]);
  const [floorList2] = useState([]);
  // 여기 체크

  const [apartName, setApartName] = useState('');
  const [dong, setDong] = useState('');
  const [hosu, setHosu] = useState('');
  const [price, setPrice] = useState(0);

  const [isOpenCompleteModal, setIsOpenCompleteModal] = useState(false);
  const formRef = useRef(null);
  const { showClause } = useClause();

  useEffect(() => {
    setError('');
  }, [city, dong, hosu]);

  useEffect(() => {
    setError2('');
  }, [order_phone]);

  useEffect(() => {
    setPrice(
      (wallpaperList.length !== 0 && Math.floor(order_size * 8.7)) +
        (floorList1.length !== 0 && Math.floor(order_size * 7.15)) +
        (floorList2.length !== 0 && Math.floor(order_size * 12))
    );
  }, [order_size]);

  const submitFunc2 = async () => {
    if (!order_size || !city || !area || !order_phone || !dong || !hosu) {
      if (!dong || !hosu) return setError('동/호수를 선택해 주세요.');
      if (!order_phone) return setError2('휴대폰 번호를 입력해 주세요.');
    }

    let sender = {
      access_type: 2,
      apart_name: consts.newApartList.find((item) => item.idx === apartName)
        .title,
      apart_code: apartName,
      dong: dong,
      hosu: hosu,
      city: city,
      area: area,
      size: order_size,
      phone: order_phone,
      paper: [],
      pad: [],
      desired_price: 0,
    };

    try {
      const res = await createAptnEvent(sender);

      if (res.code === 'OK') {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//wcs.naver.net/wcslog.js';
        script.async = true;
        document.body.appendChild(script);

        const script2 = document.createElement('script');
        script2.innerHTML = `if (typeof(wcs) != "undefined") {
if (!wcs_add) var wcs_add={};
wcs_add["wa"] = "s_1ffba687bfbd";
var _nasa={};
_nasa["cnv"] = wcs.cnv("4","${price}");
wcs_do(_nasa);
}
        `;
        script2.type = 'text/javascript';
        script2.async = true;
        document.body.appendChild(script2);
        setIsOpenCompleteModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="front_layout">
      <Header />
      <StyledDiv>
        {popupStep === 1 && (
          <>
            <PaddingDiv>
              <SytledDiv ref={formRef}>
                <img src={g27} />
                <MainP>
                  상담 예약정보를 입력해 주시면 담당자가 연락 드립니다.
                </MainP>
              </SytledDiv>
              <div className="estimate2_popup_main">
                <div className="estimate2_popup_main_box">
                  <InputSelect
                    name=""
                    label="*아파트명"
                    option={[
                      { idx: '', title: '선택' },
                      ...consts.newApartList,
                    ]}
                    value={apartName}
                    setValue={setApartName}
                    error={error}
                    setError={setError}
                  />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                    }}
                  >
                    <Input
                      className="input_text"
                      type="text"
                      style={{ width: 'calc(50%)' }}
                      placeholder={'동'}
                      name="stx"
                      label={'*동'}
                      value={dong}
                      setValue={setDong}
                      valid={'num'}
                      maxlength={20}
                      error={error}
                      setError={setError}
                    />
                    <Input
                      className="input_text"
                      type="text"
                      style={{ width: 'calc(50%)' }}
                      placeholder={'호수'}
                      name="stx"
                      label={'*호수'}
                      value={hosu}
                      setValue={setHosu}
                      valid={'num'}
                      maxlength={20}
                      error={error}
                      setError={setError}
                    />
                  </div>
                </div>
                <div className="estimate2_popup_main_box">
                  <InputSelect
                    name=""
                    label="*평형"
                    option={[
                      { idx: '', title: '선택' },
                      ...consts.acreageoption,
                    ]}
                    value={order_size}
                    setValue={setOrder_size}
                    error={error}
                    setError={setError}
                  />
                </div>
                <div className="estimate2_popup_main_box">
                  <Input
                    className="input_text"
                    type="text"
                    placeholder={'숫자만 입력해 주세요.'}
                    name="stx"
                    label={'*휴대폰 번호'}
                    value={order_phone.replace(
                      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                      '$1-$2-$3'
                    )}
                    setValue={setOrder_phone}
                    valid={'num'}
                    maxlength={20}
                    error={error2}
                    setError={setError2}
                  />
                  <p>*휴대폰번호는 상담이 완료되면 폐기 처리합니다.</p>
                </div>
                <div className="estimate2_popup_main_box">
                  <input
                    type="checkbox"
                    id="chk3"
                    className="checkbox"
                    onChange={(e) => setChk3(e.target.checked)}
                  />

                  <label className="checkbox_label_agree" htmlFor="chk3">
                    (필수)개인정보 제공 동의
                    <img
                      src={images.right_arrow2}
                      alt="개인정보 동의"
                      onClick={() => showClause(2)}
                    />
                  </label>
                </div>
              </div>
              {isOpenCompleteModal && (
                <AptEventCompleteModal
                  close={() => setIsOpenCompleteModal(false)}
                  content={
                    <ModalContent>
                      <p>견적요청이 완료되었습니다.</p>
                      <p>금일 영업시간 내 연락 드리겠습니다.</p>
                      <p>(영업시간: 09:00 ~ 18:00)</p>
                    </ModalContent>
                  }
                />
              )}
            </PaddingDiv>
          </>
        )}
        {popupStep === 2 ? (
          <>
            <PaddingDiv className="estimate5_popup_main1">
              <img src={images.clipboard_icon} />
              <div className="estimate5_popup_main1_box">
                <p className="estimate5_popup_main1_title1">
                  견적 요청이 완료되었습니다.
                </p>
                <p className="estimate5_popup_main1_title2">
                  금일 영업시간 내 연락드리겠습니다.
                </p>
                <p className="estimate5_popup_main1_title2">
                  (영업시간 : 09:00 ~ 18:00)
                </p>
              </div>
            </PaddingDiv>
          </>
        ) : (
          <></>
        )}

        <div>
          {popupStep === 1 && (
            <FixDiv>
              <button
                type="button"
                className={
                  chk3
                    ? 'estimate_popup_btn2 btn'
                    : 'estimate_popup_btn2 btn disable'
                }
                onClick={submitFunc2}
              >
                신청 완료
              </button>
            </FixDiv>
          )}
        </div>
      </StyledDiv>
      <Footer />
    </div>
  );
}
export const Alert = () => {
  const navigate = useNavigate();
  return (
    <>
      <PaddingDiv className="estimate5_popup_main1">
        <img src={images.clipboard_icon} />
        <div className="estimate5_popup_main1_box">
          <p className="estimate5_popup_main1_title1">
            견적 요청이 완료되었습니다.
          </p>
          <p className="estimate5_popup_main1_title2">
            금일 영업시간 내 연락드리겠습니다.
          </p>
          <p className="estimate5_popup_main1_title2">
            (영업시간 : 09:00 ~ 18:00)
          </p>
        </div>
      </PaddingDiv>
      <FixDiv m={true}>
        <button
          type="button"
          className="estimate_popup_btn3"
          onClick={() => {
            navigate('/');
          }}
        >
          확인
        </button>
      </FixDiv>
    </>
  );
};

export function Add() {
  const navigate = useNavigate();
  return (
    <FixDiv m={true}>
      <button
        type="button"
        className="estimate_popup_btn3"
        onClick={() => {
          navigate('/');
        }}
      >
        확인
      </button>
    </FixDiv>
  );
}

export const PaddingDiv = styled.div`
  padding: 0;

  @media screen and (max-width: 720px) {
    padding: 20px;
  }
`;
export const FixDiv = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  background: #ffffff;
`;
export const StyledDiv = styled.div`
  border-top: 1px solid #e1e1e1;
  height: fit-content;
  margin-top: 85px;
  padding-top: 20px;

  @media screen and (max-width: 720px) {
    margin-top: 60px;
  }
`;

export const MainP = styled.p`
  width: 295px;
  height: 45px;
  font-family: Pretendard;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-top: 17px;
  margin-bottom: 38px;
`;
export const EventDiv = styled.div`
  position: relative;
  //width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const EventImage = styled.img`
  position: absolute;
  object-fit: scale-down;
  display: flex;
`;

export const EventImage1 = styled(EventImage)`
  left: 5%;
  top: 21px;
  z-index: 7;
`;
export const EventImage2 = styled(EventImage)`
  //width: 85vw;
  top: 60px;
  right: 0;
  z-index: 2;
`;
export const EventImage3 = styled(EventImage)`
  z-index: 1;
  top: 82px;
`;
export const EventImage4 = styled(EventImage)`
  z-index: -1;
  width: 100vw;
  height: 619px;
  object-fit: cover;
  top: 271px;
`;
export const EventImage5 = styled(EventImage)`
  top: 700.5px;
  right: 70px;
`;
export const EventImage5_1 = styled(EventImage)`
  top: 630.5px;
`;
export const EventImage6 = styled.img`
  position: absolute;
  width: 200px;
  height: 239px;
  border-radius: 10px;
  background-color: #ffffff;
  //display: flex;
  object-fit: contain;
`;
export const SytledDiv1 = styled.div`
  width: 234px;
  height: 84px;
  background-color: #000000;
  position: absolute;
  top: 411px;
  right: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
`;
export const SytledDiv2 = styled.div`
  position: absolute;
  z-index: 4;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  top: 1102px;
`;
export const SytledDiv3 = styled.div`
  position: absolute;
  top: 529px;
  z-index: 4;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const EventText = styled.p`
  position: relative;
  width: 220px;
  font-size: 21px;
  font-weight: 300;
  line-height: 28px;
  color: #ffffff;
  z-index: 4;
  //left: 13px;
  //text-align: revert;
`;
export const EventText1 = styled.p`
  position: relative;
`;
export const EventText2 = styled.p`
  position: relative;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  z-index: 4;
`;
export const SytledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  white-space: pre-wrap;
`;
export const Section = styled.section`
  position: absolute;
  top: 800px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
`;
export const Section1 = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  z-index: 10;
  bottom: 100px;
`;

export const Title1 = styled.p`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -1.83px;
  white-space: pre-wrap;
  color: #fff;
  background-color: #000;
  position: relative;
  z-index: 4;
  height: 26px;
  top: 90%;
  text-align: center;
`;
export const Title2 = styled.p`
  font-size: 21px;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
`;
export const Span2 = styled.span`
  font-weight: bold;
`;

const ModalContent = styled.div`
  text-align: center;

  & > p {
    color: #606060;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
  }

  @media screen and (max-width: 720px) {
    margin-top: 50px;
  }
`;
