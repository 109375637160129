import images from 'libs/images';
import React from 'react';
import styled from 'styled-components';
import { Ad } from 'types/report';
import { useNavigate } from 'react-router-dom';

const ImageItem = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 62%;
  overflow: hidden;
  border-radius: 4px;

  .image_item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.2s;
  }

  .empty_box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: #4f4f4f;
    display: flex;

    & > p {
      margin: auto;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .badge_item {
    position: absolute;
    top: 12px;
    left: 15px;
    display: inline-flex;
    gap: 5px;

    .badge {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      font-weight: 400;
      border-radius: 2px;
      background: #f5f5f5;
      padding: 3px 5px;
    }
  }
`;

const InfoItem = styled.div`
  margin-top: 20px;

  & > h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }

  & > div {
    display: flex;
    justify-content: center;

    & > img {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      margin: auto 0;
    }

    & > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      margin-left: 4px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 15px;

    & > h3 {
      font-size: 16px;
    }

    & > div {
      margin-top: 10px;
      justify-content: center;

      & > img {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

interface Props {
  item?: Ad;
}

const AdCard = (props: Props) => {
  const { item } = props;

  const navigate = useNavigate();

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = images.partner_logo;
  };

  const onRoute = (url?: string) => {
    if (url) {
      navigate('/report-partner/' + url);
    }
  };

  return (
    <div onClick={() => onRoute(item?.seller_table_id)}>
      <ImageItem>
        {item ? (
          <img
            className="image_item"
            src={item?.area_picture}
            alt="area_picture"
          />
        ) : (
          <div className="empty_box">
            <p>LAYHOME</p>
          </div>
        )}

        {item && (
          <div className={`badge_item`}>
            {Number(item.is_license) === 1 && (
              <p className="badge">견축 면허</p>
            )}
            {Number(item.is_bohum) === 1 && <p className="badge">보증 보험</p>}
          </div>
        )}
      </ImageItem>
      <InfoItem>
        <h3>
          {item ? item?.seller_intro || '-' : '레이홈과 함께할 전문가 모집'}
        </h3>
        <div>
          {item && (
            <img
              src={item.seller_icon}
              onError={handleImgError}
              alt="seller_icon"
            />
          )}
          <p>{item ? item.seller_biz_name : '수익 창출을 위한 첫걸음!'}</p>
        </div>
      </InfoItem>
    </div>
  );
};

export default AdCard;
