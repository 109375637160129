import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Ad } from 'types/report';
import images from '../../libs/images';
import { useNavigate } from 'react-router-dom';
import { Swiper as SwiperType } from 'swiper';
import prev_arrow_circle from '../Home/images/prev_arrow_circle.png';
import next_arrow_circle from '../Home/images/next_arrow_circle.png';

const Container = styled.div`
  background-color: #dbdbdb;
`;

const Box = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;
  padding: 10px 0 30px 0;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    padding: 15px 0 20px 0;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;

  & > h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 77px;

    & > b {
      color: #fa5500;
      font-size: 28px;
      font-weight: 700;
    }
  }

  & > p {
    margin: auto 0 auto 15px;
    font-size: 14px;
    color: #7e7e7e;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px 12px;
  }

  @media screen and (max-width: 767px) {
    & > h3 {
      font-size: 17px;
      line-height: normal;

      & > b {
        font-size: 17px;
        color: #000000;
      }
    }

    & > p {
      font-size: 7px;
      padding: 2px 7px;
      margin-left: 7px;
    }
  }
`;

const Content = styled.div`
  margin-top: 27px;
  position: relative;

  &:hover {
    .arrow_wrap {
      display: block;
    }
  }

  .swiper {
    .swiper-slide {
      cursor: pointer;

      &:hover {
        .image_item {
          transform: scale(1.1);
          transition: transform 0.2s;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 15px;
    width: calc(100% + 30px);

    &:hover {
      .arrow_wrap {
        display: none;
      }
    }
  }
`;

const ImageItem = styled.div<{ img?: string }>`
  width: 100%;
  position: relative;
  padding-bottom: 62%;
  overflow: hidden;
  border-radius: 4px;

  .image_item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.2s;
  }

  .empty_box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: #4f4f4f;
    display: flex;

    & > p {
      margin: auto;
      color: #ffffff;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .badge_item {
    position: absolute;
    top: 12px;
    left: 15px;
    display: inline-flex;
    gap: 5px;

    .badge {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      font-weight: 400;
      border-radius: 2px;
      background: #f5f5f5;
      padding: 3px 5px;
    }
  }
`;

const InfoItem = styled.div`
  margin-top: 20px;

  & > h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }

  & > div {
    display: flex;
    justify-content: center;

    & > img {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      margin: auto 0;
    }

    & > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      margin-left: 4px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 15px;
    text-align: center;

    & > h3 {
      font-size: 16px;
    }

    & > div {
      margin-top: 10px;
      justify-content: center;

      & > img {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

const ArrowWrap = styled.div`
  display: none;

  & > button {
    background: none;
    position: absolute;
    top: 30%;
    z-index: 100;

    &:disabled {
      display: none;
    }

    & > img {
      width: 65px;
      height: 65px;
    }

    &.prev_arrow {
      left: -30px;
    }

    &.next_arrow {
      right: -30px;
    }
  }

  @media screen and (max-width: 1110px) {
    & > button {
      top: 25%;
    }
  }

  @media screen and (max-width: 910px) {
    & > button {
      top: 20%;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

interface Props {
  ads: Ad[];
}

const AdComponent = (props: Props) => {
  const { ads } = props;
  const navigate = useNavigate();

  const [swiper, setSwiper] = useState<SwiperType | null>(null); // swiper 인스턴스를 저장할 상태

  const adList = useMemo(() => {
    ads.sort(() => Math.random() - 0.5);
    if (ads.length > 21) {
      return ads.slice(0, 21);
    } else if (ads.length < 3) {
      return Array.from({ length: 3 }, (_, index) => {
        if (index < ads.length) {
          return ads[index];
        } else {
          return undefined;
        }
      });
    } else {
      return ads;
    }
  }, [ads]);

  const onRoute = (url?: string) => {
    if (url) {
      navigate('/report-partner/' + url);
    }
  };

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = images.partner_logo;
  };

  const handleSwiper = useCallback((swiperInstance: SwiperType) => {
    setSwiper(swiperInstance);
  }, []);

  return (
    <Container>
      <Box>
        <Title>
          <h3>
            <b>우리 동네</b> 전문가
          </h3>
          <p>AD</p>
        </Title>
        <Content>
          <Swiper
            slidesPerView={1.3}
            spaceBetween={10}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 35,
                centeredSlides: false,
              },
            }}
            navigation={{
              prevEl: '.prev_arrow',
              nextEl: '.next_arrow',
            }}
            onSwiper={handleSwiper} // onSwiper 콜백을 설정하여 swiper 인스턴스를 저장
          >
            {adList.map((item, i) => (
              <SwiperSlide
                key={i}
                onClick={() => onRoute(item?.seller_table_id)}
              >
                <ImageItem>
                  {item ? (
                    <img
                      className="image_item"
                      src={item?.area_picture}
                      alt="area_picture"
                    />
                  ) : (
                    <div className="empty_box">
                      <p>LAYHOME</p>
                    </div>
                  )}

                  {item && (
                    <div className={`badge_item`}>
                      {Number(item.is_license) === 1 && (
                        <p className="badge">견축 면허</p>
                      )}
                      {Number(item.is_bohum) === 1 && (
                        <p className="badge">보증 보험</p>
                      )}
                    </div>
                  )}
                </ImageItem>
                <InfoItem>
                  <h3>
                    {item
                      ? item?.seller_intro || '-'
                      : '레이홈과 함께할 전문가 모집'}
                  </h3>
                  <div>
                    {item && (
                      <img
                        src={item.seller_icon}
                        onError={handleImgError}
                        alt="seller_icon"
                      />
                    )}
                    <p>
                      {item ? item.seller_biz_name : '수익 창출을 위한 첫걸음!'}
                    </p>
                  </div>
                </InfoItem>
              </SwiperSlide>
            ))}
          </Swiper>
          <ArrowWrap className="arrow_wrap">
            <button className="prev_arrow" disabled={swiper?.isBeginning}>
              <img src={prev_arrow_circle} alt="left_arrow" />
            </button>
            <button className="next_arrow" disabled={swiper?.isEnd}>
              <img src={next_arrow_circle} alt="right_arrow" />
            </button>
          </ArrowWrap>
        </Content>
      </Box>
    </Container>
  );
};

export default AdComponent;
