import { useQuery } from 'react-query';
import * as QueryKeys from '../../constants/queryKeys';
import {
  getHomeData,
  getBannerTarget,
  getAptnPhotos,
  getCityList,
  getAreaList,
  getTerms,
} from '../../apis/Common';

const defaultOptions = { staleTime: 60 * 1000 };

const useCommonQueries = () => {
  const getHomeQuery = () => {
    return useQuery(QueryKeys.HOME_DATA, getHomeData, {
      ...defaultOptions,
    });
  };

  const getBannerTargetQuery = (params: {
    notice_table_id: string | number;
  }) => {
    return useQuery(QueryKeys.BANNER_TARGET, () => getBannerTarget(params), {
      ...defaultOptions,
    });
  };

  const getAptnPhotosQuery = () => {
    return useQuery(QueryKeys.APTN_PHOTO_DATA, getAptnPhotos, {
      ...defaultOptions,
    });
  };

  const getCityListQuery = () => {
    return useQuery(QueryKeys.CITY_LIST, getCityList, {
      ...defaultOptions,
    });
  };

  const getAreaListQuery = (params: { area_id: string }) => {
    return useQuery(
      [QueryKeys.AREA_LIST, params.area_id],
      () => getAreaList(params),
      {
        ...defaultOptions,
      }
    );
  };

  const getTermsQuery = (params: { tid: string }) => {
    return useQuery(
      [QueryKeys.TERMS_DATA, params.tid],
      () => getTerms(params),
      {
        ...defaultOptions,
      }
    );
  };

  return {
    getHomeQuery,
    getBannerTargetQuery,
    getAptnPhotosQuery,
    getCityListQuery,
    getAreaListQuery,
    getTermsQuery,
  };
};

export default useCommonQueries;
