import React from 'react';
import Modal from 'components/Common/Modal/ModalPortal';
import styled from 'styled-components';
import images from 'libs/images';
import signup_confirm from './images/signup_confirm.png';
import useVh from 'hooks/useVh';

const Container = styled.div`
  width: 503px;
  margin: auto;
  background-color: #ffffff;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

const Content = styled.div`
  padding: 15px 0 0 0;
  text-align: center;

  & > img {
    width: 158px;
    height: 195px;
  }

  & > h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    margin-top: 18px;
  }

  & > p {
    margin-top: 21px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }

  @media screen and (max-width: 767px) {
    height: calc(var(--vh, 1vh) * 100 - 114px);

    & > img {
      margin-top: 87px;
      width: 108px;
      height: 133px;
    }

    & > h3 {
      margin-top: 17px;
      font-size: 16px;
      line-height: 30px;
    }

    & > p {
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

const Bottom = styled.div`
  margin: 175px 70px 45px 70px;

  & > button {
    width: 100%;
    background-color: #000;
    color: #fff;
    height: 52px;
    font-size: 18px;
    font-weight: 700;
  }

  @media screen and (max-width: 767px) {
    margin: 0;

    & > button {
      height: 50px;
      font-size: 15px;
    }
  }
`;

interface Props {
  signupConfirmCallback: () => void;
}

const SignupConfirmModal = (props: Props) => {
  const { signupConfirmCallback } = props;

  useVh();

  return (
    <Modal>
      <Container>
        <Header>
          <button onClick={signupConfirmCallback}>
            <img src={images.exit} alt="exit" />
          </button>
        </Header>
        <Content>
          <img src={signup_confirm} alt="signup_confirm" />
          <h3>회원가입 완료</h3>
          <p>
            레이홈에 가입해 주셔서 감사합니다!
            <br />
            LAYHOME에서 나만의 테마를 담은
            <br />
            집을 만들어드립니다.
          </p>
        </Content>
        <Bottom>
          <button onClick={signupConfirmCallback}>닫기</button>
        </Bottom>
      </Container>
    </Modal>
  );
};

export default SignupConfirmModal;
