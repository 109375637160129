import React from 'react';
import { numFormat } from '../../../utils/utils';
import images from '../../../libs/images';
import styled from 'styled-components';
import arrow_right_half from '../../../assets/images/arrow_right_half.svg';

const CardWrap = styled.li`
  width: 153px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  height: fit-content;
  border: ${(props) => props.isActive && '1px solid #bfbfbf'};

  @media screen and (max-width: 767px) {
    margin: 0;
  }
`;

const CardImage = styled.img`
  width: 140px;
  height: 140px;
  object-fit: cover;
  cursor: pointer;
`;

const CardTitle = styled.p`
  margin: 5px 0 3px 0;
  font-size: 14px;
  line-height: 1.57;
  height: 44px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
`;

const CardBottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  & > p {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: #000;
  }

  & > img {
    object-fit: contain;
  }
`;

export default function Receipt_Right(props) {
  const { rows, listClickfunc, activeIndexId } = props;

  if (!rows) return null;

  return (
    <CardWrap isActive={rows.zs_table_id === activeIndexId}>
      <CardImage
        src={
          rows.style_imgs.filter((item) => item.img_url).length > 0
            ? rows.style_imgs.filter((item) => item.img_url)[0].img_url
            : images.no_img
        }
        onClick={() => {
          listClickfunc(rows.zs_table_id);
        }}
      />

      <CardTitle>{rows.model_name}</CardTitle>
      <CardBottomContent>
        <p>
          {Number(rows.price) > 0
            ? `${numFormat(rows.price)}만 원`
            : '별도문의'}
        </p>
        {!!rows.url && Number(rows.price) > 0 && (
          <img
            src={arrow_right_half}
            onClick={() =>
              window.open(`/theme/outbound?to=${rows.url}`, '_blank')
            }
          />
        )}
      </CardBottomContent>
    </CardWrap>
  );
}
