// 로그인 상태일 때 컨텐츠를 보여주도록 유도하는 컴포넌트

import React from 'react';
import styled from 'styled-components';
import login_eyes from './images/login_eyes.svg';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #fff;
  /* background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.95)
    )
    0% 0% / contain; */
  display: flex;

  & > div {
    margin: auto;
    width: 100%;
    text-align: center;
    .icon_wrap {
      & > svg {
        width: 75px;
        height: 51px;
      }
    }

    & > p {
      margin-top: 20px;
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
    }

    & > button {
      width: calc(100% - 120px);
      height: 50px;
      margin: 25px 60px 0 60px;
      border: 1px solid #000;
      color: #000000;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 400;
    }
  }
`;

const InduceLoginWrap = () => {
  const loginHandler = () => {
    document.getElementById('login_btn')?.click();
  };

  return (
    <Container>
      <div>
        <div className="icon_wrap">
          <img src={login_eyes} alt="login_eyes" />
        </div>
        <p>
          영수증 세부내역을
          <br /> 확인 하시려면
          <br /> 로그인이 필요합니다.
        </p>
        <button onClick={loginHandler}>로그인</button>
      </div>
    </Container>
  );
};

export default InduceLoginWrap;
