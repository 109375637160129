import React from 'react';
import images from '../../../libs/images';
import styled from 'styled-components';

const ModelName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  margin-top: 8px;
  font-family: Pretendard;
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 24px;
    margin-top: 11px;
  }
`;

const ReceiptButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: #000000;
  width: 118px;
  height: 40px;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.43;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 21px;
    margin-right: 6px;
  }
`;

const ItemInfo = styled.div`
  position: absolute;
  z-index: 1;
  left: 30px;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .tag {
    width: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    & > span {
      width: auto;
      border-radius: 20px;
      background: #fa5500;
      padding: 10px 14px 10px 15px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      text-align: center;
      color: #ffffff;
    }
  }

  .caution {
    margin-top: 10px;
    font-family: Pretendard;
    font-size: 14px;
    line-height: 1.57;
    color: #ffffff;
    word-break: keep-all;
  }

  @media (max-width: 767px) {
    left: 20px;
    bottom: 20px;
  }
`;

const Image = styled.img`
  height: 703px;
  object-fit: cover;
  width: 100%;

  @media screen and (max-width: 767px) {
    height: 300px;
  }
`;

const GradientWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 220px;
  background: linear-gradient(
    to top,
    rgba(7, 7, 7, 0.788),
    rgba(53, 52, 52, 0)
  );
  background-size: contain;

  @media screen and (max-width: 767px) {
    height: 130px;
  }
`;

export default function Receipt_Left(props) {
  const { row, receipt } = props;

  /* 대체 이미지 */
  const handleImgError = (e) => {
    e.target.src = images.no_img;
  };
  return (
    <>
      {
        <div>
          <ReceiptButton onClick={() => receipt()}>
            <img src={images.receipt} />
            영수증 보기
          </ReceiptButton>
          <Image src={row.img_url} onError={handleImgError} />
          <ItemInfo>
            <p className="tag">
              {row.style_tag_text.map((xx, ii) => {
                return <span key={ii}>{xx}</span>;
              })}
            </p>
            <ModelName>{row.model_name}</ModelName>
            <p className="caution">
              이 테마에 스타일링된 제품 정보입니다.
              <br />
              구매를 원치 않으시면 견적받기에서 뺄 수 있습니다.
            </p>
          </ItemInfo>
          <GradientWrap />
        </div>
      }
    </>
  );
}
