import { useQuery } from 'react-query';
import * as QueryKeys from '../../constants/queryKeys';
import { getTheme, getThemeList } from '../../apis/Theme';

const useThemeQueries = () => {
  const getThemeListQuery = () => {
    return useQuery(QueryKeys.THEME_LIST, getThemeList, {
      staleTime: 60 * 5 * 1000,
    });
  };

  const getThemeDetailQuery = (params: { theme_table_id: string }) => {
    return useQuery(
      [QueryKeys.THEME_DETAIL, params.theme_table_id],
      () => getTheme(params),
      {
        staleTime: 60 * 5 * 1000,
      }
    );
  };

  return {
    getThemeListQuery,
    getThemeDetailQuery,
  };
};

export default useThemeQueries;
