/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'components/Common/Modal/ModalPortal';
import styled from 'styled-components';
import images from 'libs/images';
import consts from 'libs/consts';
import Receipt_Left from './LeftBox';
import Receipt_Right from './RightBoxCard';
import { ThemeList } from '../../../types/theme';
import { Swiper, SwiperSlide } from 'swiper/react';
import left_slide_btn from '../../../assets/images/slide_btn_left.svg';
import right_slide_btn from '../../../assets/images/slide_btn_right.svg';
import { Swiper as SwiperType } from 'swiper';
import useVh from 'hooks/useVh';

const Container = styled.div`
  margin: auto;
  background-color: #ffffff;

  @media screen and (max-width: 1003px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    height: 100%;
  }
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

const PopupFilter = styled.div`
  display: flex;
  height: 63px;
  padding-left: 20px;
  background: #fafafa;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > label {
    & > input {
      display: none;
    }

    & > span {
      background-color: #fafafa;
      border: 1px solid #f2f2f2;
      border-radius: 20px;
      color: #909090;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      margin-right: 6px;
      padding: 10px 12.5px 10px 13.5px;
      text-align: center;
      transition: border 0.3s, background-color 0.3s, color 0.3s;
    }

    input:checked ~ span {
      color: #fff;
      background-color: #fa5500;
      border: 1px solid #fa5500;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 767px) {
    justify-content: space-around;
  }
`;

const Content = styled.div`
  display: flex;

  .left_item {
    width: 614px;
    position: relative;

    .swiper-pagination {
      display: none;
    }

    .front_swiper_prev {
      background: url(${left_slide_btn}) no-repeat 50%;
      height: 30px;
      left: 30px;
      position: absolute;
      top: 50%;
      width: 30px;
      z-index: 9999;
    }

    .front_swiper_next {
      background: url(${right_slide_btn}) no-repeat center center;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      right: 30px;
      z-index: 9999;
    }
  }

  .right_item {
    padding: 20px;
    height: 703px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    & > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px 0;
      width: 326px;
    }
  }

  @media screen and (max-width: 767px) {
    overflow-y: scroll;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    .left_item {
      width: 100%;

      .front_swiper_prev {
        left: 10px;
        top: 30%;
      }

      .front_swiper_next {
        top: 30%;
        right: 10px;
      }
    }

    .right_item {
      width: 100%;
      height: calc(var(--vh, 1vh) * 100 - 427px);
      overflow-y: initial;

      & > ul {
        justify-content: flex-start;
        width: 100%;
        gap: 20px;
      }
    }
  }
`;

interface Props {
  close: () => void;
  type: string | number;
  row: ThemeList[];
  list1: ThemeList[];
  receipt: () => void;
}

type LeftList = {
  cnt: string;
  img_url: string;

  model_name: string;
  price: string;
  si_table_id: string;
  style_tag_text: string[];
  style_tags: string[];
  type: string;
  url: string;
  zone_type: string;
  zs_table_id: string;
};

const HomeStyleModal = (props: Props) => {
  const { close, type, row, list1, receipt } = props;

  useVh();

  const [defaultType, setDefaultType] = useState(type); // 위에 타입 정하는부분
  const [rightList, setRightList] = useState(row); // 우측 리스트
  const [leftList, setLeftList] = useState<LeftList[]>([]); // 좌측 리스트
  const [swiper, setSwiper] = useState<SwiperType | null>(null); // swiper 인스턴스를 저장할 상태
  const [activeIndexId, setActiveIndexId] = useState<string>('');
  const [filterList, setFilterList] = useState<
    { idx: number; title: string }[]
  >([]);

  useEffect(() => {
    const mapList = list1.map((item) => item.zone_type);
    const uniqZoneType = mapList.filter(
      (item, index) => mapList.indexOf(item) === index
    );

    const lists = consts.homeStylePartsMerge.filter((option) =>
      uniqZoneType.includes(option.type_img)
    );

    const filterList = [{ idx: 100, title: '전체' }, ...lists];

    if (!filterList.find((item) => item.idx === type)) {
      setDefaultType(100);
    }
    setFilterList(filterList);
  }, [list1]);

  useEffect(() => {
    if (defaultType === 100) {
      setRightList(list1);
    } else {
      const findOption:
        | {
            idx: number;
            title: string;
            active_img: string;
            default_img: string;
            type_img?: string;
          }
        | {
            idx: number;
            title: string;
            type_img?: string;
          }
        | undefined = filterList.find((item) => item.idx === defaultType);
      setRightList(
        list1.filter((item) => item.zone_type === findOption?.type_img)
      );
    }
  }, [defaultType, filterList]);

  useEffect(() => {
    if (rightList.length > 0) {
      const leftData: any = rightList
        .map((item) => {
          return item.style_imgs.map((style) => {
            const data: any = {
              ...style,
              ...item,
            };

            delete data.style_imgs;

            return data;
          });
        })
        .flatMap((item) => item);

      setLeftList(leftData);
      setActiveIndexId(leftData[0].zs_table_id);
    }
  }, [rightList]);

  useEffect(() => {
    const page = document.getElementsByClassName(
      'swiper-pagination-bullet'
    )[0] as HTMLElement | null;

    if (page) {
      page.click();
    }
  }, [leftList]);

  const listClickfunc = (id: string) => {
    const findIndex = leftList.findIndex((item) => item.zs_table_id === id);

    if (swiper) {
      swiper.slideTo(findIndex);
    }
  };

  const handleSwiper = useCallback((swiperInstance: SwiperType) => {
    setSwiper(swiperInstance);
  }, []);

  return (
    <Modal>
      <Container>
        <Header>
          <h3>홈스타일링 제품보기</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit" />
          </button>
        </Header>
        <div className="popup_list">
          <div className="home_detail_popup">
            <PopupFilter>
              {filterList.map((x, i) => {
                return (
                  <label key={i} htmlFor={'chk' + i}>
                    <input
                      type="radio"
                      id={'chk' + i}
                      value={x.idx}
                      name="btn"
                      defaultChecked={defaultType === x.idx}
                      onChange={() => setDefaultType(x.idx)}
                    />
                    <span>{x.title}</span>
                  </label>
                );
              })}
            </PopupFilter>
            <Content className="home_detail_popup_box">
              <div className="left_item">
                <Swiper
                  className="home_detail_popup_left_slide"
                  navigation={{
                    prevEl: '.front_swiper_prev',
                    nextEl: '.front_swiper_next',
                  }}
                  breakpoints={{
                    375: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  onSwiper={handleSwiper} // onSwiper 콜백을 설정하여 swiper 인스턴스를 저장
                  onSlideChange={(slide) =>
                    setActiveIndexId(leftList[slide.activeIndex].zs_table_id)
                  }
                >
                  {leftList.length > 0 &&
                    leftList.map((left) => {
                      if (left.img_url) {
                        return (
                          <SwiperSlide key={left.si_table_id}>
                            <Receipt_Left row={left} receipt={receipt} />
                          </SwiperSlide>
                        );
                      }
                    })}
                </Swiper>
                <button className="front_swiper_prev" type="button" />
                <button className="front_swiper_next" type="button" />
              </div>
              <div className="right_item">
                <ul>
                  {rightList.length > 0 &&
                    rightList.map((x, i) => {
                      return (
                        <Receipt_Right
                          key={i}
                          rows={x}
                          listClickfunc={listClickfunc}
                          activeIndexId={activeIndexId}
                        />
                      );
                    })}
                </ul>
              </div>
            </Content>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default HomeStyleModal;
