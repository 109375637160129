import React, { useMemo, useState } from 'react';
import Modal from 'components/Common/Modal/ModalPortal';
import styled from 'styled-components';
import { Popup } from '../../types/home';
import { getSaveDate } from 'utils/utils';
import { Swiper, SwiperSlide } from 'swiper/react';

const Container = styled.div`
  margin: auto;
  background-color: #ffffff;

  @media screen and (max-width: 720px) {
    width: 100%;
    margin: auto 0 0 0;
  }
`;

const ImageContent = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

interface Props {
  close: () => void;
  data: Popup[];
}

const TodayModal = (props: Props) => {
  const { close, data } = props;

  const [sw1, setSw1] = useState(0);

  const getcloseDate = localStorage.getItem('closeDate');
  const savedate = getSaveDate();

  const popupList = useMemo(() => {
    const seqData = data.sort((a, b): number => {
      if (Number(a.display_seq) > Number(b.display_seq)) {
        return 1;
      } else if (Number(a.display_seq) === Number(b.display_seq)) {
        return 0;
      } else if (Number(a.display_seq) < Number(b.display_seq)) {
        return -1;
      } else {
        return 0;
      }
    });

    return seqData;
  }, [data]);

  const onRoute = (url: string) => {
    const protocols = ['http', 'https'];

    const isProtocol = !!protocols.find((item) => url.includes(item));

    if (isProtocol) {
      window.location.href = url;
    } else {
      window.location.href = `https://${url}`;
    }
  };

  return getcloseDate !== savedate && popupList.length > 0 ? (
    <Modal>
      <Container>
        <div className="today_popup_content">
          <Swiper
            className="today_popup_slide"
            spaceBetween={0}
            slidesPerView={1}
            autoplay={popupList.length > 1 ? { delay: 5000 } : false}
            loop={popupList.length > 1}
            pagination={{
              el: '.today_popup_box .front_swiper_pagination',
            }}
            navigation={{
              prevEl: '.today_popup_box .front_swiper_prev',
              nextEl: '.today_popup_box .front_swiper_next',
            }}
            onSwiper={() => setSw1(1)}
            onSlideChange={(swiper) => {
              setSw1(swiper.realIndex + 1);
            }}
          >
            {popupList.map((x, i) => {
              if (i + 1 > 5) {
                return;
              }
              return (
                <SwiperSlide key={i} onClick={() => onRoute(x.pop_url)}>
                  <ImageContent src={x.pop_thumbnail} alt="pop_thumbnail" />
                </SwiperSlide>
              );
            })}
          </Swiper>
          {popupList.length > 1 && (
            <div
              className="front_sw_pagebox_white today_popup_box"
              id="today_popup_box"
            >
              <button className="front_swiper_prev" type="button" />
              {sw1 && (
                <p className="front_swiper_fraction">
                  {sw1}/{popupList.length > 5 ? 5 : popupList.length}
                </p>
              )}
              <button className="front_swiper_next" type="button" />
            </div>
          )}

          <div className="today_popup_bottom">
            <button
              type="button"
              className=""
              onClick={() => {
                localStorage.setItem('closeDate', savedate);
                close();
              }}
            >
              오늘 하루 보지않기
            </button>
            <button type="button" className="" onClick={close}>
              닫기
            </button>
          </div>
        </div>
      </Container>
    </Modal>
  ) : (
    <></>
  );
};

export default TodayModal;
