import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import useMonthly from './MonthlySection.hook';
import { Swiper, SwiperSlide } from 'swiper/react';
import plus_icon from '../../assets/images/plus_black.svg';
import left_aroow2 from '../../assets/images/left_arrow2.svg';
import right_arrow2 from '../../assets/images/right_arrow2.svg';
import badge_calendar from '../../assets/images/life-construction/badge_calendar.png';
import badge_thumbs_up from '../../assets/images/life-construction/badge_thumbs_up.png';
import badge_handshake from '../../assets/images/life-construction/badge_handshake.png';
import monthly_item_finish_tool from '../../assets/images/life-construction/monthly_item_finish_tool.jpg';

import useLifeQueries from '../../hooks/queries/life';

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

export const Content = styled.div`
  width: 55%;
  min-width: 650px;
  margin: 52px auto 0 auto;
  position: relative;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 32px;
    min-width: 0;
  }
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;

  .top-left-content {
    margin-top: 16px;
    & > h2 {
      font-size: 38px;
      font-weight: bold;
      letter-spacing: -1.67px;
      line-height: 1.5;
    }

    & > p {
      margin: 12px 0 2px 0;
      font-size: 18px;
      font-weight: 300;
    }

    & > span {
      color: #909090;
      font-size: 14px;
    }
  }

  .top-right-content {
    & > div {
      width: 146px;
      height: 146px;
      border-radius: 50%;
      background-color: #000000;
      display: flex;
      flex-wrap: wrap;
      text-align: center;

      & > div {
        margin: auto;

        & > p {
          width: 100%;
          color: #ffffff;
          font-size: 38px;
          font-weight: 300;
          height: 36px;
          margin-bottom: 10px;
        }

        & > span {
          width: 100%;
          font-size: 20px;
          color: #a7a7a7;
          font-weight: 600;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin: 0 auto;

    .top-left-content {
      & > h2 {
        font-size: 24px;
      }

      & > p {
        margin: 8px 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
      }

      & > span {
        font-size: 12px;
        line-height: 1.33;
      }
    }

    .top-right-content {
      & > div {
        width: 112px;
        height: 112px;

        & > div {
          margin-top: 26px;

          & > p {
            font-size: 24px;
            margin-bottom: 0;
          }

          & > span {
            font-size: 14px;
          }
        }
      }
    }
  }
`;

const SlideContent = styled.div`
  margin-top: 36px;

  @media screen and (max-width: 767px) {
    margin-left: 20px;
  }
`;

const SlideImageWrap = styled.div<{
  url: string;
}>`
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .slide-image {
    background: url(${(props) => props.url}) no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .slide-image-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;

    & > h3 {
      margin: auto;
      text-align: center;
      line-height: 30px;
      color: #ffffff;
      font-size: 20px;
    }

    &.bg {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  @media screen and (max-width: 767px) {
    .slide-image-cover {
      & > p {
        font-size: 24px;
        line-height: 25px;
      }
    }
  }
`;

const SlideTitleWrap = styled.div`
  margin-top: 12px;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 13px;

  & > h3 {
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (max-width: 767px) {
    & > h3 {
      font-size: 16px;
    }
  }
`;

const SlideApplicationWrap = styled.div`
  margin-top: 14px;

  & > button {
    width: 121px;
    height: 42px;
    border: 1px solid #000000;
    border-radius: 23px;
    cursor: pointer;
    display: flex;
    margin: 0 auto;

    & > div {
      display: flex;
      margin: auto 0;
      width: 100%;

      & > p {
        font-size: 14px;
        font-weight: bold;
        margin-left: 20px;
        color: #000000;
      }

      & > img {
        margin: 0 16px 0 auto;
      }
    }
  }

  @media screen and (max-width: 767px) {
    & > button {
      width: 90px;
      height: 32px;

      & > div {
        width: auto;
        margin: auto;

        & > p {
          font-size: 14px;
          margin: auto 0;
        }

        & > img {
          width: 18px;
          height: 18px;
          margin: auto 0;
        }
      }
    }
  }
`;

const SlideNavigationWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 30%;
  z-index: 100;

  & > button {
    background: none;
  }

  .front_swiper_prev {
    position: absolute;
    left: -80px;
  }

  .front_swiper_next {
    position: absolute;
    right: -80px;
  }

  @media screen and (max-width: 1531px) {
    bottom: 35%;
  }

  @media screen and (max-width: 800px) {
    .front_swiper_prev {
      left: -60px;
    }

    .front_swiper_next {
      right: -60px;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const BadgeWrap = styled.div`
  width: 100%;
  margin-top: 140px;
  display: flex;
  justify-content: center;
  padding-bottom: 140px;
  gap: 70px;

  .badge {
    width: 208px;
    height: 208px;
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;

    &.date {
      background-color: rgba(6, 110, 77, 0.1);

      & > div > p {
        color: #066e4d;
      }
    }

    &.rate {
      background-color: rgba(101, 80, 61, 0.1);

      & > div > p {
        color: #65503d;
      }
    }

    &.brand {
      background-color: rgba(57, 57, 98, 0.1);

      & > div > p {
        color: #393962;
      }
    }

    & > div {
      margin: auto;
      text-align: center;

      & > img {
        width: 52px;
        height: 52px;
      }

      & > p {
        font-size: 22px;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }

  @media screen and (max-width: 880px) {
    .badge {
      width: 156px;
      height: 156px;
    }
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 60px;
    padding-bottom: 60px;
    gap: 0;

    .badge {
      margin-right: 0;
      width: 104px;
      height: 104px;

      & > div {
        & > img {
          width: 32px;
          height: 32px;
        }

        & > p {
          font-size: 14px;
        }
      }
    }
  }
`;

const MonthlySection = () => {
  const { dayRef, dateRef } = useMonthly();

  const { getMonthlyListQuery } = useLifeQueries();
  const { data } = getMonthlyListQuery();
  const navigate = useNavigate();

  const onRoute = (url: string) => {
    navigate(url);
  };

  return (
    <Container>
      <Content>
        <TopContent>
          <div className="top-left-content">
            <h2>이달의 공구</h2>
            <p>안심 정찰제보다 할인된 패키지 가격으로 만나보세요.</p>
            <span>* 고객님 집 평형에 따라 견적이 달라질 수 있습니다.</span>
          </div>
          <div className="top-right-content">
            <div>
              <div>
                <p ref={dayRef} />
                <span ref={dateRef} />
              </div>
            </div>
          </div>
        </TopContent>
        <SlideContent>
          <Swiper
            slidesPerView={1.4}
            spaceBetween={16}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 34,
              },
            }}
            navigation={{
              prevEl: '.front_swiper_prev',
              nextEl: '.front_swiper_next',
            }}
          >
            {data?.life_monthly_list.map((item) =>
              item.isApplication === '1' ? (
                <SwiperSlide key={item.monthly_table_id}>
                  <Link to={item.url}>
                    <SlideImageWrap url={item.image}>
                      <div className="slide-image" />
                      <div className="slide-image-cover" />
                    </SlideImageWrap>
                    <SlideTitleWrap>
                      <h3>{`${item.title}`}</h3>
                    </SlideTitleWrap>
                    <SlideApplicationWrap>
                      <button onClick={() => onRoute(item.url)}>
                        <div>
                          <p>신청</p>
                          <img src={plus_icon} alt="plus_icon" />
                        </div>
                      </button>
                    </SlideApplicationWrap>
                  </Link>
                </SwiperSlide>
              ) : (
                <SwiperSlide key={item.monthly_table_id}>
                  <SlideImageWrap url={monthly_item_finish_tool}>
                    <div className="slide-image" />
                  </SlideImageWrap>
                  <SlideTitleWrap>
                    <h3>공구 준비중</h3>
                  </SlideTitleWrap>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </SlideContent>
        {data && data.total_cnt > 3 && (
          <SlideNavigationWrap>
            <button className="front_swiper_prev" type="button">
              <img src={left_aroow2} alt="left_aroow2" />
            </button>
            <button className="front_swiper_next" type="button">
              <img src={right_arrow2} alt="right_arrow2" />
            </button>
          </SlideNavigationWrap>
        )}
      </Content>
      <BadgeWrap>
        <div className="badge date">
          <div>
            <img src={badge_calendar} alt="badge_calendar" />
            <p>기간한정</p>
          </div>
        </div>
        <div className="badge rate">
          <div>
            <img src={badge_thumbs_up} alt="badge_thumbs_up" />
            <p>최대 할인률</p>
          </div>
        </div>
        <div className="badge brand">
          <div>
            <img src={badge_handshake} alt="badge_handshake" />
            <p>브랜드 시공</p>
          </div>
        </div>
      </BadgeWrap>
    </Container>
  );
};

export default MonthlySection;
