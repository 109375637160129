import axios from '../utils/axios';
import { Home, Banner, ApPhotos, Area, AreaDetail } from '../types/home';

export const getHomeData = async (): Promise<Home> => {
  const res = await axios.post('/UserHome/MyHome');

  return res.data;
};

interface OrderParams {
  order_desired_price?: string;
  order_estimate_type: number;
  order_fk_id?: number | string;
  order_size: string;
  order_area_1st: string;
  order_area_2nd: string;
  order_desired_period: string;
  order_demolition_yn?: string;
  order_phone: string;
  order_msg?: string;
  imgs?: string[];
  style_buy?: number | string;
  order_user_name: string;
}

export const createOrder = async (params: OrderParams) => {
  const res = await axios.post('/Order/CreateOrder', params);

  return res.data;
};

interface CreateEventOrderParams {
  user_name: string;
  user_phone: string;
  size: string;
  area_id: string;
  area_detail_id: string;
  order_desired_period: string;
  request_desc: string;
  seller_table_id: string;
  seller_biz_name: string;
  evt_fk_id: string;
  url_table_id: string;
}

export const createEventOrder = async (params: CreateEventOrderParams) => {
  const res = await axios.post('/Order/CreateEventOrder', params);

  return res.data;
};

export const getBannerTarget = async (params: {
  notice_table_id: string | number;
}): Promise<{
  code: string;
  banner_list: Banner[];
}> => {
  const res = await axios.post('/UserHome/BannerTarget', params);

  return res.data;
};

// 한샘 기획전 신청 api
interface AptnSaveParams {
  access_type: number;
  apart_name?: string;
  apart_code?: string;
  dong?: string;
  hosu?: string;
  city?: string;
  area?: string;
  size: string;
  phone: string;
  paper?: string[];
  pad?: string[];
  desired_price?: number | string;
  demolition_yn?: string;
}

export const createAptnEvent = async (params: AptnSaveParams) => {
  const res = await axios.post('/EventAp/ApSave', params);

  return res.data;
};

export const getAptnPhotos = async (): Promise<{
  code: string;
  pad1: ApPhotos[];
  pad2: ApPhotos[];
  paper: ApPhotos[];
}> => {
  const res = await axios.post('/EventAp/ApPhotos');

  return res.data;
};

export const getCityList = async (): Promise<{
  area: Area[];
}> => {
  const res = await axios.get('/AreaInfo/GetAreaOne');

  return res.data;
};

export const getAreaList = async (params: {
  area_id: string;
}): Promise<{
  area: AreaDetail[];
}> => {
  const res = await axios.post('/AreaInfo/GetAreaTwo', params);

  return res.data;
};

export const getTerms = async (params: { tid: string }) => {
  const res = await axios.post('/Login/GetTerms', params);

  return res.data;
};
