import React from 'react';
import ModalPortal from '../../Common/Modal/ModalPortal';
import styled from 'styled-components';
import exit_btn from '../../../assets/images/exit.svg';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  width: 320px;
  background-color: #fff;
  margin: auto;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ModalHeader = styled.div`
  position: relative;
  padding: 20px 15px;

  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 720px) {
    border-bottom: 1px solid #e1e1e1;
  }
`;

const ModalContent = styled.div`
  padding: 20px 15px;
`;

const ModalFooter = styled.div`
  padding: 20px 15px;

  & > button {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    background-color: #066e4d;
    color: #fff;
    transition: background-color 0.3s;
    height: 38px;
  }

  @media screen and (max-width: 720px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

interface Props {
  close: () => void;
  content?: React.ReactNode;
}

const AptEventCompleteModal = (props: Props) => {
  const { close, content } = props;
  const navigate = useNavigate();

  const completeHandler = () => {
    close();
    navigate('/');
  };

  return (
    <ModalPortal>
      <Container>
        <ModalHeader>
          <h4>신청완료</h4>
          <button onClick={completeHandler}>
            <img src={exit_btn} alt="" />
          </button>
        </ModalHeader>
        <ModalContent>{content}</ModalContent>
        <ModalFooter>
          <button onClick={completeHandler}>확인</button>
        </ModalFooter>
      </Container>
    </ModalPortal>
  );
};

export default AptEventCompleteModal;
