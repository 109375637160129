import axios from '../utils/axios';
import { ReportPartnerDetail, ReportPartner, Ad, Case } from '../types/report';

export const getPartnerDetail = async (
  seller_table_id: string
): Promise<ReportPartnerDetail> => {
  const res = await axios.post('/Partner/PartnerDetail', {
    seller_table_id,
  });

  return res.data;
};

export const getPartnerDetailCase = async (params: {
  seller_table_id: string;
  page: number;
}): Promise<{
  code: string;
  case_cnt: string;
  is_end: number;
  case_list: Case[];
}> => {
  const res = await axios.post('/Partner/PartnerDetailMore', params);

  return res.data;
};

export interface PartnerListParams {
  interior_style?: string[];
  add_service?: string[];
  sort_type?: string;
  area_code?: string;
  area_code_detail?: string;
  page: number;
}

export const getPartnerList = async (
  params: PartnerListParams
): Promise<{
  code: string;
  is_end: number;
  partner_list: ReportPartner[];
  ad_list: Ad[];
  total_cnt: string;
}> => {
  const res = await axios.post('/Partner/PartnerList', params);

  return res.data;
};
