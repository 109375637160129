import { useInfiniteQuery, useQuery } from 'react-query';
import * as QueryKeys from '../../constants/queryKeys';
import { getReportList, getReport } from '../../apis/Report';

interface queryParams {
  area_code: string;
  area_code_detail: string;
  case_size: string[];
  case_work_price: string[];
  search_name: string;
  interior_style: string[];
  addService: string[];
  sort_by: string;
}

const useReportQueries = () => {
  const getReportListQuery = (params: queryParams) => {
    return useInfiniteQuery(
      [QueryKeys.REPORT_LIST, params],
      async ({ pageParam = 1 }) => {
        const res = await getReportList({
          ...params,
          page: pageParam,
        });

        return res;
      },
      {
        getNextPageParam: (data, allPages) => {
          return data.is_end === 0 ? allPages.length + 1 : undefined;
        },
        staleTime: 60 * 5 * 1000,
      }
    );
  };

  const getReportDetailQuery = (params: { case_table_id: string }) => {
    return useQuery(
      [QueryKeys.REPORT_DETAIL, params.case_table_id],
      () => getReport(params),
      {
        staleTime: 60 * 1000 * 60,
      }
    );
  };

  return {
    getReportListQuery,
    getReportDetailQuery,
  };
};

export default useReportQueries;
