import React from 'react';
import routes from '../libs/routes';
import Layout from '../components/Layout';
import {
  BannerSection,
  MonthlySection,
  StripBannerSection,
  // ProductPriceSection,
  BrandSection,
  GuaranteeSection,
} from '../components/LifeConstruction';

const LifeConstructionPage = () => {
  return (
    <Layout pageinfo={routes.life_construction}>
      <BannerSection />
      <MonthlySection />
      <StripBannerSection />
      {/* <ProductPriceSection /> */}
      <GuaranteeSection />
      <BrandSection />
    </Layout>
  );
};

export default LifeConstructionPage;
