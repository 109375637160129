import React, { useState } from 'react';

export default function InputSelect(props) {
  const {
    placeholder,
    name,
    value,
    setValue,
    label,
    error,
    errorFix,
    errorFix2,
    success,
    readOnly,
    onChange,
    onChangeFunc,
    index,
    onSearch,
    option,
    optionLabel,
    city,
    optionHour,
    optionMin,
    optionHp,
    optionNotKey,
  } = props;

  const [customError, setCustomError] = useState(
    errorFix && value === '2'
      ? '대기 상태로 상태 값 변경 시 고객상담콜을 받을 수 없습니다.'
      : ''
  );
  const [customError2, setCustomError2] = useState(
    errorFix2 && value === '1'
      ? '모든 서비스 정상 제공'
      : errorFix2 && value === '2'
      ? '견적의뢰 요청 중지'
      : errorFix2 && value === '3'
      ? '모든서비스 제공 중지'
      : ''
  );

  const handleChange = (e) => {
    if (onChange) {
      onChange(index, e, e.target.value);
    } else if (onSearch) {
      onSearch(name, e.target.value);
    } else {
      setValue(e.target.value);
      onChangeFunc && onChangeFunc(e.target.value);
    }

    if (errorFix) {
      if (e.target.value === '2') {
        setCustomError(
          '대기 상태로 상태 값 변경 시 고객상담콜을 받을 수 없습니다.'
        );
      } else {
        setCustomError('');
      }
    }

    if (errorFix2) {
      if (e.target.value === '1') {
        setCustomError2('모든 서비스 정상 제공');
      } else if (e.target.value === '2') {
        setCustomError2('견적의뢰 요청 중지');
      } else {
        setCustomError2('모든서비스 제공 중지');
      }
    }
  };

  return (
    <div className="input_box input_box_select">
      <label className="input_label" htmlFor={name}>
        {label}
      </label>
      {optionLabel && <span className="input_option_label">{optionLabel}</span>}
      <div className="input_section">
        <select
          name={name}
          onChange={handleChange}
          value={value}
          className={readOnly && 'disable'}
          disabled={readOnly}
        >
          {placeholder && <option>{placeholder}</option>}
          {option &&
            option.map((x, i) => {
              return (
                <option key={i} value={city ? x.area_id : x.idx}>
                  {city ? x.area_name : x.title}
                </option>
              );
            })}

          {optionHour &&
            [...Array(24)].map((d, i) => {
              return (
                <option key={i} value={i + 1}>
                  {i + 1 + '시'}
                </option>
              );
            })}
          {optionMin &&
            [...Array(60)].map((d, i) => {
              return (
                <option key={i} value={i + 1}>
                  {i + 1 + '분'}
                </option>
              );
            })}
          {optionHp && (
            <>
              <option value={'010'}>{'010'}</option>
              <option value={'011'}>{'011'}</option>
              <option value={'016'}>{'016'}</option>
              <option value={'017'}>{'017'}</option>
            </>
          )}
          {optionNotKey &&
            optionNotKey.map((x, i) => {
              return (
                <option key={i} value={x}>
                  {x}
                </option>
              );
            })}
        </select>
      </div>

      {error && <p className="input_error">{error}</p>}
      {success && <p className="input_success">{success}</p>}
      {customError && <p className="input_error">{customError}</p>}
      {customError2 && <p className="input_error">{customError2}</p>}
    </div>
  );
}
