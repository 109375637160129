import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface State {
  isClosed: boolean;
  closedAt?: number;
  closeHandler: () => void;
  init: () => void;
}

const initialState = {
  isClosed: false,
  closedAt: undefined,
};

const useReceiptFloatingStore = create(
  persist<State>(
    (set) => ({
      ...initialState,
      closeHandler: () =>
        set(() => ({
          isClosed: true,
          closedAt: new Date().getTime(),
        })),
      init: () =>
        set(() => ({
          ...initialState,
        })),
    }),
    {
      name: 'receiptFloating',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useReceiptFloatingStore;
