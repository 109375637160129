import React from 'react';
import styled from 'styled-components';
import images from '../../../libs/images';

const Container = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  & > input {
    background-image: url(${images.checkbox_off});
    width: 24px;
    height: 24px;

    &:checked {
      background-image: url(${images.checkbox_on});
    }
  }

  & > span {
    font-size: 16px;
  }
`;

interface Props {
  value: boolean;
  onChange: () => void;
  labelText: string;
}

const Checkbox = (props: Props) => {
  const { value, onChange, labelText } = props;

  return (
    <Container>
      <input type="checkbox" defaultChecked={value} onChange={onChange} />
      <span>{labelText}</span>
    </Container>
  );
};

export default Checkbox;
