import React from 'react';
import Modal from '../../Common/Modal/ModalPortal';
import styled from 'styled-components';
import images from 'libs/images';

const Container = styled.div`
  width: 100%;
  margin: auto;

  display: flex;
  flex-wrap: wrap;
  z-index: 1000;

  @media screen and (max-width: 767px) {
    height: 100vh;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  width: 1130px;
  height: 833px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  & > img {
    height: 100%;
    object-fit: scale-down;
  }

  @media screen and (max-width: 767px) {
    background-color: #000;
    height: 100%;

    & > img {
      max-height: calc(var(--vh, 1vh) * 100 - 100px);
    }
  }
`;

const Header = styled.div`
  margin: 0 auto;
  text-align: right;
  width: 1130px;
  height: 64px;

  & > button {
    width: 64px;
    height: 64px;
    background: url(${images.exit}) center center / 24px no-repeat;
  }

  @media screen and (max-width: 767px) {
    background-color: #000;

    & > button {
      background: url(${images.exit_white}) center center / 32px no-repeat;
    }
  }
`;

interface Props {
  close: () => void;
  detailImg: string;
}

const DetailModal = (props: Props) => {
  const { close, detailImg } = props;

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = images.partner_logo;
  };

  return (
    <Modal>
      <Container>
        <Header>
          <button onClick={close} />
        </Header>
        <Content>
          <img src={detailImg} onError={handleImgError} />
        </Content>
      </Container>
    </Modal>
  );
};

export default DetailModal;
