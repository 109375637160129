import React from 'react';
import Modal from './ModalPortal';
import styled from 'styled-components';
import exit_btn from '../../../assets/images/exit.svg';
import images from 'libs/images';
import useUserStore from 'stores/useUserStore';
import useVh from 'hooks/useVh';

const Container = styled.div`
  width: 1100px;
  margin: auto;
  background-color: #ffffff;

  @media screen and (max-width: 1100px) {
    width: 767px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`;

const ModalHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: end;

  & > h3 {
    display: none;
  }

  & > button {
    margin: auto 0;
  }

  @media screen and (max-width: 767px) {
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;

    & > h3 {
      display: block;
      margin: auto 0;
      font-size: 14px;
    }
  }
`;

const ModalContent = styled.div<{
  isUser: boolean;
}>`
  padding: 20px 20px 0 20px;
  text-align: center;

  & > img {
    margin-top: 50px;
    width: 193px;
    height: 63px;
  }

  & > h2 {
    margin-top: 71px;
    font-size: 50px;
    font-weight: 700;
    line-height: 80px;
  }

  & > p {
    font-size: 22px;
    font-weight: 400;
    line-height: 50px;
  }

  @media screen and (max-width: 1100px) {
    & > img {
      margin-top: 20px;
    }

    & > h2 {
      font-size: 32px;
    }

    & > p {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 767px) {
    height: calc(var(--vh, 1vh) * 100 - 120px);
    padding: 0;

    & > img {
      margin-top: ${(props) => (props.isUser ? '200px' : '135px')};
      width: 100px;
      height: 32px;
    }

    & > h2 {
      margin-top: 35px;
      font-size: 24px;
      line-height: normal;
    }

    & > p {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

const ModalBottom = styled.div`
  text-align: center;
  margin-top: 150px;
  padding-bottom: 115px;

  & > button {
    padding: 0 158px;
    background-color: #000000;
    color: #ffffff;
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
    height: 70px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media screen and (max-width: 1100px) {
    & > button {
      font-size: 18px;
      height: 55px;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 0;

    & > button {
      width: 100%;
      box-shadow: none;
      font-size: 14px;
    }
  }
`;

const UserModalBottom = styled.div`
  margin-top: 60px;
  padding-bottom: 115px;
  display: flex;
  justify-content: center;
  gap: 0 45px;

  & > button {
    height: 70px;
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #000;

    &:first-of-type {
      padding: 0 60px;
      color: #000;
    }

    &:last-of-type {
      padding: 0 125px;
      background-color: #000;
      color: #fff;
    }
  }

  @media screen and (max-width: 1100px) {
    & > button {
      font-size: 18px;
      height: 55px;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 0;
    gap: 0;

    & > button {
      height: 55px;
      width: 50%;
      box-shadow: none;
      font-size: 15px;

      &:first-of-type {
        padding: 0;
      }

      &:last-of-type {
        padding: 0;
      }
    }
  }
`;

const SubContentText = styled.div`
  text-align: center;

  & > p {
    font-size: 24px;
    font-weight: 400;
    line-height: 50px;

    & > b {
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1388px) {
    & > p {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 1100px) {
    & > p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin: 0 auto;

    & > p {
      font-size: 14px;
      line-height: 23px;
    }
  }
`;

const HRLine = styled.div`
  border-bottom: 1px solid #ccc;
  width: 600px;
  margin: 20px auto 35px auto;

  @media screen and (max-width: 1388px) {
    width: 470px;
  }

  @media screen and (max-width: 1100px) {
    width: 370px;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    margin: 70px auto 55px auto;
  }
`;

interface Props {
  close: () => void;
}

const ConfirmModal = (props: Props) => {
  const { close } = props;
  const { user } = useUserStore();
  useVh();

  const noticeHandler = () => {
    close();
    document.getElementById('login_btn')?.click();
  };

  return (
    <Modal>
      <Container>
        <ModalHeader>
          <h3>상담신청</h3>
          <button onClick={close}>
            <img src={exit_btn} alt="exit_btn" />
          </button>
        </ModalHeader>
        <ModalContent isUser={!!user}>
          <img src={images.logo_black} alt="logo_confirm_modal" />
          <h2>상담 접수가 완료되었습니다.</h2>
          <p>담당자가 1-2일내로 연락드리겠습니다.</p>
          {!user && (
            <>
              <HRLine />
              <SubContentText>
                <p>
                  고객님께서 <b>견적 신청하신 정보</b> 및 레이홈 MD가 추천하는
                  <b>테마 인테리어</b>
                  <br />
                  <b>서비스</b>와 <b>다양한 혜택 정보</b>를 알림톡으로
                  전해드릴게요~
                </p>
              </SubContentText>
            </>
          )}
        </ModalContent>
        {user ? (
          <ModalBottom>
            <button onClick={close}>닫기</button>
          </ModalBottom>
        ) : (
          <UserModalBottom>
            <button onClick={close}>닫기</button>
            <button onClick={noticeHandler}>알림 받기</button>
          </UserModalBottom>
        )}
      </Container>
    </Modal>
  );
};

export default ConfirmModal;
