import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 24%;
  position: relative;

  .image_content {
    position: relative;
    padding-bottom: 100%;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .next_icon {
    width: 42px !important;
    height: 42px !important;
    position: absolute;
    z-index: 1;
    right: -30px;
    top: 125px;
  }

  @media screen and (max-width: 1136px) {
    .next_icon {
      top: 100px;
    }
  }

  @media screen and (max-width: 950px) {
    .next_icon {
      top: 80px;
    }
  }

  @media screen and (max-width: 830px) {
    .next_icon {
      top: 70px;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    .image_content {
      position: relative;
      padding-bottom: 0;

      & > img {
        position: relative;
        width: 110px;
        height: 110px;
      }
    }

    .next_icon {
      transform: rotate(90deg);
      left: 40px;
      top: 100px;
      width: 30px !important;
      height: 30px !important;
      margin: 0px !important;
    }
  }
`;

const Content = styled.div`
  margin-top: 10px;
  display: flex;

  .title {
    margin-right: 13px;
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    line-height: 1.5;
  }

  .sub_title {
    font-size: 20px;
    font-weight: bold;
  }

  .how_to_description_wrap {
    margin-top: 11px;

    & > span {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    margin: auto 0 auto 18px;

    .title {
      font-size: 16px;
    }

    .sub_title {
      font-size: 14px;
      line-height: 1.43;
      margin-top: 2px;
    }

    .how_to_description_wrap {
      margin-top: 5px;
      line-height: 1.3;

      & > span {
        font-size: 12px;
      }
    }
  }
`;

interface Props {
  isLast: boolean;
  type: number;
  rows: {
    idx: number;
    img: string;
    next: string;
    num: number;
    title: string;
    description: string;
  };
}

export default function FrontThemeSubMain(props: Props) {
  const { rows, isLast } = props;

  return (
    <>
      <Container>
        <div className="image_content">
          <img src={rows.img} />
        </div>
        {!isLast && <img className="next_icon" src={rows.next} />}

        <Content>
          <p className="title">0{rows.num}</p>
          <div>
            <p
              className="sub_title"
              style={{ letterSpacing: rows.num === 3 ? -1 : 0 }}
            >
              {rows.title}
            </p>
            <div className="how_to_description_wrap">
              {rows.description.split('\n').map((desc, i) => (
                <React.Fragment key={i}>
                  <span>{desc}</span>
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        </Content>
      </Container>
    </>
  );
}
