/* eslint-disable no-useless-escape */
export const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; // 휴대폰번호
export const regEmail =
  /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/; // 이메일

export const patternNum = /[0-9]/; // 숫자
export const patternPrice = /[0-9,]/; // 금액
export const patternEng = /[a-zA-Z]/; // 영문
export const patternEngUpper = /[A-Z]/; // 영문 대문자
export const patternSpc = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim; // 특수문자 모두

export const patternSpcId = /[`~!#$%^&*()|+\=?;:'",<>\{\}\[\]\\\/ ]/gim; // 특수문자 아이디용
export const patternSpcPw = /[|+\=?;:'",.<>\{\}\[\]\\\/ ]/gim; // 특수문자 비밀번호용
export const patternSpcEmail = /[`~!#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim; // 특수문자 이메일용

export const patternKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크

export const hpHypen = (str) => {
  return str.replace(
    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
    '$1-$2-$3'
  );
};

export const numFormat = (num) => {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return 0;
  }
};

export const findJson = (object, val) => {
  let title = '정보없음';

  object.forEach((one) => {
    if (one.idx * 1 === val * 1) {
      title = one.title;
    }
  });
  return title;
};

export const findJsonKey = (object, val, key) => {
  let title = '정보없음';

  object.forEach((one) => {
    if (one.idx * 1 === val * 1) {
      title = one[key];
    }
  });
  return title;
};

export const findJsonImti = (object, val) => {
  let title = '👍';

  object.forEach((one) => {
    if (one.idx * 1 === val * 1) {
      title = one.title;
    }
  });
  return title;
};

export const getFileName = (url) => {
  let urls = url.split('/');
  return urls[urls.length - 1];
};

export const sortHandler = (list, key, od = 'asc') => {
  if (!list) return [];
  if (!key) return list;

  let listArr = list; // 새로 복사된 numbers

  if (od === 'asc') {
    listArr.sort((a, b) => {
      return a[key] - b[key];
    });
  } else {
    listArr.sort((a, b) => {
      return b[key] - a[key];
    });
  }
  return listArr;
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getSaveDate = () => {
  const today = new Date();

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  return year + '-' + month + '-' + day;
};
