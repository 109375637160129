import React from 'react';
import styled from 'styled-components';
import service_top_img from './images/service_top_image.jpg';
import service_middle_image from './images/service_middle_image.jpg';
import service_bottom_img from './images/service_bottom_image.jpg';

const Container = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;

  & > img {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 60px;
  }
`;

const ServiceComponent = () => {
  return (
    <Container>
      <img src={service_top_img} alt="서비스 소개 상단" />
      <img src={service_middle_image} alt="서비스 소개 중간" />
      <img src={service_bottom_img} alt="서비스 소개 하단" />
    </Container>
  );
};

export default ServiceComponent;
