import React, { useState } from 'react';
import styled from 'styled-components';

import EstimateModal from '../Common/Modal/EstimateModal';
import ConfirmModal from 'components/Common/Modal/ConfirmModal';

import { Home } from 'types/home';
import { ORDER_ESTIMATE_TYPE } from 'utils/enum/estimate';
import { Swiper, SwiperSlide } from 'swiper/react';

import prev_arrow_circle from './images/prev_arrow_circle.png';
import next_arrow_circle from './images/next_arrow_circle.png';

const Container = styled.div`
  max-width: 940px;
  width: calc(100% - 60px);
  padding: 40px 0 18px 0;
  margin: 0 auto;
  position: relative;
  display: flex;

  .swiper-slide {
    width: 112px !important;
  }

  @media screen and (max-width: 767px) {
    padding: 24px 0;
    max-width: 100%;
    width: calc(100% - 30px);
    margin: 0 0 0 30px;

    .swiper-slide {
      width: 65px !important;
    }
  }

  @media screen and (max-width: 485px) {
    .swiper-slide {
      width: 50px !important;
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 112px;
  cursor: pointer;

  & > img {
    width: 112px;
    height: 112px;
    object-fit: cover;
    border-radius: 50%;
  }

  & > p {
    font-size: 16px;
    margin: 10px auto 0 auto;
  }

  @media screen and (max-width: 767px) {
    width: 80px;

    & > img {
      width: 80px;
      height: 80px;
      margin: 0 auto;
    }

    & > p {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 485px) {
    width: 47px;

    & > img {
      width: 47px;
      height: 47px;
    }

    & > p {
      margin-top: 6px;
      font-size: 11px;
    }
  }
`;

const ArrowWrap = styled.div`
  & > button {
    position: absolute;
    top: 35%;
    z-index: 100;

    & > img {
      width: 65px;
      height: 65px;
    }
    &.quick_banner_prev {
      left: -90px;
    }

    &.quick_banner_next {
      right: -90px;
    }
  }

  @media screen and (max-width: 1360px) {
    display: none;
  }
`;

interface Props {
  data: Home['pick_list'];
}

const QuickBanner = (props: Props) => {
  const { data } = props;
  const [isOpenEstimate, setIsOpenEstimate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const onRoute = (route: string) => {
    window.location.href = route;
  };

  const callback = () => {
    setIsOpenEstimate(false);
    setIsConfirm(true);
  };

  return (
    <Container>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        navigation={{
          prevEl: '.front_sw_pagebox .quick_banner_prev',
          nextEl: '.front_sw_pagebox .quick_banner_next',
        }}
        breakpoints={{
          768: {
            spaceBetween: 90,
          },
        }}
      >
        {data.map((item) => (
          <SwiperSlide key={item.pick_table_id}>
            <Item onClick={() => onRoute(item.pick_url)}>
              <img src={item.pick_img} alt="pick_img" />
              <p>{item.pick_name}</p>
            </Item>
          </SwiperSlide>
        ))}
      </Swiper>
      <ArrowWrap className="front_sw_pagebox">
        <button className="quick_banner_prev">
          <img src={prev_arrow_circle} alt="banner_arrow_left" />
        </button>
        <button className="quick_banner_next">
          <img src={next_arrow_circle} alt="banner_arrow_right" />
        </button>
      </ArrowWrap>
      {isOpenEstimate && (
        <EstimateModal
          close={() => setIsOpenEstimate(false)}
          order_estimate_type={ORDER_ESTIMATE_TYPE.DEFAULT}
          callback={callback}
        />
      )}
      {isConfirm && <ConfirmModal close={() => setIsConfirm(false)} />}
    </Container>
  );
};

export default QuickBanner;
