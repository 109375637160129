import React, { useState } from 'react';
import styled from 'styled-components';
import { EventDetail } from 'types/event';
import images from 'libs/images';
import EstimateModal from 'components/Common/Modal/EstimateModal';
import ConfirmModal from 'components/Common/Modal/ConfirmModal';
import { ORDER_ESTIMATE_TYPE } from 'utils/enum/estimate';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  max-width: 750px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    margin-top: 60px;
  }
`;

const Content = styled.div<{ is_border: boolean }>`
  border: ${(props) => (!props.is_border ? '1px solid #e1e1e1' : 'none')};

  & > img {
    width: 100%;
    margin-bottom: 110px;
  }

  @media screen and (max-width: 767px) {
    & > img {
      margin-bottom: 57px;
    }
  }
`;

const ButtonWrap = styled.div<{
  is_btn: boolean;
}>`
  position: fixed;
  bottom: 0;
  max-width: 750px;
  width: 100%;

  & > button {
    width: 100%;
    text-align: center;
    height: 110px;
    color: #ffffff;
    font-size: 36px;
    font-weight: 700;
    background-color: ${(props) =>
      props.is_btn ? '#000000' : 'rgb(144,144,144)'};

    &:disabled {
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;

    & > button {
      height: 57px;
      font-size: 15px;
    }
  }
`;

interface Props {
  event: EventDetail;
  id: string;
}

const EventDetailComponent = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isopen = searchParams.get('isopen');
  const avtId = searchParams.get('avt_id');

  const { event, id } = props;

  const [isOpenEstimate, setIsOpenEstimate] = useState(isopen === 'true');
  const [isConfirm, setIsConfirm] = useState(false);

  const callback = () => {
    setIsOpenEstimate(false);
    setIsConfirm(true);
  };

  return (
    <Container>
      <Content is_border={!!event?.detail_page}>
        <img src={event.detail_page || images.fallback} alt="" />
      </Content>
      <ButtonWrap is_btn={event.btn_yn === '1'}>
        <button
          disabled={event.btn_yn !== '1'}
          onClick={() => setIsOpenEstimate(true)}
        >
          {event.btn_yn === '1' ? event.btn_name || '견적 받기' : '이벤트 종료'}
        </button>
      </ButtonWrap>
      {isOpenEstimate && (
        <EstimateModal
          close={() => setIsOpenEstimate(false)}
          order_estimate_type={ORDER_ESTIMATE_TYPE.EVENT}
          callback={callback}
          eventData={{
            seller_biz_name: event.seller_biz_name,
            form_types: event.form_types,
            seller_table_id: event.seller_table_id,
          }}
          key_id={String(avtId)}
          url_table_id={id}
        />
      )}
      {isConfirm && <ConfirmModal close={() => setIsConfirm(false)} />}
    </Container>
  );
};

export default EventDetailComponent;
