import React, { useEffect, useState } from 'react';
import images from '../../../libs/images';
import consts from '../../../libs/consts';
import { numFormat, findJson } from '../../../utils/utils';
import styled from 'styled-components';
import FrontThemeDrowingModal from '../Modal/DrowingModal';
import { LeftData } from '../../../types/theme';

const LeftBoxWrap = styled.li`
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;

  & > img {
    width: 100%;
    margin: 26px 0 13px 0;
    cursor: pointer;
  }
`;

const LeftSection = styled.div`
  width: 284px;
  border: 1px solid #cfcfcf;

  & > ul {
    padding: 0 20px;
  }

  @media screen and (max-width: 900px) {
    width: 230px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const LeftItemLi = styled.li`
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;

  & > img {
    width: 100%;
    margin: 26px 0 13px 0;
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #ff9c6a;
    margin-top: 20px;
  }

  .sub_title_1 {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    margin-top: 5px;
  }

  .sub_title_2 {
    width: 90%;
    font-size: 12px;
    line-height: 1.33;
    color: #909090;
    margin-top: 4px;
  }

  .more_btn_items {
    width: 100%;
    color: #909090;
  }
`;

const LeftListBox = styled.div`
  margin: 6px 0 11px 0;
  overflow: hidden;

  .list_item {
    margin-top: 4px;
    font-size: 14px;
    padding: 11px 13px 9px 14px;
    border: 1px solid #e1e1e1;
    display: inline-block;
    border-radius: 20px;
    margin-right: 4px;
    cursor: pointer;
  }
`;

export default function FrontLeftBox(props: LeftData) {
  const { row, res1, list } = props;
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [allPrice, setAllPrice] = useState(0);
  const [allPrice1, setAllPrice1] = useState(0);
  const [list2, setList2] = useState<string[]>([]); /* 공간구성 */
  const [list3, setList3] = useState<string[]>([]); /* 홈스타일링 정보 */

  const [isOpenDrawing, setIsOpenDrawing] = useState(false);

  useEffect(() => {
    const arr = row
      ? row.zone_list.map((x) => {
          return x.zone_type;
        })
      : [];
    const result = arr.filter((v, i: number) => arr.indexOf(v) === i);
    setList2(result);
  }, []);

  useEffect(() => {
    const arr = row
      ? row.style_list.map((x) => {
          return x.type;
        })
      : [];
    const result = arr.filter((v, i: number) => arr.indexOf(v) === i);
    setList3(result);
  }, []);

  /* 스타일링제품 총합 */
  useEffect(() => {
    const total = list.reduce(
      (acc, cur) => acc + Number(cur.price) * Number(cur.cnt),
      0
    );
    setAllPrice(total);
  }, [list]);

  /* 시공 총합 */
  useEffect(() => {
    const total = res1.reduce((acc, cur) => acc + Number(cur.price) * 1, 0);
    setAllPrice1(total);
  }, [res1]);

  return (
    <>
      <LeftSection>
        <ul className="">
          <LeftItemLi>
            <p className="title">면적</p>
            <p className="sub_title_1">{row.rep_size}평 기준</p>
            <p className="sub_title_2">
              *초과시 평당요금이 추가될 수 있습니다.
            </p>
          </LeftItemLi>
          <LeftItemLi>
            <p className="title">전체비용</p>
            <p className="sub_title_1">{numFormat(allPrice + allPrice1)}만원</p>
            <p className="sub_title_2">*시공+홈스타일링 비용 포함</p>
          </LeftItemLi>
          <LeftItemLi>
            <p className="title">주거유형</p>
            <p className="sub_title_1">
              {findJson(consts.workoption, row.theme_type)}
            </p>
            <p className="sub_title_2">*현재는 아파트만 시공이 가능합니다.</p>
          </LeftItemLi>
          <LeftItemLi>
            <p className="title">시공기간</p>
            <p className="sub_title_1">{Math.floor(row.theme_period / 7)}주</p>
          </LeftItemLi>
          <LeftItemLi>
            <p className="title">시공정보</p>
            <LeftListBox>
              {row &&
                row.work_info.map((x, i: number) => {
                  if (!active && i > 8) return;
                  return (
                    <p key={i} className="list_item">
                      {findJson(consts.buildsoption, x)}
                    </p>
                  );
                })}
            </LeftListBox>
            {row.work_info && row.work_info.length > 9 && !active ? (
              <button
                className="more_btn_items"
                type="button"
                onClick={() => setActive(!active)}
              >
                more
                <img src={images.more_btn} />
              </button>
            ) : row.work_info && row.work_info.length > 9 ? (
              <button
                className="more_btn_items"
                type="button"
                onClick={() => setActive(!active)}
              >
                close
                <img src={images.up} />
              </button>
            ) : (
              <></>
            )}
          </LeftItemLi>

          <LeftItemLi>
            <p className="title">홈스타일링 정보</p>
            <p className="sub_title_2">
              *가구 및 소품 포함 비용이며, 구매를 원치 않으시면 견적받기에서
              뺄수 있습니다.
            </p>
            <LeftListBox>
              {list3 &&
                list3.map((x, i) => {
                  if (!active2 && i > 8) return;
                  return (
                    <p key={i} className="list_item">
                      {findJson(consts.themeEqCode, x)}
                    </p>
                  );
                })}
            </LeftListBox>
            {list3 && list3.length > 9 && !active2 ? (
              <button
                className="more_btn_items"
                type="button"
                onClick={() => setActive2(!active2)}
              >
                more
                <img src={images.more_btn} />
              </button>
            ) : list3 && list3.length > 9 ? (
              <button
                className="more_btn_items"
                type="button"
                onClick={() => setActive2(!active2)}
              >
                close
                <img src={images.up} />
              </button>
            ) : (
              <></>
            )}
          </LeftItemLi>

          <LeftItemLi>
            <p className="title">공간구성</p>
            <LeftListBox>
              {list2 &&
                list2.map((x, i) => {
                  if (!active3 && i > 8) return;
                  return (
                    <p key={i} className="list_item">
                      {findJson(consts.homeStylePartsOption, x)}
                    </p>
                  );
                })}
            </LeftListBox>
            {list2 && list2.length > 9 && !active3 ? (
              <button
                className="more_btn_items"
                type="button"
                onClick={() => setActive3(!active3)}
              >
                more
                <img src={images.more_btn} />
              </button>
            ) : list2 && list2.length > 9 ? (
              <button
                className="more_btn_items"
                type="button"
                onClick={() => setActive3(!active3)}
              >
                more
                <img src={images.more_btn} />
              </button>
            ) : (
              <></>
            )}
          </LeftItemLi>

          <LeftBoxWrap>
            <img src={row.recp_img} onClick={() => setIsOpenDrawing(true)} />
          </LeftBoxWrap>
        </ul>
        {isOpenDrawing && (
          <FrontThemeDrowingModal
            close={() => setIsOpenDrawing(false)}
            image={row.recp_img}
          />
        )}
      </LeftSection>
    </>
  );
}
