// 해당 hook은, 이용 약관 및 개인정보 수집 등 cluase 에 대해 라우팅 시키는 커스텀 훅 입니다.

const useClause = () => {
  /**
   * id = 1은 개인정보 수집 및 이용 동의
   * id = 5은 이용약관 동의 (이용약관 기존은 3이였지만, 5로 변경됨.)
   * id = 4는 마케팅 정보 수신 동의
   */
  const showClause = (id: number): void => {
    window.open(`/clause/${id}`, '_blank');
  };

  return {
    showClause,
  };
};

export default useClause;
