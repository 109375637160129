import axios from '../utils/axios';
import { Event, EventDetail } from 'types/event';

export const getEventList = async (params: {
  page: number;
}): Promise<{
  code: string;
  total_cnt: string;
  sale_list: Event[];
  is_end: number;
}> => {
  const res = await axios.post('/SpecialSale/SaleList', params);

  return res.data;
};

export const getEventDetail = async (params: {
  url_table_id: string;
}): Promise<EventDetail> => {
  const res = await axios.post('/SpecialSale/SaleDetail', params);

  return res.data;
};
