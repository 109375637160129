/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'; /* GET변수 받기 */

import images from '../../../libs/images';
import consts from '../../../libs/consts';
import { findJsonKey, groupBy } from '../../../utils/utils';
import FrontThemeRightBox1 from './RightBoxContent';
import { ThemeList, Theme, ZoneList } from '../../../types/theme';
import styled from 'styled-components';
import HomeStyleModal from '../HomeStyle/HomeStyleModal';

const Container = styled.div`
  width: 860px;

  @media screen and (max-width: 1225px) {
    width: calc(100% - 330px);
  }

  @media screen and (max-width: 900px) {
    width: calc(100% - 280px);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 20px 0;
    margin: auto;
  }
`;

const RoomList = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 65px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    height: 8px;
    background: rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    background: rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-track {
    width: 1px;
    background: rgba(0, 0, 0, 0);
  }

  @media screen and (max-width: 1099px) {
    overflow-x: scroll;
  }

  @media screen and (max-width: 767px) {
    margin: 27px 0 5px 0;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const BtnBox = styled.div`
  margin: 50px 0 87px 0;

  @media screen and (max-width: 767px) {
    margin: 34px 0 59px 0;
  }
`;

const RightContent = styled.div`
  @media screen and (max-width: 767px) {
    margin-top: 15px;
  }
`;

interface Props {
  receipt: () => void;
  list1: ThemeList[];
  row: Theme;
  zone: ZoneList[];
}

export default function FrontThemeRightBox(props: Props) {
  const { list1, zone, receipt } = props;

  const [searchParams] = useSearchParams();
  const [paramZone] = useState(searchParams.get('zone'));

  const [active, setActive] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number | string>(0);

  const [detail, setDetail] = useState<ZoneList[]>([]);
  const [detailStyle, setDetailStyle] = useState<any>();

  const [groupZone, setGroupZone] = useState([]);

  const [more, setMore] = useState<number | boolean>(4);
  const [isOpenHomeStyle, setIsOpenHomeStyle] = useState(false);

  useEffect(() => {
    if (groupZone) {
      setDetail(groupZone[Number(active)]);
      setDetailStyle(
        list1.filter((item) => Number(item.zone_type) === Number(active))
      );
    }
  }, [activeIndex, active, list1]);

  useEffect(() => {
    if (zone && zone.length > 0) {
      const groupByZone = groupBy(zone, 'zone_type');
      setGroupZone(groupByZone);

      if (paramZone) {
        setActive(Number(paramZone));
        setActiveIndex(
          findJsonKey(consts.homeStylePartsOption, paramZone, 'merge')
        );
      } else {
        setActive(
          Number(groupByZone[Object.keys(groupByZone)[0]][0].zone_type)
        );
        setActiveIndex(
          findJsonKey(
            consts.homeStylePartsOption,
            groupByZone[Object.keys(groupByZone)[0]][0].zone_type,
            'merge'
          )
        );
      }
    }
  }, [zone]);

  const home_style_detail = () => {
    setIsOpenHomeStyle(true);
  };

  return (
    <>
      <Container>
        <RoomList>
          {consts.homeStylePartsOption.map((x, i) => {
            if (!groupZone[Number(x.idx)]) return;

            const xx = groupZone[Number(x.idx)] as ZoneList[];

            const type_img = findJsonKey(
              consts.homeStylePartsOption,
              xx[0].zone_type,
              'type_img'
            );
            const active_img = findJsonKey(
              consts.homeStylePartsOption,
              xx[0].zone_type,
              'active_img'
            );
            const default_img = findJsonKey(
              consts.homeStylePartsOption,
              xx[0].zone_type,
              'default_img'
            );
            const img_text = findJsonKey(
              consts.homeStylePartsOption,
              xx[0].zone_type,
              'img_text'
            );

            const imageList: {
              [field: string]: string;
            } = images;

            return (
              <div
                key={i}
                className="room_list_box"
                onClick={() => {
                  setActive(Number(xx[0].zone_type));
                  setActiveIndex(x.merge);
                  setMore(2);
                }}
              >
                <img
                  key={i}
                  alt="LayHome"
                  className={
                    active === Number(xx[0].zone_type)
                      ? 'room_list' + type_img + ' active'
                      : 'room_list' + type_img
                  }
                  src={
                    active === Number(xx[0].zone_type)
                      ? imageList[active_img]
                      : imageList[default_img]
                  }
                />
                <p>{img_text}</p>
              </div>
            );
          })}
        </RoomList>

        <RightContent>
          {detail && (
            <FrontThemeRightBox1
              row={detail}
              activeIndex={activeIndex}
              stylelist={list1}
              detailStyle={detailStyle}
              onClickHomeStyle={home_style_detail}
              more={more}
            />
          )}
        </RightContent>

        {detail && typeof more === 'number' && detail.length - 1 > more ? (
          <BtnBox>
            <button
              className="more_btn"
              onClick={() => {
                setMore(false);
              }}
            >
              more
            </button>
          </BtnBox>
        ) : (
          <></>
        )}
      </Container>
      {isOpenHomeStyle && (
        <HomeStyleModal
          close={() => setIsOpenHomeStyle(false)}
          type={activeIndex}
          row={detailStyle}
          list1={list1}
          receipt={receipt}
        />
      )}
    </>
  );
}
