/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import useThemeQueries from 'hooks/queries/theme';
import { Link } from 'react-router-dom';
import images from 'libs/images';
import { Swiper, SwiperSlide } from 'swiper/react';

import FrontThemeMain from './MainCardComponent';
import FrontThemeHowTo from './HowToCardComponent';
import FrontThemeSubMain from './SpaceCardComponent';

import styled from 'styled-components';
import { howToList } from './utils';

const ImageBox = styled.img<{ isMobile: boolean }>`
  display: ${(props) => (props.isMobile ? 'none' : 'block')};

  @media screen and (max-width: 720px) {
    display: ${(props) => (props.isMobile ? 'block' : 'none')};
  }
`;

const BannerSection = styled.div`
  background: #000000;
  color: #ffffff;
  padding-bottom: 180px;

  .theme-title-box {
    max-width: 1200px;
    width: calc(100% - 60px);
    margin: 0 auto;
    position: relative;
    padding-top: 130px;

    & > p {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing: -0.83px;
    }

    & > h2 {
      font-size: 56px;
      font-weight: bold;
      line-height: 1.23;
      letter-spacing: -2.33px;
    }

    & > div {
      margin-top: 21px;

      & > p {
        font-size: 26px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: -1.08px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 80px;

    .theme-title-box {
      padding-top: 98px;

      & > p {
        opacity: 0.6;
      }

      & > h2 {
        font-size: 34px;
      }

      & > div {
        margin-top: 14px;

        & > p {
          font-size: 14px;
          line-height: 1.57;
        }
      }
    }
  }
`;

const ContentSection = styled.div`
  padding-top: 130px;
  background: #f5f5f5;

  .front_theme_section2_slider {
    top: -280px;
    left: 43%;
  }

  .theme_content_page_wrap {
    left: 350px;
    bottom: 215px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .swiper-pagination {
      background: rgba(0, 0, 0, 0.25);
      height: 2px !important;
      margin: 0 11px;
      position: relative;
      width: 206px !important;

      & > span {
        background: #000;
        opacity: 1 !important;
      }
    }
  }

  .content-box {
    max-width: 1200px;
    width: calc(100% - 60px);
    margin: 0 auto;

    .text_wrap {
      position: relative;

      & > p {
        font-size: 35px;
        font-weight: 700;
        letter-spacing: -1.67px;
        line-height: 2;
        margin-bottom: 14px;
      }

      & > div {
        width: 50%;

        & > p {
          font-size: 18px;
          font-weight: 300;
          letter-spacing: -0.83px;
          line-height: 1.65;
          font-weight: 400;

          &:first-of-type {
            margin-bottom: 7px;
          }
        }
      }
    }
  }

  .front_theme_section2_btn_box {
    top: 0;
  }

  .front_theme_section2_list {
    top: 100px;
  }

  @media screen and (max-width: 1099px) {
    .front_theme_section2_slider {
      top: -195px;
      left: 43%;
    }

    .content-box {
      .text_wrap {
        & > div {
          width: 40%;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 30px;
    height: 1000px;
    position: relative;

    .theme_content_page_wrap {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 80px;
    }

    .content-box {
      .text_wrap {
        & > p {
          font-size: 24px;
          letter-spacing: -1px;
          line-height: 1.5;
        }

        & > div {
          width: 100%;

          & > p {
            font-size: 14px;
            line-height: 1.57;
          }
        }
      }
    }

    .front_theme_section2_btn_box {
      top: 180px;
      padding: 0 20px;

      .front_door {
        width: 52px;
        left: 30px;
        bottom: 60px;
      }
      .front_door.active {
        width: 61px;
        left: 26px;
      }
      .kitchen {
        width: 79.8px;
        left: 89px;
      }
      .kitchen.active {
        width: 92px;
        left: 83px;
        bottom: 37px;
      }

      .room {
        width: 102.8px;
        left: 159px;
      }
      .room.active {
        width: 114.8px;
        left: 153px;
        bottom: 38px;
      }

      .terrace {
        width: 86px;
        left: 277px;
        bottom: 11px;
      }
      .terrace.active {
        width: 98px;
        left: 270px;
        bottom: -1px;
      }

      .living_room {
        width: 137px;
        top: -58px;
        left: 173px;
      }

      .living_room.active {
        width: 149px;
        top: -58px;
        left: 167px;
      }

      .bath_room {
        width: 77px;
        left: 100px;
        top: -44px;
      }
      .bath_room.active {
        width: 89px;
        left: 94px;
        top: -44px;
      }
    }

    .front_theme_section2_list {
      top: 260px;
      display: flex;
      justify-content: center;

      & > img {
        position: relative;
        height: 307px;
        left: 16px;
        width: 305px;
        padding: 0;

        &.kitchen_main {
          width: 317px !important;
          height: 288px !important;
          top: 30.7px;
        }
        &.room_main {
          width: 316px !important;
          height: 290px !important;
          top: 31.7px;
        }
        &.terrace_main {
          width: 312px !important;
          height: 318px !important;
          top: 30.7px;
          left: 23px !important;
        }
        &.living_room_main {
          width: 315.5px !important;
          height: 282px !important;
          top: 46.7px;
          left: 19.5px !important;
        }
        &.bath_room_main {
          width: 317px !important;
          height: 272px !important;
          left: 22px !important;
          top: 32.7px !important;
        }
      }

      .front_theme_section2_sub_title {
        left: 0;
        top: 0;

        & > p {
          font-size: 40px;
          line-height: 1.28;
          position: relative;
          z-index: 9;
          letter-spacing: 13.79px;
          font-weight: 500;

          &:last-of-type {
            font-size: 14px;
            line-height: 1.57;
            margin-top: 8px;
            width: 100%;
            letter-spacing: -0.83px;
          }
        }
      }
    }

    .front_theme_section2_slider {
      left: 0;
      margin-left: 30px;
      top: 130px;
    }
  }

  @media screen and (max-width: 688px) {
    .theme_content_page_wrap {
      bottom: 40px;
    }
  }

  @media screen and (max-width: 419px) {
    height: 1040px;
  }
`;

const HowToSection = styled.div`
  background: #ffffff;
  padding: 140px 0 90px 0;

  & > div {
    max-width: 1200px;
    width: calc(100% - 60px);
    margin: 0 auto;

    .title_wrap {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > p {
        font-size: 23px;
        font-weight: 700;
        line-height: 2.87;
        margin-top: 13.2px;
      }
    }
    .how-to-list-wrap {
      margin-top: 58px;
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 50px 0;

    & > div {
      .title_wrap {
        & > img {
          width: 233px;
        }

        & > p {
          font-size: 16px;
          line-height: 1.57;
          margin-top: 19px;
        }
      }
      .how-to-list-wrap {
        flex-wrap: wrap;
      }
    }
  }
`;

const SwiperContent = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  position: relative;
  margin: 80px auto 0 auto;
  position: relative;

  .page_wrap {
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    display: flex;

    .theme_list_page_wrap {
      width: 30%;
      max-width: 330px;
      margin: 0 auto;
      position: relative;

      .swiper-pagination {
        position: absolute;
        background: rgba(255, 255, 255, 0.2);
        height: 2px !important;
        position: relative;

        & > span {
          background: #ffffff;
          opacity: 1 !important;
        }
      }

      .front_swiper_prev {
        position: absolute;
        left: -40px;
        bottom: -10px;
        background: none;
      }

      .front_swiper_next {
        position: absolute;
        right: -40px;
        bottom: -10px;
        background: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 25px;

    .front_sw_pagebox_black {
      margin: 0;

      bottom: 25px;
    }
  }
`;

const ThemeComponent = () => {
  const { getThemeListQuery } = useThemeQueries();
  const { data: theme } = getThemeListQuery();

  const [active, setActive] = useState(5);

  const [zone_type, setZone_type] = useState([]);

  /* 상단메뉴 클릭시 화면 top으로 이동 */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* 새로고침시 페이지 최상위로 */
  useEffect(() => {
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    if (theme) {
      activeFunction();
    }
  }, [active, theme]);

  const handleTheme = () => {
    if (active === 0) {
      return images.main_theme1;
    } else if (active === 1) {
      return images.main_theme2;
    } else if (active === 2) {
      return images.main_theme3;
    } else if (active === 3) {
      return images.main_theme4;
    } else if (active === 4) {
      return images.main_theme5;
    } else if (active === 5) {
      return images.main_theme6;
    }
  };

  const title = () => {
    if (active === 0) {
      return 'FRONT DOOR';
    } else if (active === 1) {
      return 'KITCHEN';
    } else if (active === 2) {
      return 'ROOM';
    } else if (active === 3) {
      return 'TERRACE';
    } else if (active === 4) {
      return 'BATH\nROOM';
    } else if (active === 5) {
      return 'LIVING\nROOM';
    }
  };

  const img = () => {
    if (active === 0) {
      return 'front_door_main';
    } else if (active === 1) {
      return 'kitchen_main';
    } else if (active === 2) {
      return 'room_main';
    } else if (active === 3) {
      return 'terrace_main';
    } else if (active === 4) {
      return 'bath_room_main';
    } else if (active === 5) {
      return 'living_room_main';
    }
  };

  const activeFunction = () => {
    if (!theme) {
      return;
    }

    const { front, kitchen, room, terrace, bath, living } = theme.zone_data;

    if (active === 0) {
      setZone_type(front);
    } else if (active === 1) {
      setZone_type(kitchen);
    } else if (active === 2) {
      setZone_type(room);
    } else if (active === 3) {
      setZone_type(terrace);
    } else if (active === 4) {
      setZone_type(bath);
    } else if (active === 5) {
      setZone_type(living);
    }
  };

  if (!theme) {
    return <></>;
  }

  return (
    <div>
      <BannerSection>
        <div className="theme-title-box">
          <p>시공 X 홈스타일링</p>
          <h2>#테마 인테리어란?</h2>
          <div>
            <p>잡지에서 보던 누구나 꿈꾸는 인테리어를 경험하고 싶은 이에게</p>
            <p>
              시공 X 홈스타일링을 접목해서 각 테마에 시공, 가구, 소품 등을
              제안해 드립니다.
            </p>
          </div>
        </div>
        <SwiperContent>
          {theme.theme_list && (
            <Swiper
              autoplay={{
                delay: 5000,
              }}
              slidesPerView={1.5}
              spaceBetween={16}
              pagination={{
                el: '.theme_list_page_wrap .front_swiper_pagenation',
                type: 'progressbar',
              }}
              navigation={{
                prevEl: '.theme_list_page_wrap .front_swiper_prev',
                nextEl: '.theme_list_page_wrap .front_swiper_next',
              }}
              breakpoints={{
                768: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                375: {
                  slidesPerView: 1.5,
                  spaceBetween: 16,
                },
              }}
            >
              {theme.theme_list.map((x, i) => {
                return (
                  <SwiperSlide key={i}>
                    <FrontThemeMain rows={x} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
          {theme.theme_list.length > 3 && (
            <div className="page_wrap">
              <div className="theme_list_page_wrap">
                <button className="front_swiper_prev" type="button">
                  <img src={images.left_icon} alt="prev_img" />
                </button>
                <div className="swiper-pagination front_swiper_pagenation"></div>
                <button className="front_swiper_next" type="button">
                  <img src={images.right_icon} alt="next_img" />
                </button>
              </div>
            </div>
          )}
        </SwiperContent>
      </BannerSection>
      <ContentSection>
        <div className="content-box">
          <div className="text_wrap">
            <p>#공간을 디자인하다.</p>
            <div>
              <p>
                인테리어는 낮설다. 뭐부터 해야하지? 거실은 모던미드? 아시안무드?
                <br />
                요새 유행하는 인테리어 디자인은 뭐지?
              </p>
              <p>
                이런 고민 하지 마세요! 인테리언이 벽 색상, 바닥소재, 조명,
                아일랜드 타입 조리대, 인테리어에 어울리는 가구와 소품까지
              </p>
              <p>
                싹-다 공간을 디자인 해 드립니다. 테마를 고르고, 견적 요청만
                하세요.
              </p>
            </div>
          </div>
          <div className="front_theme_section2_btn_box">
            <img
              className={'front_door' + (active === 0 ? ' active' : '')}
              onClick={() => setActive(0)}
              src={active !== 0 ? images.front_door_off : images.front_door_on}
            />
            <img
              className={'kitchen' + (active === 1 ? ' active' : '')}
              onClick={() => setActive(1)}
              src={active !== 1 ? images.kitchen_off : images.kitchen_on}
            />
            <img
              className={'room' + (active === 2 ? ' active' : '')}
              onClick={() => setActive(2)}
              src={active !== 2 ? images.room_off : images.room_on}
            />
            <img
              className={'terrace' + (active === 3 ? ' active' : '')}
              onClick={() => setActive(3)}
              src={active !== 3 ? images.terrace_off : images.terrace_on}
            />
            <img
              className={'bath_room' + (active === 4 ? ' active' : '')}
              onClick={() => setActive(4)}
              src={active !== 4 ? images.bathroom_off : images.bathroom_on}
            />
            <img
              className={'living_room' + (active === 5 ? ' active' : '')}
              onClick={() => setActive(5)}
              src={active !== 5 ? images.livingroom_off : images.livingroom_on}
            />
          </div>
          <div className="front_theme_section2_list">
            <div className="front_theme_section2_sub_title">
              <p>{title()}</p>
              <p>이 제품이 스타일링 된 테마는?</p>
            </div>
            <img className={img()} src={handleTheme()} />
          </div>
        </div>
        <Swiper
          className="front_theme_section2_slider"
          spaceBetween={16}
          slidesPerView={1.5}
          autoplay={{
            delay: 3000,
          }}
          loop={zone_type.length > 3}
          pagination={{
            el: '.theme_content_page_wrap .front_swiper_pagenation',
            type: 'progressbar',
          }}
          navigation={{
            prevEl: '.theme_content_page_wrap .front_swiper_prev',
            nextEl: '.theme_content_page_wrap .front_swiper_next',
          }}
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 60,
            },
          }}
        >
          {zone_type.length > 0 &&
            zone_type.map((x, i) => {
              return (
                <SwiperSlide key={i}>
                  <FrontThemeSubMain key={i} rows={x} type={1} />
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div className="theme_content_page_wrap">
          <button className="front_swiper_prev" type="button" />
          <div className="swiper-pagination front_swiper_pagenation"></div>
          <button className="front_swiper_next" type="button" />
        </div>
      </ContentSection>
      <HowToSection>
        <div>
          <div className="title_wrap">
            <img src={images.howto} />
            <p>테마 인테리어 진행은 어떻게 진행되나요?</p>
          </div>
          <div className="how-to-list-wrap">
            {howToList.map((x, i) => {
              return (
                <React.Fragment key={i}>
                  <FrontThemeHowTo
                    rows={x}
                    type={1}
                    isLast={howToList.length === i + 1}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </HowToSection>
      {theme.banner_list.length > 0 && (
        <section className="front_theme_section4">
          <div className="section4 fcc">
            <Swiper
              className="section4_slide"
              spaceBetween={0}
              slidesPerView={1}
              autoplay={{ delay: 5000 }}
              pagination={{
                el: '.front_sw_pagebox1 .front_swiper_pagination',
                type: 'progressbar',
              }}
              //
              noSwiping={true}
            >
              {theme.banner_list.map((x: any, i: number) => {
                if (i + 1 > 10) {
                  return;
                }
                return (
                  <SwiperSlide key={i}>
                    <Link
                      to={'/EventPage?id=' + x.notice_table_id}
                      className="banner_slide"
                    >
                      <ImageBox
                        src={x.main_mobile ? x.main_mobile : x.main_img}
                        alt="mobile_image"
                        isMobile={true}
                      />
                      <ImageBox
                        src={x.main_img}
                        alt="web_image"
                        isMobile={false}
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
      )}
    </div>
  );
};

export default ThemeComponent;
