import React, { useState } from 'react';
import Layout from 'components/Layout';
import {
  HomeBanner,
  QuickBanner,
  InteriorBrokerage,
  Event,
  Report,
} from 'components/Home';

import useCommonQueries from 'hooks/queries/common';
import TodayModal from 'components/Home/TodayModal';

const MainPage = () => {
  const { getHomeQuery } = useCommonQueries();
  const { data: home } = getHomeQuery();

  const [popShow, setPopShow] = useState(true);

  if (!home) return null;

  return (
    <Layout>
      <HomeBanner data={home.hero_banner_list} />
      <QuickBanner data={home.pick_list} />
      <InteriorBrokerage data={home.content_list} />
      <Event data={home.special_list} />
      <Report data={home.hot_case_list} />
      {popShow && (
        <TodayModal close={() => setPopShow(false)} data={home.popup_list} />
      )}
    </Layout>
  );
};

export default MainPage;
