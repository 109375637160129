import { create } from 'zustand';

interface Params {
  apart_name?: string; // 아파트명
  case_size?: string[]; // 평ㅕ
  case_work_price?: string[]; // 예산
  style_area?: string[]; // 추구 스타일
  case_service?: string[]; // 제공 서비스
  case_sortBy?: string[]; // 정렬
  city?: string; // 도/시
  area?: string; // 시/구/군
  areaText?: string;
  page?: number;
}

interface State {
  params: Params;
  setParams: (params: Params) => void;
}

const useReportStore = create<State>((set, get) => ({
  params: {
    apart_name: '',
    case_size: [],
    case_work_price: [],
    style_area: [],
    case_service: [],
    case_sortBy: [],
    city: '',
    area: '',
    areaText: '',
    page: 1,
  },
  setParams: (data) =>
    set(() => {
      const { params } = get();

      return {
        params: {
          ...params,
          ...data,
        },
      };
    }),
}));

export default useReportStore;
