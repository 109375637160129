import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface State {
  isHideLineBanner: string;
  closeLineBanner: (date: string) => void;
}

const useBannerStore = create(
  persist<State>(
    (set) => ({
      isHideLineBanner: '',
      closeLineBanner: (date: string) =>
        set(() => ({ isHideLineBanner: date })),
    }),
    {
      name: 'line_banner_closed_at',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useBannerStore;
