import React from 'react';
import Layout from 'components/Layout';
import Report from 'components/Report';

const ReportPage = () => {
  return (
    <Layout headerText="시공사례 모음">
      <Report />
    </Layout>
  );
};

export default ReportPage;
