import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import TopBanner from './TopBanner';
import Floating from './Floating';
import MobileBottomSheet from './MobileBottomSheet';

import useFooterScroll from './layout.hook';
import { HeaderTypes } from './types/headers';
import { isMobile } from 'react-device-detect';
import MobileCover from 'components/Common/Modal/MobileCover';

const Container = styled.div`
  h1,
  h2,
  h3,
  h4,
  p,
  span,
  b,
  a,
  button,
  div,
  ul,
  li {
    font-family: Noto Sans KR;
  }
`;

const Content = styled.div`
  @media screen and (max-width: 767px) {
    margin-top: -60px;
  }
`;

interface Props extends HeaderTypes {
  children: React.ReactNode;
  pageinfo?: string;
  is_hide_footer?: boolean;
  is_hide_mobile_bottom_sheet?: boolean;
  is_mobile_header_white?: boolean;
}

const Layout = (props: Props) => {
  const {
    children,
    headerText,
    isHeaderTextAlign = 'left',
    is_hide_footer,
    is_hide_mobile_bottom_sheet,
    is_mobile_header_white,
  } = props;
  const bottomRef = useRef<HTMLDivElement>(null);
  const { isFooter } = useFooterScroll(bottomRef);

  const [isShowMobileCover, setIsShowMobileCover] = useState(false);

  const calcSize = () => {
    const { innerWidth, innerHeight } = window;

    if (innerWidth <= 767 && innerWidth >= innerHeight) {
      setIsShowMobileCover(true);
    } else {
      setIsShowMobileCover(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      calcSize();

      window.addEventListener('resize', () => {
        setTimeout(() => {
          calcSize();
        }, 100);
      });

      return () => window.removeEventListener('resize', calcSize);
    }
  }, [isMobile]);

  return (
    <Container className="layout">
      <TopBanner />
      <Header
        headerText={headerText}
        isHeaderTextAlign={isHeaderTextAlign}
        is_mobile_header_white={is_mobile_header_white}
      />
      <Content>{children}</Content>
      {!is_hide_footer && (
        <footer ref={bottomRef} id="footer">
          <Footer />
        </footer>
      )}
      <Floating isFooter={isFooter} />
      {!is_hide_mobile_bottom_sheet && <MobileBottomSheet />}
      {isShowMobileCover && <MobileCover />}
    </Container>
  );
};

export default Layout;
