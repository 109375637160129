import React from 'react';
import styled from 'styled-components';
import images from '../../../libs/images';
import seller_license from './images/seller_license_logo.png';
import seller_insurance from './images/seller_insurance_logo.png';

const Container = styled.div`
  width: 410px;
  position: relative;

  @media screen and (max-width: 1100px) {
    width: 300px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const Box = styled.div`
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 50px;
  position: sticky;
  top: 140px;

  @media screen and (max-width: 1100px) {
    padding: 65px 20px 40px 20px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const Badge = styled.div`
  position: absolute;
  top: -40px;
  left: 50px;
  display: flex;
  gap: 6px;

  .badge {
    padding: 5px 7px 5px 7px;
    border-radius: 2px;

    & > img {
      width: 55px;
      height: 28px;
    }

    & > p {
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
    }

    &.license {
      background-color: #ff5500;
    }

    &.insurance {
      background-color: #000000;
    }
  }
`;

const Content = styled.div`
  & > div {
    display: flex;
  }

  & > button {
    margin-top: 50px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    background-color: #000000;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media screen and (max-width: 1100px) {
    & > div {
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const IconWrap = styled.div`
  & > img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
`;

const InfoWrap = styled.div`
  margin: 0 0 0 20px;

  & > h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
  }

  & > p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  @media screen and (max-width: 1100px) {
    width: calc(100% - 100px);

    & > h3 {
      font-size: 18px;
      margin: 0;
    }
  }
`;

const MobileContent = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    padding: 20px 0;
    margin: 0 30px;
    border-bottom: 1px solid #d9d9d9;

    & > h3 {
      font-size: 20px;
      font-weight: 700;
    }

    & > div {
      display: flex;
      margin-top: 6px;

      & > img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      & > p {
        margin: auto 0 auto 10px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
`;

interface Props {
  data: {
    seller_icon?: string;
    seller_biz_name?: string;
    seller_intro?: string;
    is_license?: string;
    is_insurance?: string;
  };
  onClickEstimate: () => void;
}

const StickyInfo = (props: Props) => {
  const { data, onClickEstimate } = props;

  return (
    <Container>
      <Box>
        <Content>
          <div>
            <IconWrap>
              <img
                src={data.seller_icon || images.partner_logo1}
                alt="partner_icon"
              />
            </IconWrap>
            <InfoWrap>
              <h3>{data.seller_intro}</h3>
              <p>{data.seller_biz_name}</p>
            </InfoWrap>
          </div>
          <button onClick={onClickEstimate}>이 업체로 상담신청</button>
        </Content>
        <Badge>
          {data.is_license === '1' && (
            <div className="badge license">
              <img src={seller_license} alt="seller_license_image" />
              <p>
                실내건축업
                <br />
                면허
              </p>
            </div>
          )}
          {data.is_insurance === '1' && (
            <div className="badge insurance">
              <img src={seller_insurance} alt="seller_insurance_image" />
              <p>
                하자 보증
                <br />
                보험
              </p>
            </div>
          )}
        </Badge>
      </Box>
      <MobileContent>
        <h3>{data.seller_intro}</h3>
        <div>
          <img
            src={data.seller_icon || images.partner_logo1}
            alt="mobile_partner_icon"
          />
          <p>{data.seller_biz_name}</p>
        </div>
      </MobileContent>
    </Container>
  );
};

export default StickyInfo;
