import axios from '../utils/axios';
import { SignupInfo, User } from 'types/user';

export const checkId = async (
  id?: string
): Promise<{
  code: string;
  is_chk: number;
}> => {
  const res = await axios.post('/Login/CheckId', { srch_id: id });

  return res.data;
};

export const signup = async (
  params: SignupInfo
): Promise<{
  code: string;
  user_info: User[];
}> => {
  const res = await axios.post('/UserInfo/Create', params);

  return res.data;
};

export const login = async (params: {
  user_id: string;
  user_passwd: string;
}): Promise<{
  code: string;
  user_info: User[];
}> => {
  const res = await axios.post('/Login/SiteLogin', params);

  return res.data;
};

export const kakaoLogin = async (params: {
  kakao_auth_key: number;
}): Promise<{
  code: string;
  user_info: User[];
}> => {
  const res = await axios.post('/Login/CheckKakao', params);

  return res.data;
};
