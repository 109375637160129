import { useQuery, useInfiniteQuery } from 'react-query';
import { getEventList, getEventDetail } from 'apis/Event';
import * as QueryKeys from 'constants/queryKeys';

const useEventQueries = () => {
  const getEventListQuery = () => {
    return useInfiniteQuery(
      QueryKeys.EVENT_LIST,
      async ({ pageParam = 1 }) => {
        const res = await getEventList({
          page: pageParam,
        });

        return res;
      },
      {
        getNextPageParam: (data, allPages) => {
          return data.is_end === 0 ? allPages.length + 1 : undefined;
        },
        staleTime: 60 * 5 * 1000,
      }
    );
  };

  const getEventDetailQuery = (url_table_id: string) => {
    return useQuery(
      [QueryKeys.Event_DETAIL, url_table_id],
      () => getEventDetail({ url_table_id }),
      { staleTime: 60 * 5 * 1000 }
    );
  };

  return {
    getEventListQuery,
    getEventDetailQuery,
  };
};

export default useEventQueries;
