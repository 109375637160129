import React from 'react';
import ModalPortal from '../../Common/Modal/ModalPortal';
import styled from 'styled-components';
import exit_btn from '../../../assets/images/exit.svg';

const Container = styled.div`
  background-color: #fff;
  width: 600px;
  position: relative;
  margin: auto;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 720px) {
    width: 400px;
  }
`;

const ModalHeader = styled.div`
  position: relative;
  height: 40px;

  & > button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const ModalContent = styled.div`
  position: relative;
  height: calc(100% - 40px);

  & > img {
    width: 100%;
    height: 100%;
  }
`;

interface Props {
  close: () => void;
  image: string;
}

const FrontThemeDrowingModal = (props: Props) => {
  const { close, image } = props;

  return (
    <ModalPortal>
      <Container>
        <div>
          <ModalHeader>
            <button onClick={close}>
              <img src={exit_btn} alt="exit" />
            </button>
          </ModalHeader>
          <ModalContent>
            <img src={image} alt="drowing_image" />
          </ModalContent>
        </div>
      </Container>
    </ModalPortal>
  );
};

export default FrontThemeDrowingModal;
