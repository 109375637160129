import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo_white from '../../assets/images/logo_white.png';

const Container = styled.div`
  background-color: #000000;
`;

const Content = styled.div`
  padding: 38px 0 48px 0;
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;

  .footer_logo {
    & > img {
      object-fit: contain;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0 105px 0;

    .footer_logo {
      & > img {
        width: 92px;
        height: 30px;
        object-fit: contain;
      }
    }
  }
`;

const Wrap = styled.div`
  margin-top: 40px;

  .top {
    display: flex;
    gap: 10px;
    line-height: 30px;

    & > a,
    p {
      font-weight: 700;
      color: #ffffff;
    }

    & > a {
      font-size: 20px;
    }

    & > p {
      font-size: 18px;
      margin-top: -2px;
    }
  }

  .info-description {
    margin-top: 20px;

    & > p {
      color: #cfcfcf;
      font-size: 17px;
      line-height: 30px;
    }

    &.pc {
      display: block;
    }

    &.mobile {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 20px;

    .top {
      gap: 5px;

      & > a,
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .info-description {
      margin-top: 32px;

      & > p {
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;

        &:last-of-type {
          margin-top: 20px;
        }
      }

      &.pc {
        display: none;
      }

      &.mobile {
        display: block;
      }
    }
  }
`;

const Footer = () => {
  return (
    <Container>
      <Content>
        <Link to="/" className="footer_logo">
          <img src={logo_white} alt="logo" />
        </Link>
        <Wrap>
          <div className="top">
            <Link to="/service">서비스 소개</Link>
            <p>|</p>
            <Link to="/clause/5">이용 약관</Link>
            <p>|</p>
            <Link to="/clause/1">개인정보처리방침</Link>
          </div>
          <div className="info-description pc">
            <p>
              대표: 유광연 | 서울시 구로구 디지털로 300 지밸리비즈플라자 15층 |
              TEL 1577-6549
            </p>
            <p>
              사업자등록번호 : 204-86-40665 | 통신판매업번호 :
              제2019-서울구로-0881호
            </p>
            <p>(주) 두꺼비세상 ALL RIGHTS RESERVED</p>
          </div>
          <div className="info-description mobile">
            <p>대표: 유광연</p>
            <p>
              서울시 구로구 디지털로 300 지밸리비즈플라자 15층 | TEL 1577-6549
            </p>
            <p>사업자등록번호 : 204-86-40665</p>
            <p>통신판매업번호 : 제2019-서울구로-0881호</p>
            <p>(주) 두꺼비세상 ALL RIGHTS RESERVED</p>
          </div>
        </Wrap>
      </Content>
    </Container>
  );
};

export default Footer;
