import React from 'react';
import styled from 'styled-components';
import Card from './Card';
import { Case } from 'types/report';

const Container = styled.div`
  margin-top: 40px;
  width: calc(100% - 60px);

  & > h3 {
    font-size: 24px;
    font-weight: 700;
  }

  @media screen and (max-width: 767px) {
    margin: 20px auto 0 auto;

    & > h3 {
      font-size: 14px;
    }
  }
`;

const Content = styled.div`
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
`;

interface Props {
  reports: Case[];
}

const ReportList = (props: Props) => {
  const { reports } = props;

  return (
    <Container>
      <h3>시공사례</h3>
      <Content>
        {reports.map((report) => (
          <Card key={report.case_table_id} item={report} />
        ))}
      </Content>
    </Container>
  );
};

export default ReportList;
