import React from 'react';
import styled from 'styled-components';
import NoticeTermsDetail from '../../../assets/images/notice/notice_terms_detail.jpg';
import termsOfUseButton from '../../../assets/images/notice/terms_of_use_button.jpg';
import privacyPolicyButton from '../../../assets/images/notice/privacy_policy_button.jpg';
import useClause from 'hooks/useClause';

const Container = styled.div`
  max-width: 750px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    margin-top: 60px;
  }
`;

const Content = styled.div`
  & > img {
    width: 100%;
    margin-bottom: 110px;
  }

  @media screen and (max-width: 767px) {
    & > img {
      margin-bottom: 57px;
    }
  }
`;

const ButtonWrap = styled.div`
  padding-top: 10px;
  padding-left: 77px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    & > img {
      max-width: 70%;
    }
  }
`;

const EventDetailComponent = () => {
  const { showClause } = useClause();

  return (
    <Container>
      <Content>
        <img src={NoticeTermsDetail} />
      </Content>
      <ButtonWrap>
        <img
          src={termsOfUseButton}
          onClick={() => {
            showClause(5);
          }}
        />
      </ButtonWrap>
      <ButtonWrap>
        <img
          src={privacyPolicyButton}
          onClick={() => {
            showClause(1);
          }}
        />
      </ButtonWrap>
    </Container>
  );
};

export default EventDetailComponent;
