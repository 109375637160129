import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Content = styled.div`
  width: 250px;
  height: 280px;
  padding: 10px;
  border: 1px solid #e1e1e1;
  margin: auto;
  text-align: center;

  & > h3 {
    margin-top: 40px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  & > p {
    margin-bottom: 5px;
  }
`;

const ThemeDetailOutboundPage = () => {
  const [searchParams] = useSearchParams();
  const param = searchParams.get('to');

  useEffect(() => {
    if (param) {
      const timeout = setTimeout(() => {
        location.href = param;
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <Container>
      <Content>
        <h3>안내드립니다!</h3>
        <p>
          레이홈에서는 고객님과의 신뢰를 위해 스타일링 제품의 판매링크를
          제공하고 있습니다.{' '}
        </p>
        <p>
          레이홈에서는 절대 스타일링 제품 비용에 마진을 붙이지 않고,
          정상가격으로 안내드리고 있습니다.
        </p>
        <p>직접 구매를 원하시면 링크를 통해 구매하셔도 무관합니다.</p>
      </Content>
    </Container>
  );
};

export default ThemeDetailOutboundPage;
