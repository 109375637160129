import service1 from './images/service1.png';
import service2 from './images/service2.png';
import service3 from './images/service3.png';
import service4 from './images/service4.png';
import service5 from './images/service5.png';
import service6 from './images/service6.png';
import service7 from './images/service7.png';
import service8 from './images/service8.png';
import service9 from './images/service9.png';
import service10 from './images/service10.png';
import service11 from './images/service11.png';

const mockService = [
  { id: 1, title: '실내건축업면허', img: service1 },
  { id: 2, title: '하자보증보험', img: service2 },
  { id: 3, title: '3D디자인', img: service3 },
  { id: 4, title: '전문현장감리', img: service4 },
  { id: 5, title: '현금영수증', img: service5 },
  { id: 6, title: '설계도면', img: service6 },
  { id: 7, title: '숙소제공', img: service7 },
  { id: 8, title: '이삿짐센터', img: service8 },
  { id: 9, title: '공정표', img: service9 },
  { id: 10, title: '계약서', img: service10 },
  { id: 11, title: '그린리모델링', img: service11 },
];

export { mockService };
