import React, { useState, useEffect } from 'react';
import Modal from 'components/Common/Modal/ModalPortal';
import styled from 'styled-components';
import images from '../../../libs/images';
import { numFormat, findJson } from '../../../utils/utils';
import consts from '../../../libs/consts';
import { Theme, ThemeList, ThemeType } from '../../../types/theme';
import useVh from 'hooks/useVh';

const Container = styled.div`
  background-color: #ffffff;
  margin: auto;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #ebebeb;

  & > h3 {
    font-size: 16px;
    font-weight: 700;
    white-space: pre-wrap;
    margin: auto;
  }

  & > button {
    & > img {
      width: 30px;
      height: 30px;
    }
  }
`;

const Content = styled.div`
  padding: 20px;
  height: 95vh;
  overflow-y: scroll;
  margin-bottom: 100px;

  @media screen and (max-width: 767px) {
    height: calc(var(--vh, 1vh) * 100 - 60px);
  }
`;

const ItemTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #ff9c6a;
  margin-top: 20px;
`;

interface Props {
  close: () => void;
  row: Theme;
  list: ThemeList[];
  res1: ThemeType[];
}

const ConstructionInfoModal = (props: Props) => {
  const { close, row, list, res1 } = props;
  useVh();
  const [allPrice, setAllPrice] = useState(0);

  const [allPrice1, setAllPrice1] = useState(0);

  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [list2, setList2] = useState<string[]>([]); /* 공간구성 */
  const [list3, setList3] = useState<string[]>([]); /* 홈스타일링 정보 */

  useEffect(() => {
    const arr = row
      ? row.zone_list.map((x) => {
          return x.zone_type;
        })
      : [];
    const result = arr.filter((v, i) => arr.indexOf(v) === i);
    setList2(result);
  }, []);

  useEffect(() => {
    const arr = row
      ? row.style_list.map((x) => {
          return x.type;
        })
      : [];
    const result = arr.filter((v, i) => arr.indexOf(v) === i);
    setList3(result);
  }, []);

  /* 스타일링제품 총합 */
  useEffect(() => {
    const total = list.reduce(
      (acc, cur) => acc + Number(cur.price) * Number(cur.cnt),
      0
    );
    setAllPrice(total);
  }, [list]);

  /* 시공 총합 */
  useEffect(() => {
    const total = res1.reduce((acc, cur) => acc + Number(cur.price) * 1, 0);
    setAllPrice1(total);
  }, [res1]);

  return (
    <Modal>
      <Container>
        <Header>
          <h3>시공정보</h3>
          <button onClick={close}>
            <img src={images.exit_white} alt="images_exit" />
          </button>
        </Header>

        <Content>
          <ul className="">
            <li className="const_info_popup_box">
              <ItemTitle>면적</ItemTitle>
              <p className="front_theme_detail_section3_left_box_sub_title1">
                {row.rep_size}평 기준
              </p>
              <p className="front_theme_detail_section3_left_box_sub_title2">
                *초과시 평당요금이 추가될 수 있습니다.
              </p>
            </li>
            <li className="front_theme_detail_section3_left_box">
              <ItemTitle>전체비용</ItemTitle>
              <p className="front_theme_detail_section3_left_box_sub_title1">
                {numFormat(allPrice + allPrice1)}만원
              </p>
              <p className="front_theme_detail_section3_left_box_sub_title2"></p>
            </li>
            <li className="front_theme_detail_section3_left_box">
              <ItemTitle>주거유형</ItemTitle>
              <p className="front_theme_detail_section3_left_box_sub_title1">
                {findJson(consts.workoption, row.theme_type)}
              </p>
              <p className="front_theme_detail_section3_left_box_sub_title2">
                *현재는 아파트만 시공이 가능합니다.
              </p>
            </li>
            <li className="front_theme_detail_section3_left_box">
              <ItemTitle>시공기간</ItemTitle>
              <p className="front_theme_detail_section3_left_box_sub_title1">
                {Math.floor(row.theme_period / 7)}주
              </p>
            </li>
            <li className="front_theme_detail_section3_left_box">
              <ItemTitle>시공정보</ItemTitle>
              <div className="front_theme_detail_section3_left_list_box">
                {row &&
                  row.work_info.map((x, i) => {
                    if (!active && i > 2) return;
                    return (
                      <p
                        key={i}
                        className="front_theme_detail_section3_left_list"
                      >
                        {findJson(consts.buildsoption, x)}
                      </p>
                    );
                  })}
              </div>
              {row.work_info && row.work_info.length > 3 && !active ? (
                <button
                  className="front_theme_detail_section3_left_more_btn"
                  type="button"
                  onClick={() => setActive(!active)}
                >
                  more
                  <img src={images.more_btn} />
                </button>
              ) : row.work_info && row.work_info.length > 3 ? (
                <button
                  className="front_theme_detail_section3_left_more_btn"
                  type="button"
                  onClick={() => setActive(!active)}
                >
                  close
                  <img src={images.up} />
                </button>
              ) : (
                <></>
              )}
            </li>

            <li className="front_theme_detail_section3_left_box">
              <ItemTitle>홈스타일링 정보</ItemTitle>
              <p className="front_theme_detail_section3_left_box_sub_title2">
                *가구 및 소품 포함 비용이며, 구매를 원치 않으시면 견적받기에서
                뺄수 있습니다.
              </p>
              <div className="front_theme_detail_section3_left_list_box">
                {list3.map((x, i) => {
                  if (!active2 && i > 13) return;
                  return (
                    <p
                      key={i}
                      className="front_theme_detail_section3_left_list"
                    >
                      {findJson(consts.themeEqCode, x)}
                    </p>
                  );
                })}
              </div>
              {list3 && list3.length > 14 && !active2 ? (
                <button
                  className="front_theme_detail_section3_left_more_btn"
                  type="button"
                  onClick={() => setActive2(!active2)}
                >
                  more
                  <img src={images.more_btn} />
                </button>
              ) : list3 && list3.length > 14 ? (
                <button
                  className="front_theme_detail_section3_left_more_btn"
                  type="button"
                  onClick={() => setActive2(!active2)}
                >
                  close
                  <img src={images.up} />
                </button>
              ) : (
                <></>
              )}
            </li>

            <li className="front_theme_detail_section3_left_box">
              <ItemTitle>공간구성</ItemTitle>
              <div className="front_theme_detail_section3_left_list_box">
                {list2.map((x, i) => {
                  if (!active3 && i > 14) return;
                  return (
                    <p
                      key={i}
                      className="front_theme_detail_section3_left_list"
                    >
                      {findJson(consts.homeStylePartsOption, x)}
                    </p>
                  );
                })}
              </div>
              {list2 && list2.length > 15 && !active3 ? (
                <button
                  className="front_theme_detail_section3_left_more_btn"
                  type="button"
                  onClick={() => setActive3(!active3)}
                >
                  more
                  <img src={images.more_btn} />
                </button>
              ) : list2 && list2.length > 15 ? (
                <button
                  className="front_theme_detail_section3_left_more_btn"
                  type="button"
                  onClick={() => setActive3(!active3)}
                >
                  close
                  <img src={images.up} />
                </button>
              ) : (
                <></>
              )}
            </li>

            {row.recp_img && (
              <li className="front_theme_detail_section3_left_box">
                <img src={row.recp_img} />
              </li>
            )}
          </ul>
        </Content>
      </Container>
    </Modal>
  );
};

export default ConstructionInfoModal;
