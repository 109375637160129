import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import images from '../../libs/images';
import reset_icon from '../Report/images/reset_icon.png';
import { styleList, serviceList } from './constants';
import { Area } from '../../types/home';
import { UseQueryResult } from 'react-query';
import useCommonQueries from 'hooks/queries/common';
import usePartnerQueries from 'hooks/queries/partner';

import AdComponent from './AdComponent';
import List from './List';
import { GrPrevious } from 'react-icons/gr';

const Container = styled.div``;

const Banner = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin: 0 auto;
  padding-bottom: 63px;

  & > h3 {
    display: block;
    padding-top: 150px;

    font-size: 56px;
    font-weight: 700;
    line-height: 77px;
  }

  & > h2 {
    display: none;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
    width: calc(100% - 60px);
    margin: 0 auto;
    padding-bottom: 40px;

    & > h3 {
      display: none;
    }

    & > h2 {
      display: block;
      padding-top: 100px;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
  }
`;

const FilterWrap = styled.div`
  margin-top: 40px;

  .filter_select_box {
    & > div {
      display: inline-block;
      margin-right: 20px;
      position: relative;

      & > button {
        display: flex;
        padding: 5px 15px;
        background-color: #f5f5f5 !important;
        border: 1px solid #8f8e8e;
        border-radius: 3px;
        width: 100%;
        appearance: none;
        & > p {
          margin: auto 0;
        }

        & > img {
          margin: auto 0 auto 10px;
        }
      }
    }

    .input_box {
      border: 1px solid;
    }
  }

  .selected_box {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .selected_item {
      background-color: #000000;
      padding: 10px 15px 10px 15px;
      border: none;
      border-radius: 3px;

      display: flex;

      & > p {
        color: #ffffff;
        font-weight: 600;
        margin: auto 0;
      }

      & > button {
        background: none;
        color: #ffffff;
        font-weight: 600;
        margin: auto 0 auto 10px;
        font-size: 18px;
      }
    }
  }

  .reset_item_box {
    width: 80px;

    & > button {
      display: flex;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;

      & > img {
        width: 18px;
        height: 18px;
        margin: auto 5px auto 5px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const FilterItem = styled.div`
  position: relative;

  .area_select {
    position: absolute;
    width: 203px;
    height: 300px;
    z-index: 100;

    .front_search_area_box {
      top: 10px;
    }
  }
`;

const FilterSelect = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;

  width: 330px;
  z-index: 100;

  & > div {
    margin-top: 10px;
    border: 1px solid #8f8e8e;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;

    .select_header {
      display: flex;

      & > h3 {
        font-size: 16px;
      }

      & > p {
        color: #8f8e8e;
        font-size: 10px;
        margin: 5px 0 0 10px;
      }
    }

    .select_content {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      color: #434343;

      & > button {
        padding: 5px 10px;
        border: 1px solid #8f8e8e;
        border-radius: 20px;

        &.selected {
          border: 2px solid #000000;
          font-weight: 600;
        }
      }
    }
  }
`;

const MobileFilterWrap = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    width: calc(100% - 60px);

    & > h2 {
      font-size: 26px;
      font-weight: 600;
    }

    .mobile_filter_content {
      margin-top: 25px;
      display: flex;

      & > button {
        color: #000000;

        &:first-of-type {
          margin-top: 2px;
        }
      }

      & > p {
        margin: 1px 5px 0 5px;
        color: #8f8e8e;
      }
    }

    .mobile_area_content {
      display: flex;
      margin-top: 40px;

      & > button {
        font-size: 22px;
        margin: 15px 10px auto 0;
      }
    }
  }
`;

const MoreBtnWrap = styled.div`
  margin: 150px 0;
  text-align: center;

  & > button {
    color: #212121;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 120px;
    border: 1px solid #434343;
  }
`;

const MobileFilterOpenWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #ffffff;

  .filter_open_header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;

    & > h3 {
      font-size: 22px;
    }
  }

  .filter_open_content {
    padding: 20px 20px 0 20px;
    height: calc(100vh - 122px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9; /* 스크롤바의 색상 */
    }

    .select_content {
      margin: 20px 0 30px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      color: #434343;

      & > button {
        padding: 10px 15px;
        border: 1px solid #8f8e8e;
        border-radius: 20px;
        color: #000000;

        &.selected {
          border: 2px solid #000000;
          font-weight: 600;
        }
      }
    }
  }

  .filter_open_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    & > button {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      height: 55px;

      &:first-of-type {
        width: 35%;
        background-color: #e2e2e2;
        color: #000000;
      }

      &:last-of-type {
        width: 65%;
        background-color: #000000;
        color: #ffffff;
      }
    }
  }
`;

const SearchInput = styled.div`
  display: block;

  @media screen and (max-width: 767px) {
    display: inline-flex;
    position: relative;
    border: 2px solid;
    height: 50px;

    min-width: auto;
    padding: 0 15px;
    margin: 0;

    .area_select_box {
      display: flex;
      width: auto;
      margin: 3px 0 0 0;

      & > button {
        width: auto;
        margin: 0;
        font-size: 14px;
        color: #000000;

        & > img {
          margin: 0;
        }
      }
    }

    & > input {
      margin-left: 15px;
      font-size: 16px;
      min-width: auto;
      width: 50%;
    }

    & > button {
      position: static;
      margin: 3px 0 0 auto;
    }
  }
`;

interface FilterTypes {
  style: string[];
  service: string[];
}

const Partner = () => {
  const [city, setCity] = useState('');
  const [area, setArea] = useState('');
  const [areaText, setAreaText] = useState('');

  const [isOpenFilters, setIsOpenFilters] = useState<{
    area: boolean;
    style: boolean;
    service: boolean;
  }>({
    area: false,
    style: false,
    service: false,
  });

  const [filterData, setFilterData] = useState<FilterTypes>({
    style: [],
    service: [],
  });

  const [copyFilterData, setCopyFilterData] = useState<FilterTypes>({
    style: [],
    service: [],
  });

  const { getCityListQuery, getAreaListQuery } = useCommonQueries();

  const { data: city_list }: UseQueryResult<{ area: Area[] }, unknown> =
    getCityListQuery();
  const { data: area_list } = getAreaListQuery({
    area_id: city,
  });

  const { getParnterListQuery } = usePartnerQueries();

  const [isOpenMobileAreaStatus, setIsOpenMobileAreaStatus] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const { data, hasNextPage, fetchNextPage } = getParnterListQuery({
    area_code: city,
    area_code_detail: area,
    interior_style: filterData.style,
    add_service: filterData.service,
  });

  const partnerList = useMemo(
    () => (data ? data.pages.flatMap((data) => data.partner_list) : []),
    [data]
  );

  useEffect(() => {
    if (isOpenFilter) {
      setCopyFilterData(filterData);
    }
  }, [isOpenFilter]);

  useEffect(() => {
    if (isOpenFilter) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      width: 100%;
  `;

      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  }, [isOpenFilter]);

  const changeSelectCaseHandler = (
    value: string,
    isCase: boolean,
    filed: 'style' | 'service',
    isMobile?: boolean
  ) => {
    if (isMobile) {
      setCopyFilterData((prevData) => ({
        ...prevData,
        [filed]: isCase
          ? prevData[filed].filter((data) => data !== value)
          : [...prevData[filed], value],
      }));
    } else {
      setFilterData((prevData) => ({
        ...prevData,
        [filed]: isCase
          ? prevData[filed].filter((data) => data !== value)
          : [...prevData[filed], value],
      }));
    }
  };

  const caseInit = (isMobile?: boolean) => {
    if (isMobile) {
      setCopyFilterData({
        style: [],
        service: [],
      });
    }
  };

  const caseApply = () => {
    setFilterData(copyFilterData);
    setIsOpenFilter(false);
  };

  const filterEvent = (type: 'area' | 'style' | 'service', value: boolean) => {
    setIsOpenFilters((prevData) => ({
      ...prevData,
      [type]: value,
    }));
  };

  const changeIsFilterHandler = (type: 'area' | 'style' | 'service') => {
    setIsOpenFilters((prevData) => ({
      ...prevData,
      [type]: !prevData[type],
    }));
  };

  const searchEl = () => (
    <SearchInput>
      {/* 지역 */}
      <div className="area_select_box">
        <button onClick={() => changeIsFilterHandler('area')}>
          {area && areaText ? areaText : '지역선택'}
          <img src={images.down} alt="down_arrow" />
        </button>
        <div
          className="front_search_area_box"
          style={{ display: isOpenFilters.area ? 'flex' : 'none' }}
        >
          <ul className="front_search_area_box_citylist">
            {city_list?.area?.map((area) => {
              return (
                <li
                  key={area.area_id}
                  className={area.area_id === city ? '' : 'active'}
                  onClick={() => {
                    setCity(area.area_id);
                    setAreaText(area.area_name);
                    setArea('');
                  }}
                >
                  {area.area_name}
                </li>
              );
            })}
          </ul>

          <ul className="front_search_area_box_arealist">
            {area_list?.area?.map((x, i) => {
              return (
                <li
                  key={i}
                  className={x.area_detail_id === area ? 'active' : ''}
                  onClick={() => {
                    setArea(x.area_detail_id);
                    setAreaText(x.area_detail_name);
                    setIsOpenFilters((prevData) => ({
                      ...prevData,
                      area: false,
                    }));
                  }}
                >
                  {x.area_detail_name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </SearchInput>
  );

  if (!data) return null;

  return (
    <Container>
      <Banner>
        <h3>
          가장 끌리는 강점을
          <br /> 가진 업체를 골라보세요
        </h3>
        <h2>
          내가 끌리는 강점을
          <br /> 가진 업체를
          <br /> 골라보세요
        </h2>

        <FilterWrap>
          <div className="filter_select_box">
            {/* 지역 */}
            <FilterItem onMouseLeave={() => filterEvent('area', false)}>
              <button onMouseOver={() => filterEvent('area', true)}>
                <p>지역</p>
                <img src={images.down} alt="down" />
              </button>

              <div
                className="area_select"
                style={{ display: isOpenFilters.area ? 'flex' : 'none' }}
              >
                <div className="front_search_area_box">
                  <ul className="front_search_area_box_citylist">
                    {city_list?.area?.map((area) => {
                      return (
                        <li
                          key={area.area_id}
                          className={area.area_id === city ? '' : 'active'}
                          onClick={() => {
                            setCity(area.area_id);
                            setAreaText(area.area_name);
                            setArea('');
                          }}
                        >
                          {area.area_name}
                        </li>
                      );
                    })}
                  </ul>

                  <ul className="front_search_area_box_arealist">
                    {area_list?.area?.map((x, i) => {
                      return (
                        <li
                          key={i}
                          className={x.area_detail_id === area ? 'active' : ''}
                          onClick={() => {
                            setArea(x.area_detail_id);
                            setAreaText(x.area_detail_name);
                            setIsOpenFilters((prevData) => ({
                              ...prevData,
                              area: false,
                            }));
                          }}
                        >
                          {x.area_detail_name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </FilterItem>

            {/* 추구 스타일 */}
            <FilterItem onMouseLeave={() => filterEvent('style', false)}>
              <button onMouseOver={() => filterEvent('style', true)}>
                <p>추구 스타일</p>
                <img src={images.down} alt="down" />
              </button>
              <FilterSelect open={isOpenFilters.style}>
                <div>
                  <div className="select_header">
                    <h3>추구 스타일</h3>
                    <p>중복선택 가능</p>
                  </div>
                  <div className="select_content">
                    {styleList.map((item) => {
                      const isCase = !!filterData.style.find(
                        (style) => style === item.value
                      );

                      return (
                        <button
                          className={isCase ? 'selected' : undefined}
                          key={item.idx}
                          onClick={() =>
                            changeSelectCaseHandler(item.value, isCase, 'style')
                          }
                        >
                          {item.title}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </FilterSelect>
            </FilterItem>

            {/* 제공 서비스 */}
            <FilterItem onMouseLeave={() => filterEvent('service', false)}>
              <button onMouseOver={() => filterEvent('service', true)}>
                <p>제공 서비스</p>
                <img src={images.down} alt="down" />
              </button>
              <FilterSelect open={isOpenFilters.service}>
                <div>
                  <div className="select_header">
                    <h3>제공 서비스</h3>
                    <p>중복선택 가능</p>
                  </div>
                  <div className="select_content">
                    {serviceList.map((item) => {
                      const isCase = !!filterData.service.find(
                        (size) => size === item.value
                      );

                      return (
                        <button
                          className={isCase ? 'selected' : undefined}
                          key={item.idx}
                          onClick={() =>
                            changeSelectCaseHandler(
                              item.value,
                              isCase,
                              'service'
                            )
                          }
                        >
                          {item.title}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </FilterSelect>
            </FilterItem>
          </div>
          {(areaText ||
            filterData.service.length > 0 ||
            filterData.style.length > 0) && (
            <div className="selected_box">
              {areaText && (
                <div className="selected_item">
                  <p>{areaText}</p>
                  <button
                    onClick={() => {
                      setArea('');
                      setAreaText('');
                    }}
                  >
                    x
                  </button>
                </div>
              )}

              {filterData.service.map((item) => (
                <div className="selected_item" key={item}>
                  <p>
                    {serviceList.find((data) => data.value === item)?.title}
                  </p>
                  <button
                    onClick={() =>
                      setFilterData((prevData) => ({
                        ...prevData,
                        service: prevData.service.filter(
                          (service) => service !== item
                        ),
                      }))
                    }
                  >
                    x
                  </button>
                </div>
              ))}

              {filterData.style.map((item) => (
                <div className="selected_item" key={item}>
                  <p>
                    {styleList.find((style) => style.value === item)?.title}
                  </p>
                  <button
                    onClick={() =>
                      setFilterData((prevData) => ({
                        ...prevData,
                        style: prevData.style.filter((style) => style !== item),
                      }))
                    }
                  >
                    x
                  </button>
                </div>
              ))}

              <div className="reset_item_box">
                <button
                  onClick={() => {
                    setFilterData({
                      service: [],
                      style: [],
                    });
                    setArea('');
                    setAreaText('');
                  }}
                >
                  <img src={reset_icon} alt="reset_icon" />
                  초기화
                </button>
              </div>
            </div>
          )}
        </FilterWrap>
        <MobileFilterWrap>
          {isOpenMobileAreaStatus ? (
            <div className="mobile_area_content">
              <button onClick={() => setIsOpenMobileAreaStatus(false)}>
                <GrPrevious />
              </button>
              {searchEl()}
            </div>
          ) : (
            <div className="mobile_filter_content">
              <button onClick={() => setIsOpenFilter(true)}>
                <img src={images.options_icon} /> 조건 필터
              </button>
              <p>|</p>
              <button onClick={() => setIsOpenMobileAreaStatus(true)}>
                지역 검색
              </button>
            </div>
          )}
        </MobileFilterWrap>
      </Banner>
      <AdComponent ads={data.pages[0].ad_list} />
      {partnerList.length > 0 && <List partnerList={partnerList} />}
      {!!hasNextPage && (
        <MoreBtnWrap>
          <button onClick={() => fetchNextPage()}>더보기</button>
        </MoreBtnWrap>
      )}

      {isOpenFilter && (
        <MobileFilterOpenWrap>
          <div className="filter_open_header">
            <h3>조건 필터</h3>
            <button onClick={() => setIsOpenFilter(false)}>
              <img src={images.exit} />
            </button>
          </div>
          <div className="filter_open_content">
            <h3>추구 스타일</h3>
            <div className="select_content">
              {styleList.map((item) => {
                const isCase = !!copyFilterData.style.find(
                  (style) => style === item.value
                );

                return (
                  <button
                    className={isCase ? 'selected' : undefined}
                    key={item.idx}
                    onClick={() =>
                      changeSelectCaseHandler(item.value, isCase, 'style', true)
                    }
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
            <h3>제공 서비스</h3>
            <div className="select_content">
              {serviceList.map((item) => {
                const isService = !!copyFilterData.service.find(
                  (service) => service === item.value
                );

                return (
                  <button
                    className={isService ? 'selected' : undefined}
                    key={item.idx}
                    onClick={() =>
                      changeSelectCaseHandler(
                        item.value,
                        isService,
                        'service',
                        true
                      )
                    }
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="filter_open_footer">
            <button onClick={() => caseInit(true)}>초기화</button>
            <button onClick={caseApply}>적용</button>
          </div>
        </MobileFilterOpenWrap>
      )}
    </Container>
  );
};

export default Partner;
