import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import prev_arrow_circle from '../../Home/images/prev_arrow_circle.png';
import next_arrow_circle from '../../Home/images/next_arrow_circle.png';

import { Content } from 'types/home';
import { Swiper as SwiperType } from 'swiper';

const Container = styled.div`
  max-width: 1200px;
  width: calc(100% - 60px);
  margin-top: 190px;
  border-top: 1px solid #000000;

  & > h1 {
    margin-top: 28px;
    font-size: 36px;
    font-weight: 700;
    line-height: 77px;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    margin: 35px auto 0 auto;

    & > h1 {
      font-size: 17px;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

const FilterWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 25px auto 0 auto;
  gap: 25px 60px;

  & > p {
    font-size: 18px;
    font-weight: 400;
    color: #8f8e8e;
    padding: 0 10px 6px 10px;
    cursor: pointer;
    margin: auto 0;

    &.active {
      color: #000000;
      border-bottom: 2px solid #000000;
      font-size: 22px;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 15px auto 0 auto;

    & > p {
      padding: 0 5px 5px 5px;
      font-size: 11px;

      &.active {
        font-size: 13px;
      }
    }
  }
`;

const ContentWrap = styled.div`
  width: 100%;

  margin: 52px auto 0 auto;

  position: relative;

  &:hover {
    .arrow_wrap {
      display: block;
    }
  }

  .swiper {
    margin: 0 65px;

    .swiper-slide {
      cursor: pointer;

      &:hover {
        & > div > img {
          transform: scale(1.1);
          transition: transform 0.2s;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 22px;
    width: calc(100% + 30px);

    &:hover {
      .arrow_wrap {
        display: none;
      }
    }

    .swiper {
      margin: 0;
    }
  }
`;

const ImageContent = styled.div`
  position: relative;
  padding-bottom: 133%;
  overflow: hidden;

  & > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.2s;
  }
`;

const ArrowWrap = styled.div`
  display: none;

  & > button {
    background: none;
    position: absolute;
    top: 40%;
    z-index: 100;

    &:disabled {
      display: none;
    }

    & > img {
      width: 65px;
      height: 65px;
    }

    &.prev_arrow {
      left: -10px;
    }

    &.next_arrow {
      right: -10px;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const Progressbar = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    margin: 28px 30px 0 auto;

    position: relative;
    align-items: center;
    z-index: 1;

    .front_swiper_pagination {
      background-color: #d9d9d9;
      position: relative;
      height: 2px;

      .swiper-pagination-progressbar-fill {
        opacity: 1 !important;
        background: #3a3a3a;
      }
    }
  }
`;

interface Props {
  contents: Content[];
}

const ReportDetailStyle = (props: Props) => {
  const { contents } = props;

  const [filter, setFilter] = useState(0);

  const [swiper, setSwiper] = useState<SwiperType | null>(null); // swiper 인스턴스를 저장할 상태

  const contentList = useMemo(
    () => contents.flatMap((item) => item.detail) || [],
    [contents]
  );

  const handleSwiper = useCallback((swiperInstance: SwiperType) => {
    setSwiper(swiperInstance);
  }, []);

  const onClickTabHandler = (index: number) => {
    setFilter(index);

    if (swiper) {
      swiper.slideTo(index * 3);
    }
  };

  const onRoute = (url: string) => {
    window.location.href = url;
  };

  if (contentList.length === 0) return null;

  return (
    <Container>
      <h1>좋아하는 스타일을 찾아보세요</h1>
      <FilterWrap>
        {contents.map((item, i) => (
          <p
            key={item.content_table_id}
            className={i === filter ? 'active' : ''}
            onClick={() => onClickTabHandler(i)}
          >
            {item.content_name}
          </p>
        ))}
      </FilterWrap>
      <ContentWrap>
        <Swiper
          slidesPerView={2.3}
          slidesPerGroup={1}
          spaceBetween={15}
          pagination={{
            el: '.front_sw_pagebox .front_swiper_pagination',
            type: 'progressbar',
          }}
          navigation={{
            prevEl: '.front_sw_pagebox .prev_arrow',
            nextEl: '.front_sw_pagebox .next_arrow',
          }}
          breakpoints={{
            768: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 48,
            },
          }}
          onSwiper={handleSwiper} // onSwiper 콜백을 설정하여 swiper 인스턴스를 저장
          onSlideChange={(slide) => {
            const { currentBreakpoint } = slide;
            const slideIndex = slide.realIndex;

            if (currentBreakpoint === '768') {
              const dataIndex = Math.ceil(slideIndex / 3);

              setFilter(dataIndex);
            } else if (currentBreakpoint === 'max') {
              const dataIndex = Math.floor(slideIndex / 3);
              setFilter(dataIndex);
            }
          }}
        >
          {contentList.map((item, i) => (
            <SwiperSlide key={i}>
              <ImageContent onClick={() => onRoute(item.content_detail_url)}>
                <img src={item.content_detail_img} alt="content_detail_img" />
              </ImageContent>
            </SwiperSlide>
          ))}
        </Swiper>
        <ArrowWrap className="front_sw_pagebox arrow_wrap">
          <button className="prev_arrow" disabled={swiper?.isBeginning}>
            <img src={prev_arrow_circle} alt="arrow_left" />
          </button>
          <button className="next_arrow" disabled={swiper?.isEnd}>
            <img src={next_arrow_circle} alt="arrow_right" />
          </button>
        </ArrowWrap>
        <Progressbar className="front_sw_pagebox">
          <div className="front_swiper_pagination"></div>
        </Progressbar>
      </ContentWrap>
    </Container>
  );
};

export default ReportDetailStyle;
